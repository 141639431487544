import { z } from 'zod';

import { ImageModel } from '@/common/models/image.model';

export type IActivityModel = typeof ActivityModel;
export type IActivityModelInstance = z.infer<IActivityModel>;

export const ActivityModel = z.object({
  name: z.string(),
  publicUrl: z.string(),
  description: z.string().optional(),
  images: z.array(ImageModel),
});
