import { css } from '@emotion/react';
import React from 'react';

import { useTheme } from '@/ui/theme';
import { Caption } from '@/ui/typography';

const useBackgroundColor = ({
  isDisabled,
  isHighlighted,
}: {
  isDisabled?: boolean;
  isHighlighted?: boolean;
}): string => {
  const { colors } = useTheme();

  if (isHighlighted) return colors.primary200;
  if (isDisabled) return colors.secondary100;

  return 'transparent';
};

interface WeekCalendarDayButtonProps {
  label: string;
  children: React.ReactNode;
  onClick: () => void;
  width: number;
  isDisabled?: boolean;
  isHighlighted?: boolean;
}

export const WeekCalendarDayButton = ({
  width,
  onClick,
  children,
  label,
  isDisabled,
  isHighlighted,
}: WeekCalendarDayButtonProps) => {
  const { colors } = useTheme();

  const styles = css`
    padding: 2.5px;
    margin: 0;
    align-items: flex-end;
    border: 1px solid ${colors.secondary200};
    width: ${width}px;
    height: ${width}px;
    border-radius: ${width}px;
    box-sizing: border-box;
    text-align: center;
    font-size: 10px;
    color: ${colors.textPrimary};
    background: ${useBackgroundColor({ isDisabled, isHighlighted })};
    z-index: 100;
    position: relative;
    cursor: pointer;
    line-height: 10px;
    position: relative;

    span {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &:focus {
      box-shadow: 0px 0px 1px white;
      border-color: ${colors.textPrimary};
    }

    svg {
      width: ${width - 2 - 10}px;
      height: ${width - 2 - 10}px;
      top: 5px;
      left: 5px;
      position: absolute;
    }
  `;

  return (
    <button role="button" onClick={onClick} aria-label={label} css={styles}>
      {isDisabled ? (
        <svg>
          <line
            x1="0"
            y1="100%"
            x2="100%"
            y2="0"
            style={{ stroke: colors.secondary200, strokeWidth: 1 }}
          />
        </svg>
      ) : (
        <Caption noWrap>{children}</Caption>
      )}
    </button>
  );
};

export default WeekCalendarDayButton;
