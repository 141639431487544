import React, { useEffect, useState } from 'react';

import { AvailableFeatureKeys } from '../constants/available-features.const';
import { FeatureSwitchContext } from '../contexts/feature-switch.context';

interface FeatureSwitchProviderProps {
  children: React.ReactNode;
}

export const FeatureSwitchProvider: React.FC<FeatureSwitchProviderProps> = (
  props
) => {
  const initialFeatures =
    typeof window !== 'undefined' ? window._bootWithFeatures || [] : [];

  const [enabledFeatures, setEnabledFeatures] =
    useState<AvailableFeatureKeys[]>(initialFeatures);

  useEffect(() => {
    window.features = {
      enable(...features: AvailableFeatureKeys[]) {
        setEnabledFeatures((current) => [...current, ...features]);
      },
    };
  }, []);

  const wrappedChildren = (
    <FeatureSwitchContext.Provider
      value={{ enabledFeatures, setEnabledFeatures }}
    >
      {props.children}
    </FeatureSwitchContext.Provider>
  );

  return wrappedChildren;
};
