import { useRouter } from 'next/router';

/**
 * Essentially a useState drop-in replacement, where state is stored in the URL query against the given key.
 * @param key The name of the query parameter to store the state in
 * @returns The current state, and a setter function
 */
export const useUrlQueryState = (key: string) => {
  const router = useRouter();

  const flagState = router.query[key] === 'true';

  const setState = (value: boolean | ((currentValue: boolean) => boolean)) => {
    const url = new URL(window.location.href);

    if (typeof value === 'function') {
      url.searchParams.set(
        key,
        value(router.query[key] === 'true') ? 'true' : 'false'
      );
    } else {
      url.searchParams.set(key, value ? 'true' : 'false');
    }

    return router.push(url, undefined, { shallow: true });
  };

  return [flagState, setState] as const;
};
