import { APIDialect } from '@/api';
import { EnnismoreAPIClientConfig } from '@/api/interfaces';

import { AvailabilityServiceClient } from './common';
import { OHIPAvailabilityServiceClient } from './ohip-availability.service';
import { OWSAvailabilityServiceClient } from './ows-availability.service';

export const getAvailabilityServiceClient = (
  config: EnnismoreAPIClientConfig,
  dialect: APIDialect
): AvailabilityServiceClient => {
  switch (dialect) {
    case 'ohip':
      return new OHIPAvailabilityServiceClient(config);
    case 'ows':
      return new OWSAvailabilityServiceClient(config);
  }
};
