import { BookingGTMStructure, ProductGTMStructure } from '@/analytics/gtm';
import type { BrandConfiguration } from '@/brand';
import type { HotelConfiguration } from '@/hotel';
import { captureException } from '@/logging';

import {
  IAvailabilitySearchQueryInstance,
  availabilitySearchQuerySelectors,
} from '../models/availability-search-query.model';
import { IRoomAvailabilityInstance } from '../models/room-availability.model';

export const formatSearchQueryAsGTMBooking = (
  query: IAvailabilitySearchQueryInstance,
  bookingHotelChainCode: BrandConfiguration['chainCode'],
  hotelConfig: HotelConfiguration
): BookingGTMStructure => {
  const selectors = availabilitySearchQuerySelectors(query);
  return {
    bookingHotelName: hotelConfig.name,
    bookingHotelCode: hotelConfig.code,
    bookingHotelChainCode,
    bookingHotelCity: hotelConfig.location.address.city,
    bookingAdults: selectors.totalAdultCount(),
    bookingChildren: selectors.totalChildCount(),
    bookingInDate: query.from,
    bookingOutDate: query.to,
    bookingNights: selectors.totalNightCount(),
    bookingRooms: selectors.roomCount(),
    bookingCurrency: hotelConfig.payments.currencyCode,
  };
};

const getDisplayPrice = (
  roomAvailability: IRoomAvailabilityInstance,
  selectedRateCode?: string
): string => {
  const stayCost =
    roomAvailability.stayCostForRoomRateOrDefault(selectedRateCode);

  if (!roomAvailability.isRoomAvailable) {
    return '0.00';
  }

  if (stayCost?.costBreakdown?.totalRoomRateWithTaxes) {
    return stayCost?.costBreakdown?.totalRoomRateWithTaxes?.fixedValue;
  }

  const grandTotalValue = stayCost?.grandTotal?.fixedValue;

  if (!grandTotalValue) {
    captureException(
      new Error(
        'Unable to get price for GTM, room is available but has no costBreakdown or grandTotal.'
      )
    );
    return '0.00';
  }

  return grandTotalValue;
};

export const formatRoomAvailabilityAsGTMProduct = (
  roomAvailability: IRoomAvailabilityInstance,
  selectedRateCode?: string
): ProductGTMStructure => {
  return {
    productId: roomAvailability.room.code,
    productType: roomAvailability.room.name,

    // If room is unavailable, use '' for price
    productPrice: getDisplayPrice(roomAvailability, selectedRateCode),
  };
};
