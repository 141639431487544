import { z } from 'zod';

import { DepositModel } from '@/finance/models';

import { ChargeBreakdownModel } from './charge-breakdown.model';

export type IRatePlan = typeof RatePlanModel;
export type IRatePlanSnapshot = z.input<IRatePlan>;
export type IRatePlanInstance = z.infer<IRatePlan>;

export const RatePlanModel = z
  .object({
    rateCode: z.string(),
    rateDescription: z.array(z.string()).default([]),
    rateName: z.string().optional(),
    cancelPolicy: z.string().optional(),
    taxInformation: z.string().optional(),
    depositPolicy: z.string().optional(),
    deposit: DepositModel.optional(),
    chargeBreakdown: ChargeBreakdownModel,
  })
  .transform((self) => ({
    ...self,
    get rateTitle() {
      return (
        self.rateName ?? self.rateDescription[self.rateDescription.length - 1]
      );
    },
  }));
