import { css } from '@emotion/react';
import React from 'react';

import backArrow from '../../resources/svg/left-back-arrow.svg';

interface BackButtonProps {
  onClick: () => void;
}

export const BackButton = (props: BackButtonProps) => {
  return (
    <button
      onClick={props.onClick}
      role="button"
      title="Go back"
      css={css`
        width: 35px;
        height: 16px;
        background: url(${backArrow.src});
        padding: 0;
        border: none;
        touch-action: manipulation;
      `}
      name="back"
      data-test="button-back"
    />
  );
};

export default BackButton;
