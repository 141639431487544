import { useCookie } from 'react-use';

import { COOKIE_KEY_SKIP_REDIRECT } from '../constants';

// Default cookie options. Expires in 1 day, strict same-site rules (don't send cross origin).
const COOKIE_OPTIONS = { expires: 1, sameSite: 'strict' } as const;

/**
 * Reads/writes from a cookie. In future, we'll make this more generic to let authenticated
 * browser sessions override any feature flag they're served.
 */
export const useSkipRedirectSwitch = () => {
  const [cookie, setCookie] = useCookie(COOKIE_KEY_SKIP_REDIRECT);

  return [
    cookie === 'true',
    (value: boolean) => setCookie(value ? 'true' : 'false', COOKIE_OPTIONS),
  ] as const;
};
