import { HotelConfiguration } from '@/hotel';

import {
  selectHotelConfigurationByReferenceId,
  useActiveBrandConfig,
} from '..';

export const useHotelConfigBySlug = (
  hotelSlug: HotelConfiguration['referenceId']
) => {
  const brandConfig = useActiveBrandConfig();
  return selectHotelConfigurationByReferenceId(brandConfig, hotelSlug);
};

export const useHotelConfigBySlugOrFail = (
  hotelSlug: HotelConfiguration['referenceId']
) => {
  const { name } = useActiveBrandConfig();
  const config = useHotelConfigBySlug(hotelSlug);

  if (!config) {
    throw new Error(
      `Hotel configuration not found in brand [${name}] matching slug [${hotelSlug}]`
    );
  }

  return config;
};
