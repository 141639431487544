import { V1Country } from '@ennismore/payment-stripe-api-client';

import { CountryCountry } from '@/em-api-client-typescript-fetch';

/**
 * BookingDayTimePart -> v1CotOrBed
 */
const DOMAIN_TO_BUF_COUNTRY: Record<CountryCountry, V1Country> = {
  [CountryCountry.COUNTRYNOTSET]: V1Country.CountryNotSet,
  [CountryCountry.AF]: V1Country.Af,
  [CountryCountry.AL]: V1Country.Al,
  [CountryCountry.DZ]: V1Country.Dz,
  [CountryCountry.AS]: V1Country.As,
  [CountryCountry.AD]: V1Country.Ad,
  [CountryCountry.AO]: V1Country.Ao,
  [CountryCountry.AI]: V1Country.Ai,
  [CountryCountry.AQ]: V1Country.Aq,
  [CountryCountry.AG]: V1Country.Ag,
  [CountryCountry.AR]: V1Country.Ar,
  [CountryCountry.AM]: V1Country.Am,
  [CountryCountry.AW]: V1Country.Aw,
  [CountryCountry.AU]: V1Country.Au,
  [CountryCountry.AT]: V1Country.At,
  [CountryCountry.AZ]: V1Country.Az,
  [CountryCountry.BS]: V1Country.Bs,
  [CountryCountry.BH]: V1Country.Bh,
  [CountryCountry.BD]: V1Country.Bd,
  [CountryCountry.BB]: V1Country.Bb,
  [CountryCountry.BY]: V1Country.By,
  [CountryCountry.BE]: V1Country.Be,
  [CountryCountry.BZ]: V1Country.Bz,
  [CountryCountry.BJ]: V1Country.Bj,
  [CountryCountry.BM]: V1Country.Bm,
  [CountryCountry.BT]: V1Country.Bt,
  [CountryCountry.BO]: V1Country.Bo,
  [CountryCountry.BQ]: V1Country.Bq,
  [CountryCountry.BA]: V1Country.Ba,
  [CountryCountry.BW]: V1Country.Bw,
  [CountryCountry.BV]: V1Country.Bv,
  [CountryCountry.BR]: V1Country.Br,
  [CountryCountry.IO]: V1Country.Io,
  [CountryCountry.BN]: V1Country.Bn,
  [CountryCountry.BG]: V1Country.Bg,
  [CountryCountry.BF]: V1Country.Bf,
  [CountryCountry.BI]: V1Country.Bi,
  [CountryCountry.KH]: V1Country.Kh,
  [CountryCountry.CM]: V1Country.Cm,
  [CountryCountry.CA]: V1Country.Ca,
  [CountryCountry.CV]: V1Country.Cv,
  [CountryCountry.KY]: V1Country.Ky,
  [CountryCountry.CF]: V1Country.Cf,
  [CountryCountry.TD]: V1Country.Td,
  [CountryCountry.CL]: V1Country.Cl,
  [CountryCountry.CN]: V1Country.Cn,
  [CountryCountry.CX]: V1Country.Cx,
  [CountryCountry.CC]: V1Country.Cc,
  [CountryCountry.CO]: V1Country.Co,
  [CountryCountry.KM]: V1Country.Km,
  [CountryCountry.CG]: V1Country.Cg,
  [CountryCountry.CD]: V1Country.Cd,
  [CountryCountry.CK]: V1Country.Ck,
  [CountryCountry.CR]: V1Country.Cr,
  [CountryCountry.CI]: V1Country.Ci,
  [CountryCountry.HR]: V1Country.Hr,
  [CountryCountry.CU]: V1Country.Cu,
  [CountryCountry.CW]: V1Country.Cw,
  [CountryCountry.CY]: V1Country.Cy,
  [CountryCountry.CZ]: V1Country.Cz,
  [CountryCountry.DK]: V1Country.Dk,
  [CountryCountry.DJ]: V1Country.Dj,
  [CountryCountry.DM]: V1Country.Dm,
  [CountryCountry.DO]: V1Country.Do,
  [CountryCountry.TP]: V1Country.Tp,
  [CountryCountry.EC]: V1Country.Ec,
  [CountryCountry.EG]: V1Country.Eg,
  [CountryCountry.SV]: V1Country.Sv,
  [CountryCountry.GQ]: V1Country.Gq,
  [CountryCountry.ER]: V1Country.Er,
  [CountryCountry.EE]: V1Country.Ee,
  [CountryCountry.ET]: V1Country.Et,
  [CountryCountry.FK]: V1Country.Fk,
  [CountryCountry.FO]: V1Country.Fo,
  [CountryCountry.FJ]: V1Country.Fj,
  [CountryCountry.FI]: V1Country.Fi,
  [CountryCountry.FR]: V1Country.Fr,
  [CountryCountry.FX]: V1Country.Fx,
  [CountryCountry.GF]: V1Country.Gf,
  [CountryCountry.PF]: V1Country.Pf,
  [CountryCountry.TF]: V1Country.Tf,
  [CountryCountry.GA]: V1Country.Ga,
  [CountryCountry.GM]: V1Country.Gm,
  [CountryCountry.GE]: V1Country.Ge,
  [CountryCountry.DE]: V1Country.De,
  [CountryCountry.GH]: V1Country.Gh,
  [CountryCountry.GI]: V1Country.Gi,
  [CountryCountry.GB]: V1Country.Gb,
  [CountryCountry.GR]: V1Country.Gr,
  [CountryCountry.GL]: V1Country.Gl,
  [CountryCountry.GD]: V1Country.Gd,
  [CountryCountry.GP]: V1Country.Gp,
  [CountryCountry.GU]: V1Country.Gu,
  [CountryCountry.GT]: V1Country.Gt,
  [CountryCountry.GN]: V1Country.Gn,
  [CountryCountry.GW]: V1Country.Gw,
  [CountryCountry.GY]: V1Country.Gy,
  [CountryCountry.HT]: V1Country.Ht,
  [CountryCountry.HM]: V1Country.Hm,
  [CountryCountry.HN]: V1Country.Hn,
  [CountryCountry.HK]: V1Country.Hk,
  [CountryCountry.HU]: V1Country.Hu,
  [CountryCountry.IS]: V1Country.Is,
  [CountryCountry.IN]: V1Country.In,
  [CountryCountry.ID]: V1Country.Id,
  [CountryCountry.IR]: V1Country.Ir,
  [CountryCountry.IQ]: V1Country.Iq,
  [CountryCountry.IE]: V1Country.Ie,
  [CountryCountry.IM]: V1Country.Im,
  [CountryCountry.IL]: V1Country.Il,
  [CountryCountry.IT]: V1Country.It,
  [CountryCountry.JM]: V1Country.Jm,
  [CountryCountry.JP]: V1Country.Jp,
  [CountryCountry.JO]: V1Country.Jo,
  [CountryCountry.KZ]: V1Country.Kz,
  [CountryCountry.KE]: V1Country.Ke,
  [CountryCountry.KI]: V1Country.Ki,
  [CountryCountry.KR]: V1Country.Kr,
  [CountryCountry.KP]: V1Country.Kp,
  [CountryCountry.KW]: V1Country.Kw,
  [CountryCountry.KG]: V1Country.Kg,
  [CountryCountry.LA]: V1Country.La,
  [CountryCountry.LV]: V1Country.Lv,
  [CountryCountry.LB]: V1Country.Lb,
  [CountryCountry.LS]: V1Country.Ls,
  [CountryCountry.LR]: V1Country.Lr,
  [CountryCountry.LY]: V1Country.Ly,
  [CountryCountry.LI]: V1Country.Li,
  [CountryCountry.LT]: V1Country.Lt,
  [CountryCountry.LU]: V1Country.Lu,
  [CountryCountry.MO]: V1Country.Mo,
  [CountryCountry.MK]: V1Country.Mk,
  [CountryCountry.MG]: V1Country.Mg,
  [CountryCountry.MW]: V1Country.Mw,
  [CountryCountry.MY]: V1Country.My,
  [CountryCountry.MV]: V1Country.Mv,
  [CountryCountry.ML]: V1Country.Ml,
  [CountryCountry.MT]: V1Country.Mt,
  [CountryCountry.MH]: V1Country.Mh,
  [CountryCountry.MQ]: V1Country.Mq,
  [CountryCountry.MR]: V1Country.Mr,
  [CountryCountry.MU]: V1Country.Mu,
  [CountryCountry.YT]: V1Country.Yt,
  [CountryCountry.MX]: V1Country.Mx,
  [CountryCountry.FM]: V1Country.Fm,
  [CountryCountry.MI]: V1Country.Mi,
  [CountryCountry.MD]: V1Country.Md,
  [CountryCountry.MC]: V1Country.Mc,
  [CountryCountry.MN]: V1Country.Mn,
  [CountryCountry.ME]: V1Country.Me,
  [CountryCountry.MS]: V1Country.Ms,
  [CountryCountry.MA]: V1Country.Ma,
  [CountryCountry.MZ]: V1Country.Mz,
  [CountryCountry.MM]: V1Country.Mm,
  [CountryCountry.NA]: V1Country.Na,
  [CountryCountry.NR]: V1Country.Nr,
  [CountryCountry.NP]: V1Country.Np,
  [CountryCountry.NL]: V1Country.Nl,
  [CountryCountry.AN]: V1Country.An,
  [CountryCountry.NC]: V1Country.Nc,
  [CountryCountry.NZ]: V1Country.Nz,
  [CountryCountry.NI]: V1Country.Ni,
  [CountryCountry.NE]: V1Country.Ne,
  [CountryCountry.NG]: V1Country.Ng,
  [CountryCountry.NU]: V1Country.Nu,
  [CountryCountry.NF]: V1Country.Nf,
  [CountryCountry.MP]: V1Country.Mp,
  [CountryCountry.NO]: V1Country.No,
  [CountryCountry.OM]: V1Country.Om,
  [CountryCountry.PK]: V1Country.Pk,
  [CountryCountry.PW]: V1Country.Pw,
  [CountryCountry.PA]: V1Country.Pa,
  [CountryCountry.PG]: V1Country.Pg,
  [CountryCountry.PY]: V1Country.Py,
  [CountryCountry.PE]: V1Country.Pe,
  [CountryCountry.PH]: V1Country.Ph,
  [CountryCountry.PN]: V1Country.Pn,
  [CountryCountry.PL]: V1Country.Pl,
  [CountryCountry.PT]: V1Country.Pt,
  [CountryCountry.PR]: V1Country.Pr,
  [CountryCountry.QA]: V1Country.Qa,
  [CountryCountry.RE]: V1Country.Re,
  [CountryCountry.RO]: V1Country.Ro,
  [CountryCountry.RU]: V1Country.Ru,
  [CountryCountry.RQ]: V1Country.Rq,
  [CountryCountry.RW]: V1Country.Rw,
  [CountryCountry.SH]: V1Country.Sh,
  [CountryCountry.KN]: V1Country.Kn,
  [CountryCountry.LC]: V1Country.Lc,
  [CountryCountry.PM]: V1Country.Pm,
  [CountryCountry.VC]: V1Country.Vc,
  [CountryCountry.WS]: V1Country.Ws,
  [CountryCountry.SM]: V1Country.Sm,
  [CountryCountry.ST]: V1Country.St,
  [CountryCountry.SA]: V1Country.Sa,
  [CountryCountry.SN]: V1Country.Sn,
  [CountryCountry.RS]: V1Country.Rs,
  [CountryCountry.SC]: V1Country.Sc,
  [CountryCountry.SL]: V1Country.Sl,
  [CountryCountry.SG]: V1Country.Sg,
  [CountryCountry.SX]: V1Country.Sx,
  [CountryCountry.SK]: V1Country.Sk,
  [CountryCountry.SI]: V1Country.Si,
  [CountryCountry.SB]: V1Country.Sb,
  [CountryCountry.SO]: V1Country.So,
  [CountryCountry.ZA]: V1Country.Za,
  [CountryCountry.GS]: V1Country.Gs,
  [CountryCountry.SS]: V1Country.Ss,
  [CountryCountry.ES]: V1Country.Es,
  [CountryCountry.LK]: V1Country.Lk,
  [CountryCountry.SD]: V1Country.Sd,
  [CountryCountry.SR]: V1Country.Sr,
  [CountryCountry.SJ]: V1Country.Sj,
  [CountryCountry.SZ]: V1Country.Sz,
  [CountryCountry.SE]: V1Country.Se,
  [CountryCountry.CH]: V1Country.Ch,
  [CountryCountry.SY]: V1Country.Sy,
  [CountryCountry.TW]: V1Country.Tw,
  [CountryCountry.TJ]: V1Country.Tj,
  [CountryCountry.TZ]: V1Country.Tz,
  [CountryCountry.TH]: V1Country.Th,
  [CountryCountry.TG]: V1Country.Tg,
  [CountryCountry.TK]: V1Country.Tk,
  [CountryCountry.TO]: V1Country.To,
  [CountryCountry.TT]: V1Country.Tt,
  [CountryCountry.TN]: V1Country.Tn,
  [CountryCountry.TR]: V1Country.Tr,
  [CountryCountry.TM]: V1Country.Tm,
  [CountryCountry.TC]: V1Country.Tc,
  [CountryCountry.TV]: V1Country.Tv,
  [CountryCountry.UK]: V1Country.Uk,
  [CountryCountry.UG]: V1Country.Ug,
  [CountryCountry.UA]: V1Country.Ua,
  [CountryCountry.AE]: V1Country.Ae,
  [CountryCountry.US]: V1Country.Us,
  [CountryCountry.UM]: V1Country.Um,
  [CountryCountry.UY]: V1Country.Uy,
  [CountryCountry.UZ]: V1Country.Uz,
  [CountryCountry.VU]: V1Country.Vu,
  [CountryCountry.VA]: V1Country.Va,
  [CountryCountry.VE]: V1Country.Ve,
  [CountryCountry.VN]: V1Country.Vn,
  [CountryCountry.VG]: V1Country.Vg,
  [CountryCountry.VI]: V1Country.Vi,
  [CountryCountry.WF]: V1Country.Wf,
  [CountryCountry.EH]: V1Country.Eh,
  [CountryCountry.YE]: V1Country.Ye,
  [CountryCountry.ZR]: V1Country.Zr,
  [CountryCountry.ZM]: V1Country.Zm,
  [CountryCountry.ZW]: V1Country.Zw,
};

export const mapDomainToBufCountry = (state: CountryCountry): V1Country =>
  DOMAIN_TO_BUF_COUNTRY[state];

export const mapBufToDomainCountry = (dayTimePart: V1Country): CountryCountry =>
  Object.keys(DOMAIN_TO_BUF_COUNTRY).find(
    (key) => DOMAIN_TO_BUF_COUNTRY[key] === dayTimePart
  ) as unknown as CountryCountry;
