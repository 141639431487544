import type { IAvailabilitySearchQueryInstance } from '../models';
import { useMaximumGuestsPerRoomCount } from './use-maximum-guests-per-room-count.hook';

/**
 * Checks if any of room configurations within the search URL query
 * is exceeding guests per room limit.
 */
export const useIsExceedingGuestsPerRoomLimit = (
  query: IAvailabilitySearchQueryInstance | undefined,
  hotelReferenceId: string | undefined
) => {
  const guestsPerRoomLimit = useMaximumGuestsPerRoomCount(hotelReferenceId);

  if (!query) return false;

  return query.rooms.some(
    (room) => guestsPerRoomLimit < room.adults + room.children
  );
};
