import { z } from 'zod';

import { YesNo, YesNoEnum } from '@/common/models';
import {
  FinancialAmountModel,
  IFinancialAmountInstance,
} from '@/finance/models/financial-amount.model';
import { RateCodeModel } from '@/finance/models/rate-code.model';
import { TaxModel } from '@/finance/models/tax.model';

import { CostBreakdownModel } from './cost-breakdown.model';

export type IChargeBreakdown = typeof ChargeBreakdownModel;
export interface IChargeBreakdownInstance extends z.infer<IChargeBreakdown> {}
export type IChargeBreakdownSnapshot = z.input<IChargeBreakdown>;

export const ChargeBreakdownModel = z
  .object({
    rateCode: RateCodeModel,
    // taxIncluded: YesNo.default(YesNoEnum.NO),
    defaultRate: YesNo.default(YesNoEnum.NO),
    // The total cost per room. Tax is included if taxIncluded is true (for example, VAT).
    // This is the amount also included in the calendar object.
    totalRoomRate: FinancialAmountModel.optional(),
    // The final cost of the booking (cost of room plus all taxes).
    grandTotal: FinancialAmountModel.optional(),
    // List of Taxes that must be applied in the booking.
    // This is used to render the popup with the breakdown of the taxes.
    taxesFees: z.array(TaxModel).default([]),
    charity: FinancialAmountModel.optional(),

    costBreakdown: CostBreakdownModel.optional(),

    // How much money will we take at checkout?
    deposit: FinancialAmountModel.optional(),
    isCalendarAvailable: z.boolean().default(false),
    averageNightlyRate: FinancialAmountModel.optional(),
    costDetail: z
      .array(
        z.object({
          cost: z.object({
            amountBeforeTax: FinancialAmountModel,
            amountAfterTax: FinancialAmountModel,
            amountSubtotalDisplay: FinancialAmountModel,
          }),
          date: z.string(),
        })
      )
      .optional(),
    containsFees: z.boolean().default(false),
  })
  .transform((self) => ({
    ...self,
    /**
     * Returns a renderable total price (eg. in the room search result card). Whether to include VAT is defined at the hotel level.
     * @param displayWithVAT does this hotel render it's prices inclusive of VAT?
     */
    getDisplayableTotalRoomRate(
      displayWithVAT: boolean
    ): IFinancialAmountInstance | undefined {
      if (!self.costBreakdown) {
        return self.totalRoomRate || self.grandTotal;
      }

      return displayWithVAT
        ? self.costBreakdown.totalRoomRateWithVAT
        : self.costBreakdown.totalRoomRate;
    },
  }));
