import { css } from '@emotion/react';
import { animated, useTransition } from '@react-spring/web';
import React from 'react';

import { useTheme } from '@/ui/theme';

interface SearchFormCardProps {
  isOpen: boolean;
  children: React.ReactNode;
  className?: string;
}

export const SearchFormCard = ({
  isOpen,
  children,
  className,
}: SearchFormCardProps) => {
  const { colors } = useTheme();
  const transition = useTransition(isOpen, {
    from: {
      opacity: 0,
      transform: 'translateY(-40px)',
    },
    enter: {
      opacity: 1,
      transform: 'translateY(0px)',
    },
    leave: {
      opacity: 0,
      transform: 'translateY(-40px)',
    },
  });

  return transition(
    (styles, item) =>
      item && (
        <animated.div
          className={className}
          style={styles}
          css={css`
            background-color: ${colors.white};
            padding: 32px;

            display: block;
            position: absolute;
            width: 100%;
            z-index: 290;

            @media all and (max-width: 730px) {
              padding: 27px;
            }
          `}
        >
          {children}
        </animated.div>
      )
  );
};
