import React from 'react';

import {
  useCurrencyPreview,
  usePreferredCurrencyValue,
  usePreviewingAlternativeCurrency,
} from '@/currency-preview';
import type { IFinancialAmountInstance } from '@/finance';
import { getLocalisedCurrencySymbol } from '@/finance/utils/formatting';
import { useLocale, useTranslation } from '@/i18n';
import { Spacer } from '@/ui/spacing';
import { BodySmall } from '@/ui/typography';

export const AlternativeCurrencyCheckoutWarning = ({
  total,
}: {
  total?: IFinancialAmountInstance;
}) => {
  const { t } = useTranslation('bookingSummary');
  const locale = useLocale();
  const isPreviewingAlternativeCurrency = usePreviewingAlternativeCurrency();
  const preferredCurrency = usePreferredCurrencyValue();

  const localisedTotal = useCurrencyPreview({
    amount: total,
    preventConversion: true,
  });

  if (!isPreviewingAlternativeCurrency || !preferredCurrency) return null;

  return (
    <>
      <Spacer s="xxs" />
      <BodySmall>
        {t('alternativeCurrencyWarning.checkout', {
          currencySymbol: getLocalisedCurrencySymbol(
            locale.baseName,
            preferredCurrency
          ),
          total: localisedTotal,
        })}
      </BodySmall>
      <Spacer s="xxs" />
    </>
  );
};
