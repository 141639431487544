import { z } from 'zod';

import { RoomAddOnsModel } from '@/booking-add-ons/models';
import { ImageModel } from '@/common/models/image.model';
import { FinancialAmountModel } from '@/finance/models';

export type IBookingRoomDetailSnapshot = z.input<typeof BookingRoomDetail>;

export const BookingRoomDetail = z
  .object({
    name: z.string(),
    code: z.string(),
    rateCode: z.string(),
    rateCost: FinancialAmountModel,
    currencyCode: z.string(),
    images: z.array(ImageModel),
    addOns: RoomAddOnsModel.optional(),
  })
  .transform((self) => ({
    ...self,
    get addOnsDetail() {
      return self.addOns?.detail;
    },
  }));
