import { css } from '@emotion/react';
import { motion } from 'framer-motion';
import React, { CSSProperties } from 'react';

import { AvailableSpaceKeyType, useSpaceMatchingKey, useTheme } from '../theme';

export const Card = (props: CardProps) => {
  const space = useSpaceMatchingKey(props.p || 's');
  const { colors, componentProperties, keylines } = useTheme();

  const border =
    props.border ||
    componentProperties.cards?.primary?.border ||
    keylines?.border;

  const background =
    componentProperties.cards?.primary?.backgroundColor ??
    props.backgroundColor ??
    colors.white;

  return (
    <motion.div
      className={props.className}
      css={css`
        padding: ${space};
        background: ${background};
        span,
        input,
        a,
        .select-spectre {
          ${componentProperties.cards?.primary?.color &&
          `color: ${componentProperties.cards?.primary.color};`}
        }
        ${props.rounded && 'border-radius: 4px;'}
        ${props.maxWidth ? `max-width: ${props.maxWidth};` : ''}
        ${props.shadow ? 'box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.3);' : ''}
        ${props.minWidth ? `min-width: ${props.minWidth};` : ''}
        ${border && `border: ${border};`}
      `}
      layout={Boolean(props.layoutId)}
      layoutId={props.layoutId}
      data-test={props.testId}
    >
      {props.children}
    </motion.div>
  );
};

interface CardProps {
  className?: string;
  children: React.ReactNode;
  rounded?: boolean;
  shadow?: boolean;
  border?: CSSProperties['border'];
  maxWidth?: string;
  minWidth?: string;
  layoutId?: string;
  p?: AvailableSpaceKeyType;
  testId?: string;
  backgroundColor?: CSSProperties['backgroundColor'];
}
