import { z } from 'zod';

import { YesNo } from '@/common/models';

import { HotelRoom } from './hotel-room.model';
import { Occupancy } from './occupancy.model';

export type IRoomOccupancy = typeof RoomOccupancy;
export type IRoomOccupancyInstance = z.infer<IRoomOccupancy>;
export type IRoomOccupancySnapshot = z.input<IRoomOccupancy>;

/**
 * RoomOccupancy
 *
 * This model represents the occupation of a hotel a room. It is used when booking rooms rather than searching.
 */
export const RoomOccupancy = z.object({
  occupancy: Occupancy,
  room: HotelRoom,
  twin: YesNo,
  rateCode: z.string(),
  internalRateCode: z.string(),
});
