import type { EnnismoreTheme } from '@/ui/theme';

const fonts = {
  headline: 'nimbusSans, sans-serif',
  intro: 'tiemposFineLight, Times',
  subheader: 'gtAmerica, sans-serif',
  body: 'nimbusSans, sans-serif',
};

export const hydeTheme: EnnismoreTheme = {
  themeKey: 'hyde',
  logoUrl: '/brands/hyde/images/logo.svg',
  faviconUrl: '/brands/hyde/favicons/favicon.webp',
  space: ['0px', '8px', '16px', '20px', '24px', '32px', '40px', '56px'],
  fonts,
  text: {
    heading1: {
      fontFamily: fonts.headline,
      fontWeight: 200,
      fontSize: '32px',
      lineHeight: '44px',
      textTransform: 'uppercase',
    },
    heading2: {
      fontFamily: fonts.headline,
      fontSize: '56px',
      lineHeight: '64px',
    },
    heading3: {
      fontFamily: fonts.headline,
      fontSize: '40px',
      lineHeight: '48px',
    },
    heading4: {
      fontFamily: fonts.headline,
      fontSize: '32px',
      lineHeight: '40px',
    },
    heading5: {
      fontFamily: 'cheltenhamCondensed',
      fontSize: '26px',
      lineHeight: '32px',
    },
    heading6: {
      fontFamily: 'cheltenhamCondensed',
      fontSize: '24px',
      lineHeight: '30px',
    },
    intro1: {
      fontFamily: fonts.intro,
      fontSize: '40px',
      lineHeight: '56px',
    },
    intro2: {
      fontFamily: fonts.intro,
      fontSize: '24px',
      lineHeight: '40px',
    },
    subheader1: {
      fontFamily: fonts.subheader,
      fontSize: '24px',
      lineHeight: '32px',
    },
    subheader2: {
      fontFamily: fonts.subheader,
      fontSize: '18px',
      lineHeight: '32px',
    },
    subheader3: {
      fontFamily: fonts.body,
      fontSize: '16px',
      lineHeight: '24px',
      textTransform: 'uppercase',
    },
    bodyLarge: {
      fontFamily: fonts.body,
      fontSize: '20px',
      lineHeight: '32px',
    },
    bodyCopy: {
      fontFamily: fonts.body,
      fontSize: '16px',
      lineHeight: '24px',
    },
    bodySmall: {
      fontFamily: fonts.body,
      fontSize: '14px',
      lineHeight: '20px',
    },
    captions: {
      fontFamily: fonts.body,
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  colors: {
    white: '#fff',
    textPrimary: '#264540',
    textSecondary: '#264540',
    background: 'linear-gradient(180deg, #9CEDA8 10.42%, #C4E3E5 100%)',
    primary100: '#FEF9F3',
    primary200: '#FEF2E3',
    primary300: '#264540',
    primary400: '#FFE7D9',
    primary500: '#FF9272',
    secondary100: '#F6F6F6',
    secondary200: '#D9D9D9',
    secondary300: '#626865',
    secondary400: '#3B423F',
    error: '#B00020',
  },
  buttons: {
    primary: {
      fontFamily: fonts.body,
      backgroundColor: '#264540',
      borderRadius: '100px',
      textTransform: 'uppercase',
      transition: 'background-color .3s ease-in, color .3s ease-in',
      letterSpacing: '.125rem',
      border: '2px solid #264540',
      ':hover': {
        backgroundColor: '#fff',
        color: '#264540',
      },
    },
    secondary: {
      fontFamily: fonts.body,
      backgroundColor: 'transparent',
      borderRadius: '100px',
      textTransform: 'uppercase',
      letterSpacing: '.125rem',
      color: '#264540',
      border: '1px solid #264540',
    },
  },
  forms: {
    label: {
      fontSize: '16px',
    },
    input: {
      backgroundColor: 'white',
      color: 'black',
      fontSize: '16px',
      border: '1px solid #949494',
    },
    radioButton: {
      selected: {
        backgroundColor: 'white',
      },
      description: {
        color: '#264540',
      },
    },
    stripe: {
      tab: {
        backgroundColor: 'white',
      },
    },
  },
  fontFaceDefinitions: [
    {
      fontFamily: 'cheltenhamCondensed',
      fontWeight: 200,
      sources: {
        woff2:
          '/brands/hyde/fonts/CheltenhamCondensed/CheltenhamCondensed.woff2',
      },
    },
    {
      fontFamily: 'nimbusSans',
      fontWeight: 200,
      sources: {
        woff2: '/brands/hyde/fonts/NimbusSans/NimbusSans.woff2',
      },
    },
  ],
  keylines: {
    border: '1px solid #D9D9D9',
  },
  componentProperties: {
    search: {
      results: {
        sidebarContainer: {
          cancellationPolicy: {
            mobile: {
              textTransform: 'uppercase',
            },
          },
          hotelPerks: {
            border: 0,
            mobile: {
              button: {
                textTransform: 'uppercase',
              },
            },
          },
        },
      },
      collapsibleForm: {
        searchSummary: {
          fontFamily: fonts.body,
          expanded: {
            borderRadius: '6px 6px 0 0',
          },
          modifySearchButton: {
            textTransform: 'uppercase',
          },
        },
        searchForm: {
          borderRadius: '0px 0px 6px 6px',
        },
      },
    },
    header: {
      backgroundColor: '#9CEDA8',
      borderBottom: '1px solid #264540',
      logo: {
        borderRight: 'none',
        left: '65px',
      },
      height: 80, // default 60px + 10px 0 padding
      wizard: {
        step: {
          textTransform: 'uppercase',
          active: {
            fontWeight: 600,
          },
        },
      },
    },
    sidebarCards: {
      primary: {
        borderRadius: '0',
        backgroundColor: 'white',
        borderTop: 'none',
        borderBottom: 'none',
        borderLeft: 'none',
        borderRight: 'none',
      },
      secondary: {
        borderRadius: '6px',
        backgroundColor: 'transparent',
        borderColor: 'primary300',
        borderTop: '1px solid #264540',
        borderBottom: '1px solid #264540',
        borderLeft: '1px solid #264540',
        borderRight: '1px solid #264540',
      },
    },
    cards: {
      primary: {
        borderRadius: '6px',
        mobile: {
          border: 'none',
        },
      },
    },
    bedroomCard: {
      roomTitle: {
        color: '#4F5273',
      },
      roomRateName: {
        color: '#264540',
        textTransform: 'uppercase',
        fontFamily: fonts.body,
        fontSize: '16px',
      },
      roomSpecItem: {
        color: '#989898',
        value: {
          color: '#264540',
        },
      },
    },
    halfScreenLayout: {
      borderRight: 'none',
    },
    checkoutSidebar: {
      border: 'none',
      cancellationPolicy: {
        title: {
          fontFamily: fonts.body,
          fontSize: '18px',
          lineHeight: '30px',
        },
      },
    },
    staySummary: {
      color: '#264540',
      borderRadius: 0,
      dataRow: {
        label: {
          opacity: 1,
          fontSize: '16px',
        },
        value: {
          fontSize: '16px',
          fontWeight: 600,
        },
      },
      title: {
        padding: '18px 0 18px',
        fontSize: '24px',
        mobile: {
          fontSize: '18px',
        },
      },
      dropdown: {
        textTransform: 'uppercase',
      },
    },
    footer: {
      divider: { borderColor: '#264540' },
    },
    calendar: {
      header: {
        backgroundColor: '#FAF2ED',
      },
    },
    page: {
      background: 'linear-gradient(180deg, #9CEDA8 10.42%, #C4E3E5 100%)',
    },
    confirmation: {
      title: {
        fontSize: '32px',
        textTransform: 'uppercase',
        mobile: {
          fontSize: '26px',
        },
      },
      header: {
        backgroundColor: 'transparent',
      },
      headerImage: {
        height: '480px',
        objectFit: 'cover',
        container: {
          margin: '0',
          width: '100%',
          paddingBottom: '48px',
        },
      },
    },
    consentInputs: {
      divider: { borderColor: '#264540' },
    },
  },
  icons: {},
  __temporary: {
    displayCarTransportOnNewLine: true,
  },
};
