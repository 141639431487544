/**
 * This file contains Zod representations of the latest Booking API schema.
 * These are here for reference at the moment - will be used to help identify
 * our mapping strategy between OWS-derived contract and the new Booking API V1 contract.
 */
import { V1DiscountCodeType } from '@ennismore/booking-api-client';
import { z } from 'zod';

export const v1DiscountCodeTypeSchema = z.nativeEnum(V1DiscountCodeType);

export const v2BookingStatusSchema = z.enum([
  'NONE',
  'CREATED',
  'CONFIRMED',
  'EXPIRED',
  'CANCELLED',
  'PAYMENT_ERROR',
  'AMENDED',
  'PAYMENT_RECEIVED',
  'UNCANCELLABLE',
  'CANCEL_RECEIVED',
  'CANCELLED_BY_USER',
  'CANCELLED_BY_PMS',
  'COMPLETED',
]);
export type V2BookingStatus = z.infer<typeof v2BookingStatusSchema>;

export const v1AmountSchema = z.object({
  value: z.number().default(0),
  decimal: z.number(),
  currencyCode: z.string(),
  description: z.string().optional(),
  approximateCost: z.boolean().optional(),
  code: z.string().optional(),
});

export const v1GuestDetailSchema = z.object({
  adults: z.number(),
  children: z.number().optional(),
  childrenAges: z.array(z.number()).optional(),
});

export const v1RoomExtrasSchema = z.object({
  typeId: z.string(),
  type: z.string(),
  text: z.string(),
});

export const v1RoomImagesSchema = z.object({
  typeId: z.string(),
  url: z.string(),
  altText: z.string(),
});

export const v1PolicySchema = z.object({
  code: z.string(),
  description: z.string(),
});

export const v1ContactDetailsSchema = z.object({
  phoneNumber: z.string(),
  emailAddress: z.string(),
});

export const bookingApiv1AddressSchema = z.object({
  addressLine: z.array(z.string()),
  city: z.string(),
  state: z.string().optional(), // placeholder default
  country: z.string(),
  postcode: z.string(),
});

export const bookingApiv1FlexyTimeSchema = z.object({
  checkInStart: z.string().optional(), // placeholder default - the whole parent object should be optional
  checkInEnd: z.string().optional(), // placeholder default - the whole parent object should be optional
  checkOutStart: z.string().optional(), // placeholder default - the whole parent object should be optional
  checkOutEnd: z.string().optional(), // placeholder default - the whole parent object should be optional
});

export const v1AdditionalInformationSchema = z.object({
  flexyTime: bookingApiv1FlexyTimeSchema.optional(),
  flexyTimeAvailable: z.boolean().default(false),
  specialRequests: z.string().optional(),
  accessibleRoom: z.boolean().optional(),
});

export const v1LocaleSchema = z.object({
  language: z.string(),
});

export const v1RoomFilterSchema = z.object({
  accessible: z.boolean().default(false),
});

export const v1RoomDetailSchema = z.object({
  code: z.string(),
  name: z.string(),
  strapline: z.string().default(''), // placeholder default
  description: z.string().default(''), // placeholder default
  images: z.array(v1RoomImagesSchema).default([]), // placeholder default
  extras: z.array(v1RoomExtrasSchema).default([]), // placeholder default
  equivalentNonAccessibleRoomCode: z.string().optional(),
  filters: v1RoomFilterSchema.default({}),
});

export const v1TaxDetailSchema = z.object({
  code: z.string(),
  name: z.string().optional(),
  description: z.string().default(''), // placeholder default
  amount: v1AmountSchema,
});

export const v1BookingRoomSchema = z.object({
  checkInDate: z.string(),
  checkOutDate: z.string(),
  roomCode: z.string(),
  rateCode: z.string(),
  guests: v1GuestDetailSchema,
  numberOfRooms: z.number(),
});
export type V1BookingRoom = z.infer<typeof v1BookingRoomSchema>;

export const v1DepositSchema = z.object({
  amount: v1AmountSchema,
});

export const v1GetBookingRequestSchema = z.object({
  hotelReferenceId: z.string(),
  locale: v1LocaleSchema,
  bookingId: z.string(),
});

export const v1GuestProfileSchema = z.object({
  title: z.string().optional(), // placeholder default - made optional
  firstName: z.string(),
  lastName: z.string(),
  address: bookingApiv1AddressSchema,
  contactDetails: v1ContactDetailsSchema,
});

export const v1GetAvailabilityRequestSchema = z.object({
  hotelReferenceId: z.string(),
  locale: v1LocaleSchema,
  checkInDate: z.string(),
  checkOutDate: z.string(),
  guests: v1GuestDetailSchema,
  numberOfRooms: z.number(),
  roomCodes: z.array(z.string()).optional(),
  rateCodes: z.array(z.string()).optional(),
  discountCode: z.string().optional(),
  discountCodeType: v1DiscountCodeTypeSchema.optional(),
});

export const v1TaxSchema = z.object({
  detail: z.array(v1TaxDetailSchema).default([]), // placeholder default
  amount: v1AmountSchema,
});

export const v1CostSchema = z.object({
  tax: v1TaxSchema.optional(), // placeholder default
  amountBeforeTax: v1AmountSchema,
  amountAfterTax: v1AmountSchema,
  amountSubtotalDisplay: v1AmountSchema,
});

export const v1CostDetailSchema = z.object({
  cost: v1CostSchema,
  date: z.string(),
});

export const v1CreateBookingRequestSchema = z.object({
  hotelReferenceId: z.string(),
  rooms: z.array(v1BookingRoomSchema),
});

export const v1RoomRateSchema = z.object({
  code: z.string(),
  name: z.string().default(''), // placeholder default
  description: z.string().default(''), // placeholder default
  cost: v1CostSchema,
  costDetail: z.array(v1CostDetailSchema).optional(),
  depositRequired: z.boolean(),
  deposit: v1DepositSchema.optional(),
  averageNightlyAmountAfterTax: v1AmountSchema.optional(),
  containsFees: z.boolean().default(false),
});

export const v1AddOnSchema = z.object({
  id: z.string(),
  displayType: z.enum([
    'ADD_ON_DISPLAY_TYPE_UNKNOWN',
    'ADD_ON_DISPLAY_TYPE_TOGGLE',
    'ADD_ON_DISPLAY_TYPE_ROCKER',
  ]),
  image: z.object({ url: z.string(), altText: z.string() }),
  name: z.string(),
  description: z.string().default(''),
  priceContent: z.string().optional(),
  controlLabel: z.string(),
  requestedQuantity: z.number().default(0),
  calculatedQuantity: z.number().default(0),
  cost: v1CostSchema,
});

export const v1ReservedRoomSchema = z.object({
  pmsId: z.string(),
  room: v1RoomDetailSchema,
  checkInDate: z.string(),
  checkOutDate: z.string(),
  rate: v1RoomRateSchema,
  guestProfile: v1GuestProfileSchema,
  guests: v1GuestDetailSchema,
  additionalInformation: v1AdditionalInformationSchema.optional(),
  addOns: z.object({
    detail: z.array(v1AddOnSchema).default([]),
    cost: v1CostSchema.optional(),
  }),
});

export const v1GetAvailableAddOnsResponseSchema = z.object({
  addOns: z.array(v1AddOnSchema),
});

export type V1AddOns = z.infer<typeof v1GetAvailableAddOnsResponseSchema>;
export type V1AddOn = V1AddOns['addOns'][number];

export const v1UpdateBookingAddOnsResponseSchema = z.object({
  status: z.string(),
});

export const v1AvailabilityRoomSchema = z.object({
  room: v1RoomDetailSchema,
  rates: z.array(v1RoomRateSchema),
});

export const v1GetBookingResponseSchema = z.object({
  bookingId: z.string(),
  status: v2BookingStatusSchema,
  rooms: z.array(v1ReservedRoomSchema),
  totalCost: v1CostSchema,
  costConfirmed: z.boolean().default(false),
  totalDeposit: z
    .object({
      amount: v1AmountSchema.optional(),
      policies: z.array(v1PolicySchema).default([]),
    })
    .optional(),
  payment: z.object({
    method: z.enum(['PAY_NOW', 'PAY_LATER']),
    clientSecret: z.string(),
    connectedAccountId: z.string().optional(),
  }),
});

export const v1GetAvailabilityResponseSchema = z.object({
  rooms: z.array(v1AvailabilityRoomSchema).default([]), // placeholder default
});

export const v1RoomOccupancySchema = z.object({
  adults: z.number().default(0),
  children: z.number().default(0),
  childrenAges: z.array(z.number().nonnegative()).default([]),
}); // placeholder new
export type V1RoomOccupancySchema = z.infer<typeof v1RoomOccupancySchema>;
