import {
  BookingApiServiceApi,
  Configuration,
} from '@ennismore/booking-api-client';
import type { AxiosError } from 'axios';
import { ZodError } from 'zod';

import { axios } from '@/api/axios-instance';
import { EnnismoreAPIClientConfig } from '@/api/interfaces';

import { parseOHIPAxiosError } from './errors';

/**
 * Parent of all Booking API services.
 * Handles setting up of client + common request options.
 */
export abstract class OHIPBookingAPIBaseService {
  protected client: BookingApiServiceApi;

  protected get options() {
    return {
      headers: {
        'X-Api-Key': this.credentials.apiKey,
        'X-Skip-Redirect': this.credentials.skipRedirect,
      },
    };
  }

  constructor(private readonly credentials: EnnismoreAPIClientConfig) {
    // OHIP Booking API is the same URL as OWS, minus the version number.
    // We should flip this behaviour when we switch to OHIP-dominant approach.
    const config = new Configuration({
      basePath: credentials.baseUrl.replace('/v2', ''),
    });

    this.client = new BookingApiServiceApi(config, undefined, axios);
  }

  protected parseError(error: AxiosError<unknown> | ZodError) {
    return parseOHIPAxiosError(error);
  }
}
