import Router from 'next/router';

import { BookingRequest } from '../interfaces';

export type CheckoutStep =
  | 'search'
  | 'additional-information'
  | 'payment'
  | 'pending'
  | 'expired'
  | 'confirmation'
  | 'one-click';

const scrollToTop = (value: boolean) => {
  window.scrollTo(0, 0);
  return value;
};

export const getBookingUrl = (
  args: {
    step: CheckoutStep;
    locale?: string;
  } & BookingRequest
) => {
  const locale = args.locale ? `/${args.locale}` : '';
  return `${locale.toLowerCase()}/${args.hotelSlug}/bookings/${
    args.bookingId
  }/${args.step}`;
};

export const navigateToBookingAdditionalInformationPage = (
  args: BookingRequest
) => {
  Router.push(
    '/[hotelSlug]/bookings/[id]/additional-information',
    getBookingUrl({
      ...args,
      step: 'additional-information',
    })
  ).then(scrollToTop);
};

export const navigateToBookingPaymentPage = (args: BookingRequest) => {
  Router.push(
    '/[hotelSlug]/bookings/[id]/payment',
    getBookingUrl({
      ...args,
      step: 'payment',
    })
  ).then(scrollToTop);
};

export const navigateToBookingOneClickPaymentPage = (args: BookingRequest) => {
  Router.push(
    '/[hotelSlug]/bookings/[id]/one-click',
    getBookingUrl({
      ...args,
      step: 'one-click',
    })
  ).then(scrollToTop);
};

export const navigateToBookingPaymentPendingPage = (args: BookingRequest) => {
  Router.push(
    '/[hotelSlug]/bookings/[id]/pending',
    getBookingUrl({
      ...args,
      step: 'pending',
    })
  ).then(scrollToTop);
};

export const navigateToBookingExpiredPage = (args: BookingRequest) => {
  Router.push(
    '/[hotelSlug]/bookings/[id]/expired',
    getBookingUrl({
      ...args,
      step: 'expired',
    })
  ).then(scrollToTop);
};

export const navigateToBookingConfirmationPage = (args: BookingRequest) => {
  Router.push(
    '/[hotelSlug]/bookings/[id]/confirmation',
    getBookingUrl({
      ...args,
      step: 'confirmation',
    })
  );
};

export const bookingRequestToSlug = (request: BookingRequest) =>
  `${request.hotelSlug}-${request.bookingId}`;
export const slugToBookingRequest = (slug: string) => {
  const [hotelSlug, bookingId] = slug.split('-');
  return { hotelSlug, bookingId };
};

export const useBookingUrls = (request: BookingRequest) => {
  return (step: CheckoutStep) => getBookingUrl({ ...request, step });
};
