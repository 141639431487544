import { useRef } from 'react';
import { useEffect } from 'react';

import { useAppEvents } from '@/events';
import { IHotelModelInstance } from '@/hotel';

import { AvailabilityEvents } from '../events';
import { IRoomAvailabilityInstance } from '../models';

/**
 * Emits an event representing the impression of hotel + availability.
 * This will be picked up and emitted to the dataLayer.
 */
export const useRoomImpressionTracking = ({
  hotel,
  availability,
}: {
  hotel: IHotelModelInstance | undefined;
  availability: IRoomAvailabilityInstance[];
}) => {
  const events = useAppEvents<AvailabilityEvents>();
  const previousHotel = useRef<string>();
  const previousAvailability = useRef<string>();

  const availabilityString = JSON.stringify(availability);
  const hotelString = JSON.stringify(hotel);

  useEffect(() => {
    if (!hotel) return;

    // Prevent duplicate events being fired
    if (
      previousAvailability.current !== availabilityString ||
      previousHotel.current !== hotelString
    ) {
      events.emit('roomsImpression', {
        hotel,
        availability,
      });

      previousAvailability.current = availabilityString;
      previousHotel.current = hotelString;
    }
  }, [events, hotelString, availabilityString]);
};
