import { z } from 'zod';

import { DomaincommonRateCodeType } from '@/em-api-client-typescript-fetch';
import { IFinancialAmountInstance } from '@/finance';
import { HotelRoom } from '@/hotel/models/hotel-room.model';
import {
  IStayCostInstance,
  StayCostModel,
} from '@/hotel/models/stay-cost.model';

export type IRoomAvailabilitySnapshot = z.input<typeof RoomAvailabilityModel>;
export type IRoomAvailabilityInstance = z.infer<typeof RoomAvailabilityModel>;

export const RoomAvailabilityModel = z
  .object({
    availability: z.array(StayCostModel),
    room: HotelRoom,
  })
  .transform((self) => ({
    ...self,
    /**
     * Returns the rate code which corresponds to the searched-for rate code, if it's a discount code.
     */
    get discountedRoomAvailability(): IStayCostInstance | undefined {
      return self.availability.find(
        (availability) =>
          availability.rateCode.type === DomaincommonRateCodeType.PROMOTION ||
          availability.rateCode.type === DomaincommonRateCodeType.DISCOUNT
      );
    },
    /**
     * Gets the stay cost which relates to the supplied room rate.
     */
    stayCostForRoomRate(
      requestRateCode: string
    ): IStayCostInstance | undefined {
      return self.availability.find(
        (stayCost) => stayCost.rateCode.requestRateCode === requestRateCode
      );
    },
    // Get the cost for the room at the default rate (the first available rate).
    get defaultRateStayCost() {
      const firstBookableRateCode = self.availability.find(
        (availability) => availability.bookable.value
      );
      if (!firstBookableRateCode) {
        return self.availability[0];
      }

      return firstBookableRateCode;
    },
  }))
  .transform((self) => ({
    ...self,
    /**
     * Are the rate codes supplied in the format of 'discount' paired with 'default'?
     * So we can render a discounted price ('discount') with the original ('default') struckthrough.
     */
    get isRoomAvailabilityDiscounted() {
      // Match either PROMOTION or DISCOUNT type
      const isAnyRateCodeDiscount = self.discountedRoomAvailability;

      // No point checking for default if neither of the above are present
      if (!isAnyRateCodeDiscount) {
        return false;
      }

      // We have a rate code of type PROMOTION or DISCOUNT included in the array
      // Next we check if there's a corresponding `default` rate code to get the original price:
      return self.availability.some(
        (availability) =>
          availability.rateCode.type === DomaincommonRateCodeType.DEFAULT
      );
    },

    stayCostForRoomRateOrDefault(
      requestRateCode?: string
    ): IStayCostInstance | undefined {
      return requestRateCode
        ? self.stayCostForRoomRate(requestRateCode)
        : self.defaultRateStayCost;
    },
  }))
  .transform((self) => ({
    ...self,
    get isRoomAvailable() {
      // TODO: Review this. How do we determine if a room is available? At the moment it's if `any` rate is available, including corporate rate.
      return self.availability.some((stayCost) => stayCost.bookable.value);
    },
    get isRoomAccessible() {
      return Boolean(self.room.filters?.accessible);
    },
    /**
     * Returns the unavailability reason code for the room, if the room is unavailable.
     */
    get roomUnavailabilityCode(): string | undefined {
      if (self.defaultRateStayCost?.bookable.value) {
        return undefined;
      }
      return self.defaultRateStayCost?.bookable.reason?.code;
    },
    /**
     * Is the room unavailable because we can't fit an extra cot?
     */
    get isUnableToFitExtraCot(): boolean {
      if (self.defaultRateStayCost?.bookable.value) return false;
      return self.defaultRateStayCost?.bookable.reason?.code === 'SEARCH_04';
    },
    get discountedRateCode() {
      return self.availability.find(
        (avail) =>
          avail.rateCode.type === DomaincommonRateCodeType.PROMOTION ||
          DomaincommonRateCodeType.DISCOUNT
      );
    },
    get rateCodeBeforeDiscount() {
      return self.availability.find(
        (avail) => avail.rateCode.type === DomaincommonRateCodeType.DEFAULT
      );
    },
    get cheapestRate() {
      return self.availability.reduce<IFinancialAmountInstance | undefined>(
        (cheapestRate, rate) => {
          if (!cheapestRate) {
            return rate.grandTotal;
          }

          if (
            rate.grandTotal?.value &&
            rate.grandTotal.value < cheapestRate.value
          ) {
            return rate.grandTotal;
          }

          return cheapestRate;
        },
        undefined
      );
    },
  }));
