import { BookingDayTimePart } from '@/em-api-client-typescript-fetch';

export type BookingDayTimePartWithoutNotSet = Exclude<
  BookingDayTimePart,
  BookingDayTimePart.NOTSET
>;

export interface FlexiTimeRange {
  start: Date | undefined;
  end: Date | undefined;
}

const todayAtHour = (hour: number) => new Date(Date.UTC(0, 0, 0, hour, 0, 0));

export const FLEXI_TIME_RANGES: {
  [key in BookingDayTimePartWithoutNotSet]: FlexiTimeRange;
} = {
  [BookingDayTimePart.HOUR0TO1]: {
    start: todayAtHour(0),
    end: todayAtHour(1),
  },
  [BookingDayTimePart.HOUR1TO2]: {
    start: todayAtHour(1),
    end: todayAtHour(2),
  },
  [BookingDayTimePart.HOUR2TO3]: {
    start: todayAtHour(2),
    end: todayAtHour(3),
  },
  [BookingDayTimePart.HOUR3TO4]: {
    start: todayAtHour(3),
    end: todayAtHour(4),
  },
  [BookingDayTimePart.HOUR4TO5]: {
    start: todayAtHour(4),
    end: todayAtHour(5),
  },
  [BookingDayTimePart.HOUR5TO6]: {
    start: todayAtHour(5),
    end: todayAtHour(6),
  },
  [BookingDayTimePart.HOUR6TO7]: {
    start: todayAtHour(6),
    end: todayAtHour(7),
  },
  [BookingDayTimePart.HOUR7TO8]: {
    start: todayAtHour(7),
    end: todayAtHour(8),
  },
  [BookingDayTimePart.HOUR8TO9]: {
    start: todayAtHour(8),
    end: todayAtHour(9),
  },
  [BookingDayTimePart.HOUR9TO10]: {
    start: todayAtHour(9),
    end: todayAtHour(10),
  },
  [BookingDayTimePart.HOUR10TO11]: {
    start: todayAtHour(10),
    end: todayAtHour(11),
  },
  [BookingDayTimePart.HOUR11TO12]: {
    start: todayAtHour(11),
    end: todayAtHour(12),
  },
  [BookingDayTimePart.HOUR12TO13]: {
    start: todayAtHour(12),
    end: todayAtHour(13),
  },
  [BookingDayTimePart.HOUR13TO14]: {
    start: todayAtHour(13),
    end: todayAtHour(14),
  },
  [BookingDayTimePart.HOUR14TO15]: {
    start: todayAtHour(14),
    end: todayAtHour(15),
  },
  [BookingDayTimePart.HOUR15TO16]: {
    start: todayAtHour(15),
    end: todayAtHour(16),
  },
  [BookingDayTimePart.HOUR16TO17]: {
    start: todayAtHour(16),
    end: todayAtHour(17),
  },
  [BookingDayTimePart.HOUR17TO18]: {
    start: todayAtHour(17),
    end: todayAtHour(18),
  },
  [BookingDayTimePart.HOUR18TO19]: {
    start: todayAtHour(18),
    end: todayAtHour(19),
  },
  [BookingDayTimePart.HOUR19TO20]: {
    start: todayAtHour(19),
    end: todayAtHour(20),
  },
  [BookingDayTimePart.HOUR20TO21]: {
    start: todayAtHour(20),
    end: todayAtHour(21),
  },
  [BookingDayTimePart.HOUR21TO22]: {
    start: todayAtHour(21),
    end: todayAtHour(22),
  },
  [BookingDayTimePart.HOUR22TO23]: {
    start: todayAtHour(22),
    end: todayAtHour(23),
  },
  [BookingDayTimePart.HOUR23TO24]: {
    start: todayAtHour(23),
    end: todayAtHour(24),
  },
};

export const getFlexyTimeRange = (
  key: BookingDayTimePart
): { start: Date; end: Date } => FLEXI_TIME_RANGES[key];

export const getDayTimePartFromStartHour = (start: string | undefined) => {
  switch (start) {
    case '00:00':
      return BookingDayTimePart.HOUR0TO1;
    case '01:00':
      return BookingDayTimePart.HOUR1TO2;
    case '02:00':
      return BookingDayTimePart.HOUR2TO3;
    case '03:00':
      return BookingDayTimePart.HOUR3TO4;
    case '04:00':
      return BookingDayTimePart.HOUR4TO5;
    case '05:00':
      return BookingDayTimePart.HOUR5TO6;
    case '06:00':
      return BookingDayTimePart.HOUR6TO7;
    case '07:00':
      return BookingDayTimePart.HOUR7TO8;
    case '08:00':
      return BookingDayTimePart.HOUR8TO9;
    case '09:00':
      return BookingDayTimePart.HOUR9TO10;
    case '10:00':
      return BookingDayTimePart.HOUR10TO11;
    case '11:00':
      return BookingDayTimePart.HOUR11TO12;
    case '12:00':
      return BookingDayTimePart.HOUR12TO13;
    case '13:00':
      return BookingDayTimePart.HOUR13TO14;
    case '14:00':
      return BookingDayTimePart.HOUR14TO15;
    case '15:00':
      return BookingDayTimePart.HOUR15TO16;
    case '16:00':
      return BookingDayTimePart.HOUR16TO17;
    case '17:00':
      return BookingDayTimePart.HOUR17TO18;
    case '18:00':
      return BookingDayTimePart.HOUR18TO19;
    case '19:00':
      return BookingDayTimePart.HOUR19TO20;
    case '20:00':
      return BookingDayTimePart.HOUR20TO21;
    case '21:00':
      return BookingDayTimePart.HOUR21TO22;
    case '22:00':
      return BookingDayTimePart.HOUR22TO23;
    case '23:00':
      return BookingDayTimePart.HOUR23TO24;
    default:
      return undefined;
  }
};
