import { useEffect } from 'react';

import { useThemeSwitcherControls } from '@/ui/theme';

// @TODO remove this after @demo
export const useSetHotelTheme = (theme: string | undefined) => {
  const themeControls = useThemeSwitcherControls();
  useEffect(() => {
    if (!theme) return;

    // If we already have the desired theme active
    if (themeControls.activeThemeKey === theme) return;

    themeControls.setActiveThemeKey(theme as any);
  }, [theme]);
};
