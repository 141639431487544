import {
  AvailableSpaceKeyType,
  AvailableSpaceKeys,
} from '../available-space-keys.const';
import { useTheme } from './use-theme.hook';

/**
 * Returning the space size associated with the given 'space key' (eg. s, m, l, xl)
 * @param key
 */
export const useSpaceMatchingKey = (key: AvailableSpaceKeyType) => {
  const { space } = useTheme();
  return space[AvailableSpaceKeys.findIndex((sizeKey) => sizeKey === key) || 0];
};
