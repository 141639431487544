import type { BrandConfiguration } from '..';

export const selectHotelConfigurationByReferenceId = (
  brandConfig: BrandConfiguration,
  hotelReferenceId: string
) => brandConfig.hotels.find((hotel) => hotel.referenceId === hotelReferenceId);

export const selectHotelConfigurationByReferenceIdOrFail = (
  brandConfig: BrandConfiguration,
  hotelReferenceId: string
) => {
  const config = selectHotelConfigurationByReferenceId(
    brandConfig,
    hotelReferenceId
  );

  if (!config) {
    throw new Error(
      `Hotel configuration not found in brand [${brandConfig.chainCode}] matching reference ID [${hotelReferenceId}]`
    );
  }

  return config;
};

export const selectHotelConfigurationByCode = (
  brandConfig: BrandConfiguration,
  code: string
) => brandConfig.hotels.find((hotel) => hotel.code === code);

export const selectHotelConfigurationByCodeOrFail = (
  brandConfig: BrandConfiguration,
  code: string
) => {
  const config = selectHotelConfigurationByCode(brandConfig, code);

  if (!config) {
    throw new Error(
      `Hotel configuration not found in brand [${brandConfig.chainCode}] matching code [${code}]`
    );
  }

  return config;
};
