import { AxiosError } from 'axios';
import { ZodError, z } from 'zod';

import { logAxiosError } from '@/api/errors/log-axios-error';
import { APIError } from '@/errors';
import { formatZodErrorAsDebugString } from '@/errors/helpers/zod-error-as-debug-string';
import { ERROR_CODES } from '@/errors/resources/error-strings.const';
import { captureException, getLogger } from '@/logging';

const debugMetadataSchema = z.object({
  'dd.trace_id': z.string().optional(),
  'dd.span_id': z.string().optional(),
  error_id: z.enum(ERROR_CODES).optional(),
});
type DebugMetadata = z.infer<typeof debugMetadataSchema>;

const debugSchema = z.object({
  metadata: debugMetadataSchema,
});

const errorDetailsSchema = z.object({
  type: z.string(),
  value: z.string(),
  debug: debugSchema,
});

const errorResponseSchema = z.object({
  code: z.string(),
  message: z.string(),
  details: z.array(errorDetailsSchema).min(1),
});

const legacyErrorResponseSchema = z.object({
  code: z.enum(ERROR_CODES),
  description: z.string(),
});

export const parseOHIPAxiosError = (error: AxiosError | ZodError): APIError => {
  // If the error passed is a ZodError, we can assume it's bubbled up from a malformed response
  // Not sure if this is best practise - but it does simplify the consumption of this function
  // I guess the main issue really is a core issue with JS error handling
  if (error instanceof ZodError) {
    captureException(error);
    getLogger().error(error);

    return {
      code: 'LOCAL_MALFORMED_RESPONSE',
      description: formatZodErrorAsDebugString(error),
    };
  }

  if (error.response) {
    logAxiosError(error);

    // Occasionally, the OHIP API may respond with a DomainError directly (code + description)
    const legacyError = legacyErrorResponseSchema.safeParse(
      error.response.data
    );

    if (legacyError.success) {
      return legacyError.data;
    }

    try {
      const validatedData = errorResponseSchema.parse(error.response.data);
      const { message, details } = validatedData;

      // Errors of this type contain an array of `details` objects, each with different metadata attributes added as
      // it's propagated through the system.
      // Here we merge all metadata objects.
      const metadata: DebugMetadata = details.reduce((metadata, detail) => {
        return { ...metadata, ...detail.debug.metadata };
      }, {});

      return {
        code: metadata.error_id || 'UNKNOWN_ERROR',
        description: message,
      };
    } catch (errorParsingError) {
      // Flag an invalid error in Sentry
      captureException(errorParsingError);

      // Invalid error response structure
      return {
        code: 'UNKNOWN_ERROR',
        description:
          "Error was returned from the Ennismore OHIP API in a format that couldn't be mapped to a friendly user message.",
      };
    }
  } else if (error.request) {
    // The request was made but no response was received
    return {
      code: 'LOCAL_NETWORK_ERROR_01',
      description: 'No response received from server',
    };
  } else {
    // Something happened in setting up the request that triggered an error
    return {
      code: 'LOCAL_REQUEST_SETUP_ERROR_01',
      description: error.message,
    };
  }
};
