import { css } from '@emotion/react';
import React, { AriaAttributes } from 'react';

import { useTheme } from '../theme';

interface AnchorButtonProps extends AriaAttributes {
  children: React.ReactNode;

  onClick?: () => void;
  noWrap?: boolean;
  title?: string;
}

export const AnchorButton = ({ noWrap, ...props }: AnchorButtonProps) => {
  const { colors, fonts } = useTheme();
  return (
    <button
      {...props}
      css={css({
        color: 'inherit',
        cursor: 'pointer',
        position: 'relative',
        fontFamily: fonts.body,
        textDecoration: 'underline',
        fontSize: 'inherit',
        whiteSpace: noWrap ? 'nowrap' : 'normal',
        touchAction: 'manipulation',

        '&.focus-visible': {
          outline: `1px solid ${colors.secondary300}`,
          outlineOffset: 2,
        },

        '&:hover::after': {
          transform: 'scaleX(0)',
          transformOrigin: 'right',
        },

        '&[role=button]': {
          userSelect: 'none',
        },
      })}
      tabIndex={0}
    />
  );
};

export default AnchorButton;
