import React from 'react';

import { PageStructuredData } from '.';
import { createStructuredDataObject } from './createStructuredDataObject.helper';

interface HotelStructuredDataProps {
  name: string;
  identifier: string;
  brand: string;
  address?: {
    streetAddress?: string;
    addressRegion?: string;
    postalCode?: string;
    addressCountry?: string;
  };
  telephone?: string;
  image?: string;
  url?: string;
}

/**
 * Adds Hotel JSON-LD to the page <head />.
 * @param props
 */
export const HotelStructuredData = (props: HotelStructuredDataProps) => {
  const { address, ...data } = props;
  return (
    <PageStructuredData
      data={createStructuredDataObject('Hotel', {
        address: createStructuredDataObject('PostalAddress', address),
        ...data,
      })}
    />
  );
};

export default HotelStructuredData;
