import { BookingServiceApi, Configuration } from '@ennismore/ows-api-client';
import { AxiosError } from 'axios';
import { ZodError } from 'zod';

import { EnnismoreAPIClientConfig } from '@/api/interfaces';
import { APIError } from '@/errors';

import { parseOWSAxiosError } from './errors';

/**
 * Parent of all OWS / legacy API services.
 * Handles setting up of client + common request options.
 */
export abstract class OWSBookingAPIBaseService {
  client: BookingServiceApi;

  get options() {
    return {
      headers: {
        'X-Api-Key': this.credentials.apiKey,
        'X-Skip-Redirect': this.credentials.skipRedirect,
      },
    };
  }

  constructor(private readonly credentials: EnnismoreAPIClientConfig) {
    // OWS Booking API is the same URL as OHIP, except it's including the version number.
    // We should flip this behaviour when we switch to OHIP-dominant approach, this should have `v2` appended:
    const config = new Configuration({
      basePath: credentials.baseUrl,
    });

    this.client = new BookingServiceApi(config);
  }

  protected parseError(error: AxiosError<unknown> | ZodError): APIError {
    return parseOWSAxiosError(error);
  }
}
