import { css } from '@emotion/react';
import React from 'react';

import { DISLOYALTY_PROMOTIONAL_RATE } from '@/booking/components/disloyalty-promotional-rate/constants';
import { Flex } from '@/box';
import { useTranslation } from '@/i18n';
import { RadioButtonAccordion } from '@/ui/controls';
import { useTheme } from '@/ui/theme';
import { Text } from '@/ui/typography';

export const DisloyaltySelectorOption = (props: {
  onSelectRoomRate: (code: string) => void;
  selectedRateCode: string | undefined;
  updateDisloyaltyRateUrl: (url: string) => void;
  hotelId: string;
  link: string;
}) => {
  const { colors, keylines, forms, text, componentProperties } = useTheme();
  const { t } = useTranslation('bookingSummary');

  return (
    <div
      css={css`
        margin-bottom: 4px;
        border-top: ${forms.radioGroup?.divider?.border ??
        keylines?.border ??
        `1px solid ${colors.primary300}`};
        padding-top: 4px;
        &:last-child {
          margin-bottom: 0;
        }
      `}
    >
      <RadioButtonAccordion
        collapsibleContent={
          <div>
            <Text
              style="bodySmall"
              css={css`
                margin-bottom: 20px;
              `}
            >
              {t('disloyaltyRate.description')}
            </Text>
            <ul
              css={css({
                ...text.bodySmall,
                marginBottom: '20px',
                listStyle: 'inside',
                lineHeight: '24px',
              })}
            >
              {t('disloyaltyRate.benefits', { returnObjects: true }).map(
                (benefit, index) => (
                  <li key={index}>{benefit}</li>
                )
              )}
            </ul>
            <Text style="bodySmall">{t('disloyaltyRate.terms')}</Text>
          </div>
        }
        onClick={() => {
          props.onSelectRoomRate(DISLOYALTY_PROMOTIONAL_RATE);
          props.updateDisloyaltyRateUrl(props.link);
        }}
        isChecked={props.selectedRateCode === DISLOYALTY_PROMOTIONAL_RATE}
        testId={DISLOYALTY_PROMOTIONAL_RATE}
        value={DISLOYALTY_PROMOTIONAL_RATE}
      >
        <Flex marginBottom={5}>
          <Text
            style="heading6"
            aria-label={t('disloyaltyRate.accessibleLabel')}
          >
            {t('disloyaltyRate.title')}
          </Text>
          <Text
            style="bodySmall"
            css={css`
              padding: 8px;
              background-color: #c5ff98;
              line-height: 1;
              border-radius: 4px;
              margin-left: 13px;
            `}
          >
            {t('disloyaltyRate.badge')}
          </Text>
        </Flex>
        <Text style="heading6" aria-label={t('disloyaltyRate.accessibleLabel')}>
          {t('disloyaltyRate.subTitle')}
        </Text>
      </RadioButtonAccordion>
    </div>
  );
};
