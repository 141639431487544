import React, { useEffect, useState } from 'react';

import { useActiveBrand } from '@/brand';

import { AuthContext } from '../context/auth.context';
import { getStoredAuthToken } from '../get-stored-auth-token';
import { storeAuthToken } from '../store-auth-token';

interface AuthProviderProps {
  children: React.ReactNode;
}

/**
 * At the moment, this just reads directly from localStorage, but in the future this provider will handle refreshing tokens.
 * @param props
 * @returns
 */
export const AuthProvider: React.FC<AuthProviderProps> = (props) => {
  const brand = useActiveBrand();
  const [authToken, setAuthToken] = useState(getStoredAuthToken(brand));

  // When the token value is updated, save it to localStorage.
  useEffect(() => {
    storeAuthToken(brand, authToken);
  }, [authToken, brand]);

  return (
    <AuthContext.Provider
      value={{
        authToken,
        setAuthToken,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
