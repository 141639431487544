import { motion } from 'framer-motion';
import React from 'react';

import type { AvailableSpaceKeyType } from '../theme';
import Stack from './Stack.component';

type AnimatedStackProps = {
  children: React.ReactNode;
  s?: AvailableSpaceKeyType;
  as?: React.ElementType;
} & React.AriaAttributes;

export const AnimatedStack = ({ children, ...rest }: AnimatedStackProps) => {
  return (
    <motion.div
      variants={{
        hidden: {},
        show: {
          transition: {
            staggerChildren: 0.3,
          },
        },
      }}
      initial="hidden"
      animate="show"
    >
      <Stack {...rest}>
        {/* <AnimatePresence> */}
        {React.Children.map(children, (child) => {
          // Handle situations where undefined is a child...
          if (!child) {
            return null;
          }
          const key = (child as any).props.key;

          const Wrapper = rest.as === 'ul' ? motion.li : motion.div;

          return (
            <Wrapper
              key={key}
              variants={{
                hidden: { opacity: 0, translateY: 0 },
                show: { opacity: 1, translateY: 0 },
              }}
            >
              {child}
            </Wrapper>
          );
        })}
      </Stack>
    </motion.div>
  );
};

export default AnimatedStack;
