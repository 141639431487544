import { CountryCountry } from '@/em-api-client-typescript-fetch';

import { BrandConfigurationFactory } from '../common/interfaces/BrandConfigurationFactory';
import de from './locale/de.json';
import enGB from './locale/en-GB.json';
import es from './locale/es.json';
import fr from './locale/fr.json';
import it from './locale/it.json';

export const mamaShelterBrandConfig: BrandConfigurationFactory = ({
  isProduction,
}) => ({
  chainCode: 'mamashelter',
  themeKey: 'mamashelter',
  name: 'Mama Shelter',
  defaultHotelCode: 'mamashelter.shoreditch-london',
  hotels: [
    {
      referenceId: 'mamashelter.belgrade-belgrade',
      code: 'mamashelter.belgrade-belgrade',
      name: 'Belgrade',
      dialect: 'ohip',
    },
    {
      referenceId: 'mamashelter.bordeaux-bordeaux',
      code: 'mamashelter.bordeaux-bordeaux',
      name: 'Bordeaux',
      dialect: 'ohip',
    },
    {
      referenceId: 'mamashelter.dijon-dijon',
      code: 'mamashelter.dijon-dijon',
      name: 'Dijon',
      dialect: 'ohip',
    },
    {
      referenceId: 'mamashelter.dubai-dubai',
      code: 'mamashelter.dubai-dubai',
      name: 'Dubai',
      dialect: 'ohip',
      visibility: { search: false },
    },
    {
      referenceId: 'mamashelter.lille-lille',
      code: 'mamashelter.lille-lille',
      name: 'Lille',
      dialect: 'ohip',
    },
    {
      referenceId: 'mamashelter.lisboa-lisbon',
      code: 'mamashelter.lisboa-lisbon',
      name: 'Lisboa',
      dialect: 'ohip',
    },
    {
      referenceId: 'mamashelter.shoreditch-london',
      code: 'mamashelter.shoreditch-london',
      name: 'London Shoreditch',
      dialect: 'ohip',
    },
    {
      referenceId: 'mamashelter.losangeles-losangeles',
      code: 'mamashelter.losangeles-losangeles',
      name: 'Los Angeles',
      dialect: 'ohip',
    },
    {
      referenceId: 'mamashelter.luxembourg-luxembourg',
      code: 'mamashelter.luxembourg-luxembourg',
      name: 'Luxembourg',
      dialect: 'ohip',
    },
    {
      referenceId: 'mamashelter.lyon-lyon',
      code: 'mamashelter.lyon-lyon',
      name: 'Lyon',
      dialect: 'ohip',
    },
    {
      referenceId: 'mamashelter.marseille-marseille',
      code: 'mamashelter.marseille-marseille',
      name: 'Marseille',
      dialect: 'ohip',
    },
    {
      referenceId: 'mamashelter.nice-nice',
      code: 'mamashelter.nice-nice',
      name: 'Nice',
      dialect: 'ohip',
    },
    {
      referenceId: 'mamashelter.east-paris',
      code: 'mamashelter.east-paris',
      name: 'Paris East',
      dialect: 'ohip',
    },
    {
      referenceId: 'mamashelter.ladefense-paris',
      code: 'mamashelter.ladefense-paris',
      name: 'Paris La Défense',
      dialect: 'ohip',
    },
    {
      referenceId: 'mamashelter.west-paris',
      code: 'mamashelter.west-paris',
      name: 'Paris West',
      dialect: 'ohip',
    },
    {
      referenceId: 'mamashelter.prague-prague',
      code: 'mamashelter.prague-prague',
      name: 'Prague',
      dialect: 'ohip',
    },
    {
      referenceId: 'mamashelter.rennes-rennes',
      code: 'mamashelter.rennes-rennes',
      name: 'Rennes',
      dialect: 'ohip',
    },
    {
      referenceId: 'mamashelter.roma-rome',
      code: 'mamashelter.roma-rome',
      name: 'Roma',
      dialect: 'ohip',
    },
    {
      referenceId: 'mamashelter.orchard-singapore',
      code: 'mamashelter.orchard-singapore',
      name: 'Singapore',
      dialect: 'ohip',
      visibility: { search: false },
    },
    {
      referenceId: 'mamashelter.toulouse-toulouse',
      code: 'mamashelter.toulouse-toulouse',
      name: 'Toulouse',
      dialect: 'ohip',
    },
    {
      referenceId: 'mamashelter.zurich-zurich',
      code: 'mamashelter.zurich-zurich',
      name: 'Zürich',
      dialect: 'ohip',
      visibility: { search: false },
    },
  ],
  homeUrl: 'https://mamashelter.com/',
  domains: [
    'booking.mamashelter.local',
    'bookings.mamashelter.local',
    'mamashelter.dev.env.ennismore.com',
    'mamashelter.staging.env.ennismore.com',
    'booking.mamashelter.com',
    'bookings.mamashelter.com',
  ],
  i18n: {
    locales: ['en-GB', 'en-US', 'de', 'fr', 'es', 'it'],
  },

  maxRoomCountPerBooking: 7,

  supportedRoomOccupancyConfigurations: [
    {
      adults: 1,
    },
  ],
  legal: {
    urls: {
      en: {
        privacyPolicy: 'https://mamashelter.com/legal-notices/?tab=3',
        cookiePolicy: 'https://mamashelter.com/legal-notices/?tab=1',
        termsOfUse: 'https://mamashelter.com/legal-notices/',
        contact: 'https://mamashelter.com/contact-us/',
      },
      de: {
        privacyPolicy: 'https://mamashelter.com/legal-notices/?tab=3',
        cookiePolicy: 'https://mamashelter.com/legal-notices/?tab=1',
        termsOfUse: 'https://mamashelter.com/legal-notices/',
        contact: 'https://mamashelter.com/contact-us/',
      },
      fr: {
        privacyPolicy: 'https://fr.mamashelter.com/mentions-legales/?tab=3',
        cookiePolicy: 'https://fr.mamashelter.com/mentions-legales/?tab=1',
        termsOfUse: 'https://fr.mamashelter.com/mentions-legales/?tab=2',
        contact: 'https://fr.mamashelter.com/contact/',
      },
      es: {
        privacyPolicy: 'https://mamashelter.com/legal-notices/?tab=3',
        cookiePolicy: 'https://mamashelter.com/legal-notices/?tab=1',
        termsOfUse: 'https://mamashelter.com/legal-notices/',
        contact: 'https://mamashelter.com/contact-us/',
      },
      it: {
        privacyPolicy: 'https://it.mamashelter.com/informazioni-legali/?tab=3',
        cookiePolicy: 'https://it.mamashelter.com/informazioni-legali/?tab=1',
        termsOfUse: 'https://it.mamashelter.com/informazioni-legali/?tab=2',
        contact: 'https://it.mamashelter.com/contattaci/',
      },
    },
  },
  showRoomTotalInBreakdown: true,
  showTaxTotalInBreakdown: true,
  showAveragePricePerNight: true,

  highlightedCountryCodes: [
    CountryCountry.FR,
    CountryCountry.IT,
    CountryCountry.NL,
    CountryCountry.GB,
    CountryCountry.US,
  ],

  features: {
    bookingManagement: true,
  },

  marketingEmailSignUpAtCheckout: true,
  collectPrivacyPolicyConsentAtCheckout: true,
  ohipDiscountCodeType: 'CORPORATE',

  translationOverrides: {
    'en-GB': enGB,
    de,
    fr,
    es,
    it,
  },

  analytics: {
    gtm: {
      containerId: isProduction ? 'GTM-PKPH2VK' : undefined,
    },
    cookieConsent: {
      provider: 'onetrust',
      oneTrustId: '3811744d-59fb-4774-a6c0-11522a20d719',
    },
  },
});
