import css from '@emotion/css';
import * as React from 'react';

import { ScreenReaderAnnouncement } from '@/accessibility/components';
import { OnlyDesktop } from '@/common';
import { LoadingIndicator } from '@/ui/controls';
import { Spacer } from '@/ui/spacing';
import { Heading } from '@/ui/typography';

import { RateCodeUnavailableCard, RoomImageDisclaimer } from '.';

interface HotelAvailabilitySearchResultsProps {
  children?: React.ReactNode;
  title?: string;
  isLoading?: boolean;
  rateCodeSearchErrorMessage: string | undefined;
  resultRoomCount?: number;
}

export const HotelAvailabilitySearchResults = (
  props: HotelAvailabilitySearchResultsProps
) => {
  return (
    <div
      css={css`
        height: 100%;

        .title {
          @media all and (max-width: 730px) {
            margin-left: 28px;
            margin-right: 28px;
          }
        }
      `}
      id="search-results"
    >
      {props.isLoading ? (
        <div
          css={css`
            height: 100px;
            position: relative;
            opacity: 0.5;
          `}
        >
          <LoadingIndicator style="dark" />
        </div>
      ) : (
        <>
          {props.title ? (
            <div className="title">
              <Heading style="heading5" as="h5">
                {props.title}
              </Heading>
              <Spacer s="s" />
            </div>
          ) : null}
          {props.rateCodeSearchErrorMessage ? (
            <>
              <RateCodeUnavailableCard />
              <Spacer s="s" />
            </>
          ) : undefined}
          {props.children ? (
            <>
              {props.children}
              {props.resultRoomCount && props.resultRoomCount > 0 && (
                <>
                  <Spacer s="m" />
                  <OnlyDesktop>
                    <RoomImageDisclaimer />
                  </OnlyDesktop>
                  <Spacer s="xl" />
                </>
              )}
            </>
          ) : undefined}

          {props.resultRoomCount && (
            <ScreenReaderAnnouncement>
              {`${props.resultRoomCount} rooms available`}
            </ScreenReaderAnnouncement>
          )}
        </>
      )}
    </div>
  );
};
