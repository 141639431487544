import { z } from 'zod';

import { ErrorModel } from './error.model';
import { YesNo } from './yes-no.model';

/**
 * This shouldn't be an error really. We should have a reason code rather than error code.
 */
export const YesNoWithReasonModel = z.object({
  reason: ErrorModel.optional(),
  value: YesNo.optional(),
});
