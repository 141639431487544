import {
  createAvailabilitySearchQueryFromBooking,
  navigateToAvailabilityResultsPage,
  navigateToAvailabilitySearchPage,
} from '@/availability/helpers';
import { availabilitySearchQuerySelectors } from '@/availability/models';
import { BookingRequest, useBooking } from '@/booking';
import {
  navigateToBookingAdditionalInformationPage,
  navigateToBookingConfirmationPage,
  navigateToBookingExpiredPage,
  navigateToBookingPaymentPage,
  navigateToBookingPaymentPendingPage,
} from '@/booking/helpers/url';
import { useHotel } from '@/hotel';
import { useLocale } from '@/i18n';

import { BookingCheckoutNavigator } from '../interfaces';

export const useStandardBookingCheckoutNavigator = (
  bookingRequest: BookingRequest
): BookingCheckoutNavigator => {
  const booking = useBooking(bookingRequest);
  const hotel = useHotel(bookingRequest.hotelSlug);
  const locale = useLocale();
  const request = { ...bookingRequest, locale };

  return {
    additionalInformationPage: () =>
      navigateToBookingAdditionalInformationPage(request),
    expiredPage: () => navigateToBookingExpiredPage(request),
    paymentPage: () => navigateToBookingPaymentPage(request),
    confirmationPage: () => navigateToBookingConfirmationPage(request),
    pendingPage: () => navigateToBookingPaymentPendingPage(request),
    searchResultsPage: () => {
      if (booking && hotel) {
        const query = createAvailabilitySearchQueryFromBooking(
          booking,
          hotel.code
        );
        const selectors = availabilitySearchQuerySelectors(query);
        return navigateToAvailabilityResultsPage(selectors.asURLQuery());
      }

      return navigateToAvailabilitySearchPage();
    },
  };
};
