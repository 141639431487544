import { BookingTaxType } from '@/em-api-client-typescript-fetch';
import { DEFAULT_TRANSLATION_STRINGS } from '@/i18n';

export const getHumanTaxTypeNameKey = (
  type: BookingTaxType
): keyof typeof DEFAULT_TRANSLATION_STRINGS['en-GB']['taxes'] => {
  switch (type) {
    case BookingTaxType.TAXUNDEFINED:
      return 'otherTaxes';
    case BookingTaxType.CITYTAX:
      return 'cityTax';
    case BookingTaxType.EMPLOYEEPROTECTION:
      return 'employeeProtection';
    case BookingTaxType.TOURISTTAX:
      return 'touristTax';
    case BookingTaxType.VAT5PERCENT:
      return 'vat';
    case BookingTaxType.VAT9PERCENT:
      return 'vat';
    case BookingTaxType.VAT10PERCENT:
      return 'vat';
    case BookingTaxType.VAT20PERCENT:
      return 'vat';
    case BookingTaxType.DESTINATIONFEE:
      return 'destinationFee';
    case BookingTaxType.STATETAX:
      return 'stateTax';
    case BookingTaxType.OCCUPANCYTAX:
      return 'occupancyTax';
    case BookingTaxType.COUNTYTAX:
      return 'countyTax';
    case BookingTaxType.LODGINGTAX:
      return 'lodgingTax';
    case BookingTaxType.BREAKFAST:
      return 'breakfastFee';
    case BookingTaxType.CITYTAXANDOTHERCOMBINED:
      return 'cityAndOtherTaxes';
  }
};
