import { BrandConfiguration } from '@/brand';

import { EnnismoreLogo, EstelleLogo } from './provider-logos';

export const getProviderLogo = (
  providerKey: BrandConfiguration['footer']['provider']['key']
) => {
  switch (providerKey) {
    case 'ennismore':
      return EnnismoreLogo;
    case 'estelle-manor':
      return EstelleLogo;
    default:
      return EnnismoreLogo;
  }
};
