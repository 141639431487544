import { css } from '@emotion/react';
import React from 'react';

import { useSpaceMatchingKey, useTheme } from '@/ui/theme';

export const SummarySectionContainer = (props: {
  children: React.ReactNode;
}) => {
  const spacing = useSpaceMatchingKey('m');
  const {
    componentProperties: { staySummary },
  } = useTheme();

  return (
    <div
      css={css({
        padding: `8px ${spacing} 8px ${spacing}`,
        backgroundColor: 'white',
        ...staySummary?.summarySection,
        ['@media all and (max-width: 730px)']:
          staySummary?.summarySection?.mobile,
      })}
    >
      {props.children}
    </div>
  );
};
