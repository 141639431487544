import React from 'react';

import Anchor from './Anchor.component';

interface AnchorLinkProps {
  href: string;
  children?: React.ReactNode;
  openInNewTab?: boolean;
  title?: string;
}

export const AnchorLink = (props: AnchorLinkProps) => {
  return (
    <Anchor
      rel={props.openInNewTab ? 'noopener noreferrer' : undefined}
      target={props.openInNewTab ? 'blank' : undefined}
      href={props.href}
      title={props.title}
    >
      {props.children}
    </Anchor>
  );
};

export default AnchorLink;
