import React from 'react';

import { useHotel } from '@/hotel';
import { getLabelTranslationKeyFromCount } from '@/hotel/helpers/occupancy-type.helpers';
import { useTranslation } from '@/i18n';

import { SearchSummary } from '../components';
import {
  IAvailabilitySearchQueryInstance,
  availabilitySearchQuerySelectors,
} from '../models/availability-search-query.model';

export const HotelAvailabilitySearchSummaryContainer: React.FC<{
  query?: IAvailabilitySearchQueryInstance;
  hotelReferenceId: string;
}> = ({ query, hotelReferenceId }) => {
  const { t: roomOccupancyTypeTranslation } =
    useTranslation('roomOccupancyTypes');
  const hotel = useHotel(hotelReferenceId);

  if (!query) return null;

  const selectors = availabilitySearchQuerySelectors(query);

  if (!hotel) return null;

  return (
    <SearchSummary
      totalNightCount={selectors.totalNightCount()}
      hotelName={hotel.name}
      startDate={query.from}
      endDate={query.to}
      roomCount={selectors.roomCount() || 0}
      occupants={
        selectors.roomCount() === 1
          ? roomOccupancyTypeTranslation(
              getLabelTranslationKeyFromCount(query.rooms[0]) || 'oneAdult' // unsure about this - shouldn't really default to anything but keeping type system happy
            )
          : undefined
      }
    />
  );
};
