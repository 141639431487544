import { useContext } from 'react';

import { BrandContext } from '../contexts';

export const useActiveBrandConfig = () => {
  const config = useContext(BrandContext);

  if (!config) {
    throw new Error(
      'Brand config context not found in the current tree. Ensure this component is a child of <BrandProvider />.'
    );
  }

  return config;
};
