import styled from '@emotion/styled';
import React from 'react';

interface Props {
  isFlushOnMobile?: boolean;
  maxWidth?: string;
  alignContent?: 'left' | 'center';
}

export const PageWrapper = styled.div<Props>(
  (props) => `
  max-width: ${props.maxWidth ?? '1280px'};
  margin: ${props.alignContent === 'left' ? '0 0 0 80px' : 'auto'};
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: content-box;

  @media all and (max-width: 720px) {
    margin: auto;
    ${props.isFlushOnMobile && 'padding: 0;'}
  }
`
);

export const SmallPageWrapper: React.FC<
  Omit<React.ComponentProps<typeof PageWrapper>, 'maxWidth'>
> = (props) => <PageWrapper maxWidth="420px" {...props} />;
