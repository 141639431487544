import orderBy from 'lodash.orderby';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

import { useTranslation } from '@/i18n';
import { US_STATE_CODES } from '@/places';

export const useStateSelectOptions = () => {
  const { t } = useTranslation('usStates');
  const { locale } = useRouter();

  // Map each state code to it's translated label, then sort by label
  const usStateCodeOptions = useMemo(
    () =>
      orderBy(
        US_STATE_CODES.map((code) => ({
          label: t(code),
          value: code,
        })),
        ['label']
      ),
    [locale]
  );

  return usStateCodeOptions;
};
