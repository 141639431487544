import { css } from '@emotion/react';
import React from 'react';

import rightChevron from '../../resources/svg/right-chevron.svg';

export const RightChevron = () => {
  return (
    <img
      src={rightChevron.src}
      alt="Right chevron"
      css={css`
        width: 24px;
        height: 24px;
      `}
    />
  );
};

export default RightChevron;
