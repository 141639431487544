import { AvailabilityEvents } from '@/availability/events';
import { BookingCheckoutEvents } from '@/booking-checkout/events';
import { BookingPaymentEvents } from '@/booking-payment/events';
import { CoreAppEvents } from '@/core/events';
import { AppEventEmitter } from '@/events';
import { RootNode } from '@/flags/hypertune/generated/hypertune';

export type HypertuneEvents = BookingPaymentEvents &
  BookingCheckoutEvents &
  AvailabilityEvents &
  CoreAppEvents;

export function setupHypertuneEventBindings(
  events: AppEventEmitter<HypertuneEvents>,
  hypertune: RootNode
) {
  const listeners = [
    events.addListener('roomsImpression', (event) => {
      hypertune.searchImpressionEvent({
        args: {
          event: {
            hotel: { referenceId: event.hotel.referenceId },
            context: hypertune.getRootArgs().context,
          },
        },
      });
    }),

    events.addListener('additionalInfoPageLoad', (event) => {
      hypertune.additionalInformationImpressionEvent({
        args: {
          event: {
            hotel: { referenceId: event.hotel.referenceId },
            booking: { id: event.booking.id },
            context: hypertune.getRootArgs().context,
          },
        },
      });
    }),
  ];

  return () => listeners.forEach((cleanup) => cleanup());
}
