import { z } from 'zod';

export type IBookingMetaData = typeof BookingMetaDataModel;

export const BookingMetaDataModel = z
  .object({
    flexyCheckInOutAvailable: z.boolean().default(false),
    dogInTow: z.boolean().default(false),
    invalidRateCode: z.array(z.string()).default([]),
  })
  .transform((self) => ({
    ...self,
    get invalidRateCodeMessage(): string | undefined {
      return self.invalidRateCode.length > 0
        ? `Rate code ${self.invalidRateCode.join(', ')} is unavailable`
        : undefined;
    },
  }));
