import { trpc } from '@/trpc/client';

type Session =
  | {
      status: 'loading' | 'unauthenticated';
      user: undefined;
    }
  | { status: 'authenticated'; user: { email: string } };

export const useAuthSession = (): Session => {
  const response = trpc.auth.session.useQuery();

  if (response.isLoading) {
    return {
      status: 'loading',
      user: undefined,
    } as const;
  }

  if (response.data?.user) {
    return { status: 'authenticated', user: response.data.user } as const;
  }

  return { status: 'unauthenticated', user: undefined };
};
