import { flatten } from 'flat';

const countWords = (string: string) =>
  string
    .replace(/(^\s*)|(\s*$)/gi, '')
    .replace(/[ ]{2,}/gi, ' ')
    .replace(/\n /, '\n')
    .split(' ').length;

export const recursivelyCountWords = (translationStrings: any) =>
  (Object.values(flatten(translationStrings)) as string[]).reduce(
    (wordCount: number, sentence: string) => {
      return wordCount + countWords(sentence);
    },
    0
  );
