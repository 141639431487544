import React from 'react';

import { useCurrencyPreview } from './hooks/useCurrencyPreview.hook';
import type { CurrencyPreviewProps } from './hooks/useCurrencyPreview.hook';

export const CurrencyPreview = (props: CurrencyPreviewProps) => {
  const currencyPreview = useCurrencyPreview(props);

  return <>{currencyPreview}</>;
};
