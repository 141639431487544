import { V1Guest } from '@ennismore/ows-api-client';

import {
  BookingGuestWithoutUnspecified,
  OccupancyConfiguration,
  ROOM_OCCUPANCY_TYPES,
} from '../constants/room-occupancy-types.const';
import type { IOccupancy } from '../models/occupancy.model';

export const getOccupancyTypeByKey = (
  key: V1Guest | string
): OccupancyConfiguration => ROOM_OCCUPANCY_TYPES[key];

export const getBookingGuestEnum = (key: string): V1Guest | undefined =>
  V1Guest[key];

export const getOccupancyTypeByKeyOrFail = (key: V1Guest | string) => {
  const type = getOccupancyTypeByKey(key);

  return type || V1Guest.Unspecified;
};

/**
 * Calculates total adult guest count for supplied BookingGuest keys
 * @param keys
 */
export const getLegacyTotalAdultGuestCount = (...keys: (V1Guest | string)[]) =>
  keys.reduce(
    (totalCount, key) =>
      getOccupancyTypeByKeyOrFail(key).totalAdultCount + totalCount,
    0
  );

/**
 * Calculates total adult guest count for supplied BookingGuest keys
 * @param keys
 */
export const getLegacyTotalChildGuestCount = (...keys: (V1Guest | string)[]) =>
  keys.reduce(
    (totalCount, key) =>
      getOccupancyTypeByKeyOrFail(key).totalChildCount + totalCount,
    0
  );

/**
 * Calculates total guest count for supplied BookingGuest keys
 * @param keys
 */
export const getLegacyTotalGuestCount = (...keys: (V1Guest | string)[]) =>
  keys.reduce(
    (totalCount, key) =>
      getOccupancyTypeByKeyOrFail(key).totalGuests + totalCount,
    0
  );

/**
 * Calculates total guest count for supplied rooms
 * @param rooms
 */
export const getTotalGuestCount = (rooms: IOccupancy[]) =>
  rooms.reduce(
    (totalCount, room) => room.adults + room.children + totalCount,
    0
  );

/**
 * Calculates total adult guest count for supplied rooms
 * @param rooms
 */
export const getTotalAdultGuestCount = (rooms: IOccupancy[]) =>
  rooms.reduce((totalCount, room) => room.adults + totalCount, 0);

/**
 * Calculates total child guest count for supplied rooms
 * @param rooms
 */
export const getTotalChildGuestCount = (rooms: IOccupancy[]) =>
  rooms.reduce((totalCount, room) => room.children + totalCount, 0);

/**
 * Gets 'TWO_ADULT' from { adults: 2, children: 0 } (example)
 */
export const getBookingGuestFromCount = (occupants: {
  adults: number;
  children?: number | undefined;
}): V1Guest | undefined => {
  for (const [key, type] of Object.entries(ROOM_OCCUPANCY_TYPES)) {
    if (
      type.totalAdultCount === occupants.adults &&
      type.totalChildCount === (occupants.children ?? 0)
    ) {
      return key as V1Guest;
    }
  }

  return undefined;
};

/**
 * Gets { adults: 2, children: 0, childrenAges: [] } from 'TWO_ADULT' (example)
 */
export const getCountFromBookingGuest = (
  guest: BookingGuestWithoutUnspecified
): IOccupancy => {
  const guestType = ROOM_OCCUPANCY_TYPES[guest];

  return {
    adults: guestType.totalAdultCount,
    children: guestType.totalChildCount,
    childrenAges: Array(guestType.totalChildCount).fill(null),
  };
};

/**
 * Gets translation key from the room object
 * E.g. {adults: 2, children: 0} -> 'twoAdult'
 * @param occupants
 * @returns
 */
export const getLabelTranslationKeyFromCount = (occupants: IOccupancy) => {
  const occupancyType = getBookingGuestFromCount(occupants);

  if (!occupancyType || occupancyType === V1Guest.Unspecified) return undefined;

  return ROOM_OCCUPANCY_TYPES[occupancyType].labelTranslationKey;
};

/**
 *  Takes a list of rooms and transforms it to a URL query object.
 *  E.g. [{ adults: 1, children: 1, childrenAges: [5] }] gets transformed to
 *  {
        'rooms[0].adults': '1',
        'rooms[0].children': '1',
        'rooms[0].childrenAges[0]': '5',
    }
 * @param rooms
 */
export const transformRoomOccupancyListToUrlQuery = (rooms: IOccupancy[]) => {
  const roomOccupancySearchParams = new URLSearchParams();

  for (let i = 0; i < rooms.length; ++i) {
    // Adult count
    roomOccupancySearchParams.set(
      `rooms[${i}].adults`,
      rooms[i].adults.toFixed()
    );
    // Children count
    if (rooms[i].children > 0) {
      roomOccupancySearchParams.set(
        `rooms[${i}].children`,
        rooms[i].children.toFixed()
      );
    }
    // Children ages
    for (let j = 0; j < rooms[i].childrenAges.length; ++j) {
      const childAge = rooms[i].childrenAges[j];
      if (childAge !== undefined) {
        roomOccupancySearchParams.set(
          `rooms[${i}].childrenAges[${j}]`,
          childAge.toFixed()
        );
      }
    }
    // Accessible rooms
    if (rooms[i].accessible) {
      roomOccupancySearchParams.set(`rooms[${i}].accessible`, 'true');
    }
  }

  return Object.fromEntries(roomOccupancySearchParams);
};
/**
 *
 * @param childrenAges
 */
export const mapChildrenAgesToValue = (
  childrenAges: IOccupancy['childrenAges']
) => childrenAges.map((age) => ({ value: age ? age.toString() : '' }));
