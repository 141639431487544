import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { API_DATE_FORMAT } from '@/api';
import { useActiveBrandConfig } from '@/brand';
import { useTranslation } from '@/i18n';
import { Checkbox } from '@/ui/form';
import { Spacer } from '@/ui/spacing';

import type { SearchFormFields } from '../validators/search-form.validator';

export const DayUseCheckboxInput = () => {
  const { t } = useTranslation('search');
  const { dayUse } = useActiveBrandConfig();

  const { register, setValue, getValues, watch } =
    useFormContext<
      Pick<SearchFormFields, 'dayUse' | 'rateCode' | 'to' | 'from'>
    >();

  const [rateCode, dayUseValue] = watch(['rateCode', 'dayUse']);

  useEffect(() => {
    if (rateCode === dayUse?.rateCode) {
      setValue('dayUse', ['dayUse']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rateCode]);

  if (!dayUse?.supported) return null;

  return (
    <>
      <Spacer s="xs" />
      <Checkbox
        label={t('form.dayUseCheckboxLabel')}
        isChecked={dayUseValue.length > 0}
        {...register('dayUse', {
          onChange: (event) => {
            if (event.currentTarget.checked) {
              setValue('rateCode', dayUse.rateCode);
              setValue('to', getValues('from'));
            } else {
              // Clear rate code
              setValue('rateCode', '');

              // Set the check out date to the day following the check in
              const checkout = dayjs(getValues('from'))
                .add(1, 'day')
                .format(API_DATE_FORMAT);
              setValue('to', checkout);
            }
          },
        })}
      />
    </>
  );
};
