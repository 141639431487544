import { css } from '@emotion/react';
import { animated, useTransition } from '@react-spring/web';
import React from 'react';

import { useTheme } from '@/ui/theme';

interface OverlayProps {
  isVisible: boolean;
  onClick?: () => void;
}

export const Overlay = ({ isVisible, onClick }: OverlayProps) => {
  const {
    componentProperties: { modals },
  } = useTheme();
  const transition = useTransition(isVisible, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      duration: 200,
    },
  });

  return transition(
    (styles, item) =>
      item && (
        <animated.div
          style={styles}
          css={css({
            background: 'rgba(0, 0, 0, 0.5)',
            width: '100vw',
            height: '100vh',
            position: 'fixed',
            zIndex: 1,
            top: 0,
            left: 0,
            ...modals?.overlay,
          })}
          onClick={onClick}
        />
      )
  );
};

export default Overlay;
