import { FontFace } from './FontFace.interface';
import { getCSSFontSrc } from './getCSSFontSrc.function';

export const createCSSFontFaceSourceString = (
  fontFace: FontFace,
  baseUrl: string
) =>
  Object.keys(fontFace.sources)
    .map((type) => {
      const src = fontFace.sources[type] as string;
      const url = new URL(src, baseUrl);
      return getCSSFontSrc(type, url.href);
    })
    .join(', ');
