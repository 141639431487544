export const AvailableSpaceKeys = [
  'none',
  'xxs',
  'xs',
  's',
  'm',
  'l',
  'xl',
  'xxl',
] as const;

export type AvailableSpaceKeyType = typeof AvailableSpaceKeys[number];
