import { css } from '@emotion/react';
import React from 'react';

interface MobileWrapperPaddingProps {
  children: React.ReactNode;
}

/**
 * Adds horizontal padding around it's children for mobile users only.
 */
export const MobileWrapperPadding = (props: MobileWrapperPaddingProps) => {
  return (
    <div
      css={css`
        @media all and (max-width: 720px) {
          padding-left: 20px;
          padding-right: 20px;
        }
      `}
    >
      {props.children}
    </div>
  );
};
