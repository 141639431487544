import { css } from '@emotion/react';
import React from 'react';

import { createArrayOfLength } from '@/common/utils/array';
import dropdownIndicator from '@/core/resources/svg/select-drop-arrow.svg';
import { useTranslation } from '@/i18n';
import { useTheme } from '@/ui/theme';

import { FieldErrorMessage, Label } from '.';

/**
 * A helper function to derive Select field's options from a max count.
 * @param maxCount e.g. 10
 * @param includeZero false by default
 * @returns e.g. ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']
 */
export const getOptionsFromMaxCount = (maxCount: number, includeZero = false) =>
  createArrayOfLength(includeZero ? maxCount + 1 : maxCount).map((index) => {
    const count = includeZero ? index : index + 1;
    return {
      label: count.toString(),
      value: count.toString(),
      disabled: false,
    };
  });

export interface SelectOption {
  label: string;
  value: string;
  disabled?: boolean;
}

interface SelectProps {
  name: string;
  label?: string;
  options: SelectOption[];
  isRequired?: boolean;
  placeholder?: string;
  /**
   * Is there a default value available for this select field? If set to false (default), add a ghost disabled item as the default selector.
   */
  hasDefault?: boolean;
  /**
   * Error message component holds whitespace when not rendered to avoid layout shift. Set hideError to true to stop this from happening.
   */
  hideError?: boolean;
  error?: string;
}

export const Select = React.forwardRef(
  (
    {
      options,
      name,
      label,
      isRequired,
      hideError,
      error,
      hasDefault,
      value,
      placeholder,
      ...rest
    }: SelectProps & React.SelectHTMLAttributes<HTMLSelectElement>,
    ref: React.ForwardedRef<HTMLSelectElement>
  ) => {
    const fieldId = `field-${name}`;
    const {
      fonts,
      colors,
      keylines,
      text,
      forms: { input: inputStyles },
    } = useTheme();
    const style = css`
      font-family: ${fonts.body};
    `;
    const padding = inputStyles?.padding ?? '16px';

    const { t } = useTranslation('common');

    const noSelectionLabel = placeholder ?? `${t('controls.select')}...`;

    return (
      <div css={style} data-test={`select-field-wrapper-${name}`}>
        {label && (
          <Label htmlFor={fieldId} isRequired={isRequired}>
            {label}
          </Label>
        )}
        <div
          css={css`
            position: relative;

            .select-spectre {
              font-family: ${fonts.body};
              font-size: ${inputStyles?.fontSize ?? text.bodyCopy.fontSize};
              background-color: ${inputStyles?.backgroundColor ??
              'transparent'};
              letter-spacing: ${inputStyles?.letterSpacing ?? 'normal'};
              line-height: 24px;
              width: 100%;
              padding: ${padding} 22px ${padding} ${padding};
              color: ${inputStyles?.color ??
              (value ? colors.textPrimary : '#777')};
              border: ${error
                ? `1px solid ${colors.error} `
                : inputStyles?.border ?? keylines?.border};
              pointer-events: none;
              white-space: nowrap;
              border-radius: ${inputStyles?.borderRadius ?? '4px'};
            }

            select {
              font-family: ${fonts.body};
              font-size: ${inputStyles?.fontSize ?? text.bodyCopy.fontSize};
              background: transparent;

              border: none;
              line-height: 24px;
              width: 100%;
              color: ${value ? colors.textPrimary : '#777'};
              height: 100%;
              opacity: 0;
              position: absolute;
              top: 0;

              &:focus + .select-spectre {
                outline: 1px solid #8f8f8f;
                outline-offset: 2px;
              }
            }

            &:after {
              width: 10px;
              height: 100%;
              content: '';
              display: block;
              position: absolute;
              right: 10px;
              top: 0px;
              pointer-events: none;
              background: url(${dropdownIndicator.src}) no-repeat center;
            }
          `}
        >
          <select
            ref={ref}
            name={name}
            data-test={`select-${name}`}
            id={fieldId}
            aria-describedby={`${fieldId}-error`}
            aria-required={isRequired}
            value={value}
            {...rest}
          >
            {!hasDefault && (
              <option value="" label={noSelectionLabel} disabled />
            )}
            {options.map((option) => {
              return (
                <option
                  value={option.value}
                  disabled={option.disabled}
                  key={option.value}
                >
                  {option.label}
                </option>
              );
            })}
          </select>
          <div className="select-spectre" tabIndex={-1}>
            {options.find((option) => option.value === value)?.label ??
              noSelectionLabel}
          </div>
        </div>
        {hideError ? null : (
          <FieldErrorMessage
            fieldName={name}
            message={error ?? undefined}
            isVisible={Boolean(error)}
          />
        )}
      </div>
    );
  }
);

Select.displayName = 'Select';
