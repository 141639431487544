import React from 'react';

import { useActiveBrandConfig } from '@/brand';

import { getTheme } from '../theme/getTheme.function';
import FontPreloader from './FontPreloader.component';

/**
 * Renders pre-loading <link /> tags for fonts defined in the default brand theme.
 * @param props
 */
export const ThemeFontPreloader = () => {
  const { themeKey } = useActiveBrandConfig();
  const theme = getTheme(themeKey);

  return (
    <FontPreloader
      fontSources={theme.fontFaceDefinitions.flatMap((font) =>
        Object.values(font.sources)
      )}
    />
  );
};

export default ThemeFontPreloader;
