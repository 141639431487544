import { getRandomNumber } from './number';

export const createArrayOfLength = (length: number) =>
  Array(length)
    .fill(undefined)
    .map((_, index) => index);
export const filterTruthy = <T>(array: T[]) =>
  array.filter(isTruthy) as Required<T>[];

export const getLastItem = <T>(array: T[]): T | undefined =>
  array[array.length - 1];

export const getRandomItem = <T>(array: T[]): T =>
  array[getRandomNumber(array.length - 1)];

/**
 * Typesafe function for use in filtering.
 *
 * Example:
 * ```
 * const optionalOrPresent: (string | undefined)[] = [undefined, 'foo', 'bar'];
 * optionalOrPresent.filter(isTruthy); // will have the type: string[]
 * ```
 * @param value
 * @returns
 */
export function isTruthy<T>(value?: T | undefined | null | false): value is T {
  return !!value;
}
