import { css } from '@emotion/react';
import React from 'react';

import ButtonWithIcon, {
  ButtonWithIconProps,
} from './ButtonWithIcon.component';

interface ButtonWithActionProps
  extends Omit<ButtonWithIconProps, 'icon' | 'iconSrc'> {
  actionText: string;
}

export const ButtonWithAction = ({
  actionText,
  ...rest
}: ButtonWithActionProps) => {
  return (
    <ButtonWithIcon
      {...rest}
      icon={
        <div
          css={css`
            opacity: 0.56;

            button:hover:not(:disabled) & {
              opacity: 1;
            }
          `}
        >
          {actionText}
        </div>
      }
    />
  );
};

export default ButtonWithAction;
