import { css } from '@emotion/react';
import React, { useRef } from 'react';

import { capitalizeString } from '@/common/helpers/text';
import type { CurrencyCode } from '@/finance/interfaces';
import {
  getLocalisedCurrencyName,
  getLocalisedCurrencySymbol,
} from '@/finance/utils/formatting';
import { useTranslation } from '@/i18n';
import { getLocalisedLanguageLabel } from '@/i18n/language-labels';
import { Divider } from '@/ui/layout';

import { ListBox, TabGroup } from '../controls';
import { Spacer } from '../spacing';
import { BodySmall, Heading } from '../typography';
import { SideDrawer } from './SideDrawer.component';

const LanguageOption = React.forwardRef(
  (
    props: {
      language: Intl.BCP47LanguageTag;
      onLanguageSelect: (language: Intl.BCP47LanguageTag) => void;
      selected: boolean;
      onClick?: React.MouseEventHandler<HTMLButtonElement>;
    },
    ref: React.Ref<HTMLButtonElement>
  ) => (
    <ListBox.Option
      ref={ref}
      optionName={props.language}
      selected={props.selected}
      onSelect={(e) => {
        props.onLanguageSelect(props.language);
        // closes the drawer
        props.onClick?.(e);
      }}
      ariaLabel={capitalizeString(getLocalisedLanguageLabel(props.language))}
    >
      <BodySmall textAlign="left">
        {capitalizeString(getLocalisedLanguageLabel(props.language))}
      </BodySmall>
      <div
        css={css`
          min-width: 20px;
        `}
      >
        <BodySmall noWrap>{props.language.toUpperCase()}</BodySmall>
      </div>
    </ListBox.Option>
  )
);
LanguageOption.displayName = 'LanguageOption';

const CurrencyOption = React.forwardRef(
  (
    props: {
      currencyCode: CurrencyCode;
      onCurrencySelect: (currency: CurrencyCode) => void;
      selected: boolean;
      locale: Intl.BCP47LanguageTag;
      onClick?: React.MouseEventHandler<HTMLButtonElement>;
    },
    ref: React.Ref<HTMLButtonElement>
  ) => (
    <ListBox.Option
      ref={ref}
      optionName={props.currencyCode}
      selected={props.selected}
      onSelect={(e) => {
        props.onCurrencySelect(props.currencyCode);
        // closes the drawer
        props.onClick?.(e);
      }}
      ariaLabel={`${capitalizeString(
        getLocalisedCurrencyName(props.locale, props.currencyCode)
        // Splitting the currency code here by full stops so it's pronounced letter after letter
        // by screen readers. AND -> A.N.D. - prevents cases where currency code might get read as a word.
      )}, currency code ${props.currencyCode?.split('')?.join('.')}.`}
    >
      <BodySmall textAlign="left">
        {capitalizeString(
          getLocalisedCurrencyName(props.locale, props.currencyCode)
        )}
      </BodySmall>
      <BodySmall noWrap>{`${props.currencyCode} - ${getLocalisedCurrencySymbol(
        props.locale,
        props.currencyCode
      )}`}</BodySmall>
    </ListBox.Option>
  )
);
CurrencyOption.displayName = 'CurrencyOption';

interface LanguageAndCurrencyDrawerProps {
  isOpen: boolean;
  setOpen: (open: boolean) => void;
  availableLanguages: Intl.BCP47LanguageTag[];
  availableCurrencies: {
    common?: CurrencyCode[];
    other: CurrencyCode[];
  };
  selectedLanguage: Intl.BCP47LanguageTag;
  selectedCurrency: CurrencyCode | undefined;
  baseCurrency: CurrencyCode | undefined;
  onLanguageSelect: (language: string) => void;
  onCurrencySelect: (currency: CurrencyCode) => void;
  children: React.ReactNode;
  defaultTab: 'language' | 'currency';
}

export function LanguageAndCurrencyDrawer(
  props: LanguageAndCurrencyDrawerProps
) {
  const { t } = useTranslation(['languageDrawer', 'payment']);

  const defaultTabRef = useRef<HTMLButtonElement>(null);

  const languageTab =
    // only show the language tab if multiple locales are supported
    props.availableLanguages.length > 1
      ? {
          trigger: {
            label: t('languageDrawer:tabLabel'),
            ariaLabel: t('languageDrawer:title'),
          },
          content: (
            <>
              <Heading style="heading5" as="h5">
                {t('languageDrawer:title')}
              </Heading>
              <Spacer s="l" />
              <ListBox
                selectedOption={props.selectedLanguage}
                ariaLabel={t('languageDrawer:tabLabel')}
              >
                {props.availableLanguages.map((locale) => (
                  <SideDrawer.Close key={locale} asChild>
                    <LanguageOption
                      key={locale}
                      language={locale}
                      selected={locale === props.selectedLanguage}
                      onLanguageSelect={props.onLanguageSelect}
                    />
                  </SideDrawer.Close>
                ))}
              </ListBox>
            </>
          ),
        }
      : null;

  const currencyTab = props.selectedCurrency
    ? {
        trigger: {
          label: t('payment:currencyDrawer.tabLabel'),
          ariaLabel: t('payment:currencyDrawer.title'),
        },
        content: (
          <>
            <Heading style="heading5" as="h5">
              {t('payment:currencyDrawer.title')}
            </Heading>
            {props.baseCurrency ? (
              <>
                <Spacer s="xxs" />
                <BodySmall color="textSecondary" aria-live="polite">
                  {t('payment:currencyDrawer.disclaimer', {
                    currencyCode: props.baseCurrency,
                    currencySymbol: getLocalisedCurrencySymbol(
                      props.selectedLanguage,
                      props.baseCurrency
                    ),
                  })}
                </BodySmall>
              </>
            ) : null}
            <Spacer s="xl" />
            <ListBox
              selectedOption={props.selectedCurrency}
              ariaLabel={t('payment:currencyDrawer.tabLabel')}
            >
              {props.availableCurrencies.common ? (
                <>
                  {props.availableCurrencies.common.map((currencyCode) => (
                    <SideDrawer.Close key={currencyCode} asChild>
                      <CurrencyOption
                        key={currencyCode}
                        currencyCode={currencyCode}
                        selected={props.selectedCurrency === currencyCode}
                        onCurrencySelect={props.onCurrencySelect}
                        locale={props.selectedLanguage}
                      />
                    </SideDrawer.Close>
                  ))}
                  <Spacer s="m" />
                  <Divider />
                  <Spacer s="m" />
                </>
              ) : null}
              {props.availableCurrencies.other.map((currencyCode) => (
                <SideDrawer.Close key={currencyCode} asChild>
                  <CurrencyOption
                    currencyCode={currencyCode}
                    selected={props.selectedCurrency === currencyCode}
                    onCurrencySelect={props.onCurrencySelect}
                    locale={props.selectedLanguage}
                  />
                </SideDrawer.Close>
              ))}
            </ListBox>
            <Spacer s="m" />
          </>
        ),
      }
    : null;

  return (
    <SideDrawer
      isOpen={props.isOpen}
      setOpen={props.setOpen}
      trigger={props.children}
      onOpenAutoFocus={(e) => {
        e.preventDefault();
        defaultTabRef.current?.focus();
      }}
    >
      <TabGroup
        ref={defaultTabRef}
        defaultTab={
          {
            currency: t('payment:currencyDrawer.tabLabel'),
            language: t('languageDrawer:tabLabel'),
          }[props.defaultTab]
        }
        tabs={[
          ...(languageTab ? [languageTab] : []),
          ...(currencyTab ? [currencyTab] : []),
        ]}
      />
    </SideDrawer>
  );
}

LanguageAndCurrencyDrawer.Trigger = SideDrawer.Trigger;
