import { css } from '@emotion/react';
import React from 'react';

import { Spacer } from '@/ui/spacing';

import DropdownIcon from '../icons/DropdownIcon.component';
import DataTableRow from './DataTableRow.component';

interface CollapsibleDataTableRowProps {
  label: React.ReactNode;
  value: React.ReactNode;
  collapsibleContent: React.ReactNode;
}

export const CollapsibleDataTableRow = (
  props: CollapsibleDataTableRowProps
) => {
  const [isCollapsed, setIsCollapsed] = React.useState(true);

  return (
    <>
      <a
        css={css`
          cursor: pointer;
        `}
        role="button"
        onClick={() => setIsCollapsed((state) => !state)}
      >
        <DataTableRow
          label={props.label}
          value={
            <>
              {props.value}{' '}
              <span
                style={{
                  position: 'relative',
                  top: -2,
                  marginLeft: 5,
                  display: 'inline-block',
                }}
              >
                <DropdownIcon isExpanded={!isCollapsed} />
              </span>
            </>
          }
        />
      </a>
      {isCollapsed ? undefined : (
        <React.Fragment>
          <Spacer s="xs" />
          {props.collapsibleContent}
        </React.Fragment>
      )}
    </>
  );
};
