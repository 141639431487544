const regex = /{{\s*([^}\s]+)\s*}}/g;

/**
 * Creates a rudimentary renderer for a handlebar-ish string template.
 * @param body
 * @param vars
 */
export function createStringTemplate<
  Vars extends Record<string, string | undefined>
>() {
  return (body: string, vars: Vars) =>
    body.replace(regex, (_, match) => vars[match] || '');
}
