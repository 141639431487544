import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

import type { IFinancialAmountInstance } from '@/finance';

import WeekCalendar from './WeekCalendar.component';

interface ConnectedWeekCalendarProps {
  dates: {
    date: string;
    price?: IFinancialAmountInstance;
    isAvailable: boolean;
    isLoaded: boolean;
  }[];
  onDateRangeChange: (start: string, end: string) => void;
  initialDateRange: { start?: string; end?: string };
  onNavigateLeft: () => void;
  onNavigateRight: () => void;
  isLoading: boolean;

  /**
   * Fired when the date is clicked. Date changing is handled internally, this is for analytics.
   */
  onDateClicked?: (date: string) => void;
}

export const ConnectedWeekCalendar = (props: ConnectedWeekCalendarProps) => {
  const [startDate, setStartDate] = useState<string | null>(
    props.initialDateRange.start || null
  );
  const [endDate, setEndDate] = useState<string | null>(
    props.initialDateRange.end || null
  );

  useEffect(() => {
    setStartDate(props.initialDateRange.start || null);
    setEndDate(props.initialDateRange.end || null);
  }, [props.initialDateRange.start, props.initialDateRange.end]);

  return (
    <WeekCalendar
      month={dayjs(props.dates[0]?.date).format('MMMM')}
      dates={props.dates}
      isLoading={props.isLoading}
      onDateClicked={(date) => {
        props.onDateClicked && props.onDateClicked(date);

        // If there's an end date set, clear it and assume user is selecting a new period.
        if (endDate) {
          setEndDate(null);
          setStartDate(date);
          return;
        }
        // If user is selecting the end date, and it's before the start date, assume user is attempting to change the start date instead.
        if (startDate && dayjs(date).isBefore(startDate)) {
          setStartDate(date);
          return;
        }

        // Is the end date the same as the start? If so, exit
        if (startDate && dayjs(startDate).isSame(date, 'date')) return;

        setEndDate(date);
        if (startDate) {
          props.onDateRangeChange(startDate, date);
        }
      }}
      selectionStartDate={startDate || undefined}
      selectionEndDate={endDate || undefined}
      onDecrementWeeklyView={props.onNavigateLeft}
      onIncrementWeeklyView={props.onNavigateRight}
    />
  );
};

export default ConnectedWeekCalendar;
