import {
  TRPCClientError,
  createTRPCProxyClient,
  httpBatchLink,
  httpLink,
} from '@trpc/client';
import { createTRPCNext } from '@trpc/next';
import type { TRPC_ERROR_CODE_KEY } from '@trpc/server/rpc';

import { getEnvironmentKey } from '@/env';

import type { AppRouter } from '../server/routers/app.router';

function getBaseUrl() {
  // when running in Jest, use MSW
  if (typeof process.env.JEST_WORKER_ID !== 'undefined') {
    return process.env.TRPC_MSW_BASE_URL;
  }
  if (typeof window !== 'undefined')
    // browser should use relative path
    return '';
  if (process.env.NEXT_PUBLIC_VERCEL_URL)
    // reference for vercel.com
    return `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`;
  if (process.env.RENDER_INTERNAL_HOSTNAME)
    // reference for render.com
    return `http://${process.env.RENDER_INTERNAL_HOSTNAME}:${process.env.PORT}`;
  // assume localhost
  return `http://localhost:${process.env.PORT ?? 3000}`;
}

function logErrorTraceDetails(error: unknown) {
  if (error instanceof TRPCClientError) {
    const traceId = error.shape.tracing?.datadog?.traceId;
    const env = getEnvironmentKey();

    if (traceId) {
      // Emit this in any environment so FullStory etc. picks it up
      console.log(`^ Trace #[${traceId}]`, env);

      // For now emitting the URL only in dev + staging
      if (env === 'dev' || env === 'staging') {
        console.log(
          `Trace available (once ingested) at: https://app.datadoghq.eu/apm/trace/${traceId}?env=${env}`
        );
      }
    }
  }
}

const url = `${getBaseUrl()}/api/trpc`;
const linkProps = {
  /**
   * If you want to use SSR, you need to use the server's full URL
   * @link https://trpc.io/docs/ssr
   **/
  url,
};

export const trpc = createTRPCNext<AppRouter>({
  config({ ctx }) {
    return {
      links: [
        // Disable batching if in Jest (msw-trpc doesn't support batching)
        process.env.JEST_WORKER_ID
          ? httpLink(linkProps)
          : httpBatchLink(linkProps),
      ],
      /**
       * @link https://tanstack.com/query/v4/docs/reference/QueryClient
       **/
      queryClientConfig: {
        defaultOptions: {
          mutations: {
            onError(error) {
              logErrorTraceDetails(error);
            },
          },
          queries: {
            onError(error) {
              logErrorTraceDetails(error);
            },
            retry(count, error) {
              if (error instanceof TRPCClientError) {
                switch (error.data.code as TRPC_ERROR_CODE_KEY) {
                  // If the error is due to an invalid auth session, skip retrying.
                  case 'FORBIDDEN':
                    return false;
                }
              }

              return count < 2;
            },
          },
        },
      },
    };
  },
  /**
   * @link https://trpc.io/docs/ssr
   **/
  ssr: false,
});

/**
 * For use without hooks.
 */
export const trpcClient = createTRPCProxyClient<AppRouter>({
  links: [
    process.env.JEST_WORKER_ID ? httpLink(linkProps) : httpBatchLink(linkProps),
  ],
});
