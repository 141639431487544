import React from 'react';

import { APIError, DomainError } from '@/errors';
import { DEFAULT_TRANSLATION_STRINGS, useTranslation } from '@/i18n';

import { ErrorMessage, ErrorMessageProps } from '.';

interface TranslatedErrorMessageProps
  extends Omit<ErrorMessageProps, 'message'> {
  dictionaryKey: keyof typeof DEFAULT_TRANSLATION_STRINGS['en-GB']['errors'];
}

interface TranslatedErrorMessageFromDomainErrorProps {
  domainError: APIError;
}

export const TranslatedErrorMessage: React.FC<
  TranslatedErrorMessageProps | TranslatedErrorMessageFromDomainErrorProps
> = (props) => {
  const dictionaryKey =
    'dictionaryKey' in props
      ? props.dictionaryKey
      : new DomainError(props.domainError).messageTranslationKey;

  const { t } = useTranslation('errors');
  return <ErrorMessage message={t(dictionaryKey)} {...props} />;
};
