import { useQueryClient } from 'react-query';

import { BookingRequest } from '../interfaces';

/**
 * Returns a function that will invalidate the given booking within the cache.
 * Use this when you want to trigger a reload of data.
 * @param request
 * @returns
 */
export const useInvalidateBookingSummary = (request: BookingRequest) => {
  const client = useQueryClient();
  return () => client.invalidateQueries(['bookingSummary', request]);
};
