import { css } from '@emotion/react';
import React from 'react';

import tickSvg from '@/hox-core/resources/controls/tick.svg';

import { LoadingIndicator } from '../controls';
import { useTheme } from '../theme';
import { BodyCopy } from '../typography';

interface CheckboxProps {
  name: string;
  label: React.ReactNode;
  isError?: boolean;
  noWrapLabel?: boolean;
  isChecked?: boolean;
  isRequired?: boolean;
  isLoading?: boolean;
}

export const Checkbox = React.forwardRef(
  (
    {
      name,
      label,
      isError,
      noWrapLabel,
      isChecked,
      isRequired,
      isLoading,
      ...rest
    }: CheckboxProps & React.InputHTMLAttributes<HTMLInputElement>,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const fieldId = `field-${name}`;
    const { colors, keylines, forms, text } = useTheme();

    return (
      <div
        className={isError ? 'error' : undefined}
        css={css`
          max-width: 65ch;

          display: flex;
          align-items: center;
          position: relative;

          label {
            user-select: none;
            color: ${isError ? colors.error : colors.textPrimary};
            display: flex;
            cursor: pointer;
            white-space: ${noWrapLabel ? 'nowrap' : 'normal'};
            flex: 1;
            span {
              font-size: ${forms?.label?.fontSize ?? text?.bodyCopy?.fontSize};
            }
          }

          input {
            position: absolute;
            width: 16px;
            height: 16px;
            opacity: 0;

            &.focus-visible + .box {
              border: 1px solid #222222;
              outline: 1px solid ${colors.secondary300};
              outline-offset: 2;
            }
          }
          .box {
            width: 25px;
            height: 25px;
            background-color: ${forms?.input?.backgroundColor ?? 'transparent'};
            border: ${forms?.input?.border ?? keylines?.border};
            margin-right: 16px;
            margin-top: -1px;
            position: relative;
            border-radius: ${forms?.checkbox?.borderRadius ?? '3px'};
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .tick {
            img {
              width: 12px;
            }
          }
          .spinner {
            width: 100%;
          }
          .title {
            flex: 1;
            position: relative;
            top: -2px;
          }

          &.error {
            label {
              color: ${colors.error};
            }
            input {
              border-bottom: 1px solid ${colors.error};
            }

            &.empty {
              ::placeholder {
                color: ${colors.error};
              }
            }
          }
        `}
      >
        <label>
          <input
            ref={ref}
            name={name}
            type="checkbox"
            id={fieldId}
            data-test={fieldId}
            tabIndex={0}
            value={name}
            checked={isChecked}
            aria-checked={isChecked}
            aria-required={isRequired}
            disabled={isLoading}
            aria-busy={isLoading}
            {...rest}
          />
          <div className="box">
            {isLoading ? (
              <div className="spinner">
                <LoadingIndicator style="dark" width={12} height={12} />
              </div>
            ) : isChecked ? (
              <div className="tick">
                <img src={tickSvg.src} alt="" />
              </div>
            ) : null}
          </div>
          <div css={{ flex: 1 }}>
            <div className="title">
              <BodyCopy color={isError ? 'error' : 'textPrimary'}>
                {label}
              </BodyCopy>
            </div>
          </div>
        </label>
      </div>
    );
  }
);

Checkbox.displayName = 'Checkbox';
