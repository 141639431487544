import { useEffect } from 'react';

import { BookingRequest } from '..';
import { useBookingSummary } from './use-booking-summary.hook';

/**
 * When the booking matching the given bookingRequest information returns 404, fires bookingExpiredCallback.
 * @param bookingRequest
 * @param bookingExpiredCallback
 */
export const useBookingExpiredEvent = (
  bookingRequest: BookingRequest,
  bookingExpiredCallback: () => void
) => {
  const booking = useBookingSummary(bookingRequest);

  useEffect(() => {
    if (booking.error?.isBookingNotFound) {
      bookingExpiredCallback();
    }
  }, [booking.error?.isBookingNotFound]);
};
