import { css } from '@emotion/react';
import * as RadixTabs from '@radix-ui/react-tabs';
import React from 'react';

import { useTheme } from '../theme';

type TabsGroupProps = {
  tabs: {
    trigger: { label: string; ariaLabel?: string };
    content: React.ReactNode;
  }[];
  defaultTab?: string;
};

const tabListStyle = css`
  display: flex;
  width: 100%;
  height: 97px;
  align-items: end;
`;

const tabContentStyle = css`
  height: calc(100vh - 97px);
  padding: 32px 25px 0px;
  overflow: auto;
`;

export const TabGroup = React.forwardRef(
  (
    { tabs, defaultTab }: TabsGroupProps,
    ref?: React.Ref<HTMLButtonElement>
  ) => {
    const { keylines, colors, text } = useTheme();

    const tabLabelStyle = css`
      flex: 1;
      display: flex;
      justify-content: center;
      border-bottom: ${keylines?.border || '1px solid #3e3a37'};
      padding-top: 16px;
      padding-bottom: 16px;
      color: ${colors.textPrimary};
      font-family: ${text.bodySmall.fontFamily};
      font-size: ${text.bodySmall.fontSize};
      line-height: ${text.bodySmall.lineHeight};
      letter-spacing: 0.03em;

      &[aria-selected='false'] {
        opacity: 0.8;
        border-bottom-color: #e2d9cd;
      }
    `;

    return (
      <RadixTabs.Root defaultValue={defaultTab}>
        <RadixTabs.List css={tabListStyle}>
          {tabs.map((tab) => (
            <RadixTabs.Trigger
              key={`tab-label-${tab.trigger.label}`}
              ref={defaultTab === tab.trigger.label ? ref : undefined}
              value={tab.trigger.label}
              aria-label={tab.trigger.ariaLabel}
              css={tabLabelStyle}
            >
              {tab.trigger.label}
            </RadixTabs.Trigger>
          ))}
        </RadixTabs.List>
        {tabs.map((tab) => (
          <RadixTabs.Content
            key={`tab-content-${tab.trigger.label}`}
            value={tab.trigger.label}
            css={tabContentStyle}
          >
            {tab.content}
          </RadixTabs.Content>
        ))}
      </RadixTabs.Root>
    );
  }
);

TabGroup.displayName = 'TabGroup';
