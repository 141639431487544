import { z } from 'zod';

import { partialSafeParse } from '@/zod';

import { dateFormatRegex } from '../helpers/date';
import {
  RoomOccupancySearchUrlQuerySchema,
  transformRoomOccupancySearchUrlQuery,
} from './room-occupancy-search-url-query.validator';

/**
 * Validates the url schema and transforms rooms to the correct format
 *
 * NB: Reason for moving from AvailabilitySearchUrlQuerySchema, is to allow both to be used by partialSafeParse
 */
const AvailabilitySearchUrlQuerySchemaRooms = z
  .object({})
  .catchall(z.union([z.string(), z.array(z.string()), z.undefined()]))
  .transform((query) => {
    const rooms = transformRoomOccupancySearchUrlQuery(query);

    return { ...query, rooms };
  });

/**
 * This schema represents the structure of the URL query itself.
 * Audience will mostly be website developers implementing connections, so not
 * worrying too much about translations for the time being.
 *
 * NB: Separate to the availability search form validation.
 */
export const AvailabilitySearchUrlQuerySchema = z.object({
  hotelCode: z.string(),
  rateCode: z.string().optional(),
  checkin: z
    .string()
    .regex(dateFormatRegex, 'Please enter a valid check-in date'),
  checkout: z
    .string()
    .regex(dateFormatRegex, 'Please enter a valid check-out date'),
  rooms: RoomOccupancySearchUrlQuerySchema,
  dayUse: z
    .string()
    .optional()
    .transform((value) => value === 'true' || value === '1'),
  accessible: z
    .string()
    .optional()
    .transform((value) => value === 'true' || value === '1'),
});

export type AvailabilitySearchUrlQuery = z.infer<
  typeof AvailabilitySearchUrlQuerySchema
>;

/**
 * Validates the given object against the AvailabilitySearchUrlQuerySchema, and returns
 * an instance of AvailabilitySearchUrlQuery if valid.
 */
export const validateSearchUrlQuery = (
  query: unknown,
  options: {
    validateHotelCode: (hotelCode: string) => boolean;
  }
) => {
  const queryWithTransformedRooms =
    AvailabilitySearchUrlQuerySchemaRooms.parse(query);

  return partialSafeParse(
    AvailabilitySearchUrlQuerySchema,
    queryWithTransformedRooms,
    (values, ctx) => {
      if (!options.validateHotelCode(values.hotelCode)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Incorrect hotel code.',
          path: ['hotelCode'],
        });
      }
    }
  );
};
