import React from 'react';

import { Flex } from '@/box';
import { Card } from '@/core/components';

import { LoadingIndicator } from '../controls';

interface LoadingCardProps {
  children?: React.ReactNode;
}

export const LoadingCard: React.FC<LoadingCardProps> = (props) => {
  return (
    <Card>
      <LoadingIndicator style="dark" />
      <Flex justifyContent={'center'}>{props.children}</Flex>
    </Card>
  );
};
