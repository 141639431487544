import { V1Guest } from '@ennismore/ows-api-client';
import dayjs from 'dayjs';
import { useState } from 'react';

import { API_DATE_FORMAT, yesNoToBool } from '@/api';
import {
  getWeeklyCalendarSurroundingWeek,
  selectCalendarSearchStartDate,
} from '@/availability/helpers/calendar-date';
import { FinancialAmountModel } from '@/finance';
import type { IFinancialAmountInstance } from '@/finance';

import { useAvailabilityCalendar } from './use-availability-calendar.hook';

export const useAvailabilityCalendarDates = (
  args: {
    selectedCheckInDate: string;
    selectedCheckOutDate: string;
    roomOccupants: V1Guest;
    hotelReferenceId: string;
    roomCode: string;
    requestRateCode: string;
  },
  options: {
    /**
     * For lazy loading calendar days.
     * This should be set to 'false' if the parent component is out of view.
     */
    enabled: boolean;
  } = { enabled: true }
) => {
  const [calendarWeekCursor, setCalendarWeekCursor] = useState(0);

  // Apply the offset to the check in date
  const checkInDateAfterOffset = dayjs(args.selectedCheckInDate)
    .add(calendarWeekCursor, 'week')
    .format(API_DATE_FORMAT);

  // Fetch an array containing every day of the week surrounding the offset check in date
  const weeklyDates = getWeeklyCalendarSurroundingWeek(checkInDateAfterOffset);

  const { data, isLoading } = useAvailabilityCalendar(
    {
      from: selectCalendarSearchStartDate(weeklyDates),
      to: weeklyDates[weeklyDates.length - 1],
      guest: args.roomOccupants,
      roomCode: [args.roomCode],
      rateCode: [args.requestRateCode],
      hotelReferenceId: args.hotelReferenceId,
    },
    options
  );

  // The API returns a list of rooms and their calendars.
  // Find the one matching the code for the room we searched for.
  const roomCalendar = data?.roomCalendar.find(
    (calendar) => calendar.room.code === args.roomCode
  );

  // Reconcile our weekly dates with the prices and availability returned from the calendar endpoint.
  const dates: {
    date: string;
    price?: IFinancialAmountInstance;
    isAvailable: boolean;
    isLoaded: boolean;
  }[] = weeklyDates.map((weekDate) => {
    const calendarEntry = roomCalendar?.calendar.find(
      (calendarDate) => calendarDate.date === weekDate
    );

    if (!calendarEntry) {
      return {
        date: weekDate,
        price: undefined,
        isAvailable: false,
        isLoaded: true,
      };
    }

    const calendarEntryForSelectedRateCode =
      calendarEntry.calendarDayAvailability.find(
        (avail) => avail.rateCode.requestRateCode === args.requestRateCode
      );

    if (!calendarEntryForSelectedRateCode) {
      return {
        date: weekDate,
        price: undefined,
        isAvailable: false,
        isLoaded: true,
      };
    }

    const amount = calendarEntryForSelectedRateCode.rateCode.amount
      ? FinancialAmountModel.parse(
          calendarEntryForSelectedRateCode.rateCode.amount
        )
      : undefined;

    return {
      date: weekDate,
      isAvailable: yesNoToBool(calendarEntryForSelectedRateCode.bookable),
      isLoaded: true,
      price: amount,
    };
  });

  return {
    isLoading,
    dates,
    moveForwardOneWeek() {
      setCalendarWeekCursor((current) => current + 1);
    },
    moveBackOneWeek() {
      setCalendarWeekCursor((current) => current - 1);
    },
  };
};
