import { useEffect } from 'react';

import { useAppEvents } from '@/events';
import { FeatureFlagEvents } from '@/flags/events';

import { useDisloyaltyDummyRateFlag } from './useDisloyaltyDummyRateFlag.hook';

/**
 * Hacked-in directly for the time being whilst we evaluate Hypertune.
 * Evaluates the flag in one spot, to stop duplicate events firing.
 * @param args
 */
export function useDisloyaltyDummyRateFlagTracking(args: {
  hotelReferenceId: string | undefined;
}) {
  const { hotelReferenceId = '' } = args;
  const events = useAppEvents<FeatureFlagEvents>();
  const showDummyRate = useDisloyaltyDummyRateFlag({ hotelReferenceId });

  useEffect(() => {
    events.emit('disloyaltyDummyRateEvaluation', { result: showDummyRate });
  }, [showDummyRate, events]);
}
