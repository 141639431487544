import { css } from '@emotion/react';
import React from 'react';

import { useTheme } from '@/ui/theme';

interface PageProps {
  children: React.ReactNode;
  className?: string;
}

export const Page = (props: PageProps) => {
  const {
    componentProperties: { page },
  } = useTheme();
  return (
    <div
      className={props.className}
      css={css`
        width: 100%;
        height: 100%;
        min-height: 100vh;
        background: ${page?.background ?? 'rgb(240, 240, 240)'};
      `}
    >
      {props.children}
    </div>
  );
};

export default Page;
