import { css } from '@emotion/react';
import React from 'react';

import Card from '@/core/components/layout/Card.component';
import { useTranslation } from '@/i18n';
import { Button } from '@/ui/controls';
import { Spacer } from '@/ui/spacing';
import { useTheme } from '@/ui/theme';
import { BodyCopy, Heading } from '@/ui/typography';

interface AvailabilitySearchErrorCardProps {
  onModifySearchRequested?: () => void;
  onRetryRequested?: () => void;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  description: React.ReactNode;
  button?: React.ReactNode;
}

export const AvailabilitySearchErrorCard = (
  props: AvailabilitySearchErrorCardProps
) => {
  const { t } = useTranslation(['common', 'search']);
  const {
    componentProperties: { search },
  } = useTheme();
  return (
    <Card
      css={css({
        ['@media all and (max-width: 730px)']: search?.errorCard?.mobile,
      })}
    >
      <div
        css={css`
          padding: 20px 0;
        `}
      >
        {props.title && (
          <>
            <Heading style="heading4" as="h4" textAlign="center">
              {props.title}
            </Heading>
            <Spacer s="xs" />
          </>
        )}

        {props.subtitle && (
          <>
            <Heading style="heading5" as="h5" textAlign="center">
              {props.subtitle}
            </Heading>
            <Spacer s="xs" />
          </>
        )}

        <div
          css={css`
            text-align: center;
            max-width: 500px;
            margin: 0 auto;
          `}
        >
          <BodyCopy role="alert" aria-live="polite">
            {props.description}
          </BodyCopy>

          {props.button && (
            <>
              <Spacer s="m" />
              {props.button}
            </>
          )}

          {props.onModifySearchRequested && (
            <>
              <Spacer s="m" />
              <Button onClick={props.onModifySearchRequested}>
                {t('search:modifySearchButtonLabel')}
              </Button>
            </>
          )}

          {props.onRetryRequested && (
            <>
              <Spacer s="m" />
              <Button onClick={props.onRetryRequested}>
                {t('common:controls.retry')}
              </Button>
            </>
          )}
        </div>
      </div>
    </Card>
  );
};
