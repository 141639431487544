import { V1GetAvailabilityRequest } from '@ennismore/booking-api-client';

import { resolveHotelReferenceIdFromHotelCode } from '@/hotel/codes';
import { DAY_USE_CODE } from '@/hotel/constants';
import { IOccupancy } from '@/hotel/models';

import { IAvailabilitySearchQueryInstance } from '../models';

export const SearchQueryTransformers = {
  /**
   * Transforms an instance of IAvailabilitySearchQuery into V1GetAvailabilityRequest, compatible with the Booking API.
   *
   * Accepts locale, discount code type and the current room occupancy selection. V1GetAvailabilityRequest is for a
   * single room search, so we pass the room occupancy for the current room.
   */
  toV1GetAvailabilityRequest(context: {
    query: IAvailabilitySearchQueryInstance;
    occupancy: IOccupancy;
    ohipDiscountCodeType?: 'PROMO' | 'CORPORATE' | 'NONE';
    locale: Intl.Locale;
  }): V1GetAvailabilityRequest & { locale: { language: string } } {
    const { query, occupancy, ohipDiscountCodeType, locale } = context;

    const hotelReferenceId = resolveHotelReferenceIdFromHotelCode(
      query.hotelCode
    );

    // If dayUse flag is selected, use the day use code, otherwise map the rate code
    const discountCode = query.dayUse ? DAY_USE_CODE : query.rateCode;
    const discountCodeType = discountCode ? ohipDiscountCodeType : undefined;

    return {
      checkInDate: query.from,
      checkOutDate: query.to,
      discountCode,
      discountCodeType,
      hotelReferenceId,
      numberOfRooms: query.rooms.length,
      locale: { language: locale.baseName },
      guests: {
        ...occupancy,
        // filter undefined children ages
        childrenAges: occupancy.childrenAges.filter(
          (age) => age !== null && typeof age !== 'undefined'
        ) as number[],
      },
    };
  },
};
