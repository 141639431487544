import { css } from '@emotion/react';
import React from 'react';

import { useTheme } from '@/ui/theme';
import { Heading } from '@/ui/typography';

interface SearchResultRoomCardTitleProps {
  name: string;
  subtitle?: string;
  id?: string;
}

const Brush = ({ children }: { children: React.ReactNode }) => (
  <mark
    css={css`
      position: relative;
      color: currentColor;
      text-decoration: none;
      background: none;
      display: inline-block;
      z-index: 0;

      ::before {
        position: absolute;
        top: 2px;
        left: 0;
        width: 100%;
        height: 30px;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 40.62 47.91' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M40.62 2.68c-1.35-.06-3.37-2-5.41-2.57s-.43 1.21-3.06 1.21c-7.53-2.23-12.75.53-18.44 3.09C8.28-.29 9.33 9 6 10.9c2.28-6.69-3.72-7-2.34-.17a3.57 3.57 0 00.08 1c1.44 2.1.74 4.52 1 6.79-2.27 0-4.53.72-3.95 3.39s2.62-.22 3.93.39c.28.13.58.22.88.33-.15 2.67-2.31 2.59-4.1 3.23A2.75 2.75 0 000 28.7c-.09 1.19 2.67 3 2.73 1-.32-4 5.2-1.05 2.39 1.26-3.12 6.49 5.45 4.89 4.8 10.29-.56 2.71.68 2.65 3 3.26 7.81.1.37 2.16 7.33.85 1.06 0 5.85-1.63 6.61-2.47.3 1.18 3.55 3.17 5.61 3.14 1.23.34 0-.9 3.39 1.88 1 0 .19-.88 2.2-.88h2.57z' fill='%23ffea00'/%3E%3C/svg%3E"),
          url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 39.68 45.76' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M38 17.11c-1.73-.39-.93 1.2-1.18 1.88-3 3.11-3.35-2.51-2.09-4.59 3.11-5.19-7.09-2.53-5-8.39-1.15-4.44-10.86-3.55-15-4.26-.21 0-.43-1-.64-1.58C7.26-.67 4.33 2 1.06 1.13H0v44.32h1a2.59 2.59 0 00.6.23c5.53-.27 8.34 1 13.91-1.63 2.45-3.06 14.13 3.09 15-2.32a15.6 15.6 0 013.15-5.29l2.19-.69c-.65-2.25-1.29-4.5-1.94-6.75a3.81 3.81 0 01-3.44-2.48c.41-2.62 4 2.84 3.56-1.65 0-3.77 4.68-2 5.62-4.72.11-1.39-.16-2.7-1.65-3.04z' fill='%23ffea00'/%3E%3C/svg%3E");
        background-repeat: no-repeat, no-repeat;
        background-position: 0, 100%;
        background-size: 25px 30px, 26px 30px;
        content: '';
        z-index: -1 /*!rtl:end:ignore*/;
      }
      ::after {
        position: absolute;
        top: 2px;
        left: 25px;
        width: calc(100% - 51px);
        height: 30px;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 14.02 45.83' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14 .48h-.3C9.93.48 9.25-.27 7.76.11 4.72.89 1.67.5.07.5H0v44.34h.23c1.93 0 4.74-.62 6.39.81.78.67 5.28-.81 6.9-.81H14z' fill='%23ffea00'/%3E%3C/svg%3E");
        background-repeat: repeat-x;
        background-position: 50%;
        background-size: 9px 30px;
        content: '';
        z-index: -1 /*!rtl:end:ignore*/;
      }
    `}
  >
    {children}
  </mark>
);

export const SearchResultRoomCardTitle = ({
  name,
  subtitle,
  id,
}: SearchResultRoomCardTitleProps) => {
  const { themeKey } = useTheme();

  const BrushProvider = themeKey === 'mamashelter' ? Brush : React.Fragment;

  return (
    <Heading as="h2" style="heading5" inline id={id}>
      <BrushProvider>
        {name}
        {subtitle && (
          <span>
            <Heading as="h6" style="heading6" inline fontStyle="italic" noWrap>
              {` (${subtitle})`}
            </Heading>
          </span>
        )}
      </BrushProvider>
    </Heading>
  );
};

export default SearchResultRoomCardTitle;
