import { AxiosError } from 'axios';
import { ZodError, z } from 'zod';

import { logAxiosError } from '@/api/errors/log-axios-error';
import { APIError } from '@/errors';
import { formatZodErrorAsDebugString } from '@/errors/helpers/zod-error-as-debug-string';
import { ERROR_CODES } from '@/errors/resources/error-strings.const';
import { captureException } from '@/logging';

const errorResponseSchema = z.object({
  code: z.enum(ERROR_CODES),
  description: z.string(),
});

export const parseOWSAxiosError = (error: AxiosError | ZodError): APIError => {
  // If the error passed is a ZodError, we can assume it's bubbled up from a malformed response
  // Not sure if this is best practise - but it does simplify the consumption of this function
  // I guess the main issue really is a core issue with JS error handling
  if (error instanceof ZodError) {
    captureException(error);

    return {
      code: 'LOCAL_MALFORMED_RESPONSE',
      description: formatZodErrorAsDebugString(error),
    };
  }

  if (error.response) {
    logAxiosError(error);

    try {
      return errorResponseSchema.parse(error.response.data);
    } catch (errorParsingError) {
      // Flag an invalid error in Sentry
      captureException(errorParsingError);

      // Invalid error response structure
      return {
        code: 'UNKNOWN_ERROR',
        description:
          "Error was returned from the Ennismore API in a format that couldn't be mapped to a friendly user message.",
      };
    }
  } else if (error.request) {
    // The request was made but no response was received
    return {
      code: 'LOCAL_NETWORK_ERROR_01',
      description: 'No response received from server',
    };
  } else {
    // Something happened in setting up the request that triggered an error
    return {
      code: 'LOCAL_REQUEST_SETUP_ERROR_01',
      description: error.message,
    };
  }
};
