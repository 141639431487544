import { getNumberOfNightsBetween } from '@/common/utils/date';
import { IFinancialAmountInstance } from '@/finance';
import { IHotelModelInstance, IStayCostInstance } from '@/hotel';

/**
 * TODO: This is a temporary fix for the issue where the average nightly price is not calculated correctly.
 * This should be fixed in the backend. https://ennismore.atlassian.net/browse/BP-1761
 */
export const calculateAverageNightlyRateNet = (
  hotelReferenceId: string,
  netPrice: IFinancialAmountInstance | undefined,
  checkInDate: string,
  checkOutDate: string,
  taxes: IHotelModelInstance['legacyTaxes']
): IStayCostInstance['averageNightlyRate'] => {
  const hotels = [
    'hoxton.williamsburg-newyork',
    'hoxton.chicago-chicago',
    '21c.museumhotel-bentonville',
    '21c.museumhotel-chicago',
    '21c.museumhotel-cincinnati',
    '21c.museumhotel-durham',
    '21c.museumhotel-kansascity',
    '21c.museumhotel-lexington',
    '21c.museumhotel-louisville',
    '21c.museumhotel-stlouis',
  ];
  const noTaxes = taxes.includedTaxesMessageTranslationKey === 'none';
  const hasHotel = hotels.includes(hotelReferenceId);

  return (noTaxes || hasHotel) && netPrice
    ? {
        ...netPrice,
        value:
          netPrice.value / getNumberOfNightsBetween(checkInDate, checkOutDate),
      }
    : undefined;
};
