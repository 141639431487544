import { z } from 'zod';

import {
  CountryCountry,
  StateUsaState,
} from '@/em-api-client-typescript-fetch';

export const AddressModel = z.object({
  addressLine: z.array(z.string()).default([]),
  city: z.string().optional(),
  postCode: z.string().optional(),
  country: z.nativeEnum(CountryCountry).optional(),
  usaState: z.nativeEnum(StateUsaState).optional(),
});
