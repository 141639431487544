import dayjs from 'dayjs';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { API_DATE_FORMAT } from '@/api';
import { useLocale } from '@/i18n';

import { DatePicker, DatePickerProps } from './DatePicker.component';

interface DateRangePickerFieldProps extends Partial<DatePickerProps> {}

export const DateRangePickerField: React.FC<DateRangePickerFieldProps> = (
  props
) => {
  const { setValue, watch } = useFormContext<{
    from?: string;
    to?: string;
  }>();
  const locale = useLocale();

  const [from, to] = watch(['from', 'to']);

  return (
    <DatePicker
      startDate={from}
      onStartDateChange={(day?: Date) =>
        setValue('from', day ? dayjs(day).format(API_DATE_FORMAT) : undefined)
      }
      endDate={to}
      onEndDateChange={(day?: Date) =>
        setValue('to', day ? dayjs(day).format(API_DATE_FORMAT) : undefined)
      }
      locale={locale}
      {...props}
    />
  );
};

export default DateRangePickerField;
