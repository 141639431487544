import React from 'react';

import { CookieConsentProviderConfig } from './config';
import { ConsentManagerScript } from './providers/ConsentManager.component';
import { OneTrustScript } from './providers/OneTrust.component';

/**
 * Receives a configuration object for one of the available providers, rendering the corresponding provider script.
 */
export function CookieConsentScript({
  config,
}: {
  config: CookieConsentProviderConfig;
}) {
  switch (config.provider) {
    case 'onetrust':
      return <OneTrustScript id={config.oneTrustId} />;
    case 'consent-manager':
      return <ConsentManagerScript id={config.accountId} />;
  }
}
