import { css } from '@emotion/react';
import React, { AnchorHTMLAttributes, CSSProperties } from 'react';

import { useTheme } from '@/ui/theme';

export interface AnchorProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  noUnderline?: boolean;
  noWrap?: boolean;
  color?: CSSProperties['color'];
}

export const Anchor = ({
  noUnderline,
  color,
  noWrap,
  ...props
}: AnchorProps) => {
  const { colors } = useTheme();
  return (
    <a
      css={css`
        color: ${color || 'rgba(47, 47, 47)'};
        text-decoration: ${noUnderline ? 'none' : 'underline'};
        white-space: ${noWrap ? 'nowrap' : 'inherit'};
        cursor: pointer;

        &.focus-visible {
          outline: 1px solid ${colors.secondary300};
          outline-offset: 2;
        }
      `}
      {...props}
      tabIndex={0}
    >
      {props.children}
    </a>
  );
};

export default Anchor;
