import React from 'react';

import Anchor from './Anchor.component';

interface AnchorButtonProps {
  children: React.ReactNode;
  onClick: () => void;
}

export const AnchorButton = (props: AnchorButtonProps) => {
  return (
    <Anchor role="button" onClick={props.onClick}>
      {props.children}
    </Anchor>
  );
};

export default AnchorButton;
