import {
  BookingGTMStructure,
  ExistingBookingGTMStructure,
  ProductGTMStructure,
} from '@/analytics/gtm';
import { IHotelModelInstance } from '@/hotel';

import { IBookingInstance } from '../models/booking.model';
import { IRoomStayInstance } from '../models/room-stay.model';
import { calculateStayTotal } from './totals';

type Common = {
  hotel: IHotelModelInstance;
  booking: IBookingInstance;
};

/**
 * Formats a RoomStay instance (usually attached to a concrete Booking) as a Found-defined GTM product.
 * @param room
 */
export const formatRoomForGTM = (
  room: IRoomStayInstance
): ProductGTMStructure => {
  const firstRatePlanTotal = room.firstRatePlan.chargeBreakdown.grandTotal;

  if (!firstRatePlanTotal) {
    throw new Error(
      'Failed to format booking for GTM, first rate plan total is blank'
    );
  }

  return {
    productId: room.firstRoomDetail.code,
    productPrice: firstRatePlanTotal.fixedValue,
    productType: room.firstRoomDetail.name,
    productRateName: room.firstRatePlan.rateTitle,
    productQty: 1,
  };
};

export const formatPersistedBookingForGTM = (
  args: Common
): ExistingBookingGTMStructure => ({
  ...formatBookingForGTM(args),
  bookingId: args.booking.pmsId,
  bookingRevenue: calculateStayTotal(
    args.booking,
    args.hotel.payments.currencyCode
  ).fixedValue,
  bookingCoupon:
    args.booking.firstTotalChargeBreakdown.rateCode.requestRateCode,
});

export const formatBookingForGTM = ({
  booking,
  hotel,
}: Common): BookingGTMStructure => {
  return {
    bookingHotelName: hotel.name,
    bookingHotelChainCode: hotel.brandReferenceId,
    bookingHotelCode: hotel.code,
    bookingHotelCity: hotel.location.address.city || 'Unknown',
    bookingAdults: booking.totalAdultCount,
    bookingChildren: booking.totalChildCount,
    bookingInDate: booking.firstRoomStay.from,
    bookingOutDate: booking.firstRoomStay.to,
    bookingNights: booking.totalNumberOfNights,
    bookingRooms: booking.numberOfRooms,
    bookingCurrency: hotel.payments.currencyCode,
  };
};
