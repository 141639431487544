/* eslint-disable */
import * as sdk from 'hypertune';

export const queryId = '006a5df5-3f52-4ee1-878e-e40e5716ff81';

export const query: sdk.Query<sdk.ObjectValueWithVariables> = {
  variableDefinitions: {},
  fragmentDefinitions: {},
  fieldQuery: {
    Query: {
      type: 'InlineFragment',
      objectTypeName: 'Query',
      selection: {
        root: {
          fieldArguments: { __isPartialObject__: true },
          fieldQuery: {
            Root: {
              type: 'InlineFragment',
              objectTypeName: 'Root',
              selection: {
                disloyaltyRepeatedBanner: {
                  fieldArguments: { __isPartialObject__: true },
                  fieldQuery: null,
                },
                additionalInformationImpressionEvent: {
                  fieldArguments: { __isPartialObject__: true },
                  fieldQuery: null,
                },
                purchaseEvent: {
                  fieldArguments: { __isPartialObject__: true },
                  fieldQuery: null,
                },
                searchImpressionEvent: {
                  fieldArguments: { __isPartialObject__: true },
                  fieldQuery: null,
                },
                disloyaltyDummyRate: {
                  fieldArguments: { __isPartialObject__: true },
                  fieldQuery: null,
                },
                testFlag: { fieldArguments: {}, fieldQuery: null },
              },
            },
          },
        },
      },
    },
  },
};

/**
 * @deprecated use '@vercel/flags/providers/hypertune' package instead.
 */
export const vercelFlagDefinitions = {
  testFlag: {
    options: [
      { label: 'Off', value: false },
      { label: 'On', value: true },
    ],
    origin:
      'https://app.hypertune.com/projects/3057/main/draft/logic?selected_field_path=root%3EtestFlag',
  },
};

export type FlagValues = {
  testFlag: boolean;
};

export type FlagPaths = keyof FlagValues & string;

export const flagFallbacks: FlagValues = {
  testFlag: false,
};

export function decodeFlagValues<TFlagPaths extends keyof FlagValues & string>(
  encodedValues: string,
  flagPaths: TFlagPaths[]
): Pick<FlagValues, TFlagPaths> {
  return sdk.decodeFlagValues({ flagPaths, encodedValues });
}

export type Rec = {};

export const EnvironmentEnumValues = [
  'preview',
  'live',
  'staging',
  'dev',
  'local',
] as const;
export type Environment = (typeof EnvironmentEnumValues)[number];

export type Rec3 = {
  id: string;
};

export type Rec2 = {
  environment: Environment;
  session: Rec3;
};

export type RootArgs = {
  context: Rec2;
};

export type Rec5 = {
  referenceId: string;
};

export type Rec4 = {
  hotel: Rec5;
};

export type Rec7 = {
  context: Rec2;
  booking: Rec3;
  hotel: Rec5;
};

export type Rec6 = {
  event: Rec7;
};

export type Rec9 = {
  context: Rec2;
  hotel: Rec5;
};

export type Rec8 = {
  event: Rec9;
};

export type Root = {
  disloyaltyRepeatedBanner: boolean;
  additionalInformationImpressionEvent: boolean;
  purchaseEvent: boolean;
  searchImpressionEvent: boolean;
  disloyaltyDummyRate: boolean;
  testFlag: boolean;
};

const rootFallback = {
  disloyaltyRepeatedBanner: false,
  additionalInformationImpressionEvent: false,
  purchaseEvent: false,
  searchImpressionEvent: false,
  disloyaltyDummyRate: false,
  testFlag: false,
};

export type Rec11 = {
  args: Rec4;
};

export type Rec12 = {
  args: Rec6;
};

export type Rec13 = {
  args: Rec8;
};

export type Rec10 = {
  disloyaltyRepeatedBanner: Rec11;
  additionalInformationImpressionEvent: Rec12;
  purchaseEvent: Rec12;
  searchImpressionEvent: Rec13;
  disloyaltyDummyRate: Rec11;
};

export class RootNode extends sdk.Node {
  override typeName = 'Root' as const;

  getRootArgs(): RootArgs {
    const { step } = this.props;
    return (
      step?.type === 'GetFieldStep' ? step.fieldArguments : {}
    ) as RootArgs;
  }

  get({
    args,
    fallback = rootFallback as Root,
  }: {
    args: Rec10;
    fallback?: Root;
  }): Root {
    const getQuery = sdk.mergeFieldQueryAndArgs(
      query.fragmentDefinitions,
      sdk.getFieldQueryForPath(query.fragmentDefinitions, query.fieldQuery, [
        'Query',
        'root',
      ]),
      args
    );
    return this.getValue({ query: getQuery, fallback }) as Root;
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/3057/main/draft/logic?selected_field_path=root%3EdisloyaltyRepeatedBanner})
   */
  disloyaltyRepeatedBanner({
    args,
    fallback,
  }: {
    args: Rec4;
    fallback: boolean;
  }): boolean {
    const props0 = this.getFieldNodeProps('disloyaltyRepeatedBanner', {
      fieldArguments: args,
    });
    const expression0 = props0.expression;

    if (expression0 && expression0.type === 'BooleanExpression') {
      const node = new sdk.BooleanNode(props0);
      return node.get({ fallback });
    }

    const node = new sdk.BooleanNode(props0);
    node._logUnexpectedTypeError();
    return node.get({ fallback });
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/3057/main/draft/logic?selected_field_path=root%3EadditionalInformationImpressionEvent})
   */
  additionalInformationImpressionEvent({ args }: { args: Rec6 }): void {
    const props0 = this.getFieldNodeProps(
      'additionalInformationImpressionEvent',
      { fieldArguments: args }
    );
    const expression0 = props0.expression;

    if (expression0 && expression0.type === 'NoOpExpression') {
      const node = new sdk.VoidNode(props0);
      return node.get({});
    }

    const node = new sdk.VoidNode(props0);
    node._logUnexpectedTypeError();
    return node.get({});
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/3057/main/draft/logic?selected_field_path=root%3EpurchaseEvent})
   */
  purchaseEvent({ args }: { args: Rec6 }): void {
    const props0 = this.getFieldNodeProps('purchaseEvent', {
      fieldArguments: args,
    });
    const expression0 = props0.expression;

    if (expression0 && expression0.type === 'NoOpExpression') {
      const node = new sdk.VoidNode(props0);
      return node.get({});
    }

    const node = new sdk.VoidNode(props0);
    node._logUnexpectedTypeError();
    return node.get({});
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/3057/main/draft/logic?selected_field_path=root%3EsearchImpressionEvent})
   */
  searchImpressionEvent({ args }: { args: Rec8 }): void {
    const props0 = this.getFieldNodeProps('searchImpressionEvent', {
      fieldArguments: args,
    });
    const expression0 = props0.expression;

    if (expression0 && expression0.type === 'NoOpExpression') {
      const node = new sdk.VoidNode(props0);
      return node.get({});
    }

    const node = new sdk.VoidNode(props0);
    node._logUnexpectedTypeError();
    return node.get({});
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/3057/main/draft/logic?selected_field_path=root%3EdisloyaltyDummyRate})
   */
  disloyaltyDummyRate({
    args,
    fallback,
  }: {
    args: Rec4;
    fallback: boolean;
  }): boolean {
    const props0 = this.getFieldNodeProps('disloyaltyDummyRate', {
      fieldArguments: args,
    });
    const expression0 = props0.expression;

    if (expression0 && expression0.type === 'BooleanExpression') {
      const node = new sdk.BooleanNode(props0);
      return node.get({ fallback });
    }

    const node = new sdk.BooleanNode(props0);
    node._logUnexpectedTypeError();
    return node.get({ fallback });
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/3057/main/draft/logic?selected_field_path=root%3EtestFlag})
   */
  testFlag({
    args = {},
    fallback,
  }: {
    args?: Rec;
    fallback: boolean;
  }): boolean {
    const props0 = this.getFieldNodeProps('testFlag', { fieldArguments: args });
    const expression0 = props0.expression;

    if (expression0 && expression0.type === 'BooleanExpression') {
      const node = new sdk.BooleanNode(props0);
      return node.get({ fallback });
    }

    const node = new sdk.BooleanNode(props0);
    node._logUnexpectedTypeError();
    return node.get({ fallback });
  }
}

/**
 * Welcome to Hypertune, the most powerful feature flag, A/B testing and app
 * configuration platform.
 *
 * Follow the quickstart: https://docs.hypertune.com/quickstart
 *
 * This is your schema, written in GraphQL. Use Boolean for feature flags,
 * custom enums for flags with more than two states, Int for numeric flags like
 * limits and timeouts, Strings for in-app copy, and custom object and list types
 * for more complex app configuration.
 *
 * Once you've defined your schema, head to the Logic tab.
 */
export type Source = {
  /**
   * You can add arguments to any field in your schema, which you can then
   * reference when defining your logic. We've added a 'context' argument on your
   * root field already, which contains details of the current 'user'.
   */
  root: Root;
};

const sourceFallback = {
  root: {
    disloyaltyRepeatedBanner: false,
    additionalInformationImpressionEvent: false,
    purchaseEvent: false,
    searchImpressionEvent: false,
    disloyaltyDummyRate: false,
    testFlag: false,
  },
};

export type Rec15 = {
  args: RootArgs;
  Root: Rec10;
};

export type Rec14 = {
  root: Rec15;
};

/**
 * Welcome to Hypertune, the most powerful feature flag, A/B testing and app
 * configuration platform.
 *
 * Follow the quickstart: https://docs.hypertune.com/quickstart
 *
 * This is your schema, written in GraphQL. Use Boolean for feature flags,
 * custom enums for flags with more than two states, Int for numeric flags like
 * limits and timeouts, Strings for in-app copy, and custom object and list types
 * for more complex app configuration.
 *
 * Once you've defined your schema, head to the Logic tab.
 */
export class SourceNode extends sdk.Node {
  override typeName = 'Query' as const;

  get({
    args,
    fallback = sourceFallback as Source,
  }: {
    args: Rec14;
    fallback?: Source;
  }): Source {
    const getQuery = sdk.mergeFieldQueryAndArgs(
      query.fragmentDefinitions,
      sdk.getFieldQueryForPath(query.fragmentDefinitions, query.fieldQuery, []),
      args
    );
    return this.getValue({ query: getQuery, fallback }) as Source;
  }

  /**
   * You can add arguments to any field in your schema, which you can then
   * reference when defining your logic. We've added a 'context' argument on your
   * root field already, which contains details of the current 'user'.
   */
  root({ args }: { args: RootArgs }): RootNode {
    const props0 = this.getFieldNodeProps('root', { fieldArguments: args });
    const expression0 = props0.expression;

    if (
      expression0 &&
      expression0.type === 'ObjectExpression' &&
      expression0.objectTypeName === 'Root'
    ) {
      return new RootNode(props0);
    }

    const node = new RootNode(props0);
    node._logUnexpectedTypeError();
    return node;
  }
}

export type VariableValues = Rec;
export type DehydratedState = sdk.DehydratedState<Source, VariableValues>;
export type CreateSourceOptions = {
  token: string;
  variableValues?: VariableValues;
  override?: sdk.DeepPartial<Source> | null;
} & sdk.CreateOptions;

export function createSource({
  token,
  variableValues = {},
  override,
  ...options
}: CreateSourceOptions): SourceNode {
  return sdk.create({
    NodeConstructor: SourceNode,
    token,
    query,
    queryId,
    variableValues,
    override,
    options,
  });
}

export const emptySource = new SourceNode({
  context: null,
  logger: null,
  parent: null,
  step: null,
  expression: null,
});

export function createSourceForServerOnly({
  token,
  variableValues = {},
  override,
  ...options
}: CreateSourceOptions): SourceNode {
  return typeof window === 'undefined'
    ? createSource({ token, variableValues, override, ...options })
    : emptySource;
}

/**
 * @deprecated use createSource instead.
 */
export const initHypertune = createSource;
/**
 * @deprecated use SourceNode instead.
 */
export type QueryNode = SourceNode;
/**
 * @deprecated use Source instead.
 */
export type Query = Source;
