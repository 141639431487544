import { css } from '@emotion/react';
import Image from 'next/legacy/image';
import React from 'react';

const containerStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 110px;
`;

const inputStyles = css`
  width: 20px;
  text-align: center;
`;

const buttonStyles = (isLoading: boolean | undefined) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: ${isLoading ? '#c4c4c4' : '#3e3a37'};
  ${isLoading ? 'cursor: not-allowed;' : ''}
  width: 30px;
  height: 30px;
  color: white;
  position: relative;
  &:disabled {
    background-color: #c4c4c4;
    cursor: not-allowed;
  }
  &:focus {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #3e3a37;
  }
`;

const Button = ({
  isLoading,
  ...rest
}: {
  isLoading: boolean | undefined;
} & React.ButtonHTMLAttributes<HTMLButtonElement>) => (
  <button
    type="button"
    css={() => buttonStyles(isLoading)}
    aria-disabled={isLoading}
    aria-busy={isLoading}
    {...rest}
    onClick={(e) => {
      /**
       * Mimicking the disabled behaviour here when button action is being resolved,
       * that is because doing it via the 'disabled' property loses focus and impacts accessibility.
       */
      if (!isLoading) rest.onClick?.(e);
    }}
  >
    {rest.children}
  </button>
);

export const Rocker = React.forwardRef(
  (
    {
      count,
      onInc,
      onDec,
      isLoading,
      ...rest
    }: {
      count: number;
      onInc: () => void;
      onDec: () => void;
      isLoading?: boolean;
    } & React.InputHTMLAttributes<HTMLInputElement>,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <div css={containerStyles}>
        <Button
          onClick={onDec}
          isLoading={isLoading}
          disabled={count === 0}
          // TODO: translate this
          aria-label="Decrease value"
          aria-describedby={rest['aria-describedby']}
        >
          <Image src="/images/minus.svg" width={10} height={10} alt="" />
        </Button>
        <input
          ref={ref}
          {...rest}
          /**
           * We're using 'text' instead of 'number' for the input type because
           * number inputs add their own increment and decrement buttons in some browsers,
           * and we don't want to care about that. Also editing the count directly isn't required.
           */
          type="text"
          aria-hidden
          readOnly
          tabIndex={-1}
          css={inputStyles}
        />
        <p
          aria-live="assertive"
          aria-label={rest['aria-label']}
          // TODO: we need global .sr-only class...
          style={{
            height: '1px',
            width: '1px',
            overflow: 'hidden',
            margin: '-1px',
            padding: 0,
            position: 'absolute',
            whiteSpace: 'nowrap',
          }}
        />
        <Button
          onClick={onInc}
          isLoading={isLoading}
          // TODO: translate this
          aria-label="Increase value"
          aria-describedby={rest['aria-describedby']}
        >
          <Image src="/images/plus.svg" width={10} height={10} alt="" />
        </Button>
      </div>
    );
  }
);

Rocker.displayName = 'Rocker';
