import { css } from '@emotion/react';
import React from 'react';

import RoomRateCode from '@/booking/components/RoomRateCode.component';
import { CurrencyPreview } from '@/currency-preview';
import type { IFinancialAmountInstance } from '@/finance/models';
import { useTranslation } from '@/i18n';
import { Spacer } from '@/ui/spacing';
import { BodyCopy } from '@/ui/typography';

import RoomTotal from './room/RoomTotal.component';

interface HotelRoomListItemDiscountedRateProps {
  priceBeforeDiscount: IFinancialAmountInstance;
  name: string;
  description?: string;
  roomTotalElement: React.ReactElement<typeof RoomTotal> | undefined | false;
  isAvailable: boolean;
}

export const HotelRoomListItemDiscountedRate = (
  props: HotelRoomListItemDiscountedRateProps
) => {
  const { t } = useTranslation('search');

  return (
    <>
      <BodyCopy>
        {t('roomCard.discountedRoomRateWas')}
        {': '}
        <span
          css={css`
            text-decoration: line-through;
            font-weight: normal;
          `}
        >
          <CurrencyPreview amount={props.priceBeforeDiscount} />
        </span>
      </BodyCopy>
      <Spacer s="xs" />
      <RoomRateCode
        name={props.name}
        description={props.description}
        isAvailable={props.isAvailable}
        roomTotalElement={props.roomTotalElement}
      />
    </>
  );
};

export default HotelRoomListItemDiscountedRate;
