import { css } from '@emotion/react';
import React from 'react';

import { Markdown } from '@/markdown';

import { useTheme } from '../theme';
import { BodySmall } from '../typography';

export interface ErrorMessageProps {
  message?: string;
  textAlign?: 'center' | 'left';
}

export const ErrorMessage = (props: ErrorMessageProps) => {
  const { colors } = useTheme();
  if (!props.message) return null;

  return (
    <BodySmall textAlign={props.textAlign}>
      <span
        css={css`
          color: ${colors.error};
          text-align: ${props.textAlign || 'left'};
        `}
        aria-live="assertive"
        role="alert"
      >
        <Markdown source={props.message} />
      </span>
    </BodySmall>
  );
};

export default ErrorMessage;
