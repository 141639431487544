import { z } from 'zod';

import {
  getLegacyTotalAdultGuestCount,
  getLegacyTotalChildGuestCount,
} from '@/hotel/helpers';
import { Occupancy } from '@/hotel/models/occupancy.model';
import type { IOccupancy } from '@/hotel/models/occupancy.model';

/**
 * e.g. [
 *  { adults: 1, children: 1, childrenAges: [5] },
 *  { adults: 2, children: 2, childrenAges: [4, 8] },
 *  { adults: 5, children: 4, childrenAges: [4, 8, 0, 0] },
 *  { adults: 1, children: 1, childrenAges: [null] },
 * ]
 *  */
export const RoomOccupancySearchUrlQuerySchema = z
  .array(Occupancy)
  .min(1, 'At least one room must be provided');

/**
 * Takes serialised room occupancy URL query
 * and transforms it to a validateable list of rooms.
 * E.g. { 'rooms[0].adults: '2', 'rooms[0].children: '1', 'rooms[0].childrenAges[0]: '5' }
 * is transformed to: [{ adults: 2, children: 1, childrenAges: [5] }]
 * @param query
 * @returns
 */
export const transformRoomOccupancySearchUrlQuery = (
  query: Record<string, string | string[] | undefined>
) => {
  const rooms: IOccupancy[] = [];

  // DEPRECATED: supporting the old room occupancy configuration strings
  // first so that they can be overriden with the new format.
  if (query.rooms) {
    const roomTypes = Array.isArray(query.rooms) ? query.rooms : [query.rooms];
    for (let i = 0; i < roomTypes.length; ++i) {
      rooms[i] = {
        adults: getLegacyTotalAdultGuestCount(roomTypes[i]),
        children: getLegacyTotalChildGuestCount(roomTypes[i]),
        childrenAges: [],
        accessible: false,
      };
    }
  }

  for (const [key, value] of Object.entries(query)) {
    if (typeof value === 'undefined') continue;
    // Look for the following keys:
    // 'rooms[i].adults';
    // 'rooms[i].children';
    // 'rooms[i].accessible'.
    // 'rooms[i].childrenAges[j]'.
    const matches = key.match(
      /^rooms\[(\d{1,2})\]\.(adults|children|accessible|childrenAges\[(\d{1,2})\])$/
    );

    // Map Room Occupancy Attribute
    if (matches) {
      const roomIndex = parseInt(matches[1]);
      const roomOccupancyAttribute = matches[2].includes('childrenAges')
        ? 'childrenAges'
        : matches[2];
      const childIndex = parseInt(matches[3]);

      // Initialise room if handling the first occupancy attribute of the room
      if (!rooms[roomIndex])
        rooms[roomIndex] = {
          adults: 0,
          children: 0,
          childrenAges: [],
          accessible: false,
        };

      switch (roomOccupancyAttribute) {
        case 'adults':
        case 'children':
          // Support repeated search params, e.g. &rooms[0].adults=1&rooms[0].adults=2
          // will be serialised as { 'rooms[0].adults': ['1', '2'] },
          // in which case the last value should override the others.
          rooms[roomIndex][roomOccupancyAttribute] = Array.isArray(value)
            ? parseInt(value[value.length - 1])
            : parseInt(value);
          break;
        case 'childrenAges':
          rooms[roomIndex][roomOccupancyAttribute][childIndex] = Array.isArray(
            value
          )
            ? parseInt(value[value.length - 1])
            : parseInt(value);
          break;
        case 'accessible':
          rooms[roomIndex][roomOccupancyAttribute] = Array.isArray(value)
            ? value[value.length - 1] === 'true'
              ? true
              : false
            : value === 'true'
            ? true
            : false;
      }
    }
  }

  /**
   * Normalise Rooms list.
   * Transforms [,,{ adults: 2, children: 1, childrenAge: [3] }] to
   * [ { adults: 0, children: 0, childrenAges: [] },
   * { adults: 0, children: 0, childrenAges: [] },
   * { adults: 2, children: 1, childrenAge: [3] }]
   */
  for (let i = 0; i < rooms.length; ++i) {
    if (!rooms[i]) {
      rooms[i] = {
        adults: 0,
        children: 0,
        childrenAges: [],
        accessible: false,
      };
    }
  }

  /**
   * Normalise Children Ages.
   * Transforms [,,5] to [5] as we do mot want any undefined values
   */
  const roomsWithNormalisedChildrenAges = rooms.map((room) => ({
    ...room,
    childrenAges: room.childrenAges.filter((age) => age !== undefined),
  }));

  return roomsWithNormalisedChildrenAges;
};
