import { FontFace } from './FontFace.interface';

export const createCSSFontImports = (fontFaceDefinitions: FontFace[]) => {
  const externalImports = fontFaceDefinitions.filter(
    (def) => def.externalImport
  );
  if (!externalImports.length) {
    return '';
  }
  const unique = new Set(externalImports.map((def) => def.externalImport));

  return `${Array.from(unique)
    .map((importUrl) => `@import url(${importUrl})`)
    .join(' ')};`;
};
