import React from 'react';

import { usePageLoadTracker } from '../hooks';

interface GTMPageViewProviderProps {
  children: React.ReactNode;
}

/**
 * Add this at the top of the client-side loaded page (_app.tsx). This will record page views within GTM.
 * @param param0
 */
export const GTMPageViewProvider = ({ children }: GTMPageViewProviderProps) => {
  usePageLoadTracker();
  return <>{children}</>;
};

export default GTMPageViewProvider;
