import { useEffect } from 'react';

import { useAppEvents } from './use-app-events.hook';

/**
 * Listen for events matching given key. Dispose properly.
 * @param key The event key
 * @param fn
 */
export const useAppEventListener = <Events>(
  key: keyof Events,
  fn: (payload: Events[keyof Events]) => void
) => {
  const events = useAppEvents<Events>();

  useEffect(() => {
    events.addListener(key, fn);
    return () => events.removeListener(key, fn);
  }, [key]);
};
