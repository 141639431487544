import { Box as ChakraBox } from '@chakra-ui/react';
import React, { forwardRef } from 'react';
import { withDefaultProps } from 'react-propose';

import { BoxProps, transformProps } from '../helpers';

export const Box = forwardRef<'div', BoxProps>((props, ref) => {
  const resolvedProps = transformProps(props);
  return <ChakraBox {...(resolvedProps as any)} ref={ref as any} />;
});
Box.displayName = 'Box';

export const Flex = withDefaultProps(Box, { display: 'flex' });

export const Grid = withDefaultProps(Box, { display: 'grid' });
