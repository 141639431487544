import { V1CotOrBed } from '@ennismore/payment-stripe-api-client';

import { BookingCotOrBed } from '@/em-api-client-typescript-fetch';

/**
 * BookingCotOrBed -> V1CotOrBed
 */
const COT_TO_V1_COT: Record<BookingCotOrBed, V1CotOrBed> = {
  [BookingCotOrBed.COTBEDNOTSET]: V1CotOrBed.CotBedNotSet,
  [BookingCotOrBed.BED]: V1CotOrBed.Bed,
  [BookingCotOrBed.COT]: V1CotOrBed.Cot,
};

export const mapDomainToBufCotOrBed = (cotOrBed: BookingCotOrBed): V1CotOrBed =>
  COT_TO_V1_COT[cotOrBed];

export const mapBufToDomainCotOrBed = (cotOrBed: V1CotOrBed): BookingCotOrBed =>
  Object.keys(COT_TO_V1_COT).find(
    (key) => COT_TO_V1_COT[key] === cotOrBed
  ) as unknown as BookingCotOrBed;
