import { css } from '@emotion/react';
import * as React from 'react';
import { useId } from 'react';

import { useTheme } from '@/ui/theme';

interface StandoutDataTableRowProps {
  label: string | React.ReactNode;
  sublabel?: string | React.ReactNode;
  value: string | React.ReactNode;
  testId?: string;
}

export const StandoutDataTableRow = (props: StandoutDataTableRowProps) => {
  const { colors, fonts } = useTheme();
  const fieldLabelId = useId();

  return (
    <div
      className="field-section"
      data-test={`standout-data-table-row--${props.testId}`}
      css={css`
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        line-height: 26px;

        &:not(:last-child) {
          /* margin-bottom: 8px; */
        }

        .label-wrapper {
          align-self: flex-start;
          .label {
            font-family: ${fonts.body};
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            color: ${colors.textPrimary};
            margin-right: 20px;

            strong {
              font-weight: 400;
            }
          }
          .sublabel {
            font-family: ${fonts.body};
            line-height: 20px;
            margin-bottom: -20px;
            text-align: right;
            font-size: 12px;
            margin-top: -4px;
          }
        }

        .value {
          font-family: ${fonts.body};
          text-align: right;
          font-weight: bold;
          font-size: 18px;
          line-height: 24px;
          color: ${colors.textPrimary};
        }
      `}
    >
      <div className="label-wrapper">
        <div className="label" id={fieldLabelId}>
          {props.label}
        </div>
        {props.sublabel && <div className="sublabel">{props.sublabel}</div>}
      </div>
      <div className="value" aria-labelledby={fieldLabelId}>
        {props.value}
      </div>
    </div>
  );
};

export default StandoutDataTableRow;
