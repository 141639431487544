import { DEFAULT_TRANSLATION_STRINGS } from '@/i18n';

export const ERROR_CODES = [
  'OPERA_01',
  'LOCAL_NETWORK_ERROR_01',
  'API_RESPONSE_VALIDATION_01',
  'REDIRECT_01',
  'REDIRECT_02',
  'SUMMARY_VALIDATION_01',
  'SUMMARY_VALIDATION_02',
  'SEARCH_10',
  'EXCEEDED_NUMBER_OF_GUESTS_PER_ROOM',
  'SEARCH_VALIDATION_01',
  'SEARCH_VALIDATION_02',
  'SEARCH_VALIDATION_03',
  'SEARCH_VALIDATION_04',
  'SEARCH_VALIDATION_05',
  'SEARCH_VALIDATION_06',
  'SEARCH_VALIDATION_07',
  'SEARCH_VALIDATION_08',
  'SEARCH_VALIDATION_09',
  'CREATE_VALIDATION_01',
  'CREATE_VALIDATION_02',
  'CREATE_VALIDATION_03',
  'CREATE_VALIDATION_04',
  'CREATE_VALIDATION_05',
  'CREATE_VALIDATION_06',
  'CREATE_VALIDATION_07',
  'CREATE_VALIDATION_08',
  'CREATE_VALIDATION_09',
  'CREATE_01',
  'CREATE_02',
  'CREATE_03',
  'CREATE_04',
  'CONFIRM_VALIDATION_01',
  'CONFIRM_VALIDATION_02',
  'CONFIRM_VALIDATION_03',
  'CONFIRM_VALIDATION_04',
  'CONFIRM_VALIDATION_05',
  'CONFIRM_VALIDATION_06',
  'CONFIRM_VALIDATION_07',
  'CONFIRM_VALIDATION_08',
  'CONFIRM_VALIDATION_09',
  'CONFIRM_VALIDATION_10',
  'CONFIRM_VALIDATION_11',
  'CONFIRM_VALIDATION_12',
  'CONFIRM_VALIDATION_13',
  'CONFIRM_VALIDATION_14',
  'CONFIRM_VALIDATION_15',
  'CONFIRM_VALIDATION_16',
  'CONFIRM_VALIDATION_17',
  'CONFIRM_VALIDATION_18',
  'CONFIRM_VALIDATION_19',
  'PAYMENT_STRIPE_00',
  'PAYMENT_STRIPE_01',
  'PAYMENT_STRIPE_02',
  'PAYMENT_STRIPE_03',
  'PAYMENT_STRIPE_04',
  'PAYMENT_STRIPE_05',
  'PAYMENT_STRIPE_06',
  'PAYMENT_STRIPE_07',
  'PAYMENT_STRIPE_08',
  'PAYMENT_STRIPE_09',
  'PAYMENT_STRIPE_10',
  'PAYMENT_STRIPE_11',
  'PAYMENT_STRIPE_12',
  'PAYMENT_STRIPE_13',
  'PAYMENT_STRIPE_14',
  'PAYMENT_STRIPE_15',
  'PAYMENT_STRIPE_16',
  'PAYMENT_STRIPE_17',
  'SEARCH_01',
  'SEARCH_02',
  'SEARCH_03',
  'SEARCH_04',
  'SEARCH_05',
  'CONFIRM_01',
  'CONFIRM_02',
  'CONFIRM_03',
  'CONFIRM_04',
  'CONFIRM_05',
  'SUMMARY_01',
  'GATEWAY_03',
  'GATEWAY_10',
  'GATEWAY_07',
  'LOCAL_GET_BOOKING_404',
  'LOCAL_MALFORMED_RESPONSE',
  'LOCAL_UNAUTHORIZED',
  'UNKNOWN_ERROR',
  'LOCAL_REQUEST_SETUP_ERROR_01',
  'BOOKINGS_FETCH',
  'OWS_LIMITER_429',
  'OWS_LIMITER_504',
] as const;

export const ERROR_STRINGS: {
  errors: {
    [key in (typeof ERROR_CODES)[number]]: {
      internalDescription?: string;
      retryable?: boolean;
      translationKey: keyof (typeof DEFAULT_TRANSLATION_STRINGS)['en-GB']['errors'];
      titleTranslationKey?: keyof (typeof DEFAULT_TRANSLATION_STRINGS)['en-GB']['errors'];
    };
  };
} = {
  errors: {
    LOCAL_NETWORK_ERROR_01: {
      retryable: true,
      translationKey: 'networkConnection',
    },
    EXCEEDED_NUMBER_OF_GUESTS_PER_ROOM: {
      translationKey: 'numberOfGuestsPerRoomExceedsLimit',
    },
    REDIRECT_01: {
      translationKey: 'redirecting',
    },
    REDIRECT_02: {
      translationKey: 'redirecting',
    },
    API_RESPONSE_VALIDATION_01: {
      internalDescription: 'UI received a malformed response from the API',
      translationKey: 'generic',
    },
    SUMMARY_VALIDATION_01: {
      internalDescription: 'Request is not valid',
      translationKey: 'generic',
    },
    SUMMARY_VALIDATION_02: {
      internalDescription: 'Booking id can not be null',
      translationKey: 'generic',
    },
    SEARCH_10: {
      internalDescription: 'Invalid promotion code',
      titleTranslationKey: 'promoCodeNotFoundTitle',
      translationKey: 'promoCodeNotFoundDescription',
    },
    SEARCH_VALIDATION_01: {
      translationKey: 'generic',
      internalDescription: 'Request is not valid',
    },
    SEARCH_VALIDATION_02: {
      translationKey: 'locationMissing',
      internalDescription: 'Hotel code can not be null',
    },
    SEARCH_VALIDATION_03: {
      translationKey: 'checkInDateMissing',
      internalDescription: 'Param [from] must be set',
    },
    SEARCH_VALIDATION_04: {
      translationKey: 'generic',
      internalDescription: 'Param [delta] must be between 0 and 6',
    },
    SEARCH_VALIDATION_05: {
      translationKey: 'generic',
      internalDescription: 'from/to must be formatted $DATE_FORMAT',
    },
    SEARCH_VALIDATION_06: {
      translationKey: 'checkOutDateMissing',
      internalDescription: 'Param [to] must be set',
    },
    SEARCH_VALIDATION_07: {
      translationKey: 'checkOutAfterCheckInDate',
      internalDescription: '[to] must be after [from]',
    },
    SEARCH_VALIDATION_08: {
      translationKey: 'occupantsMissing',
      internalDescription: 'Room Guest is mandatory',
    },
    SEARCH_VALIDATION_09: {
      translationKey: 'futureCheckIn',
      internalDescription: 'from cannot be in the past',
    },
    CREATE_VALIDATION_01: {
      translationKey: 'generic',
      internalDescription: 'Request is not valid',
    },
    CREATE_VALIDATION_02: {
      translationKey: 'locationMissing',
      internalDescription: 'Hotel code can not be null',
    },
    CREATE_VALIDATION_03: {
      translationKey: 'checkInDateMissing',
      internalDescription: 'From is not in a valid format',
    },
    CREATE_VALIDATION_04: {
      translationKey: 'generic',
      internalDescription: 'Param [room_request] can not be null',
    },
    CREATE_VALIDATION_05: {
      translationKey: 'generic',
      internalDescription: 'Rate Code can not be null',
    },
    CREATE_VALIDATION_06: {
      translationKey: 'generic',
      internalDescription: 'Room Code can not be null',
    },
    CREATE_VALIDATION_07: {
      translationKey: 'occupantsMissing',
      internalDescription: 'Room Guest is mandatory',
    },
    CREATE_VALIDATION_08: {
      translationKey: 'checkOutDateMissing',
      internalDescription: 'Param [to] must be set',
    },
    CREATE_VALIDATION_09: {
      translationKey: 'checkOutAfterCheckInDate',
      internalDescription: '[to] must be after [from]',
    },
    CREATE_01: {
      translationKey: 'roomNoLongerAvailable',
      internalDescription: 'One or more rooms no longer available',
    },
    CREATE_02: {
      translationKey: 'generic',
      internalDescription: 'Error while creating booking',
    },
    CREATE_03: {
      translationKey: 'generic',
      internalDescription: 'Room Guest is not valid',
    },
    CREATE_04: {
      translationKey: 'generic',
      internalDescription: 'Room Guest is not valid',
    },
    CONFIRM_VALIDATION_01: {
      translationKey: 'generic',
      internalDescription: 'Request is not valid',
    },
    CONFIRM_VALIDATION_02: {
      translationKey: 'bookingNotFound',
      internalDescription: 'Booking not found',
    },
    CONFIRM_VALIDATION_03: {
      translationKey: 'generic',
      internalDescription: 'Profile is mandatory',
    },
    CONFIRM_VALIDATION_04: {
      translationKey: 'generic',
      internalDescription: 'Payment is mandatory',
    },
    CONFIRM_VALIDATION_05: {
      translationKey: 'generic',
      internalDescription: 'Comment should have a max size of 80 characters',
    },
    CONFIRM_VALIDATION_06: {
      translationKey: 'generic',
      internalDescription: 'First name can not be null',
    },
    CONFIRM_VALIDATION_07: {
      translationKey: 'generic',
      internalDescription: 'Last name can not be null',
    },
    CONFIRM_VALIDATION_08: {
      translationKey: 'generic',
      internalDescription: 'Phone can not be null',
    },
    CONFIRM_VALIDATION_09: {
      translationKey: 'generic',
      internalDescription: 'Email can not be null',
    },
    CONFIRM_VALIDATION_10: {
      translationKey: 'generic',
      internalDescription: 'Profile must have at least one address',
    },
    CONFIRM_VALIDATION_11: {
      translationKey: 'generic',
      internalDescription: 'Address city can not be null',
    },
    CONFIRM_VALIDATION_12: {
      translationKey: 'generic',
      internalDescription: 'Address state can not be null',
    },
    CONFIRM_VALIDATION_13: {
      translationKey: 'generic',
      internalDescription: 'Address post code can not be null',
    },
    CONFIRM_VALIDATION_14: {
      translationKey: 'generic',
      internalDescription: 'Address country code can not be null',
    },
    CONFIRM_VALIDATION_15: {
      translationKey: 'generic',
      internalDescription: 'Address line can not be null',
    },
    CONFIRM_VALIDATION_16: {
      translationKey: 'generic',
      internalDescription: 'Payment holder name can not be null',
    },
    CONFIRM_VALIDATION_17: {
      translationKey: 'generic',
      internalDescription: 'Card number can not be null',
    },
    CONFIRM_VALIDATION_18: {
      translationKey: 'cardDetailsGeneric',
      internalDescription:
        'Payment expiration year must be between now and 10 years time',
    },
    CONFIRM_VALIDATION_19: {
      translationKey: 'cardDetailsGeneric',
      internalDescription: 'Payment expiration month must be between 1 and 12',
    },
    PAYMENT_STRIPE_00: {
      translationKey: 'generic',
      internalDescription: 'resource_missing',
    },
    PAYMENT_STRIPE_01: {
      translationKey: 'generic',
      internalDescription: 'incorrect_number',
    },
    PAYMENT_STRIPE_02: {
      translationKey: 'cardDeclinedNoFunds',
      internalDescription: 'balance_insufficient',
    },
    PAYMENT_STRIPE_03: {
      translationKey: 'cardDeclinedGeneric',
      internalDescription: 'card_decline_rate_limit_exceeded',
    },
    PAYMENT_STRIPE_04: {
      translationKey: 'cardDeclinedGeneric',
      internalDescription: 'card_declined',
    },
    PAYMENT_STRIPE_05: {
      translationKey: 'chargeAlreadyRefunded',
      internalDescription: 'charge_already_refunded',
    },
    PAYMENT_STRIPE_06: {
      translationKey: 'stripeEmailMissing',
      internalDescription: 'email_invalid',
    },
    PAYMENT_STRIPE_07: {
      translationKey: 'cardDetailsGeneric',
      internalDescription: 'expired_card',
    },
    PAYMENT_STRIPE_08: {
      translationKey: 'billingAddressGeneric',
      internalDescription: 'incorrect_address',
    },
    PAYMENT_STRIPE_09: {
      translationKey: 'cardDetailsGeneric',
      internalDescription: 'incorrect_cvc',
    },
    PAYMENT_STRIPE_10: {
      translationKey: 'generic',
      internalDescription: 'incorrect_number',
    },
    PAYMENT_STRIPE_11: {
      translationKey: 'billingAddressGeneric',
      internalDescription: 'incorrect_zip',
    },
    PAYMENT_STRIPE_12: {
      translationKey: 'cardDetailsGeneric',
      internalDescription: 'invalid_card_type',
    },
    PAYMENT_STRIPE_13: {
      translationKey: 'cardDetailsGeneric',
      internalDescription: 'invalid_cvc',
    },
    PAYMENT_STRIPE_14: {
      translationKey: 'cardDetailsGeneric',
      internalDescription: 'invalid_expiry_month',
    },
    PAYMENT_STRIPE_15: {
      translationKey: 'cardDetailsGeneric',
      internalDescription: 'invalid_expiry_year',
    },
    PAYMENT_STRIPE_16: {
      translationKey: 'cardDetailsGeneric',
      internalDescription: 'invalid_number',
    },
    PAYMENT_STRIPE_17: {
      translationKey: 'cardDetailsGeneric',
      internalDescription: 'postal_code_invalid',
    },
    SEARCH_01: {
      translationKey: 'generic',
      internalDescription: 'Room Guest is not valid',
    },
    SEARCH_02: {
      translationKey: 'minimumStay',
      internalDescription: 'Room has a minimum stay of 3 nights',
    },
    SEARCH_03: {
      translationKey: 'noAvailability',
      internalDescription: 'Room has no availability',
    },
    SEARCH_04: {
      translationKey: 'noChildBed',
      internalDescription: "Sorry! We can't fit an extra bed/cot in here.",
    },
    SEARCH_05: {
      translationKey: 'generic',
      internalDescription: 'The rate code used it not valid',
    },
    CONFIRM_01: {
      translationKey: 'confirmBookingAlreadyPaid',
      internalDescription: 'Booking has already been paid',
    },
    CONFIRM_02: {
      translationKey: 'generic',
      internalDescription: 'Credit card mask must be 4 digits only',
    },
    CONFIRM_03: {
      translationKey: 'generic',
      internalDescription: 'Stripe token code is mandatory',
    },
    CONFIRM_04: {
      translationKey: 'unsupportedCard',
      internalDescription:
        'You can use only the following type of cards MASTERCARD, VISA, AMERICAN_EXPRESS and DINERS',
    },
    CONFIRM_05: {
      translationKey: 'invalidCreditCard',
      internalDescription: 'Credit card number is not valid',
    },
    SUMMARY_01: {
      translationKey: 'bookingNotFound',
      internalDescription: 'Booking not found',
    },
    GATEWAY_03: {
      titleTranslationKey: 'serviceUnavailableTitle',
      translationKey: 'serviceUnavailable',
      internalDescription: 'Generic gateway error',
    },
    GATEWAY_10: {
      translationKey: 'singleRoomBookingOnly',
      internalDescription:
        'Multiple rooms selected when code is only valid for one.',
    },
    GATEWAY_07: {
      translationKey: 'festiveClosure',
      internalDescription: 'Hotel is closed from 2021-12-23 to 2022-01-02.',
      titleTranslationKey: 'festiveClosureTitle',
    },
    LOCAL_GET_BOOKING_404: {
      translationKey: 'bookingNotFound',
      internalDescription: 'Locally, booking not found',
    },
    LOCAL_MALFORMED_RESPONSE: {
      translationKey: 'generic',
    },
    LOCAL_UNAUTHORIZED: {
      translationKey: 'unauthorized',
    },
    OPERA_01: {
      titleTranslationKey: 'serviceUnavailableTitle',
      translationKey: 'serviceUnavailable',
    },
    UNKNOWN_ERROR: {
      translationKey: 'generic',
    },
    LOCAL_REQUEST_SETUP_ERROR_01: {
      translationKey: 'generic',
    },
    BOOKINGS_FETCH: {
      translationKey: 'generic',
      internalDescription: 'Unable to get bookings',
    },
    OWS_LIMITER_429: {
      translationKey: 'generic',
      internalDescription: 'OWS rate limiting',
    },
    OWS_LIMITER_504: {
      translationKey: 'generic',
      internalDescription: 'OWS 504',
    },
  },
} as const;
