import React from 'react';

import { useActiveBrand } from '@/brand';
import { IHotelBannerInstance } from '@/hotel/models/hotel-banner.model';
import { Markdown } from '@/markdown';
import { Banner } from '@/ui/layout';
import { useTheme } from '@/ui/theme';
import { BodyCopy } from '@/ui/typography';

import { GleneaglesBanner } from './banners/GleneaglesBanner.component';

interface AvailabilitySearchResultsBannerProps {
  bannerModel: IHotelBannerInstance;
}

export const AvailabilitySearchResultsBanner = (
  props: AvailabilitySearchResultsBannerProps
) => {
  const { componentProperties } = useTheme();

  // Added specifically for Gleneagles Christmas promotion
  // Ideally this would be theme key, but we don't have that available in the context
  if (useActiveBrand() === 'gleneagles') {
    return <GleneaglesBanner />;
  }

  return (
    <Banner
      imageUrl={props.bannerModel.image?.source}
      alt={props.bannerModel.image?.altText || ''}
    >
      <BodyCopy color={componentProperties.banners?.color ?? 'textPrimary'}>
        <Markdown source={props.bannerModel.description} />
      </BodyCopy>
    </Banner>
  );
};
