import React from 'react';

import { useSkipRedirectSwitch } from '@/availability-fallback/hooks';

import { EnnismoreAPIClientConfigContext } from '../contexts';
import { EnnismoreAPIClientConfig } from '../interfaces';

interface EnnismoreAPIProviderProps {
  children: React.ReactNode;
  config: EnnismoreAPIClientConfig;
}

export const EnnismoreApiProvider: React.FC<EnnismoreAPIProviderProps> = (
  props
) => {
  const config = { ...props.config };
  const [skipRedirect] = useSkipRedirectSwitch();

  // If the user has 'skip-redirect' enabled at the feature level
  if (skipRedirect) {
    config.skipRedirect = true;
  }

  return (
    <EnnismoreAPIClientConfigContext.Provider value={config}>
      {props.children}
    </EnnismoreAPIClientConfigContext.Provider>
  );
};
