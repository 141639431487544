import React from 'react';

import { CoreAppEvents } from '@/core/events';
import { useAppEvents } from '@/events';

import BrandLogo from './BrandLogo';

interface BrandLogoButtonProps {
  label: string;
  brandHomeUrl: string;
  analyticsLocation: string;
}

export const BrandLogoButton = ({
  label,
  brandHomeUrl,
  analyticsLocation,
}: BrandLogoButtonProps) => {
  const events = useAppEvents<CoreAppEvents>();

  return (
    <a
      href={brandHomeUrl}
      aria-label={label}
      className="logo"
      onClick={() =>
        events.emit('navigation', {
          location: analyticsLocation,
          originator: 'Brand Logo',
        })
      }
    >
      <BrandLogo />
    </a>
  );
};

export default BrandLogoButton;
