import { V1DayTimePart } from '@ennismore/payment-stripe-api-client';

import { BookingDayTimePart } from '@/em-api-client-typescript-fetch';

/**
 * BookingDayTimePart -> v1CotOrBed
 */
const DOMAIN_TO_BUF_DAY_TIME_PART: Record<BookingDayTimePart, V1DayTimePart> = {
  [BookingDayTimePart.NOTSET]: V1DayTimePart.NotSet,
  [BookingDayTimePart.HOUR0TO1]: V1DayTimePart.Hour0To1,
  [BookingDayTimePart.HOUR1TO2]: V1DayTimePart.Hour1To2,
  [BookingDayTimePart.HOUR2TO3]: V1DayTimePart.Hour2To3,
  [BookingDayTimePart.HOUR3TO4]: V1DayTimePart.Hour3To4,
  [BookingDayTimePart.HOUR4TO5]: V1DayTimePart.Hour4To5,
  [BookingDayTimePart.HOUR5TO6]: V1DayTimePart.Hour5To6,
  [BookingDayTimePart.HOUR6TO7]: V1DayTimePart.Hour6To7,
  [BookingDayTimePart.HOUR7TO8]: V1DayTimePart.Hour7To8,
  [BookingDayTimePart.HOUR8TO9]: V1DayTimePart.Hour8To9,
  [BookingDayTimePart.HOUR9TO10]: V1DayTimePart.Hour9To10,
  [BookingDayTimePart.HOUR10TO11]: V1DayTimePart.Hour10To11,
  [BookingDayTimePart.HOUR11TO12]: V1DayTimePart.Hour11To12,
  [BookingDayTimePart.HOUR12TO13]: V1DayTimePart.Hour12To13,
  [BookingDayTimePart.HOUR13TO14]: V1DayTimePart.Hour13To14,
  [BookingDayTimePart.HOUR14TO15]: V1DayTimePart.Hour14To15,
  [BookingDayTimePart.HOUR15TO16]: V1DayTimePart.Hour15To16,
  [BookingDayTimePart.HOUR16TO17]: V1DayTimePart.Hour16To17,
  [BookingDayTimePart.HOUR17TO18]: V1DayTimePart.Hour17To18,
  [BookingDayTimePart.HOUR18TO19]: V1DayTimePart.Hour18To19,
  [BookingDayTimePart.HOUR19TO20]: V1DayTimePart.Hour19To20,
  [BookingDayTimePart.HOUR20TO21]: V1DayTimePart.Hour20To21,
  [BookingDayTimePart.HOUR21TO22]: V1DayTimePart.Hour21To22,
  [BookingDayTimePart.HOUR22TO23]: V1DayTimePart.Hour22To23,
  [BookingDayTimePart.HOUR23TO24]: V1DayTimePart.Hour23To24,
};

export const mapDomainToBufDayTimePart = (
  dayTimePart: BookingDayTimePart
): V1DayTimePart => DOMAIN_TO_BUF_DAY_TIME_PART[dayTimePart];

export const mapBufToDomainDayTimePart = (
  dayTimePart: V1DayTimePart
): BookingDayTimePart =>
  Object.keys(DOMAIN_TO_BUF_DAY_TIME_PART).find(
    (key) => DOMAIN_TO_BUF_DAY_TIME_PART[key] === dayTimePart
  ) as unknown as BookingDayTimePart;
