/**
 * This package allows us to maintain backwards compatibility with the current "hotelCode" structure.
 * New OHIP hotels (not listed above) will use hotelReferenceId exclusively, so we can assume when a brochure website supplies hotelCode,
 * we will check against this list, and if non-existant we will assume it's a hotelReferenceId.
 */

const codes = {
  'hoxton.herengracht-amsterdam': 'HAMS',
  brussels: 'HB9D2',
  'hoxton.charlottenburg-berlin': 'HB9D1',
  holborn: 'HOLB',
  'shepherds-bush': 'HB9D5',
  shoreditch: 'HOX',
  paris: 'THP',
  southwark: 'THSW',
  'hoxton.williamsburg-newyork': 'THW',
  'hoxton.downtown-losangeles': '21853524',
  'hoxton.portland-portland': '21850537',
  'hoxton.chicago-chicago': '22023679',
  rome: 'THR',
  barcelona: 'THBCN',
  gleneagles: 'GLEN',
  townhouse: 'EDI',
};

/**
 * Mapping method used to transform hotel code (used in the legacy availability search) to hotel reference id.
 * The hotelCode property is being deprecated, brochure website developers will be asked to switch to the new format instead.
 * @param hotelCode A hotel code - e.g. THSW or 21853524
 * @returns The matching hotel reference ID
 */
export const legacyHotelCodeToReferenceId = (hotelCode: string) => {
  return Object.entries(codes).find(([, code]) => hotelCode === code)?.[0];
};

/**
 * Mapping method used to transform a hotelReferenceID to hotel code (used in the legacy availability search).
 * The hotelCode property is being deprecated, brochure website developers will be asked to switch to the new format instead.
 * @param hotelCode A hotel reference ID - e.g. shoreditch or morgans.sanderson
 * @returns The matching hotel core
 */
export const referenceIdToLegacyHotelCode = (referenceId: string) => {
  return codes[referenceId] as string | undefined;
};

/**
 * This function attempts to match the given hotel code against a static list of codes -> referenceIds.
 * If this fails, we can assume the code is a hotelReferenceId.
 * If you pass: HOX -> shoreditch
 * If you pass: hoxton.lloyd-amsterdam -> hoxton.lloyd-amsterdam
 * @param hotelCode
 * @returns A matching hotelReferenceId, or the given hotel code (now assumed to be hotelReferenceId)
 */
export const resolveHotelReferenceIdFromHotelCode = (hotelCode: string) => {
  // If the supplied hotel code is listed above, it'll be returned here. If not, we will assume it's a hotelReferenceId.
  const foundReferenceId = legacyHotelCodeToReferenceId(hotelCode);

  return foundReferenceId ?? hotelCode;
};
