import { DEFAULT_TRANSLATION_STRINGS } from '@/i18n';
import { captureException, defaultLogger } from '@/logging';

import { ERROR_STRINGS } from './resources/error-strings.const';

const fallbackGenericErrorMessage = 'generic';

export const getErrorDescriptionKey = (
  domainErrorKey?: keyof typeof ERROR_STRINGS['errors']
): keyof typeof DEFAULT_TRANSLATION_STRINGS['en-GB']['errors'] => {
  if (!domainErrorKey) {
    defaultLogger.error(`Unable to get message for an unspecified error key`);
    return 'generic';
  }
  try {
    return (
      ERROR_STRINGS['errors'][domainErrorKey].translationKey ||
      fallbackGenericErrorMessage
    );
  } catch (e) {
    // TODO: Use global error helper
    captureException(
      new Error(
        `Error translation message key not configured for domain error key [${domainErrorKey}]`
      )
    );
    return fallbackGenericErrorMessage;
  }
};
