import React from 'react';

import { useHotel } from '@/hotel/hooks';

export const CancellationPolicyPrePurchaseBody = ({
  hotelReferenceId,
}: {
  hotelReferenceId: string;
}) => {
  const hotel = useHotel(hotelReferenceId);

  if (!hotel) return null;

  return (
    <React.Fragment>{hotel.paymentCancellationPolicy.summary}</React.Fragment>
  );
};

export default CancellationPolicyPrePurchaseBody;
