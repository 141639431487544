import type { V1BookingRoom } from '@ennismore/booking-api-client';
import { useEffect, useState } from 'react';

import { IAvailabilitySearchQueryInstance } from '@/availability/models';
import { captureException } from '@/logging';

interface BookingRoomRequestWithRate extends V1BookingRoom {
  internalRateCode: string;
  requestRateCode: string;
}

export const useCreateBookingBasket = (args: {
  query: IAvailabilitySearchQueryInstance;
}) => {
  const [selectedRooms, setSelectedRooms] = useState<
    BookingRoomRequestWithRate[]
  >([]);

  /**
   * Add a room to the basket.
   * @param room
   */
  const addRoom = (room: BookingRoomRequestWithRate) => {
    setSelectedRooms((rooms) => [...rooms, room]);
  };

  /**
   * Reset the basket.
   */
  const reset = () => {
    setSelectedRooms([]);
  };

  useEffect(() => {
    if (args.query.rooms.length <= 0) {
      captureException(
        new Error(
          'Attempting to instantiate a basket with no rooms in search query'
        )
      );
    }

    // Reset basket when room query changes
    reset();
  }, [args.query.rooms]);

  const nextRoomNumberToSelect = selectedRooms.length + 1;

  const nextRoomToSelect =
    args.query.rooms[selectedRooms.length] || args.query.rooms[0];

  const numberOfRoomsToSelect = args.query.rooms.length;
  const isMultiRoomSearch = numberOfRoomsToSelect > 1;
  const hasSelectedOneOrMoreRooms = selectedRooms.length > 0;

  const isRateSwitchingLocked = isMultiRoomSearch && hasSelectedOneOrMoreRooms;

  /**
   * After adding this next room, will the creation from this basket be ready to execute?
   */
  const isFinalRoomToSelect =
    selectedRooms.length + 1 >= args.query.rooms.length;
  const isBasketFull = selectedRooms.length >= args.query.rooms.length;

  const selectedInternalRateCode =
    selectedRooms.length > 0 ? selectedRooms[0].internalRateCode : undefined;
  const selectedRequestRateCode =
    selectedRooms.length > 0 ? selectedRooms[0].requestRateCode : undefined;

  return {
    setSelectedRooms,
    addRoom,
    reset,
    selectedRooms,
    selectedInternalRateCode,
    selectedRequestRateCode,

    nextRoomToSelect,
    isFinalRoomToSelect,
    nextRoomNumberToSelect,
    isBasketFull,
    isMultiRoomSearch,
    isRateSwitchingLocked,
  };
};
