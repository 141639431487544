import { css } from '@emotion/react';
import React, { AnchorHTMLAttributes } from 'react';

interface AnchorProps extends AnchorHTMLAttributes<HTMLAnchorElement> {}

export const Anchor = (props: AnchorProps) => {
  return (
    <a
      {...props}
      css={css({
        color: 'inherit',
        cursor: 'pointer',
        position: 'relative',

        '&:hover::after': {
          transform: 'scaleX(0)',
          transformOrigin: 'right',
        },

        '&[role=button]': {
          userSelect: 'none',
        },

        // '&::after': {
        //   transition: 'transform .3s ease-in-out',
        //   backgroundColor: 'hoxWarmBlack',
        //   content: '""',
        //   height: '1px',
        //   position: 'absolute',
        //   left: 0,
        //   bottom: '-1px',
        //   width: '100%',
        //   transformOrigin: 'left',
        //   transform: 'scaleX(1)',
        //   willChange: 'transform',
        // },
      })}
    >
      {props.children}
    </a>
  );
};

export default Anchor;
