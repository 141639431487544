import { z } from 'zod';

export enum YesNoEnum {
  NOTSET = 'YES_NO_NOT_SET',
  NO = 'NO',
  YES = 'YES',
}

/**
 * This needs some explaining :)
 * This might look like one a "wtf" moment... but essentially, with the current gRPC/OpenAPI setup, any properties with false boolean values get removed from a returned object.
 *
 * Returning a YES/NO enum keeps that property in each object.
 */
export const YesNo = z.preprocess((value) => {
  if (typeof value === 'boolean') {
    return value;
  }

  return value === YesNoEnum.YES.toString();
}, z.boolean());
