import { useMemo } from 'react';

import { createApiClient } from '../create-api-client.function';
import { useEnnismoreApiClientConfig } from './use-ennismore-api-client-config.hook';

export const useEnnismoreApiClient = () => {
  const config = useEnnismoreApiClientConfig();

  const { baseUrl, apiKey: clientKey } = config;

  return useMemo(
    () => createApiClient({ baseUrl, apiKey: clientKey }),
    [baseUrl, clientKey]
  );
};
