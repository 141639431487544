import { CountryCountry } from '@/em-api-client-typescript-fetch';

export const OPERA_COUNTRY_CODES = [
  { label: 'United Kingdom', code: CountryCountry.GB },
  { label: 'United States', code: CountryCountry.US },
  { label: 'France', code: CountryCountry.FR },
  { label: 'Germany', code: CountryCountry.DE },
  { label: 'Netherlands', code: CountryCountry.NL },
  { label: 'Afghanistan', code: CountryCountry.AF },
  { label: 'Albania', code: CountryCountry.AL },
  { label: 'Algeria', code: CountryCountry.DZ },
  { label: 'American Samoa', code: CountryCountry.AS },
  { label: 'Andorra', code: CountryCountry.AD },
  { label: 'Angola', code: CountryCountry.AO },
  { label: 'Anguilla/St Kitts', code: CountryCountry.AI },
  { label: 'Antarctica', code: CountryCountry.AQ },
  { label: 'Antigua and Barbuda', code: CountryCountry.AG },
  { label: 'Argentina', code: CountryCountry.AR },
  { label: 'Armenia', code: CountryCountry.AM },
  { label: 'Aruba', code: CountryCountry.AW },
  { label: 'Australia', code: CountryCountry.AU },
  { label: 'Austria', code: CountryCountry.AT },
  { label: 'Azerbaijan', code: CountryCountry.AZ },
  { label: 'Bahamas', code: CountryCountry.BS },
  { label: 'Bahrain', code: CountryCountry.BH },
  { label: 'Bangladesh', code: CountryCountry.BD },
  { label: 'Barbados', code: CountryCountry.BB },
  { label: 'Belarus', code: CountryCountry.BY },
  { label: 'Belgium', code: CountryCountry.BE },
  { label: 'Belize', code: CountryCountry.BZ },
  { label: 'Benin', code: CountryCountry.BJ },
  { label: 'Bermuda', code: CountryCountry.BM },
  { label: 'Bhutan', code: CountryCountry.BT },
  { label: 'Bolivia', code: CountryCountry.BO },
  { label: 'Bonaire,Saba,St Eustatius', code: CountryCountry.BQ },
  { label: 'Bosnia Herzegovina', code: CountryCountry.BA },
  { label: 'Botswana', code: CountryCountry.BW },
  { label: 'Bouvet Island', code: CountryCountry.BV },
  { label: 'Brazil', code: CountryCountry.BR },
  { label: 'British Indian Ocean Territory', code: CountryCountry.IO },
  { label: 'Brunei Darussalam', code: CountryCountry.BN },
  { label: 'Bulgaria', code: CountryCountry.BG },
  { label: 'Burkina Fasa', code: CountryCountry.BF },
  { label: 'Burundi', code: CountryCountry.BI },
  { label: 'Cambodia', code: CountryCountry.KH },
  { label: 'Cameroon', code: CountryCountry.CM },
  { label: 'Canada', code: CountryCountry.CA },
  { label: 'Cape Verde', code: CountryCountry.CV },
  { label: 'Cayman Islands', code: CountryCountry.KY },
  { label: 'Central African Republic', code: CountryCountry.CF },
  { label: 'Chad', code: CountryCountry.TD },
  { label: 'Chile', code: CountryCountry.CL },
  { label: 'China', code: CountryCountry.CN },
  { label: 'Christmas Island', code: CountryCountry.CX },
  { label: 'Cocos (Keeling) Islands', code: CountryCountry.CC },
  { label: 'Colombia', code: CountryCountry.CO },
  { label: 'Comoros', code: CountryCountry.KM },
  { label: 'Congo', code: CountryCountry.CG },
  { label: 'Congo, The Democratic Republic of the', code: CountryCountry.CD },
  { label: 'Cook Island', code: CountryCountry.CK },
  { label: 'Costa Rica', code: CountryCountry.CR },
  { label: "Cote D'Ivoire", code: CountryCountry.CI },
  { label: 'Croatia', code: CountryCountry.HR },
  { label: 'Cuba', code: CountryCountry.CU },
  { label: 'Curacao', code: CountryCountry.CW },
  { label: 'Cyprus', code: CountryCountry.CY },
  { label: 'Czech Republic', code: CountryCountry.CZ },
  { label: 'Denmark', code: CountryCountry.DK },
  { label: 'Djibouti', code: CountryCountry.DJ },
  { label: 'Dominica', code: CountryCountry.DM },
  { label: 'Dominican Republic', code: CountryCountry.DO },
  { label: 'Ecuador', code: CountryCountry.EC },
  { label: 'Egypt', code: CountryCountry.EG },
  { label: 'El Salvador', code: CountryCountry.SV },
  { label: 'Equatorial Guinea', code: CountryCountry.GQ },
  { label: 'Eritrea', code: CountryCountry.ER },
  { label: 'Estonia', code: CountryCountry.EE },
  { label: 'Ethiopia', code: CountryCountry.ET },
  { label: 'Falkland Islands', code: CountryCountry.FK },
  { label: 'Faroe Islands', code: CountryCountry.FO },
  { label: 'Fiji', code: CountryCountry.FJ },
  { label: 'Finland', code: CountryCountry.FI },
  { label: 'French Guiana', code: CountryCountry.GF },
  { label: 'French Polynesia', code: CountryCountry.PF },
  { label: 'French Souther Territories', code: CountryCountry.TF },
  { label: 'Gabon', code: CountryCountry.GA },
  { label: 'Gambia', code: CountryCountry.GM },
  { label: 'Georgia', code: CountryCountry.GE },
  { label: 'Ghana', code: CountryCountry.GH },
  { label: 'Gibraltar', code: CountryCountry.GI },
  { label: 'Greece', code: CountryCountry.GR },
  { label: 'Greenland', code: CountryCountry.GL },
  { label: 'Grenada', code: CountryCountry.GD },
  { label: 'Guadeloupe/St Martin', code: CountryCountry.GP },
  { label: 'Guam', code: CountryCountry.GU },
  { label: 'Guatemala', code: CountryCountry.GT },
  { label: 'Guinea', code: CountryCountry.GN },
  { label: 'Guinea Bissau', code: CountryCountry.GW },
  { label: 'Guyana', code: CountryCountry.GY },
  { label: 'Haiti', code: CountryCountry.HT },
  { label: 'Heard and McDonald Islands', code: CountryCountry.HM },
  { label: 'Honduras', code: CountryCountry.HN },
  { label: 'Hong Kong', code: CountryCountry.HK },
  { label: 'Hungary', code: CountryCountry.HU },
  { label: 'Iceland', code: CountryCountry.IS },
  { label: 'India', code: CountryCountry.IN },
  { label: 'Indonesia', code: CountryCountry.ID },
  { label: 'Iran', code: CountryCountry.IR },
  { label: 'Iraq', code: CountryCountry.IQ },
  { label: 'Ireland', code: CountryCountry.IE },
  { label: 'Isle Of Man', code: CountryCountry.IM },
  { label: 'Israel', code: CountryCountry.IL },
  { label: 'Italy', code: CountryCountry.IT },
  { label: 'Jamaica', code: CountryCountry.JM },
  { label: 'Japan', code: CountryCountry.JP },
  { label: 'Jordan', code: CountryCountry.JO },
  { label: 'Kazakhstan', code: CountryCountry.KZ },
  { label: 'Kenya', code: CountryCountry.KE },
  { label: 'Kiribati', code: CountryCountry.KI },
  { label: 'Korea, Republic Of', code: CountryCountry.KR },
  { label: "Korea,Democratic People's Republic of", code: CountryCountry.KP },
  { label: 'Kuwait', code: CountryCountry.KW },
  { label: 'Kyrgzstan', code: CountryCountry.KG },
  { label: 'Laos', code: CountryCountry.LA },
  { label: 'Latvia', code: CountryCountry.LV },
  { label: 'Lebanon', code: CountryCountry.LB },
  { label: 'Lesotho', code: CountryCountry.LS },
  { label: 'Liberia', code: CountryCountry.LR },
  { label: 'Libyan Arab Jamahiriya', code: CountryCountry.LY },
  { label: 'Liechtenstein', code: CountryCountry.LI },
  { label: 'Lithuania', code: CountryCountry.LT },
  { label: 'Luxembourg', code: CountryCountry.LU },
  { label: 'Macau', code: CountryCountry.MO },
  { label: 'Macedonia', code: CountryCountry.MK },
  { label: 'Madagascar', code: CountryCountry.MG },
  { label: 'Malawi', code: CountryCountry.MW },
  { label: 'Malaysia', code: CountryCountry.MY },
  { label: 'Maldives', code: CountryCountry.MV },
  { label: 'Mali', code: CountryCountry.ML },
  { label: 'Malta', code: CountryCountry.MT },
  { label: 'Marshall Islands', code: CountryCountry.MH },
  { label: 'Martinique', code: CountryCountry.MQ },
  { label: 'Mauritania', code: CountryCountry.MR },
  { label: 'Mauritius', code: CountryCountry.MU },
  { label: 'Mayotte', code: CountryCountry.YT },
  { label: 'Mexico', code: CountryCountry.MX },
  { label: 'Micronesia, Federated States of', code: CountryCountry.FM },
  { label: 'Moldova', code: CountryCountry.MD },
  { label: 'Monaco', code: CountryCountry.MC },
  { label: 'Mongolia', code: CountryCountry.MN },
  { label: 'Montenegro', code: CountryCountry.ME },
  { label: 'Montserrat', code: CountryCountry.MS },
  { label: 'Morocco', code: CountryCountry.MA },
  { label: 'Mozambique', code: CountryCountry.MZ },
  { label: 'Myanmar', code: CountryCountry.MM },
  { label: 'Namibia', code: CountryCountry.NA },
  { label: 'Nauru', code: CountryCountry.NR },
  { label: 'Nepal', code: CountryCountry.NP },
  { label: 'New Caledonia', code: CountryCountry.NC },
  { label: 'New Zealand', code: CountryCountry.NZ },
  { label: 'Nicaragua', code: CountryCountry.NI },
  { label: 'Niger', code: CountryCountry.NE },
  { label: 'Nigeria', code: CountryCountry.NG },
  { label: 'Niue', code: CountryCountry.NU },
  { label: 'Norfolk Island', code: CountryCountry.NF },
  { label: 'Northern Mariana Islands', code: CountryCountry.MP },
  { label: 'Norway', code: CountryCountry.NO },
  { label: 'Oman', code: CountryCountry.OM },
  { label: 'Pakistan', code: CountryCountry.PK },
  { label: 'Palau', code: CountryCountry.PW },
  { label: 'Panama', code: CountryCountry.PA },
  { label: 'Papua New Guinea', code: CountryCountry.PG },
  { label: 'Paraguay', code: CountryCountry.PY },
  { label: 'Peru', code: CountryCountry.PE },
  { label: 'Philippines', code: CountryCountry.PH },
  { label: 'Pitcairn', code: CountryCountry.PN },
  { label: 'Poland', code: CountryCountry.PL },
  { label: 'Portugal', code: CountryCountry.PT },
  { label: 'Puerto Rico', code: CountryCountry.PR },
  { label: 'Qatar', code: CountryCountry.QA },
  { label: 'Reunion', code: CountryCountry.RE },
  { label: 'Romania', code: CountryCountry.RO },
  { label: 'Russia', code: CountryCountry.RU },
  { label: 'Rwanda', code: CountryCountry.RW },
  { label: 'Saint Helena', code: CountryCountry.SH },
  { label: 'Saint Kitts and Nevis', code: CountryCountry.KN },
  { label: 'Saint Lucia', code: CountryCountry.LC },
  { label: 'Saint Pierre And Miquelon', code: CountryCountry.PM },
  { label: 'Saint Vincent And The Grenadines', code: CountryCountry.VC },
  { label: 'Samoa', code: CountryCountry.WS },
  { label: 'San Marino', code: CountryCountry.SM },
  { label: 'Sao Tome and Principe', code: CountryCountry.ST },
  { label: 'Saudi Arabia', code: CountryCountry.SA },
  { label: 'Senegal', code: CountryCountry.SN },
  { label: 'Serbia', code: CountryCountry.RS },
  { label: 'Seychelles', code: CountryCountry.SC },
  { label: 'Sierra Leone', code: CountryCountry.SL },
  { label: 'Singapore', code: CountryCountry.SG },
  { label: 'Sint Maarten', code: CountryCountry.SX },
  { label: 'Slovakia', code: CountryCountry.SK },
  { label: 'Slovenia', code: CountryCountry.SI },
  { label: 'Solomon Islands', code: CountryCountry.SB },
  { label: 'Somalia', code: CountryCountry.SO },
  { label: 'South Africa', code: CountryCountry.ZA },
  {
    label: 'South Georgia and the South Sandwich Islands',
    code: CountryCountry.GS,
  },
  { label: 'South Sudan', code: CountryCountry.SS },
  { label: 'Spain', code: CountryCountry.ES },
  { label: 'Sri Lanka', code: CountryCountry.LK },
  { label: 'Sudan', code: CountryCountry.SD },
  { label: 'Suriname', code: CountryCountry.SR },
  { label: 'Svalbard and Jan Mayen Islands', code: CountryCountry.SJ },
  { label: 'Swaziland', code: CountryCountry.SZ },
  { label: 'Sweden', code: CountryCountry.SE },
  { label: 'Switzerland', code: CountryCountry.CH },
  { label: 'Syria', code: CountryCountry.SY },
  { label: 'Taiwan', code: CountryCountry.TW },
  { label: 'Tajikistan', code: CountryCountry.TJ },
  { label: 'Tanzania', code: CountryCountry.TZ },
  { label: 'Thailand', code: CountryCountry.TH },
  { label: 'Timor-Leste', code: CountryCountry.TP },
  { label: 'Togo', code: CountryCountry.TG },
  { label: 'Tokelau', code: CountryCountry.TK },
  { label: 'Tonga', code: CountryCountry.TO },
  { label: 'Trinidad And Tobago', code: CountryCountry.TT },
  { label: 'Tunisia', code: CountryCountry.TN },
  { label: 'Turkey', code: CountryCountry.TR },
  { label: 'Turkmenistan', code: CountryCountry.TM },
  { label: 'Turks and Caicos Islands', code: CountryCountry.TC },
  { label: 'Tuvalu', code: CountryCountry.TV },
  { label: 'Uganda', code: CountryCountry.UG },
  { label: 'Ukraine', code: CountryCountry.UA },
  { label: 'United Arab Emirates', code: CountryCountry.AE },
  { label: 'United States Minor Outlying Islands', code: CountryCountry.UM },
  { label: 'Uruguay', code: CountryCountry.UY },
  { label: 'Uzbekistan', code: CountryCountry.UZ },
  { label: 'Vanuatu', code: CountryCountry.VU },
  { label: 'Vatican City State', code: CountryCountry.VA },
  { label: 'Venezuela', code: CountryCountry.VE },
  { label: 'Vietnam', code: CountryCountry.VN },
  { label: 'Virgin Islands, British', code: CountryCountry.VG },
  { label: 'Virgin Islands, US', code: CountryCountry.VI },
  { label: 'Wallis and Futuna Islands', code: CountryCountry.WF },
  { label: 'Western Sahara', code: CountryCountry.EH },
  { label: 'Yemen', code: CountryCountry.YE },
  { label: 'Zambia', code: CountryCountry.ZM },
  { label: 'Zimbabwe', code: CountryCountry.ZW },
] as const;
