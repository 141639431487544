import React from 'react';

import { useTranslation } from '@/i18n';
import { Markdown } from '@/markdown';
import { ButtonLink } from '@/ui/controls';

import { AvailabilitySearchErrorCard } from '../AvailabilitySearchErrorCard.component';

interface Props {
  contactHotelUrl: string;
}

export function ServiceUnavailableCard(props: Props) {
  const { t } = useTranslation(['errors', 'bookingManagement']);

  return (
    <AvailabilitySearchErrorCard
      title={t('errors:serviceUnavailableTitle')}
      description={<Markdown source={t('errors:serviceUnavailable')} />}
      button={
        <ButtonLink href={props.contactHotelUrl}>
          {t('bookingManagement:managementCard.contactUsButtonLabel')}
        </ButtonLink>
      }
    />
  );
}
