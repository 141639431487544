import React from 'react';

import { CookieConsentProviderConfig } from './config';
import { ConsentManagerConsentStatusProvider } from './providers/ConsentManager.component';
import { OneTrustConsentStatusProvider } from './providers/OneTrust.component';

/**
 * Responsible for listening to consent status changes and providing a context to child components.
 * Child components can use the provider-agnostic `useCookieConsentStatus()` hook to determine consent.
 */
export function CookieConsentStatusProvider(props: {
  config: CookieConsentProviderConfig | undefined;
  children: React.ReactNode;
}) {
  if (!props.config) {
    return <>{props.children}</>;
  }

  switch (props.config.provider) {
    case 'onetrust':
      return <OneTrustConsentStatusProvider {...props} />;
    case 'consent-manager':
      return <ConsentManagerConsentStatusProvider {...props} />;
  }
}
