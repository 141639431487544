import { isTruthy } from '@/common/utils/array';
import { IOccupancy, getBookingGuestFromCount } from '@/hotel';

export const validateChildAges =
  (requiresChildAges: boolean | undefined) => (rooms: IOccupancy[]) =>
    requiresChildAges
      ? rooms.every(
          (room) =>
            room.childrenAges.filter((childrenAge) => childrenAge !== undefined)
              .length === room.children
        )
      : true;

export const validateRoomOccupancy =
  (
    supportedRoomOccupancyConfigurations: {
      adults: number;
      children?: number | undefined;
    }[]
  ) =>
  (rooms: IOccupancy[]): boolean => {
    if (!rooms.length) return false;

    const mapped = supportedRoomOccupancyConfigurations
      .map(getBookingGuestFromCount)
      .filter(isTruthy);

    const selectedBookingGuestOptions = rooms
      .map(getBookingGuestFromCount)
      .filter(isTruthy);
    const matchedBookingGuestOptions = mapped.filter((value) =>
      selectedBookingGuestOptions.includes(value)
    );
    return Boolean(matchedBookingGuestOptions.length);
  };
