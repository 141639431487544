import {
  Configuration,
  PaymentStripeBookingServiceApi,
} from '@ennismore/payment-stripe-api-client';
import { z } from 'zod';

import {
  mapBufToDomainPaymentMethod,
  mapDomainToBufCotOrBed,
  mapDomainToBufCountry,
  mapDomainToBufDayTimePart,
  mapDomainToBufUSAState,
} from '@/api/mappings';
import {
  PaymentPaymentMethod,
  StripePrePaymentRequest,
  StripePrePaymentResponse,
} from '@/em-api-client-typescript-fetch';

const prePaymentResponseSchema = z.object({
  clientSecret: z.string(),
  method: z.nativeEnum(PaymentPaymentMethod),
  created: z.string().transform((value) => new Date(value)),
});

export abstract class PaymentServiceClient {
  constructor(
    protected readonly baseUrl: string,
    protected readonly apiKey: string
  ) {}

  abstract createPaymentToken(
    request: StripePrePaymentRequest
  ): Promise<StripePrePaymentResponse>;
}

export class OpenAPIV3PaymentServiceClient extends PaymentServiceClient {
  get client() {
    return new PaymentStripeBookingServiceApi(
      new Configuration({ basePath: this.baseUrl.replace('/v2', '') })
    );
  }

  async createPaymentToken(
    request: StripePrePaymentRequest
  ): Promise<StripePrePaymentResponse> {
    // Map a domain-level StripePrePaymentRequest to v1PrePaymentRequest

    const response = await this.client.prePayment(
      {
        ...request,
        metadata: {
          ...request.metadata,
          cot: request.metadata.cot
            ? mapDomainToBufCotOrBed(request.metadata.cot)
            : undefined,
          flexyTime: {
            checkIn: request.metadata.flexyTime?.checkIn
              ? mapDomainToBufDayTimePart(request.metadata.flexyTime.checkIn)
              : undefined,
            checkOut: request.metadata.flexyTime?.checkOut
              ? mapDomainToBufDayTimePart(request.metadata.flexyTime.checkOut)
              : undefined,
          },
          usaState: request.metadata.usaState
            ? mapDomainToBufUSAState(request.metadata.usaState)
            : undefined,
          country: request.metadata.country
            ? mapDomainToBufCountry(request.metadata.country)
            : undefined,
        },
      },
      {
        headers: { 'X-Api-Key': this.apiKey },
      }
    );

    return prePaymentResponseSchema.parse({
      ...response.data,
      method: mapBufToDomainPaymentMethod(response.data.method),
    });
  }
}

export const createPaymentServiceClient = (args: {
  baseUrl: string;
  apiKey: string;
}): PaymentServiceClient =>
  new OpenAPIV3PaymentServiceClient(args.baseUrl, args.apiKey);
