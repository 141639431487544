import React from 'react';

import { Divider } from '../layout';
import { Spacer } from '../spacing';
import { Heading } from '../typography';

interface FormFieldSectionProps {
  title?: string;
  children: React.ReactNode;
}

export const FormFieldSection = (props: FormFieldSectionProps) => {
  return (
    <div>
      <Divider />
      {props.title && (
        <>
          <Spacer s="s" />
          <Heading as="h6" style="heading6">
            {props.title}
          </Heading>
        </>
      )}
      <Spacer s="s" />
      {props.children}
    </div>
  );
};

export default FormFieldSection;
