import React from 'react';

import { useTheme } from '../theme';

interface GreaterThanIconProps {
  color?: string;
}

export const GreaterThanIcon = ({ color }: GreaterThanIconProps) => {
  const { colors } = useTheme();
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 11L7 6L1 0.999999" stroke={color || colors.secondary400} />
    </svg>
  );
};

export default GreaterThanIcon;
