import type { BrandConfigurationFactory } from '@/brand/common/interfaces/BrandConfigurationFactory';
import { CountryCountry } from '@/em-api-client-typescript-fetch';

import enGB from './locale/en-GB.json';
import es from './locale/es.json';
import fr from './locale/fr.json';

// import pt from './locale/pt.json';

export const slsBrandConfig: BrandConfigurationFactory = ({
  isProduction,
}) => ({
  chainCode: 'sls',
  themeKey: 'sls',
  name: 'SLS',
  defaultHotelCode: 'sls.barcelona-barcelona',
  hotels: [
    {
      referenceId: 'sls.bahamar-nassau',
      code: 'sls.bahamar-nassau',
      name: 'Baha Mar',
      dialect: 'ohip',
    },
    {
      referenceId: 'sls.barcelona-barcelona',
      code: 'sls.barcelona-barcelona',
      name: 'Barcelona',
      dialect: 'ohip',
      openingDate: '2024-12-16',
    },
    {
      referenceId: 'sls.beverlyhills-losangeles',
      code: 'sls.beverlyhills-losangeles',
      name: 'Beverly Hills',
      dialect: 'ohip',
    },
    {
      referenceId: 'sls.cancun-cancun',
      code: 'sls.cancun-cancun',
      name: 'Cancun',
      dialect: 'ohip',
    },
    {
      referenceId: 'sls.dubai-dubai',
      code: 'sls.dubai-dubai',
      name: 'Dubai',
      dialect: 'ohip',
    },
    {
      referenceId: 'sls.luxbrickell-miami',
      code: 'sls.luxbrickell-miami',
      name: 'Lux Brickell',
      dialect: 'ohip',
    },
    {
      referenceId: 'sls.playamujeres-cancun',
      code: 'sls.playamujeres-cancun',
      name: 'Playa Mujeres',
      dialect: 'ohip',
    },
    {
      referenceId: 'sls.puertomadero-buenosaires',
      code: 'sls.puertomadero-buenosaires',
      name: 'Puerto Madero',
      dialect: 'ohip',
    },
    {
      referenceId: 'sls.southbeach-miami',
      code: 'sls.southbeach-miami',
      name: 'South Beach',
      dialect: 'ohip',
    },
  ],
  homeUrl: 'https://slshotels.com',
  domains: [
    'booking.sls.local',
    'sls.dev.env.ennismore.com',
    'sls.staging.env.ennismore.com',
    'booking.slshotels.com',
  ],
  i18n: {
    locales: ['en-GB', 'en-US', 'es', 'fr'],
  },
  maxRoomCountPerBooking: 6,
  supportedRoomOccupancyConfigurations: [
    {
      adults: 1,
    },
    {
      adults: 2,
    },
    {
      adults: 1,
      children: 1,
    },
    {
      adults: 1,
      children: 2,
    },
    {
      adults: 1,
      children: 3,
    },
    {
      adults: 1,
      children: 4,
    },
    {
      adults: 2,
      children: 1,
    },
    {
      adults: 2,
      children: 2,
    },
    {
      adults: 2,
      children: 3,
    },
    {
      adults: 3,
    },
    {
      adults: 3,
      children: 1,
    },
    {
      adults: 3,
      children: 2,
    },
    {
      adults: 4,
    },
    {
      adults: 4,
      children: 1,
    },
    {
      adults: 5,
    },
  ],
  legal: {
    urls: {
      en: {
        privacyPolicy:
          'https://slshotels.com/terms-and-policies/privacy-policy/',
        cookiePolicy: 'https://slshotels.com/terms-and-policies/cookie-policy/',
        termsOfUse: 'https://slshotels.com/terms-and-policies/',
        accessibility:
          'https://slshotels.com/terms-and-policies/commitment-to-accessibility/',
        contact: 'https://slshotels.com',
      },
      es: {
        privacyPolicy:
          'https://es.slshotels.com/terms-and-policies/privacy-policy/',
        cookiePolicy:
          'https://es.slshotels.com/terms-and-policies/cookie-policy/',
        termsOfUse: 'https://es.slshotels.com/terms-and-policies/',
        accessibility:
          'https://es.slshotels.com/terms-and-policies/commitment-to-accessibility/',
        contact: 'https://es.slshotels.com',
      },
      fr: {
        privacyPolicy:
          'https://fr.slshotels.com/terms-and-policies/privacy-policy/',
        cookiePolicy:
          'https://fr.slshotels.com/terms-and-policies/cookie-policy/',
        termsOfUse: 'https://fr.slshotels.com/terms-and-policies/',
        accessibility:
          'https://fr.slshotels.com/terms-and-policies/commitment-to-accessibility/',
        contact: 'https://fr.slshotels.com',
      },
      // pt: {
      //   privacyPolicy:
      //     'https://pt.slshotels.com/terms-and-policies/privacy-policy/',
      //   cookiePolicy:
      //     'https://pt.slshotels.com/terms-and-policies/cookie-policy/',
      //   termsOfUse: 'https://pt.slshotels.com/terms-and-policies/',
      //   accessibility:
      //     'https://pt.slshotels.com/terms-and-policies/commitment-to-accessibility/',
      //   contact: 'https://pt.slshotels.com',
      // },
    },
  },
  ohipDiscountCodeType: 'CORPORATE',

  showRoomTotalInBreakdown: true,
  showTaxTotalInBreakdown: true,

  highlightedCountryCodes: [
    CountryCountry.ES,
    CountryCountry.FR,
    CountryCountry.IT,
    CountryCountry.GB,
    CountryCountry.US,
  ],
  features: {
    bookingManagement: true,
  },
  translationOverrides: {
    'en-GB': enGB,
    fr,
    es,
    // pt,
  },
  showAveragePricePerNight: true,
  analytics: {
    gtm: {
      containerId: isProduction ? 'GTM-TTTGGTKX' : undefined,
    },
    cookieConsent: {
      provider: 'onetrust',
      oneTrustId: 'ff38592a-96c5-4840-a8f6-486139dab6c6',
    },
  },
});
