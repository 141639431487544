import dayjs from 'dayjs';

import { API_DATE_FORMAT } from '@/api';
import { createArrayOfLength } from '@/common/utils/array';

type DateOrString = string;

/**
 * Generates an array of the week surrounding with the supplied date offset by n.
 * For example: Jan 28th 2020 is a Tuesday of week beginning the 27th.
 * So, getWeekDays('2020-01-28') returns ['2020-01-26', '2020-01-27', '2020-01-28', '2020-01-29', '2020-01-30', '2020-01-31', '2020-02-01']
 * We're using this to render the weekly calendar.
 * The offset is so that the given date is always the 3rd item in the list.
 * @param date
 */
export const getWeeklyCalendarSurroundingWeek = (checkInDate: DateOrString) => {
  const offset = -2;
  const middleDate = dayjs(checkInDate);

  return createArrayOfLength(7).map((index) =>
    middleDate.add(index + offset, 'd').format(API_DATE_FORMAT)
  );
};

/**
 * Returns the start date of the week period to search for weekly calendar dates.
 * This will either be the first date of the array, or today. To prevent searching in the past.
 * @param week An array containing 7 days, the week to search
 */
export const selectCalendarSearchStartDate = (week: string[]) => {
  const firstDate = week[0];

  if (dayjs(firstDate).isBefore(dayjs(), 'day')) {
    return dayjs().format(API_DATE_FORMAT);
  }

  return week[0];
};
