import { createContext } from 'react';

import { AvailableThemeKeys } from './available-theme-keys.type';

interface ThemeSwitcher {
  setActiveThemeKey: (themeKey: AvailableThemeKeys) => void;
  resetThemeKey: () => void;
  activeThemeKey: AvailableThemeKeys;
  defaultThemeKey: AvailableThemeKeys;
}

export const ThemeSwitcherContext = createContext<ThemeSwitcher | null>(null);
