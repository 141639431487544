import React from 'react';

import { useTranslation } from '@/i18n';
import { BodyCopy } from '@/ui/typography';

interface StepProgressProps {
  total: number;
  current: number;
}

export const StepProgress = (props: StepProgressProps) => {
  const { t } = useTranslation();
  return (
    <BodyCopy>
      {t('common:controls.steps', {
        currentStep: props.current,
        totalStepCount: props.total,
      })}
    </BodyCopy>
  );
};

export default StepProgress;
