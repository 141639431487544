import {
  AnimatePresence,
  Target,
  TargetAndTransition,
  motion,
} from 'framer-motion';
import React, { useState } from 'react';

import { DropdownIndicator } from '../icons';
import { Spacer } from '../spacing/Spacer.component';
import AnchorButton from './AnchorButton.component';

interface CollapsibleSectionProps {
  linkText: string;
  linkTitle?: string;
  children: React.ReactNode;
}

const closedStyle: Target = {
  height: 0,
};
const openStyle: TargetAndTransition = {
  height: 'auto',
};

/**
 * Collapsible children, with a link to toggle
 * @param props
 * @returns
 */
export const CollapsibleSection = (props: CollapsibleSectionProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div>
      <div>
        <AnchorButton
          aria-label={`${props.linkText} - ${props.linkTitle}`}
          onClick={() => setIsOpen((state) => !state)}
          aria-expanded={isOpen}
        >
          {props.linkText}
          <span style={{ marginLeft: 5, display: 'inline-block' }}>
            <DropdownIndicator isFlipped={isOpen} />
          </span>
        </AnchorButton>
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={closedStyle}
            animate={openStyle}
            exit={closedStyle}
            style={{ overflow: 'hidden' }}
          >
            <Spacer s="s" />
            {props.children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
