import { useLanguage } from '@/i18n';

import { useActiveBrandConfig } from './useActiveBrandConfig';

export const useBrandUrls = () => {
  const language = useLanguage();
  const { legal } = useActiveBrandConfig();

  return legal.urls[language];
};
