/* eslint-disable react/no-unused-prop-types */

/* ^ Passing props down causes eslint to think they're unused */
import React from 'react';

import { CurrencyCode } from '@/finance/utils/formatting';

import { PageStructuredData } from '.';
import { ReservationStatus } from './ReservationStatus.enum';
import { createStructuredDataObject } from './createStructuredDataObject.helper';

interface ReservationStructuredDataProps {
  reservationId: string;
  checkinTime: string;
  checkoutTime: string;
  totalPrice: string;
  basePrice?: string;
  priceCurrency: CurrencyCode;
  reservationStatus: ReservationStatus;
}

/**
 * Adds LodgingReservation JSON-LD to the page <head />.
 * @param props
 */
export const ReservationStructuredData = (
  props: ReservationStructuredDataProps
) => {
  return (
    <PageStructuredData
      data={createStructuredDataObject('LodgingReservation', props)}
    />
  );
};

export default ReservationStructuredData;
