export const getDefaultApiRequestOptions = (
  locale: string,
  options?: {
    skipRedirect?: boolean;
  }
) => ({
  headers: {
    'Accept-Language': locale,
    ...(options?.skipRedirect ? { 'X-Skip-Redirect': 'true' } : {}),
  },
});
