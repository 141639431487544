import React from 'react';
import { useMedia } from 'react-use';

interface ResponsiveConditionalProps {
  mobile?: React.ReactNode;
  desktop?: React.ReactNode;
  breakpoint?: number;
}

export const ResponsiveConditional = (props: ResponsiveConditionalProps) => {
  const isMobile = useMedia(`(max-width: ${props.breakpoint || 730}px)`);

  return isMobile ? (
    <div className="mobile">{props.mobile}</div>
  ) : (
    <div className="desktop">{props.desktop}</div>
  );
};

export default ResponsiveConditional;
