import { type FullStory } from '@fullstory/browser';

import { AvailabilityEvents } from '@/availability/events';
import { BookingCheckoutEvents } from '@/booking-checkout/events';
import type { BookingPaymentEvents } from '@/booking-payment/events';
import type { AppEventEmitter } from '@/events';
import { FeatureFlagEvents } from '@/flags/events';
import { defaultLogger } from '@/logging';

export type FullStoryEvents = FeatureFlagEvents &
  AvailabilityEvents &
  BookingCheckoutEvents &
  BookingPaymentEvents;

export function setupFullStoryEventBindings(
  events: AppEventEmitter<FullStoryEvents>,
  fullstoryFn: typeof FullStory | undefined
) {
  const listeners = [
    events.addListener('roomsImpression', (event) => {
      if (!fullstoryFn) {
        defaultLogger.debug('fs not initialised');
        return;
      }

      fullstoryFn('trackEvent', {
        name: 'Bedroom Search Results Impression',
        properties: {
          affiliation: event.hotel.referenceId,
        },
      });
    }),
    events.addListener('bookingPayment', (event) => {
      if (!fullstoryFn) {
        defaultLogger.debug('fs not initialised');
        return;
      }

      const breakdown = event.booking.firstTotalChargeBreakdown;

      // Following the Segment spec for 'Order Completed' events per FullStory docs
      // https://segment.com/docs/connections/spec/ecommerce/v2/#order-completed
      fullstoryFn('trackEvent', {
        name: 'Order Completed',

        properties: {
          order_id: event.booking.id,
          affiliation: event.hotel.referenceId,
          revenue: breakdown.grandTotal?.floatValue,
          currency: breakdown.grandTotal?.currencyCode,
        },
      });
    }),

    // Temporary direct binding for the Dis-loyalty dummy rate
    // @TODO remove once experiment is complete
    events.addListener('disloyaltyDummyRateEvaluation', (event) => {
      fullstoryFn?.('trackEvent', {
        name: 'Hypertune Flag Evaluation',
        properties: {
          experiment_id: 'disloyaltyDummyRate',
          result: event.result,
        },
      });
    }),
  ];

  return () => listeners.forEach((cleanup) => cleanup());
}
