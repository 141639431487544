import React from 'react';

import PageStructuredData from './PageStructuredData.component';
import { createStructuredDataObject } from './createStructuredDataObject.helper';

export const FullHouseStructuredData = () => (
  <PageStructuredData
    data={createStructuredDataObject('OfferCatalog', { numberOfItems: 0 })}
  />
);
