import { css } from '@emotion/react';
import React from 'react';

interface ScreenReaderAnnouncementProps {
  'aria-live'?: 'off' | 'assertive' | 'polite';
  children: React.ReactNode;
}

const styles = css`
  position: absolute;
  left: -22222px;
`;

export const ScreenReaderAnnouncement = (
  props: ScreenReaderAnnouncementProps
) => {
  return (
    <div aria-live={props['aria-live'] || 'polite'} role="alert" css={styles}>
      {props.children}
    </div>
  );
};

export default ScreenReaderAnnouncement;
