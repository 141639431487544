/**
 * https://github.com/Swizec/useDimensions/blob/master/src/index.ts
 */
import { useCallback, useLayoutEffect, useState } from 'react';

export interface DimensionObject {
  width: number;
  height: number;
  top: number;
  left: number;
  x: number;
  y: number;
  right: number;
  bottom: number;
}

export type UseDimensionsHook = [any, undefined | DimensionObject, HTMLElement];

export interface UseDimensionsArgs {
  liveMeasure?: boolean;
}

function getDimensionObject(node: HTMLElement): DimensionObject {
  const rect = node.getBoundingClientRect() as any; // Not going to pick through this

  return {
    width: rect.width,
    height: rect.height,
    top: 'x' in rect ? rect.x : rect.top,
    left: 'y' in rect ? rect.y : rect.left,
    x: 'x' in rect ? rect.x : rect.left,
    y: 'y' in rect ? rect.y : rect.top,
    right: rect.right,
    bottom: rect.bottom,
  };
}

/* 
Usage: 

const MyComponent = () => {
  const [ref, { x, y, width }] = useDimensions();

  return <div ref={ref}>This is the element you'll measure</div>;
};

*/
export function useDimensions({
  liveMeasure = true,
}: UseDimensionsArgs = {}): UseDimensionsHook {
  const [dimensions, setDimensions] = useState<DimensionObject>();
  const [node, setNode] = useState<any>(null);

  const ref = useCallback((node) => {
    setNode(node);
  }, []);

  useLayoutEffect(() => {
    if (!node) {
      return;
    }

    const measure = () =>
      window.requestAnimationFrame(() =>
        setDimensions(getDimensionObject(node))
      );
    measure();

    if (liveMeasure) {
      window.addEventListener('resize', measure);
      window.addEventListener('scroll', measure);

      return () => {
        window.removeEventListener('resize', measure);
        window.removeEventListener('scroll', measure);
      };
    }

    // Keep compiler happy...
    return;
  }, [node]);

  return [ref as any, dimensions, node];
}

export default useDimensions;
