import { css } from '@emotion/react';
import React from 'react';

import { useTheme } from '@/ui/theme';

export interface Tab {
  label: string;
  key: string;
}

interface TabControlProps {
  tabs: Tab[];
  activeTabKey?: string;
  onSelect: (key: string) => void;
}

export const TabControl = (props: TabControlProps) => {
  const { colors, fonts } = useTheme();
  return (
    <div
      css={css`
        border-bottom: #f0f0f0 1px solid;

        button {
          font-family: ${fonts.body};
          font-size: 20px;
          line-height: 24px;
          background: transparent;
          border: 0;
          cursor: pointer;
          margin-right: 32px;
          padding-bottom: 6px;
          font-weight: 300;
          border-bottom: 3px solid transparent;

          &.active {
            border-bottom: 3px solid ${colors.primary500};
            font-weight: 400;
          }
        }
      `}
    >
      {props.tabs.map((tab) => (
        <button
          type="button"
          onClick={() => props.onSelect(tab.key)}
          className={tab.key === props.activeTabKey ? 'active' : undefined}
          key={tab.key}
        >
          {tab.label}
        </button>
      ))}
    </div>
  );
};

export default TabControl;
