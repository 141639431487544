import { z } from 'zod';

import { getNumberOfNightsBetween } from '@/common/utils/date';
import {
  getTotalAdultGuestCount,
  getTotalChildGuestCount,
  getTotalGuestCount,
  transformRoomOccupancyListToUrlQuery,
} from '@/hotel/helpers/occupancy-type.helpers';
import { Occupancy } from '@/hotel/models/occupancy.model';

import { availabilityQueryToURLParamString } from '../helpers/query-url';

export type IAvailabilitySearchQueryInstance = z.infer<
  typeof AvailabilitySearchQuery
>;
export type IAvailabilitySearchQuerySnapshot = z.input<
  typeof AvailabilitySearchQuery
>;

export const availabilitySearchQuerySelectors = (
  query: IAvailabilitySearchQueryInstance
) => {
  function asURLQuery() {
    return {
      checkin: query.from,
      checkout: query.to,
      hotelCode: query.hotelCode,
      rateCode: query.rateCode,
      dayUse: query.dayUse ? 'true' : 'false',
      ...transformRoomOccupancyListToUrlQuery(query.rooms),
    };
  }
  function asURLQueryString() {
    return availabilityQueryToURLParamString(asURLQuery());
  }

  return {
    asURLQuery,
    asURLQueryString,
    totalNightCount(): number {
      return getNumberOfNightsBetween(query.from, query.to);
    },
    totalGuestCount(): number {
      return getTotalGuestCount(query.rooms);
    },
    totalAdultCount(): number {
      return getTotalAdultGuestCount(query.rooms);
    },
    totalChildCount(): number {
      return getTotalChildGuestCount(query.rooms);
    },
    roomCount(): number {
      return query.rooms.length;
    },

    resultsURL() {
      return `/availability?${asURLQueryString()}`;
    },
  };
};

export const AvailabilitySearchQuery = z.object({
  from: z.string(),
  to: z.string(),
  hotelCode: z.string(),
  rateCode: z.string().optional(),
  rooms: z.array(Occupancy).default([]),
  dayUse: z.boolean(),
});
