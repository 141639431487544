import { DomainError } from '@/errors';

export class AvailabilitySearchError extends DomainError {
  get isRateCodeInvalid() {
    return this.code === 'SEARCH_10';
  }
  get isFullHouse() {
    return this.code === 'SEARCH_03';
  }
  get isRecoverableByModifyingSearch() {
    return [
      'SEARCH_10',
      'SEARCH_VALIDATION_09',
      'SEARCH_VALIDATION_07',
    ].includes(this.code);
  }
  get isRetryable() {
    return ['LOCAL_NETWORK_ERROR_01', 'OPERA_01', 'GATEWAY_03'].includes(
      this.code
    );
  }
  /**
   * Does the error code indicate an instruction to redirect? If so, we should redirect immediately.
   * NOTE: This supercedes the auto-redirect flag.
   */
  get isRedirectInstruction() {
    return ['REDIRECT_01', 'REDIRECT_02'].includes(this.code);
  }
  /**
   * Should this error result in a redirect if a failure has occurred?
   * NOTE: This is skipped if the auto-redirect flag is disabled.
   */
  get isRedirectable() {
    return ['OPERA_01', 'GATEWAY_03'].includes(this.code);
  }
}
