import {
  V1CalendarRequest,
  V1CalendarResponse,
} from '@ennismore/ows-api-client';
import { UseQueryOptions, useQuery } from 'react-query';

import { useEnnismoreApiClient } from '@/api';
import { useSkipRedirectSwitch } from '@/availability-fallback/hooks';
import { mapNetworkError } from '@/errors';
import { useLogger } from '@/logging';

import { AvailabilitySearchError } from '../../availability/models/availability-search.error';

export const useAvailabilityCalendar = (
  args: V1CalendarRequest,
  options: UseQueryOptions<V1CalendarResponse, AvailabilitySearchError> = {}
) => {
  const client = useEnnismoreApiClient();
  const logger = useLogger();
  const [skipRedirect] = useSkipRedirectSwitch();

  return useQuery<V1CalendarResponse, AvailabilitySearchError>(
    ['availabilityCalendar', args],
    async () => {
      logger.debug('loading calendar', args.from, args.to, args.roomCode);

      try {
        const { data } = await client.bookingApi.bookingServiceCalendar(args, {
          headers: { 'X-Skip-Redirect': skipRedirect },
        });

        return data;
      } catch (e) {
        throw new AvailabilitySearchError(await mapNetworkError(e));
      }
    },
    {
      // refetchOnWindowFocus: false,
      // refetchOnMount: false,
      staleTime: 15 * 60e3, // 15 mins
      ...options,
    }
  );
};
