import { css } from '@emotion/react';
import React from 'react';

import DropdownIcon from '../icons/DropdownIcon.component';

interface CollapsibleSectionProps {
  collapsibleContent: React.ReactNode;
  children: React.ReactNode;
}

export const CollapsibleSection = (props: CollapsibleSectionProps) => {
  // Internal state for now. If we need to add external state management
  // we can do so at a configuration level using optional props.
  const [isExpanded, setIsExpanded] = React.useState(true);
  return (
    <div
      css={css`
        display: relative;
      `}
    >
      <div
        css={css`
          padding: 0;
          display: block;
          width: 100%;
          text-align: left;
          background: transparent;
          border: none;
        `}
        onClick={() => setIsExpanded((state) => !state)}
        title={
          isExpanded
            ? 'Select to hide additional content in this box'
            : 'Select to expand this box'
        }
      >
        {props.children} <DropdownIcon isExpanded={isExpanded} />
        <div style={{ display: isExpanded ? 'block' : 'none' }}>
          {props.collapsibleContent}
        </div>
      </div>
    </div>
  );
};

export default CollapsibleSection;
