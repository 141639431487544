import React from 'react';

/**
 * Pre-loading <link /> tags for the given font source addresses.
 * @param props
 */
export const FontPreloader = (props: { fontSources: string[] }) => {
  return (
    <React.Fragment>
      {props.fontSources.map((font) => (
        <link
          rel="preload"
          href={font}
          key={font}
          as="font"
          crossOrigin="anonymous"
        />
      ))}
    </React.Fragment>
  );
};

export default FontPreloader;
