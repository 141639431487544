import React, { useEffect } from 'react';

import { useAppEvents } from '@/events';

import { FullStoryEvents, setupFullStoryEventBindings } from '../fullstory';

export function FullStoryEventsProvider(props: { children: React.ReactNode }) {
  const events = useAppEvents<FullStoryEvents>();

  useEffect(() => {
    return setupFullStoryEventBindings(events, window.FS);
  }, [events]);

  return <>{props.children}</>;
}
