import React from 'react';

import ResponsiveConditional from './ResponsiveConditional.component';

interface OnlyDesktopProps {
  children: React.ReactNode;
}

export const OnlyDesktop = (props: OnlyDesktopProps) => {
  return <ResponsiveConditional desktop={props.children} />;
};

export default OnlyDesktop;
