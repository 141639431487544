import { IBookingInstance } from '@/booking/models/booking.model';

import { ReservationStatus } from './ReservationStatus.enum';

/**
 * Returns the http://schema.org representation of the given booking state.
 * @param booking
 */
export const getReservationStatusForBooking = (
  booking: IBookingInstance,
  isPaymentBeingTaken: boolean
): ReservationStatus => {
  if (booking.isConfirmed) {
    return ReservationStatus.Confirmed;
  }

  if (isPaymentBeingTaken) {
    return ReservationStatus.Pending;
  }

  return ReservationStatus.Hold;
};
