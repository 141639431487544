import css from '@emotion/css';
import Image from 'next/legacy/image';
import React from 'react';

import tickSvg from '@/hox-core/resources/controls/tick.svg';
import { Card } from '@/ui/layout/Card.component';
import { useTheme } from '@/ui/theme';

type AlertCardProps = {
  children: React.ReactNode;
  type: 'info' | 'success' | 'warning';
  className?: string;
};

const AlertIcon = (props: { type: AlertCardProps['type'] }) => {
  switch (props.type) {
    case 'success':
      return <Image src={tickSvg.src} width={16} height={16} />;
    case 'info':
      return <span>i</span>;
    case 'warning':
      return <span>!</span>;
  }
};

const getColors = (type: AlertCardProps['type']) => {
  switch (type) {
    // @todo update colours for different states
    case 'success':
    case 'warning':
    case 'info':
      return { borderColor: '#3979C5', backgroundColor: '#F2F8FF' };
  }
};

export const AlertCard = ({ children, type, className }: AlertCardProps) => {
  const { colors } = useTheme();
  const { borderColor, backgroundColor } = getColors(type);
  return (
    <Card
      className={className}
      border={`1px solid ${borderColor}`}
      backgroundColor={backgroundColor}
      rounded
    >
      <div
        role="alert"
        css={css`
          display: flex;
        `}
      >
        {
          <span
            aria-hidden
            css={css`
              width: 26px;
              height: 26px;
              flex-shrink: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 100%;
              background-color: ${borderColor};
              margin-right: 21px;
              font-size: 20px;
              font-weight: 600;
              color: ${colors.white};
            `}
          >
            <AlertIcon type={type} />
          </span>
        }
        <div>{children}</div>
      </div>
    </Card>
  );
};
