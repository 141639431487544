import { FinancialAmountModel } from '@/finance';

import { IBookingInstance } from '../models/booking.model';

/**
 * This is calculated by adding every roomStay[]->firstRatePlan->grandTotal together.
 */
export const calculateStayTotal = (
  booking: IBookingInstance,
  currencyCode: string
) => {
  const value = booking.roomStay.reduce(
    (acc, stay) =>
      acc + (stay.firstRatePlan.chargeBreakdown.grandTotal?.value || 0),
    0
  );

  return FinancialAmountModel.parse({
    currencyCode: currencyCode,
    decimal: 2,
    value,
  });
};
