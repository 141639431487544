import type { InternalAxiosRequestConfig } from 'axios';

import { getCurrentLanguage } from '@/i18n';

export const languageInterceptor = (config: InternalAxiosRequestConfig) => {
  // These will always be in a user preferred order
  const browserLanguages =
    typeof navigator !== 'undefined' ? navigator?.languages || [] : [];

  const currentLanguage = getCurrentLanguage();

  // A 'Set' allows to avoid duplicate locales
  const acceptLanguages = Array.from(
    new Set([currentLanguage, ...browserLanguages])
  );

  config.headers['Accept-Language'] = acceptLanguages.join(',');

  return config;
};
