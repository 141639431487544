import type { BrandConfigurationFactory } from '@/brand/common/interfaces/BrandConfigurationFactory';
import { CountryCountry } from '@/em-api-client-typescript-fetch';

import enGB from './locale/en-GB.json';
import fr from './locale/fr.json';

export const delanoBrandConfig: BrandConfigurationFactory = () => ({
  chainCode: 'delano',
  themeKey: 'delano',
  name: 'Delano',
  defaultHotelCode: 'delano.maisondelano-paris',
  hotels: [
    {
      referenceId: 'delano.maisondelano-paris',
      code: 'delano.maisondelano-paris',
      name: 'Paris',
      dialect: 'ohip',
    },
    {
      referenceId: 'delano.dubai-dubai',
      code: 'delano.dubai-dubai',
      name: 'Dubai',
      dialect: 'ohip',
    },
  ],
  homeUrl: 'https://delanohotels.com',
  domains: [
    'booking.delano.local',
    'delano.dev.env.ennismore.com',
    'delano.staging.env.ennismore.com',
    'booking.delanohotels.com',
  ],
  i18n: {
    locales: ['en-GB', 'en-US', 'fr'],
  },

  maxRoomCountPerBooking: 8,

  supportedRoomOccupancyConfigurations: [
    {
      adults: 1,
    },
    {
      adults: 2,
    },
    {
      adults: 1,
      children: 1,
    },
    {
      adults: 1,
      children: 2,
    },
    {
      adults: 1,
      children: 3,
    },
    {
      adults: 1,
      children: 4,
    },
    {
      adults: 2,
      children: 1,
    },
    {
      adults: 2,
      children: 2,
    },
    {
      adults: 2,
      children: 3,
    },
    {
      adults: 3,
    },
    {
      adults: 3,
      children: 1,
    },
    {
      adults: 3,
      children: 2,
    },
    {
      adults: 4,
    },
    {
      adults: 4,
      children: 1,
    },
    {
      adults: 5,
    },
  ],
  legal: {
    urls: {
      en: {
        privacyPolicy:
          'https://delanohotels.com/terms-policies/privacy-policy/',
        cookiePolicy: 'https://delanohotels.com/terms-policies/cookie-policy/',
        termsOfUse: 'https://delanohotels.com/terms-policies/',
        accessibility: 'https://ennismore.com/accessibility/',
        contact: 'https://maisondelanoparis.com/en/enquiry-form/concierge/',
      },
      fr: {
        privacyPolicy:
          'https://delanohotels.com/terms-policies/privacy-policy/',
        cookiePolicy: 'https://delanohotels.com/terms-policies/cookie-policy/',
        termsOfUse: 'https://delanohotels.com/terms-policies/',
        accessibility: 'https://ennismore.com/accessibility/',
        contact:
          'https://maisondelanoparis.com/fr/enquiry-form/nous-contacter/',
      },
    },
  },
  ohipDiscountCodeType: 'CORPORATE',

  showRoomTotalInBreakdown: true,
  showTaxTotalInBreakdown: true,

  highlightedCountryCodes: [
    CountryCountry.FR,
    CountryCountry.IT,
    CountryCountry.NL,
    CountryCountry.GB,
    CountryCountry.US,
  ],
  features: {
    bookingManagement: true,
  },
  translationOverrides: {
    'en-GB': enGB,
    fr,
  },
  showAveragePricePerNight: true,
  analytics: {
    gtm: {
      containerId: 'GTM-PFSKKGBZ',
    },
    cookieConsent: {
      provider: 'onetrust',
      oneTrustId: '01909c15-6184-7a65-9ee6-d2ff5b4c7a3c',
    },
  },
});
