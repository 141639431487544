import { DomainError } from '@/errors';

export class CreateBookingError extends DomainError {
  /**
   * This is true when a user has attempted to book multiple rooms using a code applicable to only one.
   */
  get isInvalidRoomRequest() {
    return this.code === 'GATEWAY_10';
  }
}
