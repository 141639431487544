import { css } from '@emotion/react';
import React from 'react';

import { Box } from '@/box';
import { AnchorLink } from '@/ui/controls';
import { Spacer } from '@/ui/spacing';
import { BodyCopy, Heading } from '@/ui/typography';

export const GleneaglesBanner = () => {
  return (
    <div
      css={css`
        background: #304162;
        color: #f0e3e1;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 24px;
        padding-bottom: 24px;
        padding-left: 20px;
        padding-right: 20px;

        @media all and (max-width: 760px) {
          flex-wrap: wrap;
        }
      `}
    >
      <Box width={[100, 107]} marginLeft={[0, 8]} marginTop={[18, 0]}>
        <img src="/brands/gleneagles/images/banner.png" width="100%" alt="" />
      </Box>

      <Box
        marginLeft={[0, 42]}
        marginTop={20}
        marginBottom={[0, 20]}
        width={['100%', 'auto']}
        textAlign={['center', 'left']}
        paddingRight={[0, 32]}
      >
        <Heading as="h4" style="heading4">
          <div css={css({ color: '#f0e3e1', marginBottom: 16 })}>
            {`Festive Packages`}
          </div>
        </Heading>
        <BodyCopy>
          <div
            css={css({ a: { textDecoration: 'underline' }, color: '#f0e3e1' })}
          >
            {/* <Markdown source="Want to join us this Christmas? [Click here for our Christmas Package](https://booking.gleneagles.com/en-gb/availability?checkin=2023-12-24&checkout=2023-12-27&hotelCode=GLEN&rooms=TWO_ADULT&rateCode=) (24th - 27th Dec), [here for our Halfway Holiday](https://booking.gleneagles.com/en-gb/availability?checkin=2023-12-27&checkout=2023-12-30&hotelCode=GLEN&rooms=TWO_ADULT&rateCode=) (27th - 30th Dec) or [here for our New Year Break](https://booking.gleneagles.com/en-gb/availability?checkin=2023-12-30&checkout=2024-01-02&hotelCode=GLEN&rooms=TWO_ADULT&rateCode=)."></Markdown> */}
            {`There's something for everyone.`}
            <Spacer s="xs" />
            <AnchorLink href="https://booking.gleneagles.com/en-gb/availability?checkin=2023-12-24&checkout=2023-12-27&hotelCode=GLEN&rooms=TWO_ADULT&rateCode=">{`Christmas Stays`}</AnchorLink>{' '}
            {`(24th - 27th Dec)`}
            <br />
            <AnchorLink href="https://booking.gleneagles.com/en-gb/availability?checkin=2023-12-27&checkout=2023-12-30&hotelCode=GLEN&rooms=TWO_ADULT&rateCode=">{`Halfway Holiday`}</AnchorLink>{' '}
            {`(27th - 30th Dec)`}
            <br />
            <AnchorLink href="https://booking.gleneagles.com/en-gb/availability?checkin=2023-12-30&checkout=2024-01-02&hotelCode=GLEN&rooms=TWO_ADULT&rateCode=">{`New Year Break`}</AnchorLink>{' '}
            {`(30th - 2nd Jan)`}
          </div>
        </BodyCopy>
      </Box>
    </div>
  );
};
