import type { V1GetAvailabilityRequest } from '@ennismore/booking-api-client';
import { useQuery } from 'react-query';

import { useEnnismoreApiClientConfig } from '@/api';
import { MissingAPIDialectError } from '@/api/errors/missing-api-dialect.error';
import {
  selectHotelConfigurationByReferenceIdOrFail,
  useActiveBrandConfig,
} from '@/brand';
import { trpcClient } from '@/trpc/client';

import { AVAILABILITY_MAX_ATTEMPTS } from '../availability.const';
import {
  AvailabilitySearchResultsModel,
  IAvailabilitySearchResultsModelSnapshot,
} from '../models';
import { AvailabilitySearchError } from '../models/availability-search.error';
import { getAvailabilityServiceClient } from '../services/client';

export const useBedroomAvailability = (
  args: V1GetAvailabilityRequest & { locale: { language: string } },
  options: { onError?: (error: AvailabilitySearchError) => void } = {}
) => {
  const brandConfig = useActiveBrandConfig();
  const clientConfig = useEnnismoreApiClientConfig();

  const {
    dialect,
    legacyTaxes: { isAdvanced },
  } = selectHotelConfigurationByReferenceIdOrFail(
    brandConfig,
    args.hotelReferenceId
  );

  if (!dialect) {
    throw new MissingAPIDialectError();
  }

  const { data: dehydratedData, ...rest } = useQuery<
    IAvailabilitySearchResultsModelSnapshot,
    AvailabilitySearchError
  >(
    [
      'bedroomAvailabilitySearch',
      brandConfig.chainCode,
      args,
      dialect,
      isAdvanced,
    ],
    async () => {
      return await getAvailabilityServiceClient(
        clientConfig,
        dialect
      ).getAvailability(args, { withCostBreakdown: isAdvanced });
    },
    {
      // Retry up to (AVAILABILITY_MAX_ATTEMPTS - 1) times if the error is flagged as retryable
      // (retry count doesn't include initial attempt)
      retry: (count, error) =>
        error.isRetryable && count < AVAILABILITY_MAX_ATTEMPTS - 1,
      retryOnMount: false,
      staleTime: 15 * 60e3,
      onSuccess(result) {
        // Report the successful loading of availability
        trpcClient.telemetry.reportAvailabilitySuccess.mutate({
          availabilityQuery: args,
          roomCount: result.rooms.length,
        });
      },
      onError(error) {
        options.onError?.(error);
      },
    }
  );

  const parsed = dehydratedData
    ? AvailabilitySearchResultsModel.safeParse(dehydratedData)
    : undefined;

  // ZodErrors are difficult to read when emitted to the console so manually emitting here temporarily
  if (parsed && !parsed.success) {
    console.log(
      'GetAvailability response validation failed: ',
      parsed.error.errors
    );
    throw parsed.error;
  }

  return {
    data: parsed?.data,
    ...rest,
  };
};
