import { css } from '@emotion/react';
import React from 'react';

interface LoadingIndicatorProps {
  width?: number;
  style?: 'light' | 'dark';
  height?: number;
}

export const LoadingIndicator = (props: LoadingIndicatorProps) => {
  const style = props.style || 'light';

  const ringColor =
    style === 'light'
      ? 'border: 1px solid rgba(255, 255, 255, 0.2);'
      : 'border: 1px solid rgba(0, 0, 0, 0.1);';
  const highlightColor =
    style === 'light'
      ? 'border-top-color: rgba(255, 255, 255, 0.8);'
      : 'border-top-color: #656565;';

  return (
    <div
      css={css`
        position: relative;
        width: 100%;
        height: ${props.height || 100}px;
      `}
    >
      <div
        css={css`
          @keyframes rotate-spinner {
            100% {
              transform: rotate(360deg);
            }
          }
          border-radius: 50%;
          ${ringColor}
          ${highlightColor}
          position: absolute;
          left: 25%;
          right: 25%;
          top: 25%;
          bottom: 25%;
          margin: auto;
          width: ${props.width || 40}px;
          height: ${props.width || 40}px;
          transition: opacity 250ms;
          animation: rotate-spinner 0.8s linear;
          animation-iteration-count: infinite;
        `}
      />
    </div>
  );
};

export default LoadingIndicator;
