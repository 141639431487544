import { css } from '@emotion/react';
import { motion } from 'framer-motion';
import React from 'react';

import { useTheme } from '@/ui/theme';

interface CardProps {
  children: React.ReactNode;
  layoutId?: string;
  'aria-labelledby'?: string;
  testId?: string;
  className?: string;
}

export const Card = (props: CardProps) => {
  const { colors, componentProperties } = useTheme();
  const cardStyles = componentProperties.cards;
  const styles = cardStyles?.primary;

  const border = styles?.border;
  const borderRadius = styles?.borderRadius;

  return (
    <motion.section
      className={props.className}
      css={css`
        background-color: ${cardStyles?.primary?.backgroundColor ??
        colors.white};
        padding: 40px;
        /* border: 1px solid #d8d8d8; */
        span {
          ${cardStyles?.primary?.color && `color: ${cardStyles.primary.color};`}
        }
        ${border && `border: ${border};`}
        ${borderRadius && `border-radius: ${borderRadius};`}

        @media all and (max-width: 730px) {
          padding: ${cardStyles?.primary?.mobile?.padding ?? '28px'};
          ${cardStyles?.primary?.mobile?.margin &&
          `margin: ${cardStyles?.primary?.mobile?.margin};`}
          ${cardStyles?.primary?.mobile?.border &&
          `border: ${cardStyles?.primary?.mobile?.border};`}
        }
        div[role='separator'] {
          ${cardStyles?.primary?.divider?.borderColor &&
          `border-color: ${cardStyles?.primary?.divider?.borderColor};`}
        }
      `}
      layoutId={props.layoutId}
      aria-labelledby={props['aria-labelledby']}
      data-test={props.testId}
    >
      {props.children}
    </motion.section>
  );
};

export default Card;
