/**
 * Higher-order function that flips the output of given function.
 * @param fn
 * @returns
 */
export function negate<T extends (...args: any[]) => boolean>(
  fn: T
): (...fnArgs: Parameters<T>) => boolean {
  return (...args: Parameters<T>): boolean => {
    return !fn(...args);
  };
}
