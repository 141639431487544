import { z } from 'zod';

import { iconSchema } from './icon.model';

export const HotelRoomExtraModel = z.object({
  // We're not typing this deliberately, as these IDs are provided in the CMS
  // rather than an enum. runtime checking is more appropriate here.
  id: iconSchema,
  title: z.string().optional(),
  text: z.string().optional(),
});
