import orderBy from 'lodash.orderby';
import { useMemo } from 'react';

import { negate } from '@/common/utils/function';
import { CountryCountry } from '@/em-api-client-typescript-fetch';
import { useTranslation } from '@/i18n';
import { OPERA_COUNTRY_CODES } from '@/places';

export interface CountrySelectOption {
  label: string;
  value: string;
  disabled: boolean;
}

/**
 * Fetches translations for, formats, then sorts options for country selection.
 * Highlighted countries (configured by brand) are placed at the beginning of the array.
 * @returns Sorted country selection options
 */
export const useCountrySelectOptions = (
  highlightedCountryCodes: CountryCountry[]
): CountrySelectOption[] => {
  const { t: getCountryLabelTranslation } = useTranslation('countries');

  return useMemo(() => {
    const countryOptions = OPERA_COUNTRY_CODES.map(
      (country): CountrySelectOption => ({
        label: getCountryLabelTranslation(
          country.code as any as TemplateStringsArray
        ),
        value: country.code,
        disabled: false,
      })
    );

    if (highlightedCountryCodes.length <= 0) {
      return orderBy(countryOptions, ['label']);
    }

    // Check if option code is included in the list supplied in brand config
    const isOptionHighlighted = ({ value }) =>
      highlightedCountryCodes.includes(value as CountryCountry);

    const highlightedCountries = orderBy(
      countryOptions.filter(isOptionHighlighted),
      ['label']
    );
    const otherCountries = orderBy(
      countryOptions.filter(negate(isOptionHighlighted)),
      ['label']
    );

    return [
      ...highlightedCountries,
      { label: '─', value: '__spacer__', disabled: true },
      ...otherCountries,
    ];
  }, [
    OPERA_COUNTRY_CODES,
    highlightedCountryCodes,
    // t's identity changes only when namespaces change
    // so it's safe to include in this dep array
    getCountryLabelTranslation,
  ]);
};
