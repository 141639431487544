import React from 'react';

interface DataTableProps {
  children: React.ReactNode;
}

/**
 * Wrap around <DataTableRow /> elements. Just a div with the table role.
 */
export const DataTable: React.FC<DataTableProps> = (props) => {
  return <div role="table">{props.children}</div>;
};
