import React from 'react';

import { PageStructuredData, createStructuredDataObject } from '.';

interface HotelRoomOffersStructuredDataProps {
  itemOffered: {
    name: string;
    identifier: string;
  };
  name: string;
  identifier: string;
  priceSpecification: {
    price: string;
    priceCurrency: string;
    unitText?: string; // "Total" if the price is for the entire stay. "Nightly" if the price is per night. Defaults to "Nightly".
  };
}

/**
 * Adds HotelRoomOffers JSON-LD to the page <head />.
 * @param props
 */
export const HotelRoomOffersStructuredData = ({
  itemOffered,
  priceSpecification,
  ...props
}: HotelRoomOffersStructuredDataProps) => {
  return (
    <PageStructuredData
      data={createStructuredDataObject('Offer', {
        itemOffered: createStructuredDataObject('HotelRoom', itemOffered),
        priceSpecification: createStructuredDataObject(
          'UnitPriceSpecification',
          priceSpecification
        ),
        ...props,
      })}
    />
  );
};

export default HotelRoomOffersStructuredData;
