import React from 'react';

import { hoxtonTheme } from '@/brand/hoxton/hoxton.theme';

import { EnnismoreTheme } from './ennismore-theme.interface';

export const EnnismoreThemeContext = React.createContext<EnnismoreTheme>(
  // Default to The Hoxton
  hoxtonTheme
);
