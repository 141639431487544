import { V1Guest } from '@ennismore/ows-api-client';

import { IBookingInstance } from '@/booking/models/booking.model';
import { DAY_USE_CODE } from '@/hotel/constants';

import {
  AvailabilitySearchQuery,
  IAvailabilitySearchQueryInstance,
} from '../models';

export const createAvailabilitySearchQueryFromBooking = (
  booking: IBookingInstance,
  hotelCode: string
): IAvailabilitySearchQueryInstance => {
  return AvailabilitySearchQuery.parse({
    from: booking.firstRoomStay.from,
    to: booking.firstRoomStay.to,
    hotelCode,
    dayUse: booking.firstRoomStay.firstRatePlan.rateCode === DAY_USE_CODE,
    rooms: booking.roomStay.map((stay) => ({
      type: stay.roomGuest || V1Guest.OneAdult,
    })),
  });
};
