import React from 'react';

import { useCanary } from '../hooks';

interface CanaryProviderProps {
  children: React.ReactNode;
}

export const CanaryProvider = (props: CanaryProviderProps) => {
  useCanary();
  return <>{props.children}</>;
};

export default CanaryProvider;
