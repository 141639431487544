import i18n from 'i18next';
import { useRouter } from 'next/router';
import React, { useEffect, useMemo, useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import { I18nextProvider } from 'react-i18next';

import { useActiveBrandConfig } from '@/brand';
import { formatUTCDate, setDayjsLocale } from '@/common/utils/date';
import { useFeatureSwitchStatus } from '@/features';

import { getTranslationStrings } from './languages';

interface LocaleProviderProps {
  children: React.ReactNode;
}

let currentLanguage = 'en-GB';

/**
 * Language getter. To be used in cases where language is needed outside of React components.
 */
export const getCurrentLanguage = () => currentLanguage;

const setCurrentLanguage = (language: Intl.BCP47LanguageTag) => {
  currentLanguage = language;
};

/**
 * Updates the language within i18next using the locale from the Next.js router.
 * @param props
 */
export const LocaleProvider: React.FC<LocaleProviderProps> = (props) => {
  const router = useRouter();
  const brandConfig = useActiveBrandConfig();

  const [instance] = useState(() => i18n.createInstance());

  const rtl = useFeatureSwitchStatus('rtl');
  useEffect(() => {
    document.body.dir = rtl ? 'rtl' : '';
  }, [rtl]);

  useMemo(() => {
    instance.init({
      resources: getTranslationStrings(brandConfig.translationOverrides),
      lng: 'en-GB', // Default language - could be defined in brand config in future
      fallbackLng: 'en-GB',
      saveMissing: true,

      interpolation: {
        escapeValue: false,

        format: (value, format) => {
          if (value instanceof Date && format) {
            return formatUTCDate(value, format);
          }
          return value;
        },
      },
    });
  }, [brandConfig.chainCode]);

  useEffect(() => {
    const locale = router.locale
      ? new Intl.Locale(router.locale).baseName
      : 'en-GB';
    instance?.changeLanguage(locale);
    setDayjsLocale(locale);
    setCurrentLanguage(locale);
  }, [router.locale, instance]);

  return (
    // I18nextProvider isn't up to date with the new React types (children not defined) so skipping checks here.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <I18nextProvider i18n={instance}>{props.children}</I18nextProvider>
  );
};

export default LocaleProvider;
