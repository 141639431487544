import React from 'react';

import Card from '@/core/components/layout/Card.component';
import { useTranslation } from '@/i18n';
import { Spacer } from '@/ui/spacing';
import { BodyCopy, Heading } from '@/ui/typography';

interface RestaurantComingSoonProps {}

export const RestaurantComingSoon: React.FC<RestaurantComingSoonProps> = () => {
  const { t } = useTranslation('restaurants');
  return (
    <Card>
      <Heading style="heading5" as="h5">
        {t('restaurantCardTitle')}
      </Heading>
      <Spacer s="xs" />
      <BodyCopy>{t('openingRestaurant')}</BodyCopy>
    </Card>
  );
};
