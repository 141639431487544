import { AxiosError } from 'axios';

import { getLogger } from '@/logging';

export function logAxiosError(error: AxiosError) {
  getLogger().debug({
    message: 'axios error received',
    request: {
      body: error.request?.body,
      headers: error.request?.headers,
      url: error.request?.url,
    },
    response: error.response && {
      data: error.response.data,
      headers: error.response.headers,
      status: error.response.status,
    },
  });
}
