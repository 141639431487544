import { css } from '@emotion/react';
import React from 'react';

import crossSvg from '../../resources/svg/cross.svg';

interface CloseButtonProps {
  onClick: () => void;
}

export const CloseButton = (props: CloseButtonProps) => {
  return (
    <button
      css={css`
        border: none;
        padding: 0;
        cursor: pointer;
        background: transparent;
        touch-action: manipulation;

        &.focus-visible {
          outline: 1px solid #8f8f8f;
          outline-offset: 2px;
        }
      `}
      onClick={props.onClick}
      aria-label="Close popup"
      role="button"
      tabIndex={0}
    >
      <img alt="" src={crossSvg.src} width={24} height={24} />
    </button>
  );
};

export default CloseButton;
