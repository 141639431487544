import Head from 'next/head';
import React from 'react';

interface PageStructuredDataProps<T> {
  data: T & { '@type': string; '@schema': string };
}

/**
 * Serializes given data into JSON-LD and adds to the current page <head />.
 * @param props
 */
export const PageStructuredData = function <T>(
  props: PageStructuredDataProps<T>
) {
  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(props.data),
        }}
      ></script>
    </Head>
  );
};

export default PageStructuredData;
