import { css } from '@emotion/react';
import orderBy from 'lodash.orderby';
import React from 'react';
import type { SubmitHandler } from 'react-hook-form';

import { Box, Flex } from '@/box';
import {
  selectHotelConfigurationByCodeOrFail,
  useActiveBrandConfig,
} from '@/brand';
import { useTranslation } from '@/i18n';
import { Form } from '@/ui/form';
import { Divider } from '@/ui/layout';
import { Spacer } from '@/ui/spacing';
import { useTheme } from '@/ui/theme';

import {
  DatePickerField,
  DayUseCheckboxInput,
  HotelCodeSelectInput,
  RateCodeTextInputContainer,
  RoomCountSelectInput,
  RoomOccupancySelectInputArray,
  SearchButton,
  SearchFormErrorMessage,
} from '.';
import { createSearchFormValidationSchema } from '../validators/search-form.validator';
import type { SearchFormFields } from '../validators/search-form.validator';

interface SearchFormProps {
  onSubmit: SubmitHandler<SearchFormFields>;
  isLoading?: boolean;
  errorMessage?: string;
  initialValues?: SearchFormFields;
}

export const SearchForm = (props: SearchFormProps) => {
  const { t: searchTranslation } = useTranslation('search');
  const brandConfig = useActiveBrandConfig();
  const getHotelConfig = (hotelCode: string) =>
    selectHotelConfigurationByCodeOrFail(brandConfig, hotelCode);

  const { forms } = useTheme();

  const validationSchema = createSearchFormValidationSchema({
    searchTranslation,
    resolveHotelOpeningDate: (hotelCode: string) =>
      getHotelConfig(hotelCode).openingDate,
    resolveHotelLocationName: (hotelCode: string) =>
      getHotelConfig(hotelCode).name,
  });

  // Format hotel dropdown options, alphabetise
  // TODO: Move this
  const hotelOptions = orderBy(
    brandConfig.hotels.filter((hotel) => hotel.visibility.search),
    [(hotel) => hotel.name?.toLowerCase()],
    ['asc']
  ).map((hotel) => ({
    label: hotel.name,
    value: hotel.code,
    disabled: !hotel.visibility.search,
  }));

  return (
    <Form
      onSubmit={(values) => props.onSubmit(values)}
      defaultValues={props.initialValues}
      validationSchema={validationSchema}
    >
      <Flex flexWrap={['wrap', 'nowrap']}>
        {hotelOptions.length > 1 && (
          <Box paddingRight={[0, 40]} width={[1, 'auto']} minWidth={[1, 1 / 3]}>
            <HotelCodeSelectInput options={hotelOptions} />
          </Box>
        )}
        <Box
          paddingRight={0}
          width={[1, 1 / 2]}
          marginTop={[20, 0]}
          {...forms?.searchForm?.datePickerContainer}
        >
          <DatePickerField />
        </Box>
      </Flex>
      <Spacer s="xxs" />
      <DayUseCheckboxInput />
      <Spacer s="m" />
      <Flex flexWrap="wrap">
        <Box
          width={[1, 1 / 4]}
          paddingRight={[0, 40]}
          {...forms?.searchForm?.roomCountSelectorContainer}
        >
          <RoomCountSelectInput />
        </Box>
        <Box
          width={[1, 9 / 12]}
          {...forms?.searchForm?.roomOccupancySelectorListContainer}
        >
          <RoomOccupancySelectInputArray />
        </Box>
      </Flex>
      <Divider
        css={css({
          // Opt-in usage
          display: 'none',
          ...forms?.searchForm?.divider,
        })}
      />
      <Spacer s="xxs" />
      <RateCodeTextInputContainer
        defaultValue={props.initialValues?.rateCode}
      />
      <Flex alignItems="center" flexDirection={['column', 'row']}>
        <Box width="auto" minWidth={[1, 1 / 4, 1 / 5]}>
          <SearchButton isLoading={props.isLoading} />
        </Box>
        <SearchFormErrorMessage message={props.errorMessage} />
      </Flex>
    </Form>
  );
};
