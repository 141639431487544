import { ZodError } from 'zod';

/**
 * Recieves a ZodError and formats to a simple, terse message suitable for including in debug messages.
 */
export function formatZodErrorAsDebugString(error: ZodError) {
  return Object.entries(error.flatten().fieldErrors)
    .map(([field, value]) => `${field}: ${value?.join(',')}`)
    .join('; ');
}
