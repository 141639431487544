import { css } from '@emotion/react';
import React from 'react';

import ResponsiveConditional from '@/common/components/responsive/ResponsiveConditional.component';
import { IHotelModelInstance } from '@/hotel';
import DesktopHotelPerks from '@/hox-core/components/DesktopHotelPerks.component';
import { AnimatedStack } from '@/ui/spacing';
import { useTheme } from '@/ui/theme';

import { SearchResultsCancellationPolicySidebarSectionContainer } from '.';

export const SearchResultsSidebarContainer: React.FC<{
  hotelModel: IHotelModelInstance;
}> = ({ hotelModel }) => {
  const {
    componentProperties: { search },
  } = useTheme();
  return (
    <ResponsiveConditional
      desktop={
        <AnimatedStack s={search?.results?.sidebarContainer?.rowGap}>
          <SearchResultsCancellationPolicySidebarSectionContainer
            hotelReferenceId={hotelModel.referenceId}
          />

          <DesktopHotelPerks
            perks={hotelModel.content.perks}
            css={css(search?.results?.sidebarContainer?.hotelPerks)}
          />
        </AnimatedStack>
      }
    />
  );
};
