import { css } from '@emotion/react';
import React from 'react';
import { useId } from 'react';

import type { EnnismoreThemeIcons } from '@/ui/theme';
import { useTheme } from '@/ui/theme';
import { useIcon } from '@/ui/theme/hooks/use-icon';
import { BodySmall } from '@/ui/typography';

export const HotelRoomSpecItem = (props: {
  label: string;
  value: string;
  id: EnnismoreThemeIcons;
}) => {
  const specLabelId = useId();
  const iconSrc = useIcon(props.id);
  const {
    componentProperties: { bedroomCard },
  } = useTheme();

  return (
    <div
      css={css({
        display: 'flex',
        alignItems: 'center',
        'div > span': {
          fontSize: '14px',
          lineHeight: '22px',
          ...bedroomCard?.roomSpecItem,
        },
        img: {
          maxWidth: '24px',
          minWidth: '12px',
          display: 'block',
          margin: 'auto',
          ...bedroomCard?.roomSpecItem?.icon,
        },
      })}
    >
      <div
        css={css`
          width: 24px;
          margin-right: 8px;
        `}
      >
        <img src={iconSrc} alt="" />
      </div>
      <div
        css={css({
          flex: '1',
          ['.value span']: bedroomCard?.roomSpecItem?.value,
        })}
      >
        <BodySmall id={specLabelId}>{props.label}</BodySmall>
        <div className="value" aria-labelledby={specLabelId}>
          <BodySmall color="textSecondary">{props.value}</BodySmall>
        </div>
      </div>
    </div>
  );
};

export default HotelRoomSpecItem;
