import { useContext } from 'react';

import { EnnismoreAPIClientConfigContext } from '../contexts';

export const useEnnismoreApiClientConfig = () => {
  const config = useContext(EnnismoreAPIClientConfigContext);

  if (!config) {
    throw new Error(
      `This hook must be used by a child of <EnnismoreApiClientProvider />`
    );
  }

  return config;
};
