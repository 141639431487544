import { z } from 'zod';

import { BookingTaxType } from '@/em-api-client-typescript-fetch';
import { FinancialAmountModel } from '@/finance';
import { sumFinancialAmount } from '@/finance/helpers/sumFinancialAmount.func';
import { TaxModel } from '@/finance/models/tax.model';

export type ICostBreakdown = typeof CostBreakdownModel;
export interface ICostBreakdownInstance extends z.infer<ICostBreakdown> {}

export const CostBreakdownModel = z
  .object({
    taxesFees: z.array(TaxModel),
    totalRoomRate: FinancialAmountModel,
    totalTaxesFee: FinancialAmountModel,
  })
  .transform((self) => ({
    ...self,
    get totalRoomRateWithTaxes() {
      return sumFinancialAmount(self.totalRoomRate, self.totalTaxesFee);
    },
    get totalRoomRateWithVAT() {
      return self.taxesFees.reduce((total, tax) => {
        if (!tax.amount) {
          return total;
        }

        switch (tax.tax) {
          case BookingTaxType.VAT5PERCENT:
          case BookingTaxType.VAT9PERCENT:
          case BookingTaxType.VAT10PERCENT:
          case BookingTaxType.VAT20PERCENT:
            return sumFinancialAmount(total, tax.amount);
          default:
            return total;
        }
      }, self.totalRoomRate);
    },
  }));
