import { V1Guest } from '@ennismore/ows-api-client';
import React from 'react';

import { IRoomAvailabilityInstance } from '@/availability/models/room-availability.model';
import { useActiveBrandConfig } from '@/brand';
import { ResponsiveConditional } from '@/common';
import { useHotel } from '@/hotel';
import { useTranslation } from '@/i18n';
import { Markdown } from '@/markdown';
import { Spacer } from '@/ui/spacing';
import { BodyCopy } from '@/ui/typography';

import { RoomWeeklyCalendarContainer } from '..';

interface UnavailableRoomCardContentProps {
  roomAvailabilityModel: IRoomAvailabilityInstance;
  selectedRateCode: string;
  checkInDate: string;
  checkOutDate: string;
  hotelReferenceId: string;
  roomOccupants: V1Guest;
  onDateRangeChange: (start: string, end: string) => void;
}

export const UnavailableRoomCardContent = (
  props: UnavailableRoomCardContentProps
) => {
  const { t } = useTranslation('search');
  const hotel = useHotel(props.hotelReferenceId);
  const brand = useActiveBrandConfig();

  if (!hotel) {
    return null;
  }

  const {
    payments: { currencyCode },
    dialect,
  } = hotel;

  // We should only show the calendar if changing dates will make this room available.
  // TODO: Make this more generic
  const showWeeklyCalendar =
    dialect === 'ows' &&
    brand.showInlineCalendar &&
    !props.roomAvailabilityModel.isUnableToFitExtraCot;

  const reasonMessage = props.roomAvailabilityModel.isUnableToFitExtraCot ? (
    <div>{t('unavailable.noChildBed')}</div>
  ) : undefined;

  return (
    <>
      <BodyCopy>
        {reasonMessage || (
          <Markdown source={t('unavailable.datesUnavailable')} />
        )}
      </BodyCopy>

      <Spacer s="s" />

      {showWeeklyCalendar ? (
        <ResponsiveConditional
          breakpoint={1200}
          desktop={
            <>
              <RoomWeeklyCalendarContainer
                selectedRateCode={props.selectedRateCode}
                roomAvailability={props.roomAvailabilityModel}
                checkInDate={props.checkInDate}
                checkOutDate={props.checkOutDate}
                hotelReferenceId={props.hotelReferenceId}
                roomOccupants={props.roomOccupants}
                currencyCode={currencyCode}
                onDateRangeChange={props.onDateRangeChange}
              />
              <Spacer s="xs" />
            </>
          }
        />
      ) : undefined}
    </>
  );
};

export default UnavailableRoomCardContent;
