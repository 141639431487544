import { createContext } from 'react';

import { AvailableFeatureKeys } from '../constants/available-features.const';

export const FeatureSwitchContext = createContext<{
  enabledFeatures: AvailableFeatureKeys[];
  setEnabledFeatures: React.Dispatch<
    React.SetStateAction<AvailableFeatureKeys[]>
  >;
}>({
  enabledFeatures: [],
  setEnabledFeatures: () => {
    // noop
  },
});
