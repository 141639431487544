import { useActiveBrandConfig } from '@/brand';
import { useHotel } from '@/hotel';

/**
 * Gets maximum guests per room count.
 */
export const useMaximumGuestsPerRoomCount = (
  hotelReferenceId: string | undefined
) => {
  const brandConfig = useActiveBrandConfig();

  const hotel = useHotel(hotelReferenceId);

  const roomOccupancyConfigurations =
    hotel?.operational.supportedRoomOccupancyConfigurations ??
    brandConfig.supportedRoomOccupancyConfigurations;

  return Math.max(
    ...roomOccupancyConfigurations.map((room) => room.adults + room.children)
  );
};
