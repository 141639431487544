import { EventEmitter } from 'events';

import { defaultLogger } from '@/logging';

export class AppEventEmitter<E> {
  private emitter: EventEmitter;

  constructor() {
    this.emitter = new EventEmitter();
  }

  emit<K extends keyof E>(key: K, payload?: E[K]) {
    defaultLogger.info(`[events]`, `Emit event [${String(key)}]`, payload);
    this.emitter.emit(key as string, payload);
  }

  /**
   *
   * @param key The key of the event to listen
   * @param fn Callback to handle the event when received
   * @returns A cleanup function that removes the event listener
   */
  addListener<K extends keyof E>(key: K, fn: (payload: E[K]) => void) {
    defaultLogger.info(`[events]`, `Listening for event [${String(key)}]`);
    this.emitter.addListener(key as string, fn);

    return () => {
      this.removeListener(key, fn);
    };
  }

  removeListener<K extends keyof E>(key: K, fn: (payload: E[K]) => void) {
    defaultLogger.info(
      `[events]`,
      `Cease listening for event [${String(key)}]`
    );
    this.emitter.removeListener(key as string, fn);
  }
}
