import {
  BookingServiceApi,
  Configuration,
  OperaLiveSupportApi,
} from '@ennismore/ows-api-client';

import { EnnismoreAPIClient, EnnismoreAPIClientConfig } from './interfaces';

/**
 * Builds an instance of the Ennismore API Client.
 */
export const createApiClient = ({
  apiKey: clientKey,
  baseUrl,
}: EnnismoreAPIClientConfig): EnnismoreAPIClient => {
  const config = new Configuration({
    basePath: baseUrl,
    apiKey: (type) => {
      switch (type) {
        case 'X-Api-Key':
          return clientKey;
        default:
          throw new Error(`Attempted to access unknown header [${type}]`);
      }
    },
  });

  return {
    bookingApi: new BookingServiceApi(config),
    liveSupportApi: new OperaLiveSupportApi(config),
  };
};
