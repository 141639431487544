import { V1Guest } from '@ennismore/ows-api-client';
import React from 'react';

import { weeklyCalendarGTMEvents } from '@/analytics/gtm';
import { useAvailabilityCalendarDates } from '@/availability-calendar';
import { useHasBeenViewed } from '@/common/hooks/useHasBeenViewed.hook';
import { ConnectedWeekCalendar } from '@/core/components/controls/calendar';

import { IRoomAvailabilityInstance } from '../models/room-availability.model';

interface RoomAvailabilityWeeklyCalendarContainerProps {
  selectedRateCode: string;
  roomAvailability: IRoomAvailabilityInstance;
  checkInDate: string;
  checkOutDate: string;
  hotelReferenceId: string;
  roomOccupants: V1Guest;
  currencyCode: string;
  onDateRangeChange: (start: string, end: string) => void;
}

export const RoomWeeklyCalendarContainer = ({
  roomAvailability,
  selectedRateCode,
  checkInDate,
  checkOutDate,
  hotelReferenceId,
  roomOccupants,
  currencyCode,
  onDateRangeChange,
}: RoomAvailabilityWeeklyCalendarContainerProps) => {
  const { ref, hasBeenViewed } = useHasBeenViewed();

  const result = useAvailabilityCalendarDates(
    {
      selectedCheckInDate: checkInDate,
      selectedCheckOutDate: checkOutDate,
      roomOccupants,
      hotelReferenceId,
      roomCode: roomAvailability.room.code,
      requestRateCode: selectedRateCode,
    },
    {
      enabled: hasBeenViewed,
    }
  );

  if (!roomAvailability) {
    return null;
  }

  return (
    <div ref={ref}>
      <ConnectedWeekCalendar
        dates={result.dates}
        onNavigateLeft={result.moveBackOneWeek}
        onNavigateRight={result.moveForwardOneWeek}
        onDateRangeChange={(start, end) => {
          weeklyCalendarGTMEvents.trackDateRangeChange({
            beCalendarInDate: start,
            beCalendarOutDate: end,
          });
          onDateRangeChange(start, end);
        }}
        onDateClicked={(date) => {
          const amount = result.dates.find((d) => d.date == date)?.price;

          /* Bit hacky here. Having to fetch the price from the array and trim the currency symbol from the top.*/
          return weeklyCalendarGTMEvents.trackDateClick({
            beCalendarDate: date,
            beCalendarPrice: amount?.fixedValue || '',
            beCalendarCurrency: currencyCode,
          });
        }}
        initialDateRange={{ start: checkInDate, end: checkOutDate }}
        isLoading={result.isLoading || !hasBeenViewed}
      />
    </div>
  );
};
