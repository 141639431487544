import { useRouter } from 'next/router';
import React, { ComponentProps, useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import type {
  DefaultValues,
  FieldValues,
  SubmitHandler,
  UseFormProps,
} from 'react-hook-form';
import type { ZodSchema } from 'zod';

import { useForm } from './hooks';

interface FormProps<T extends FieldValues = any>
  // we omit the native `onSubmit` event in favor of `SubmitHandler` event
  // this makes the values returned by the submit handler fully typed
  extends Omit<ComponentProps<'form'>, 'onSubmit'> {
  onSubmit: SubmitHandler<T>;
  validationSchema: ZodSchema<T>;
  defaultValues?: DefaultValues<T>;
  hookFormOptions?: UseFormProps<T>;
  triggerValidationOnRender?: boolean;
}

function FormInner<T extends FieldValues>(
  {
    onSubmit,
    validationSchema,
    defaultValues,
    children,
    hookFormOptions,
    triggerValidationOnRender,
    ...props
  }: FormProps<T>,
  ref: React.ForwardedRef<HTMLFormElement>
) {
  const { locale } = useRouter();
  const formOptions = useForm({
    schema: validationSchema,
    defaultValues,
    ...hookFormOptions,
  });

  useEffect(() => {
    formOptions.clearErrors();
    if (triggerValidationOnRender) {
      formOptions.trigger();
    }
  }, [locale, triggerValidationOnRender]);

  return (
    <FormProvider {...formOptions}>
      <form ref={ref} onSubmit={formOptions.handleSubmit(onSubmit)} {...props}>
        {children}
      </form>
    </FormProvider>
  );
}

export const Form = React.forwardRef(FormInner) as <T extends FieldValues>(
  props: FormProps<T> & { ref?: React.ForwardedRef<HTMLFormElement> }
) => ReturnType<typeof FormInner>;
