import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useLocale = () => {
  const { i18n } = useTranslation();
  const locale = useMemo(
    () => new Intl.Locale(i18n.language || 'en-GB'),
    [i18n.language]
  );
  return locale;
};
