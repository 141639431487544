import { getFloatValue } from '@/common/helpers/numbers';
import { currencyCodeSchema } from '@/finance/enums/currency-code.enum';
import {
  getLocalisedCurrencySymbol,
  localisedCurrencyValue,
} from '@/finance/utils/formatting';
import { useLocale } from '@/i18n';

import { usePreferredCurrencyValue } from '../state';
import { useExchangeRate } from './useExchangeRate.hook';

export type CurrencyPreviewProps = {
  amount:
    | {
        value: number;
        currencyCode: string;
        decimal: number;
      }
    | undefined;
  rounded?: boolean;
  preventConversion?: boolean;
  dropCurrencySymbol?: boolean;
  dropUnsupportedCurrencySymbol?: boolean;
};

export const useCurrencyPreview = ({
  amount,
  rounded,
  preventConversion = false,
  dropCurrencySymbol = false,
  dropUnsupportedCurrencySymbol = false,
}: CurrencyPreviewProps) => {
  const locale = useLocale();
  const preferredCurrency = usePreferredCurrencyValue();

  // Validate base currency
  const baseCurrencyParseResult = currencyCodeSchema.safeParse(
    amount?.currencyCode.toUpperCase()
  );
  const baseCurrency = baseCurrencyParseResult.success
    ? baseCurrencyParseResult.data
    : undefined;
  const activeCurrency = preferredCurrency ?? baseCurrency;

  const {
    data: exchangeRate,
    isLoading,
    isSuccess,
  } = useExchangeRate(
    baseCurrency,
    preventConversion ? undefined : preferredCurrency
  );

  const floatValue =
    amount?.value && amount?.decimal
      ? getFloatValue(amount.value, amount.decimal)
      : undefined;

  // While we're waiting for the exchange rate, or in case we're missing valid amount,
  // we can display a placeholder hinting the currency setting. E.g. '£-', '$-' etc.
  // Also testing rate conversion upfront to prevent NaN reaching UI. E.g. ['100'] would work!
  if (isLoading || !floatValue || !baseCurrency || Number.isNaN(floatValue * 1))
    return `${
      activeCurrency
        ? getLocalisedCurrencySymbol(locale.baseName, activeCurrency)
        : ''
    }-`;

  // When things go bad, fallback to
  // displaying non-converted base currency.
  if (
    !isSuccess ||
    !exchangeRate ||
    typeof exchangeRate !== 'number' ||
    !activeCurrency ||
    preventConversion
  )
    return localisedCurrencyValue({
      locale: locale.baseName,
      value: floatValue,
      currency: baseCurrency,
      maximumFractionDigits: rounded ? 0 : 2,
      dropCurrencySymbol,
      dropUnsupportedCurrencySymbol,
    });

  // Conversion
  return localisedCurrencyValue({
    locale: locale.baseName,
    value: floatValue * exchangeRate,
    currency: activeCurrency,
    maximumFractionDigits: rounded ? 0 : 2,
    dropCurrencySymbol,
    dropUnsupportedCurrencySymbol,
  });
};
