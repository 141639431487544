import Cookies from 'js-cookie';
import { useEffect } from 'react';

import { useActiveBrandConfig } from '@/brand';

export const useCanary = () => {
  const { isCanary } = useActiveBrandConfig();
  const cookieKey = 'canary';
  const onValue = 'always';

  useEffect(() => {
    if (!isCanary) {
      return;
    }

    if (Cookies.get(cookieKey) !== onValue) {
      Cookies.set(cookieKey, onValue);
    }
  });
};
