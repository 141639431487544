import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';

import { useDimensions, useScroll } from '@/common/hooks';
import type { DimensionObject } from '@/common/hooks/useDimensions.hook';
import { useTheme } from '@/ui/theme';

const useScrollableContainerParams = ({
  sidebarDimensions,
  headerHeight,
  topPadding = 21,
}: {
  sidebarDimensions: DimensionObject | undefined;
  headerHeight: number;
  topPadding?: number;
}) => {
  const [placeholderHeight, setPlaceholderHeight] = useState(0);
  const { scrollY, scrollDirection } = useScroll();

  const fitsTheScreen =
    sidebarDimensions &&
    sidebarDimensions.height + headerHeight + topPadding <= window.innerHeight;
  const topCSSValue = fitsTheScreen
    ? headerHeight + topPadding
    : sidebarDimensions && scrollDirection === 'down'
    ? window.innerHeight - sidebarDimensions.height
    : undefined;
  const bottomCSSValue =
    sidebarDimensions && scrollDirection === 'up'
      ? window.innerHeight -
        headerHeight -
        topPadding -
        sidebarDimensions.height
      : undefined;

  // determine placeholder's height
  useEffect(() => {
    if (!sidebarDimensions) return;

    if (
      // no need for placeholder when at the top of the page or the sidebar fits the screen
      window.innerHeight + scrollY <=
        sidebarDimensions.height + headerHeight + topPadding ||
      fitsTheScreen
    ) {
      return setPlaceholderHeight(0);
    }

    const basePlaceholderHeight =
      window.innerHeight + scrollY - headerHeight - topPadding;

    // Sidebar is below viewport
    if (window.innerHeight < sidebarDimensions.y) {
      if (scrollDirection === 'up' || scrollDirection === 'down') {
        setPlaceholderHeight(basePlaceholderHeight - sidebarDimensions.height);
      }
      // Sidebar's top is visible
    } else if (sidebarDimensions.y > 0) {
      if (scrollDirection === 'up' || scrollDirection === 'down') {
        setPlaceholderHeight(
          basePlaceholderHeight - (window.innerHeight - sidebarDimensions.y)
        );
      }
      // Sidebar's bottom is visible but still sticky
    } else if (sidebarDimensions.bottom === window.innerHeight) {
      if (scrollDirection === 'up') {
        setPlaceholderHeight(basePlaceholderHeight - sidebarDimensions.height);
      } else if (scrollDirection === 'down') {
        setPlaceholderHeight(scrollY);
      }
      // Below sidebar's parent
    } else if (sidebarDimensions.bottom < window.innerHeight) {
      if (scrollDirection === 'up' || scrollDirection === 'down') {
        setPlaceholderHeight(
          basePlaceholderHeight -
            sidebarDimensions.height -
            (window.innerHeight - sidebarDimensions.bottom)
        );
      }
    }
  }, [scrollDirection, window.innerHeight, window.innerWidth]);

  return { placeholderHeight, topCSSValue, bottomCSSValue };
};

interface ScrollableSidebarContainerProps {
  breakpoint: number;
  children: React.ReactNode;
}

export const ScrollableSidebarContainer: React.FC<
  ScrollableSidebarContainerProps
> = ({ children, breakpoint }) => {
  const [sidebarRef, sidebarDimensions] = useDimensions();
  const {
    componentProperties: { header },
  } = useTheme();
  const topPadding = 21;
  const { placeholderHeight, topCSSValue, bottomCSSValue } =
    useScrollableContainerParams({
      sidebarDimensions,
      topPadding,
      headerHeight: header?.height ? header?.height + 1 : 61,
    });

  return (
    <aside
      css={css`
        max-width: 340px;
        margin-left: 20px;
        padding-top: ${topPadding}px;
        overflow-anchor: none;
        @media all and (max-width: ${breakpoint}px) {
          margin-left: 0px;
          padding-top: 0px;
          max-width: 100%;
        }
        @media all and (max-width: 330px) {
          min-width: 0;
          width: 100%;
        }
      `}
    >
      <div
        css={css`
          @media all and (min-width: ${breakpoint}px) {
            height: ${placeholderHeight}px;
          }
        `}
      ></div>
      <div
        ref={sidebarRef}
        css={css`
          @media all and (min-width: ${breakpoint}px) {
            position: sticky;
          }
          ${topCSSValue !== undefined ? `top: ${topCSSValue}px;` : ''}
          ${bottomCSSValue !== undefined ? `bottom: ${bottomCSSValue}px;` : ''}
        `}
      >
        {children}
      </div>
    </aside>
  );
};

export default ScrollableSidebarContainer;
