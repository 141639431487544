import React from 'react';

import ResponsiveConditional from './ResponsiveConditional.component';

interface OnlyMobileProps {
  children: React.ReactNode;
}

export const OnlyMobile = (props: OnlyMobileProps) => {
  return <ResponsiveConditional mobile={props.children} />;
};

export default OnlyMobile;
