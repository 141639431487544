import { BookingRequest } from '../interfaces';
import { IBookingInstance } from '../models/booking.model';
import { useBookingSummary } from './use-booking-summary.hook';

export const useBooking = (
  request: BookingRequest,
  options?: Parameters<typeof useBookingSummary>[1]
): IBookingInstance | undefined => {
  const { data } = useBookingSummary(request, options);
  return data;
};
