import React from 'react';

import icon from '../resources/svg/button-plus.svg';
import ButtonWithIcon, {
  ButtonWithIconProps,
} from './ButtonWithIcon.component';

interface ButtonWithPlusIconProps
  extends Omit<ButtonWithIconProps, 'iconSrc' | 'icon'> {}

export const ButtonWithPlusIcon = (props: ButtonWithPlusIconProps) => {
  return (
    <ButtonWithIcon {...props} iconSrc={icon.src}>
      {props.children}
    </ButtonWithIcon>
  );
};

export default ButtonWithPlusIcon;
