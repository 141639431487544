import React, { CSSProperties, ComponentProps } from 'react';

import Anchor from './Anchor.component';

export interface AnchorLinkProps {
  href: string;
  children: React.ReactNode;
  openInNewTab?: boolean;
  rel?: string;
  noUnderline?: boolean;
  color?: CSSProperties['color'];
  noWrap?: boolean;
  title?: string;
  onClick?: ComponentProps<typeof Anchor>['onClick'];
}

export const AnchorLink = (props: AnchorLinkProps) => {
  return (
    <Anchor
      href={props.href}
      rel={props.openInNewTab ? 'noopener noreferrer' : undefined}
      target={props.openInNewTab ? 'blank' : undefined}
      noUnderline={props.noUnderline}
      color={props.color}
      noWrap={props.noWrap}
      title={props.title}
      onClick={props.onClick}
    >
      {props.children}
    </Anchor>
  );
};

export default AnchorLink;
