import { z } from 'zod';

import { FinancialAmountModel, TaxModel } from '@/finance';

export type IBookingAddOnInstance = z.infer<typeof BookingAddOnModel>;
export type IBookingAddOnSnapshot = z.input<typeof BookingAddOnModel>;

export const BookingAddOnModel = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().default(''),
  displayType: z.enum([
    'ADD_ON_DISPLAY_TYPE_TOGGLE',
    'ADD_ON_DISPLAY_TYPE_ROCKER',
    'ADD_ON_DISPLAY_TYPE_UNKNOWN',
  ]),
  image: z.object({ url: z.string(), altText: z.string() }),
  priceContent: z.string().optional(),
  controlLabel: z.string(),
  requestedQuantity: z.number().default(0),
  calculatedQuantity: z.number().default(0),
  cost: z.object({
    amountAfterTax: FinancialAmountModel,
    amountBeforeTax: FinancialAmountModel,
    amountSubtotalDisplay: FinancialAmountModel,
    taxesFees: z.object({
      amount: FinancialAmountModel.optional(),
      detail: z.array(TaxModel).default([]),
    }),
  }),
});
