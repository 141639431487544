import type { V1GetAvailabilityRequest } from '@ennismore/booking-api-client';
import React, { useState } from 'react';

import { useHotel } from '@/hotel';
import { localisedDistance, useLocale, useTranslation } from '@/i18n';
import { MobileWrapperPadding } from '@/ui/layout';
import { Stack } from '@/ui/spacing';
import { Text } from '@/ui/typography';

import { NearbyHotelAvailabilityCard } from './NearbyHotelAvailabilityCard.component';

interface NearbyAvailableHotelsProps extends V1GetAvailabilityRequest {
  locale: { language: string };
  onRequestSwitchHotelSearch: (hotelReferenceId: string) => void;
}

export const NearbyAvailableHotels: React.FC<NearbyAvailableHotelsProps> = ({
  hotelReferenceId,
  onRequestSwitchHotelSearch,
  ...rest
}) => {
  const { t } = useTranslation('search');
  const hotel = useHotel(hotelReferenceId);
  const [areRoomsAvailable, setAreRoomsAvailable] = useState(false);
  const locale = useLocale();

  if (!hotel?.location.nearbyHotels) {
    return null;
  }

  const nearbyHotels = hotel.location.nearbyHotels;

  const nearbyHotelCards = nearbyHotels.map(
    ({ hotelReferenceId, distanceKm }) => {
      if (!distanceKm) return null;

      return (
        <NearbyHotelAvailabilityCard
          key={hotelReferenceId}
          hotelReferenceId={hotelReferenceId}
          onButtonClick={() => onRequestSwitchHotelSearch(hotelReferenceId)}
          onRoomsAvailable={() => setAreRoomsAvailable(true)}
          distance={localisedDistance(distanceKm, locale)}
          {...rest}
        />
      );
    }
  );

  return (
    <Stack>
      {areRoomsAvailable && (
        <MobileWrapperPadding>
          <Text style="heading5">{t('nearby.hotels.title')}</Text>
        </MobileWrapperPadding>
      )}
      {nearbyHotelCards}
    </Stack>
  );
};
