import { css } from '@emotion/react';
import React from 'react';

import { useTheme } from '../theme';

export const Divider = (
  props: {
    vertical?: boolean;
    inheritColor?: boolean;
    className?: string;
  } & Partial<
    Pick<React.CSSProperties, 'marginRight' | 'marginLeft' | 'height'>
  >
) => {
  const { colors, keylines } = useTheme();
  const borderStyle = keylines?.border ?? `1px solid ${colors.primary300}`;

  const { vertical, inheritColor, className, ...rest } = props;

  return (
    <div
      role="separator"
      // elements with the role separator have an implicit 'aria-orientation' value of 'horizontal' - no need to define it
      aria-orientation={vertical ? 'vertical' : undefined}
      aria-hidden="true"
      className={className}
      css={css({
        borderRight: vertical ? borderStyle : undefined,
        borderTop: vertical ? undefined : borderStyle,
        borderColor: inheritColor ? 'inherit' : undefined,
        ...rest,
      })}
    />
  );
};
