import { z } from 'zod';

export type IFinancialAmountInstance = z.infer<typeof FinancialAmountModel>;
export type IFinancialAmountSnapshot = z.input<typeof FinancialAmountModel>;

export const FinancialAmountModel = z
  .object({
    value: z.number().default(0),
    decimal: z.number(),
    currencyCode: z.string(),
  })
  .transform((self) => ({
    ...self,
    get fixedValue() {
      return (self.value / Math.pow(10, self.decimal)).toFixed(self.decimal);
    },
    get floatValue() {
      return Number.parseFloat(
        (self.value / Math.pow(10, self.decimal)).toFixed(self.decimal)
      );
    },
  }));
