import { useHotelCurrencyValue, usePreferredCurrencyValue } from '../state';

export const usePreviewingAlternativeCurrency = () => {
  const hotelCurrency = useHotelCurrencyValue();
  const preferredCurrency = usePreferredCurrencyValue();

  return (
    preferredCurrency && hotelCurrency && hotelCurrency !== preferredCurrency
  );
};
