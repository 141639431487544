import { ZodError, ZodType } from 'zod';

import { DomainError } from '@/errors';

/**
 * Thrown when an API response doesn't pass validation.
 */
export class ResponseValidationError<T extends ZodType> extends DomainError {
  constructor(public readonly zodError: ZodError<T>) {
    super({
      code: 'API_RESPONSE_VALIDATION_01',
      description: `Received error of the following shape: ${zodError.message}`,
    });
  }

  format() {
    return this.zodError.format();
  }

  get message() {
    return `API returned a response with an unexpected shape: ${JSON.stringify(
      this.zodError,
      undefined,
      2
    )}`;
  }
}
