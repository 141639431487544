import { useContext } from 'react';

import { AvailableFeatureKeys } from '../constants/available-features.const';
import { FeatureSwitchContext } from '../contexts/feature-switch.context';

/**
 * Returns the local status of a feature flag.
 * Note: Feature switches should be safe-off. User experience should not suffer at all if the feature is unavailable.
 * @param featureKey
 */
export const useFeatureSwitchStatus = (featureKey: AvailableFeatureKeys) => {
  const context = useContext(FeatureSwitchContext);
  return context.enabledFeatures.includes(featureKey);
};
