import { captureException } from '@/logging';

import { getAuthStorageKey } from './get-auth-storage-key';

export const storeAuthToken = (brand: string, token: string | null) => {
  if (!localStorage) {
    captureException(new Error('localStorage unsupported in this browser'));
    return;
  }

  if (!token) {
    return localStorage.removeItem(getAuthStorageKey(brand));
  }

  localStorage.setItem(getAuthStorageKey(brand), token);
};
