import { V1UsaState } from '@ennismore/payment-stripe-api-client';

import { StateUsaState } from '@/em-api-client-typescript-fetch';

/**
 * BookingDayTimePart -> v1CotOrBed
 */
const DOMAIN_TO_BUF_USA_STATE: Record<StateUsaState, V1UsaState> = {
  [StateUsaState.USASTATENOTSET]: V1UsaState.UsaStateNotSet,
  [StateUsaState.AK]: V1UsaState.Ak,
  [StateUsaState.AL]: V1UsaState.Al,
  [StateUsaState.AR]: V1UsaState.Ar,
  [StateUsaState.AS]: V1UsaState.As,
  [StateUsaState.AZ]: V1UsaState.Az,
  [StateUsaState.CA]: V1UsaState.Ca,
  [StateUsaState.CO]: V1UsaState.Co,
  [StateUsaState.CT]: V1UsaState.Ct,
  [StateUsaState.DC]: V1UsaState.Dc,
  [StateUsaState.DE]: V1UsaState.De,
  [StateUsaState.FL]: V1UsaState.Fl,
  [StateUsaState.FM]: V1UsaState.Fm,
  [StateUsaState.GA]: V1UsaState.Ga,
  [StateUsaState.GU]: V1UsaState.Gu,
  [StateUsaState.HI]: V1UsaState.Hi,
  [StateUsaState.IA]: V1UsaState.Ia,
  [StateUsaState.ID]: V1UsaState.Id,
  [StateUsaState.IL]: V1UsaState.Il,
  [StateUsaState.IN]: V1UsaState.In,
  [StateUsaState.KS]: V1UsaState.Ks,
  [StateUsaState.KY]: V1UsaState.Ky,
  [StateUsaState.LA]: V1UsaState.La,
  [StateUsaState.MA]: V1UsaState.Ma,
  [StateUsaState.MD]: V1UsaState.Md,
  [StateUsaState.ME]: V1UsaState.Me,
  [StateUsaState.MH]: V1UsaState.Mh,
  [StateUsaState.MI]: V1UsaState.Mi,
  [StateUsaState.MN]: V1UsaState.Mn,
  [StateUsaState.MO]: V1UsaState.Mo,
  [StateUsaState.MP]: V1UsaState.Mp,
  [StateUsaState.MS]: V1UsaState.Ms,
  [StateUsaState.MT]: V1UsaState.Mt,
  [StateUsaState.NC]: V1UsaState.Nc,
  [StateUsaState.ND]: V1UsaState.Nd,
  [StateUsaState.NE]: V1UsaState.Ne,
  [StateUsaState.NH]: V1UsaState.Nh,
  [StateUsaState.NJ]: V1UsaState.Nj,
  [StateUsaState.NM]: V1UsaState.Nm,
  [StateUsaState.NV]: V1UsaState.Nv,
  [StateUsaState.NY]: V1UsaState.Ny,
  [StateUsaState.OH]: V1UsaState.Oh,
  [StateUsaState.OK]: V1UsaState.Ok,
  [StateUsaState.OR]: V1UsaState.Or,
  [StateUsaState.PA]: V1UsaState.Pa,
  [StateUsaState.PR]: V1UsaState.Pr,
  [StateUsaState.PW]: V1UsaState.Pw,
  [StateUsaState.RI]: V1UsaState.Ri,
  [StateUsaState.SC]: V1UsaState.Sc,
  [StateUsaState.SD]: V1UsaState.Sd,
  [StateUsaState.TN]: V1UsaState.Tn,
  [StateUsaState.TX]: V1UsaState.Tx,
  [StateUsaState.UT]: V1UsaState.Ut,
  [StateUsaState.VA]: V1UsaState.Va,
  [StateUsaState.VI]: V1UsaState.Vi,
  [StateUsaState.VT]: V1UsaState.Vt,
  [StateUsaState.WA]: V1UsaState.Wa,
  [StateUsaState.WI]: V1UsaState.Wi,
  [StateUsaState.WV]: V1UsaState.Wv,
  [StateUsaState.WY]: V1UsaState.Wy,
};

export const mapDomainToBufUSAState = (state: StateUsaState): V1UsaState =>
  DOMAIN_TO_BUF_USA_STATE[state];

export const mapBufToDomainUSAState = (
  dayTimePart: V1UsaState
): StateUsaState =>
  Object.keys(DOMAIN_TO_BUF_USA_STATE).find(
    (key) => DOMAIN_TO_BUF_USA_STATE[key] === dayTimePart
  ) as unknown as StateUsaState;
