export interface Feature {
  id: AvailableFeatureKeys;
  name: string;
  description?: string;
  // Does toggling this feature require a page reload?
  requiresReload?: boolean;
}

export const AVAILABLE_FEATURES = {
  'flip-forms': {
    id: 'flip-forms',
    name: 'Flip Forms',
    description: 'Flip which side of the split layout the form is rendered.',
    requiresReload: false,
  },
  'theme-editor': {
    id: 'theme-editor',
    name: 'Colour Scheme Editor',
    description: 'Tool for overriding the active colour scheme.',
  },
  'hide-weekly-calendar': {
    id: 'hide-weekly-calendar',
    name: 'Hide Weekly Calendar',
    description: 'Hide the calendar for all room rates on desktop.',
  },
  'mobile-handoff': {
    id: 'mobile-handoff',
    name: 'Mobile Handoff',
    description:
      'Render a QR code that allows the user to continue booking on a mobile device.',
  },
  'debug-text': {
    id: 'debug-text',
    name: 'Debug Text Styles',
    description: 'Shows the text style against each piece of text.',
  },
  rtl: {
    id: 'rtl',
    name: 'Right to left',
    description: 'Enables RTL at the page body level.',
  },
} as const;

export type AvailableFeatureKeys = keyof typeof AVAILABLE_FEATURES;
