import React from 'react';

import { Box, Flex } from '@/box';
import { Footer } from '@/hox-core/components/Footer.component';

import { Header } from '.';

interface ErrorScreenProps {
  children: React.ReactNode;
}

export const ErrorScreen = (props: ErrorScreenProps) => {
  return (
    <Flex flexDirection="column" height="100vh">
      <Header />
      <Box maxWidth={700} padding="32px 32px 132px 32px" margin="auto" flex={1}>
        {props.children}
      </Box>
      <Box justifySelf="flex-end">
        <Footer />
      </Box>
    </Flex>
  );
};
