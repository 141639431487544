import React from 'react';

import { Box, Flex } from '@/box';
import { Button } from '@/ui/controls';
import { BodyCopy, Heading } from '@/ui/typography';

interface FeatureSwitchProps {
  name: string;
  description?: string;
  onButtonClick: () => void;
  isEnabled: boolean;
}

export const FeatureSwitch = (props: FeatureSwitchProps) => {
  return (
    <Flex justifyContent="space-between" marginBottom={20}>
      <Box maxWidth={400} marginRight={32}>
        <Heading as="h6" style="heading6">
          {props.name}
        </Heading>
        <BodyCopy>{props.description}</BodyCopy>
      </Box>
      <Box justifySelf="flex-end" alignSelf="center">
        <Button onClick={props.onButtonClick}>
          {props.isEnabled ? 'Disable' : 'Enable'}
        </Button>
      </Box>
    </Flex>
  );
};

export default FeatureSwitch;
