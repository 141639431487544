import { V1Guest } from '@ennismore/ows-api-client';
import { z } from 'zod';

import { diffInDays } from '@/common/utils/date';
import { RoomOccupancyTranslationKey } from '@/hotel/constants/room-occupancy-types.const';
import {
  getLegacyTotalAdultGuestCount,
  getLegacyTotalChildGuestCount,
  getLegacyTotalGuestCount,
  getOccupancyTypeByKeyOrFail,
} from '@/hotel/helpers/occupancy-type.helpers';
import { ProfileModel } from '@/profile/models';

import { BookingRoomDetail } from './booking-room-detail.model';
import { RatePlanModel } from './rate-plan.model';

export type IRoomStay = typeof RoomStayModel;
export type IRoomStaySnapshot = z.input<IRoomStay>;
export type IRoomStayInstance = z.infer<IRoomStay>;

export const RoomStayModel = z
  .object({
    operaId: z.string(),
    // Assumption: One ratePlan per roomStay
    ratePlan: z.array(RatePlanModel).min(1),
    // Assumption: One roomDetail per roomStay
    roomDetail: z.array(BookingRoomDetail).min(1),
    from: z.string(),
    to: z.string(),
    profile: ProfileModel,
    status: z.string(),
    roomGuest: z.nativeEnum(V1Guest).optional(),
  })
  .transform((self) => ({
    ...self,
    get roomCount() {
      return self.roomDetail.length;
    },
    /**
     * There is always 1 rate plan per roomStay.
     */
    get firstRatePlan() {
      return self.ratePlan[0];
    },
    /**
     * There is always 1 room per roomStay.
     */
    get firstRoomDetail() {
      return self.roomDetail[0];
    },
    get numberOfNights() {
      return diffInDays(self.from, self.to);
    },
    get roomGuestTypeLabelTranslationKey():
      | RoomOccupancyTranslationKey
      | undefined {
      if (!self.roomGuest) {
        return undefined;
      }
      return getOccupancyTypeByKeyOrFail(self.roomGuest).labelTranslationKey;
    },
    get totalGuestCount() {
      if (!self.roomGuest) return 0;
      return getLegacyTotalGuestCount(self.roomGuest);
    },
    get totalAdultGuestCount() {
      if (!self.roomGuest) return 0;
      return getLegacyTotalAdultGuestCount(self.roomGuest);
    },
    get totalChildGuestCount() {
      if (!self.roomGuest) return 0;
      return getLegacyTotalChildGuestCount(self.roomGuest);
    },
  }));
