import { QueryObserverOptions, UseQueryResult, useQuery } from 'react-query';

import { useEnnismoreApiClientConfig } from '@/api';
import {
  selectHotelConfigurationByReferenceIdOrFail,
  useActiveBrand,
  useActiveBrandConfig,
} from '@/brand';
import { mapNetworkError } from '@/errors';
import { useLocale } from '@/i18n';

import { BookingError } from '../errors/booking.error';
import { BookingRequest } from '../interfaces';
import {
  BookingModel,
  IBookingInstance,
  IBookingSnapshot,
} from '../models/booking.model';
import { getBookingServiceClient } from '../services/client';

export type BookingSummaryQueryKeyType = [
  'bookingSummary',
  { brandKey: string; hotelSlug: string; bookingId: string; locale: string }
];

export function useBookingSummaryQueryKey({
  bookingId,
  hotelSlug,
}: BookingRequest) {
  const brandKey = useActiveBrand();
  const locale = useLocale();

  return [
    'bookingSummary',
    {
      brandKey,
      bookingId,
      hotelSlug,
      locale: locale.baseName,
    },
  ] satisfies BookingSummaryQueryKeyType;
}

export type Response = IBookingSnapshot;

/**
 * Fetches a booking summary resource matching the given request
 * @param request Arguments required to identify the booking
 * @param options Options to pass through to useQuery
 * @returns
 */
export const useBookingSummary = (
  request: BookingRequest,
  options?: QueryObserverOptions<Response, BookingError>
): UseQueryResult<IBookingInstance, BookingError> => {
  const brandConfig = useActiveBrandConfig();
  const locale = useLocale();
  const clientConfig = useEnnismoreApiClientConfig();
  const bookingSummaryQueryKey = useBookingSummaryQueryKey(request);

  const result = useQuery<Response, BookingError, Response>(
    bookingSummaryQueryKey,
    async (): Promise<Response> => {
      const { dialect } = selectHotelConfigurationByReferenceIdOrFail(
        brandConfig,
        request.hotelSlug
      );
      const client = getBookingServiceClient(clientConfig, dialect);

      try {
        return await client.getBooking({
          bookingId: request.bookingId,
          hotelReferenceId: request.hotelSlug,
          locale: {
            language: locale.baseName,
          },
        });
      } catch (error) {
        throw new BookingError(await mapNetworkError(error));
      }
    },
    // {retryOnMount: false} is needed here to stop consumers looping infinitely when conditionally rendering.
    // In two minds as to whether the consumers of this method should have to think about that...
    // See: https://github.com/tannerlinsley/react-query/issues/2173
    { ...options, retry: false, retryOnMount: false, staleTime: 20e3 }
  );

  // If we have data, use it to instantiate a booking model.
  const parsed = result.data ? BookingModel.safeParse(result.data) : undefined;

  // ZodErrors are difficult to read when emitted to the console so manually emitting here temporarily
  if (parsed && !parsed.success) {
    console.log('GetBooking response validation failed: ', parsed.error.errors);
    throw parsed.error;
  }

  return {
    ...result,
    data: parsed?.data,
  } as any;
};
