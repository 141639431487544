import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';
import Cookies from 'js-cookie';
import { useEffect } from 'react';

import {
  CurrencyCode,
  currencyCodeSchema,
} from '@/finance/enums/currency-code.enum';

const preferredCurrencyCookieStorage = createJSONStorage<
  CurrencyCode | undefined
>(() => {
  return {
    getItem: (key) => {
      const result = currencyCodeSchema.safeParse(Cookies.getJSON(key));
      return result.success ? JSON.stringify(result.data) : null;
    },
    // store preferred currency within cookie for 36 hours
    setItem: (key, value) => Cookies.set(key, value, { expires: 1.5 }),
    removeItem: (key) => Cookies.remove(key),
  };
});

// atoms
const preferredCurrencyAtom = atomWithStorage<CurrencyCode | undefined>(
  'preferredCurrency',
  undefined,
  preferredCurrencyCookieStorage
);
const hotelCurrencyAtom = atom<CurrencyCode | undefined>(undefined);

// hooks
export const usePreferredCurrency = () => useAtom(preferredCurrencyAtom);
export const usePreferredCurrencyValue = () =>
  useAtomValue(preferredCurrencyAtom);
export const useHotelCurrencyValue = () => {
  return useAtomValue(hotelCurrencyAtom);
};
export const useSetHotelCurrency = () => {
  return useSetAtom(hotelCurrencyAtom);
};

/**
 * Receives a hotel's currency (depending on the currency search/booking context)
 * and updates the global page currency selector and navbar state accordingly.
 * @param hotelCurrency The currency of the hotel the user is currently searching or booking.
 */
export const useSyncHotelCurrency = (
  hotelCurrency: CurrencyCode | undefined
) => {
  const setHotelCurrency = useSetHotelCurrency();

  useEffect(() => {
    if (hotelCurrency) {
      setHotelCurrency(
        () => hotelCurrency.toUpperCase() as Uppercase<typeof hotelCurrency>
      );
    }
  }, [hotelCurrency, setHotelCurrency]);
};
