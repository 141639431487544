import { useRouter } from 'next/router';

import { useParseAvailabilitySearchUrlQuery } from './use-parse-availability-search-url-query.hook';

export const useAvailabilityURLQuery = () => {
  const { query } = useRouter();

  const searchQuery = useParseAvailabilitySearchUrlQuery(query);

  return searchQuery;
};
