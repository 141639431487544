import { createContext, useContext } from 'react';

/**
 * @docs https://ennismore.atlassian.net/wiki/spaces/BP/pages/3820257284/Cookie+Consent+Status
 */
export interface CookieConsentStatus {
  necessary: boolean;
  functional: boolean;
  performance: boolean;
  targeting: boolean;
}

export const cookieConsentStatusInitialState: CookieConsentStatus = {
  necessary: false,
  functional: false,
  performance: false,
  targeting: false,
};

export const CookieConsentStatusContext = createContext<CookieConsentStatus>(
  cookieConsentStatusInitialState
);

/**
 * Returns the current cookie consent status for each purpose.
 * @docs https://ennismore.atlassian.net/wiki/spaces/BP/pages/3820257284/Cookie+Consent+Status#Booking-Engine-UI-Usage
 */
export function useCookieConsentStatus() {
  return useContext(CookieConsentStatusContext);
}
