import { useEffect, useState } from 'react';
import { useMount } from 'react-use';

export const useScroll = () => {
  const [scrollX, setScrollX] = useState<number>(0);
  const [scrollY, setScrollY] = useState<number>(0);
  const [lastScrollY, setLastScrollY] = useState<number>(0);
  const [scrollDirection, setScrollDirection] = useState<'up' | 'down'>();

  useMount(() => {
    const supportPageOffset = window.pageXOffset !== undefined;
    const isCSS1Compat = (document.compatMode || '') === 'CSS1Compat';
    const onScroll = () => {
      const scrollX = supportPageOffset
        ? window.pageXOffset // pageXOffset has better cross-browser support than scrollX
        : isCSS1Compat // ensures support for < IE 9
        ? document.documentElement.scrollLeft
        : document.body.scrollLeft;
      const scrollY = supportPageOffset
        ? window.pageYOffset // pageYOffset has better cross-browser support than scrollY
        : isCSS1Compat // ensures support for < IE 9
        ? document.documentElement.scrollTop
        : document.body.scrollTop;
      setScrollX(scrollX);
      setScrollY(scrollY);
    };
    window.addEventListener('scroll', onScroll, {
      // boosts scroll performance by telling upfront that
      // the default scroll behaviour won't be prevented.
      passive: true,
    });

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  });

  useEffect(() => {
    setScrollDirection(scrollY > lastScrollY ? 'down' : 'up');
    setLastScrollY(scrollY);
  }, [scrollY]);

  return { scrollX, scrollY, scrollDirection };
};
