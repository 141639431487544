/**
 * Possible page types and descriptions, defined at 5.1. PAGE SCOPED in the Found documentation.
 */
export enum GTMPageType {
  // for the homepage
  Home = 'home',

  // for regular content pages
  Content = 'content',

  // every content page has a different page type (use the following list of value for content page type:)
  Blog = 'blog', // on hotel blog pages
  Hotel = 'hotel', // on hotel landing pages
  OtherContent = 'other_content', // ...the rest

  // for the availability search result pages:
  AvailabilitySearch = 'availability_search',

  // for the room booking page:
  RoomBooking = 'room_booking',

  // for the steps in the checkout:
  Checkout = 'checkout',

  // for booking thankyou pages;
  Confirmation = 'confirmation',
}
