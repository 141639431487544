import { animated, useSpring } from '@react-spring/web';
import Image from 'next/legacy/image';
import React, { useState } from 'react';

import ContentSlider from './ContentSlider.component';

export interface Image {
  source: string;
  altText: string;
  id: string;
}

interface ImageSliderProps {
  images: Image[];
}

export const ImageSlider = (props: ImageSliderProps) => {
  const [loadedImageCount, setLoadedImageCount] = useState(0);
  // const [_, setFailedImageUrls] = useState<string[]>([]);
  const allImagesLoaded = loadedImageCount >= props.images.length;
  const style = useSpring({ opacity: allImagesLoaded ? 1 : 0 });

  const incrementLoadedImages = () => setLoadedImageCount((count) => count + 1);

  return (
    <animated.div style={style}>
      <ContentSlider>
        {props.images.map((image) => (
          <Image
            src={image.source}
            key={image.id}
            alt={image.altText}
            onError={() => {
              // We increment here to show the loading has finished, even if unsuccessful.
              incrementLoadedImages();
            }}
            onLoad={incrementLoadedImages}
            width={336}
            height={352}
            layout="responsive"
            objectFit="cover"
          />
        ))}
      </ContentSlider>
    </animated.div>
  );
};

export default ImageSlider;
