import React from 'react';

import { useTheme } from '../theme';

interface LessThanIconProps {
  color?: string;
}

export const LessThanIcon = ({ color }: LessThanIconProps) => {
  const { colors } = useTheme();
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7 1L1 6L7 11" stroke={color || colors.secondary400} />
    </svg>
  );
};

export default LessThanIcon;
