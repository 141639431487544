import React, { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { Box, Flex } from '@/box';
import { useActiveBrandConfig } from '@/brand';
import { resolveHotelReferenceIdFromHotelCode } from '@/hotel/codes';
import { useTranslation } from '@/i18n';
import { useTheme } from '@/ui/theme';

import { useRoomOccupancyOptions } from '../hooks';
import type { SearchFormFields } from '../validators/search-form.validator';
import { AccessibleRoomsFilterCheckboxInput } from './AccessibleRoomsFilterCheckbox.component';
import { ChildAgeSelectInput } from './ChildAgeInput.component';
import RoomOccupancySelectInput from './RoomOccupancySelectInput.component';

export const RoomOccupancySelectInputArray = () => {
  const { t } = useTranslation('search');
  const { watch, control } =
    useFormContext<
      Pick<SearchFormFields, 'roomOccupancy' | 'roomCount' | 'hotelCode'>
    >();

  const [roomCount, hotelChoice] = watch(['roomCount', 'hotelCode']);

  const { forms } = useTheme();

  const hotelReferenceId = resolveHotelReferenceIdFromHotelCode(hotelChoice);

  const roomOccupancyConfigurations = useRoomOccupancyOptions(hotelReferenceId);

  const { hideAccessibleRoomsCheckbox } = useActiveBrandConfig();

  const {
    fields: roomOccupancyFields,
    append: appendRoomOccupancyFields,
    remove: removeRoomOccupancyFields,
    update: updateRoomOccupancyFields,
  } = useFieldArray({
    name: 'roomOccupancy',
    control,
  });

  useEffect(() => {
    if (roomCount) {
      const roomCountInt = parseInt(roomCount);
      let fieldsLength = roomOccupancyFields.length;
      // Catch up with the room count
      if (roomCountInt > fieldsLength) {
        while (roomCountInt !== fieldsLength) {
          appendRoomOccupancyFields(
            { value: '', childrenAges: [], accessibleRooms: [] },
            { shouldFocus: false }
          );
          fieldsLength++;
        }
        // Drop the undesired rooms
      } else if (roomCountInt < fieldsLength) {
        while (roomCountInt !== fieldsLength) {
          removeRoomOccupancyFields(fieldsLength - 1);
          fieldsLength--;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomCount]);

  return (
    <>
      {Array(roomOccupancyFields.length)
        .fill(undefined)
        .map((_, index) => (
          <Flex
            key={`${roomOccupancyFields[index].id}-${index}`}
            flexWrap="wrap"
          >
            <Box
              key={`${roomOccupancyFields[index].id}-${index}`}
              width={[1, 1 / 2]}
              paddingRight={[0, 20]}
              {...forms?.searchForm?.roomOccupancySelectorContainer}
            >
              <RoomOccupancySelectInput
                label={t('form.roomSelectorLabel', {
                  roomNumber: index + 1,
                })}
                options={roomOccupancyConfigurations}
                name={`roomOccupancy.${index}.value`}
                updateValue={updateRoomOccupancyFields}
                index={index}
              />
            </Box>
            <Box
              key={`${roomOccupancyFields[index].id}-${index}-c`}
              width={[1, 1 / 2]}
              paddingLeft={[0, 20]}
              {...forms?.searchForm?.childAgeSelectorContainer}
            >
              <ChildAgeSelectInput
                label={t('form.childAgeSelectorLabel')}
                name={`roomOccupancy.${index}.value`}
                index={index}
              />
            </Box>
            {!hideAccessibleRoomsCheckbox && (
              <Box width={[1, 1 / 2]}>
                <AccessibleRoomsFilterCheckboxInput index={index} />
              </Box>
            )}
          </Flex>
        ))}
    </>
  );
};
