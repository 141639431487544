import { css } from '@emotion/react';
import React from 'react';

import {
  getFullLocalisedDateString,
  getLocalisedDateString,
} from '@/common/utils/date';
import { useLocale, useTranslation } from '@/i18n';
import { useTheme } from '@/ui/theme';
import { BodyCopy, Heading } from '@/ui/typography';

export interface SearchSummaryProps {
  totalNightCount: number;
  hotelName: string;
  startDate: string;
  endDate: string;
  roomCount: number;
  occupants?: string;
}

export const SearchSummary = (props: SearchSummaryProps) => {
  const { t } = useTranslation('search');
  const locale = useLocale();
  const {
    componentProperties: { search },
  } = useTheme();

  const getStaySentenceDictionaryKey = () => {
    // Is same date
    if (props.startDate === props.endDate) {
      return 'summary.dayUseSentence';
    }

    return props.totalNightCount === 1
      ? 'summary.sentence'
      : 'summary.sentence_plural';
  };

  const staySentence = t(getStaySentenceDictionaryKey(), {
    nightCount: props.totalNightCount,
    hotelName: props.hotelName,
  });

  const roomCountTranslationProps = { roomCount: String(props.roomCount) };

  return (
    <div data-test="search-summary">
      <BodyCopy css={css(search?.collapsibleForm?.searchSummary?.copy)}>
        {staySentence}
      </BodyCopy>
      <Heading as="h2" style="heading5">
        {t('summary.dateSentence', {
          startDate: getLocalisedDateString(props.startDate, locale, {
            weekday: 'short',
            year: undefined,
          }),
          endDate: getFullLocalisedDateString(props.endDate, locale),
          occupants: props.occupants,
          roomCount:
            props.roomCount === 1
              ? t('summary.roomCount', roomCountTranslationProps)
              : t('summary.roomCount_plural', roomCountTranslationProps),
          // Handles undefined occupants case.
          // Feels hacky, so we should consider something more elegant.
        }).replace(' ,', '')}
      </Heading>
    </div>
  );
};
