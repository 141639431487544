import { useEffect, useState } from 'react';

import { AvailabilitySearchUrlQuery } from '@/availability/dtos/availability-search-url-query.dto';
import { AvailabilityEvents } from '@/availability/events';
import { IAvailabilitySearchQueryInstance } from '@/availability/models';
import { useAppEvents } from '@/events';
import { resolveHotelReferenceIdFromHotelCode } from '@/hotel/codes';

import { useParseAvailabilitySearchUrlQuery } from './use-parse-availability-search-url-query.hook';

type EmitParams = {
  query: AvailabilitySearchUrlQuery | Record<string, never>;
  placement?: string;
};

export const useEmitAvailabilitySearchFormEventHook = () => {
  // Analytics
  const events = useAppEvents<AvailabilityEvents>();
  const [emitParams, setEmitParams] = useState<EmitParams | undefined>({
    query: {},
  });

  const query = useParseAvailabilitySearchUrlQuery(emitParams?.query);

  const emitAvailabilitySearchFormSubmissionEvent = (
    query: IAvailabilitySearchQueryInstance,
    placement: string
  ) => {
    events.emit('checkRoomAvailability', {
      hotel: {
        referenceId: resolveHotelReferenceIdFromHotelCode(query.hotelCode),
      },
      query,
      metadata: { placement },
    });
  };

  useEffect(() => {
    if (query.validData && query.validData.rooms && emitParams?.placement) {
      emitAvailabilitySearchFormSubmissionEvent(
        query.validData as IAvailabilitySearchQueryInstance,
        emitParams.placement
      );
    }
  }, [query.validData, emitParams?.placement]);

  return setEmitParams;
};
