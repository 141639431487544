import { css } from '@emotion/react';
import dayjs from 'dayjs';
import React from 'react';

import useDimensions from '@/common/hooks/useDimensions.hook';
import { CurrencyPreview, useCurrencyPreview } from '@/currency-preview';
import type { IFinancialAmountInstance } from '@/finance';
import { useTranslation } from '@/i18n';
import { useTheme } from '@/ui/theme';
import { Caption } from '@/ui/typography';

import WeekCalendarDayButton from './WeekCalendarDayButton.component';

const defaultWidth = 40;

interface WeekCalendarDayProps {
  isDisabled?: boolean;
  isStart?: boolean;
  isSelected?: boolean;
  isCheckOut?: boolean;
  isFinalNight?: boolean;
  width?: number;
  price?: IFinancialAmountInstance;
  isAvailable: boolean;
  isLoaded: boolean;
  onClick: () => void;

  // This is a hack. Eventually we will pass around Date objects and use date-fns.
  date: string;
  rawDate: Date;
}

/**
 * isStart = true, if this date marks the first night of the stay
 * isSelected = true, if this date is in the middle of the stay
 * isFinalNight = true, if this night is the final night of the stay, checking out the following day (it's a style thing, to enable the right handle)
 * isCheckOut = true, if the user will be checking out on this day
 * @param props
 */
export const WeekCalendarDay = (props: WeekCalendarDayProps) => {
  const { fonts, colors } = useTheme();
  const { t } = useTranslation();
  const isHighlighted = props.isStart || props.isSelected || props.isFinalNight;
  const width = props.width || defaultWidth;

  const formattedPrice = useCurrencyPreview({
    amount: props.price,
    rounded: true,
    dropUnsupportedCurrencySymbol: true,
  });

  const displayLeftSpacer =
    (props.isSelected || props.isFinalNight) && !props.isStart;
  const displayRightSpacer =
    (props.isSelected || props.isStart) && !props.isFinalNight;

  const isFull = !props.isAvailable;

  const getButtonText = () => {
    if (!props.isLoaded || !props.isAvailable) return '';

    return formattedPrice;
  };

  const getButtonLabelSelectionStatus = (): string | void => {
    if (props.isStart) {
      return 'Selected as check in date';
    }
    if (props.isCheckOut) {
      return 'Selected as check out date';
    }
  };

  const getButtonLabel = (): string => {
    const buttonSelectionStatus = getButtonLabelSelectionStatus();
    const date = dayjs(props.rawDate).format('DD MMMM YYYY');
    const fullOrPrice = isFull
      ? t('search:calendar.unavailableDateMessage')
      : getButtonText();

    return [buttonSelectionStatus, date, fullOrPrice]
      .filter(Boolean)
      .join('. ');
  };

  const [ref, spacerDimensions] = useDimensions({ liveMeasure: true });

  const spacerWidth = spacerDimensions?.width || 0;

  return (
    <div
      css={css`
        flex: 1;
        display: flex;
        align-items: center;
        flex-direction: column;

        > .date {
          margin-bottom: 4px;
        }

        .selector {
          position: relative;
          width: 100%;
          flex: 1;
          display: flex;
        }

        .background--left,
        .background--right {
          width: 50%;
          height: ${width}px;
        }
        .background--left {
          margin-right: -20px;
          z-index: 85;
          background: ${displayLeftSpacer ? colors.primary200 : 'transparent'};
        }
        .background--right {
          margin-left: -20px;
          z-index: 85;
          background: red;
          background: ${displayRightSpacer ? colors.primary200 : 'transparent'};
        }

        .check-out-wrapper {
          position: relative;
          display: ${props.isCheckOut ? 'block' : 'none'};

          &__check-out-indicator {
            background: ${colors.primary200};
            position: absolute;
            width: ${spacerWidth * 2}px;
            height: ${width}px;
            left: -${spacerWidth * 2 - width / 2}px;
          }
          &__check-out-label {
            font-size: 9px;
            position: absolute;
            top: ${width + 7}px;
            left: -${width / 4}px;
            text-align: center;
            letter-spacing: 0.97px;
            min-width: 60px;
            text-transform: uppercase;
            color: #1d1d1d;
            font-family: ${fonts.body};
            white-space: nowrap;
          }
        }
      `}
    >
      <div className="date">
        <Caption color="secondary300">{props.date}</Caption>
      </div>
      <div className="selector">
        {/*
          This acts as a spacing element even when not visible.
          So rather than conditionally displaying this, we're toggling the background transparency.
        */}
        <div className="background--left" />
        <div>
          {props.isCheckOut && (
            <div className="check-out-wrapper">
              <div className="check-out-wrapper__check-out-indicator"></div>
              <div className="check-out-wrapper__check-out-label">
                {t('search:calendar.checkOutLabel')}
              </div>
            </div>
          )}
          <WeekCalendarDayButton
            label={getButtonLabel()}
            onClick={props.onClick}
            width={width}
            isDisabled={props.isDisabled}
            isHighlighted={isHighlighted}
          >
            {getButtonText()}
          </WeekCalendarDayButton>
        </div>
        {/*
          This acts as a spacing element even when not visible.
          So rather than conditionally displaying this, we're toggling the background transparency
        */}
        <div className="background--right" ref={ref} />
      </div>
    </div>
  );
};

export default WeekCalendarDay;
