import { z } from 'zod';

import { BookingMetaDataModel } from '@/booking/models/booking-metadata.model';
import { DomaincommonRateCodeType } from '@/em-api-client-typescript-fetch';

import {
  IRoomAvailabilityInstance,
  RoomAvailabilityModel,
} from './room-availability.model';

export type IAvailabilitySearchResultsModel =
  typeof AvailabilitySearchResultsModel;
export type IAvailabilitySearchResultsModelInstance =
  z.infer<IAvailabilitySearchResultsModel>;

export type IAvailabilitySearchResultsModelSnapshot =
  z.input<IAvailabilitySearchResultsModel>;

export const AvailabilitySearchResultsModel = z
  .object({
    rooms: z.array(RoomAvailabilityModel),
    metaData: BookingMetaDataModel.optional(),
  })
  .transform((self) => ({
    ...self,
    get isHotelFullyBooked() {
      return !self.rooms.some((room) => room.isRoomAvailable);
    },
    /**
     * Returns a list of results with the available rooms first.
     * @deprecated Server-side sorting of rooms should be respected.
     */
    get roomsSortedByAvailableFirst() {
      return self.rooms.slice().sort((room) => (room.isRoomAvailable ? -1 : 1));
    },
    get defaultRateCode(): string {
      const firstRoom = self.rooms[0];
      return (
        // Default rate code,
        firstRoom?.defaultRateStayCost?.rateCode.requestRateCode ||
        // Fallback to 'BAR'
        'BAR'
      );
    },
    get availableRooms() {
      return self.rooms.filter((room) => room.isRoomAvailable);
    },

    /**
     * If a block code is included for any of the rooms in the search results, we should hide unavailable rooms.
     */
    get shouldHideUnavailableRooms(): boolean {
      return Boolean(
        self.rooms.findIndex((room) =>
          room.availability.findIndex(
            (stayCost) =>
              stayCost.rateCode.type === DomaincommonRateCodeType.BLOCKCODE
          )
        )
      );
    },
  }))
  .transform((self) => ({
    ...self,
    /**
     * Returns array of rooms after filtering out unavailable rooms, if relevant.
     */
    get filteredRooms() {
      if (self.shouldHideUnavailableRooms) {
        return self.rooms.filter((room) => room.isRoomAvailable);
      }

      return self.rooms;
    },
    /**
     * Returns array of accessible rooms.
     */
    get accessibleRooms() {
      return self.rooms.filter((room) => room.isRoomAccessible);
    },
    get cheapestAvailableRoom() {
      return self.availableRooms.reduce<IRoomAvailabilityInstance | undefined>(
        (cheapestRoom, room) => {
          if (!cheapestRoom) {
            return room;
          }

          const currentCheapestRate = cheapestRoom.cheapestRate;
          const comparisonCheapestRate = room.cheapestRate;

          if (
            currentCheapestRate &&
            comparisonCheapestRate &&
            comparisonCheapestRate.value < currentCheapestRate.value
          ) {
            return room;
          }

          return cheapestRoom;
        },
        undefined
      );
    },
  }));
