import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

export const useHasBeenViewed = () => {
  // Track when this component has been viewed at least once
  const [hasBeenViewed, setHasBeenViewed] = useState<boolean>(false);
  // Tracks whether this component is visible in the viewport
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && !hasBeenViewed) {
      setHasBeenViewed(true);
    }
  }, [inView]);

  return {
    ref,
    hasBeenViewed,
  };
};
