import { css } from '@emotion/react';
import React from 'react';

import downArrow from '@/core/resources/svg/down-arrow.svg';
import upArrow from '@/core/resources/svg/up-arrow.svg';

interface DropdownIconProps {
  isExpanded: boolean;
}

export const DropdownIcon = (props: DropdownIconProps) => {
  return (
    <span
      css={css`
        position: relative;
      `}
    >
      <span className="dropdown-indicator">
        <img
          src={props.isExpanded ? upArrow.src : downArrow.src}
          alt={props.isExpanded ? 'Click to collapse' : 'Click to expand'}
        />
      </span>
    </span>
  );
};

export default DropdownIcon;
