import React from 'react';

import { DataTableRow, StandoutDataTableRow } from '@/core/components';
import { useHotel } from '@/hotel';

/**
 * A table data row containing the City Tax overrides.
 */
export const CityTaxLineItemOverride = ({
  hotelReferenceId,
  standout,
}: {
  hotelReferenceId: string;
  standout?: boolean;
}) => {
  const hotel = useHotel(hotelReferenceId);

  if (!hotel) return null;

  const { cityTaxAmount, cityTaxDescription, cityTaxSummary } = hotel;

  if (!cityTaxSummary) return null;

  const TableRow = standout ? StandoutDataTableRow : DataTableRow;

  return (
    <TableRow
      label={cityTaxSummary}
      value={cityTaxAmount ?? ''}
      helpText={cityTaxDescription}
      testId="city-tax"
      hideColon={!cityTaxAmount}
    />
  );
};
