import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { trackPageView } from '../gtm';

/**
 * Called whenever the route changes
 * @param path The active path
 */
const handleRouteChange = (path: string) =>
  trackPageView({ path, referrer: document.referrer });

export const usePageLoadTracker = () => {
  const router = useRouter();

  useEffect(() => {
    // Firstly, trigger the page load once
    handleRouteChange(router.asPath);

    // Subscribe to future path change events from Next's Router
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => router.events.off('routeChangeComplete', handleRouteChange);
  }, []);
};
