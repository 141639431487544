import { z } from 'zod';

import { ChargeModel, RateCodeModel } from '@/finance';

/**
 * This represents a room's rate for the entire stay.
 */
export const RoomRate = z.object({
  charges: z.array(ChargeModel).default([]),
  rateCode: RateCodeModel,
  taxInclusive: z.boolean().default(false),
});
