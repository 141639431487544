import { css } from '@emotion/react';
import React from 'react';

import { useSpaceMatchingKey } from '../theme';

interface SplitScreenLayoutProps {
  contentSection: React.ReactNode | React.ReactNodeArray;
  descriptionSection: React.ReactNode | React.ReactNodeArray;
}

export const SplitScreenLayout: React.FC<SplitScreenLayoutProps> = (props) => {
  const smallSpace = useSpaceMatchingKey('s');
  const largeSpace = useSpaceMatchingKey('l');
  return (
    <div
      css={css`
        display: flex;

        @media all and (max-width: 720px) {
          display: block;
        }
      `}
    >
      <div
        css={css`
          max-width: 325px;

          margin-right: ${smallSpace};

          @media all and (max-width: 720px) {
            margin-right: 0;
            max-width: 100%;
            margin-bottom: ${largeSpace};
          }
        `}
      >
        {props.descriptionSection}
      </div>
      <div
        css={css`
          flex: 1;

          margin-left: ${smallSpace};
          @media all and (max-width: 720px) {
            margin-left: 0;
          }
        `}
      >
        {props.contentSection}
      </div>
    </div>
  );
};
