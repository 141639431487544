/**
 * Creates a JSON-LD object.
 * @param type The http://schema.org object type (eg. Hotel)
 * @param data The rest of the data object
 * @param schema Which schema does this relate to?
 */
export const createStructuredDataObject = (
  type: string,
  data: any,
  schema = 'http://schema.org'
) => ({
  '@schema': schema,
  '@type': type,
  ...data,
});
