import { useRouter } from 'next/router';

import { useActiveBrand } from '@/brand';
import { trpc } from '@/trpc/client';

import { storeAuthToken } from '../store-auth-token';

export const useLogout = () => {
  const router = useRouter();
  const brand = useActiveBrand();

  // NOTE: This is mixed with old auth stuff. Will remove when implementing the new API requests in the next PR.
  const logout = trpc.auth.logout.useMutation();

  return () => {
    // Clear out the local JWT token
    storeAuthToken(brand, null);

    logout.mutate();

    // Push the user to the login page
    router.push('/account/login');
  };
};
