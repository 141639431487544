type PageViewEvent = {
  event: string;
  page: string;
  referrer?: string;
};

export const pushToDataLayer = (event: unknown) => {
  if (!window) return;

  if (!window.dataLayer) {
    window.dataLayer = [];
  }

  /**
   * Odd requirement / recommendation from GA when processing Ecommerce events:
   * `It's recommended that you use the following command to clear the ecommerce object
   *  prior to pushing an ecommerce event to the data layer. Clearing the object will
   *  prevent multiple ecommerce events on a page from affecting each other.`
   * Source: https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce#clear-ecommerce
   */
  if (event && typeof event === 'object' && 'ecommerce' in event) {
    window.dataLayer.push({ ecommerce: null });
  }

  window.dataLayer.push(event);
};

export const getLatestPageViewEvent = (): PageViewEvent | undefined => {
  if (!window.dataLayer) {
    return undefined;
  }

  // Grab all 'pageview' events from the dataLayer
  const pageViewEvents = (window.dataLayer as any).filter(
    (entry: any) => entry.event === 'pageview'
  );

  // Return the most recent event
  return pageViewEvents[pageViewEvents.length - 1];
};

/**
 * Add a pageview event to the dataLayer.
 * Note: If this function is called multiple times with the same path, it'll only add one entry.
 * @param path The current path (excluding protocol and domain)
 */
export const trackPageView = ({
  path,
  referrer,
}: {
  path: string;
  referrer?: string;
}) => {
  const pageEvent: PageViewEvent = {
    event: 'pageview',
    page: path,
    referrer,
  };

  // Avoid adding consecutive events when called multiple times
  const latestPageViewEvent = getLatestPageViewEvent();
  if (latestPageViewEvent?.page === path) {
    return;
  }

  pushToDataLayer(pageEvent);

  return pageEvent;
};

/**
 * Provides the dataLayer with key information about the current page type and environment.
 * We're performing this in _document.tsx.
 * @param param0
 */
export const setupPageForGTM = ({ environment }: { environment: string }) => {
  pushToDataLayer({
    page: {
      environment,
    },
  });
};

export interface BookingGTMStructure {
  bookingHotelName: string;
  bookingHotelCode: string;
  bookingHotelChainCode: string;
  bookingHotelCity: string;
  bookingAdults: number;
  bookingChildren: number;
  bookingInDate: string;
  bookingOutDate: string;
  bookingNights: number;
  bookingRooms: number;
  bookingCurrency: string;
}

export interface ProductGTMStructure {
  productId: string;
  productType: string;
  productRateName?: string;
  productPrice: string;
  productQty?: number;
}

/**
 * Represents a booking that exists, thus having a booking ID and revenue assigned
 */
export interface ExistingBookingGTMStructure extends BookingGTMStructure {
  bookingId: string;
  bookingRevenue: string;
  bookingCoupon: string;
}

export interface TrackAddToCartEventArgs {
  booking: BookingGTMStructure;
  productAddToCart: ProductGTMStructure[];
}
export const trackAddToCartEventInGTM = (args: TrackAddToCartEventArgs) =>
  pushToDataLayer({ event: 'add_to_cart', ...args });

export interface TrackPurchaseEventInGTMArgs {
  booking: ExistingBookingGTMStructure;
  productPurchase: ProductGTMStructure[];
}
export const trackPurchaseEventInGTM = (args: TrackPurchaseEventInGTMArgs) =>
  pushToDataLayer({
    event: 'purchase',
    ...args,
  });

export interface TrackBookingSearchEventInGTMArgs {
  booking: BookingGTMStructure;
  productImpressions: ProductGTMStructure[];
}
export const trackBookingSearchEventInGTM = (
  args: TrackBookingSearchEventInGTMArgs
) =>
  pushToDataLayer({
    event: 'booking_search',
    ...args,
  });

export interface TrackCheckoutPageVisitEventInGTMArgs {
  booking: BookingGTMStructure;
  productCheckout: ProductGTMStructure[];
}
export const trackCheckoutPageVisitEventInGTM = (
  args: TrackCheckoutPageVisitEventInGTMArgs
) => {
  pushToDataLayer({
    event: 'checkout',
    ...args,
  });
};

export const trackGooglePayImpressionInGTM = () =>
  pushToDataLayer({ event: 'googlePayImpression' });

export const trackGooglePaySelectionInGTM = () =>
  pushToDataLayer({ event: 'googlePaySelection' });

export const trackGooglePayPaymentInGTM = () =>
  pushToDataLayer({ event: 'googlePayPayment' });

export const trackApplePayImpressionInGTM = () =>
  pushToDataLayer({ event: 'applePayImpression' });

export const trackApplePaySelectionInGTM = () =>
  pushToDataLayer({ event: 'applePaySelection' });

export const trackApplePayPaymentInGTM = () =>
  pushToDataLayer({ event: 'applePayPayment' });

export interface WeeklyCalendarDateRangeChangeArgs {
  beCalendarInDate: string;
  beCalendarOutDate: string;
}

export interface WeeklyCalendarDateClickArgs {
  beCalendarDate: string;
  beCalendarPrice: string;
  beCalendarCurrency: string;
}
export const weeklyCalendarGTMEvents = {
  trackAdvanceWeek: () => pushToDataLayer({ event: 'calendar_advance' }),
  trackPreviousWeek: () => pushToDataLayer({ event: 'calendar_previous' }),
  trackDateClick: (args: WeeklyCalendarDateClickArgs) =>
    pushToDataLayer({
      event: 'calendar_date_change',
      ...args,
    }),
  trackDateRangeChange: (args: WeeklyCalendarDateRangeChangeArgs) =>
    pushToDataLayer({ event: 'calendar_date_range_change', ...args }),
};
