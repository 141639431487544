import React from 'react';

export const EnnismoreLogo = (props: {
  width: React.SVGAttributes<SVGSVGElement>['width'];
  height: React.SVGAttributes<SVGSVGElement>['height'];
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 565.76 276.16"
    width={props.width}
    height={props.height}
    fill="currentColor"
  >
    <title>{'Ennismore'}</title>
    <polygon points="187.41 31.86 187.41 187.03 152.4 44.05 138.89 48.76 138.89 227.4 152.44 232.12 152.44 103.72 187.31 244.27 201.44 249.19 201.44 26.97 187.41 31.86" />
    <polygon points="538.4 171.73 538.4 144.18 563.14 143.52 563.14 131.77 538.4 131.1 538.4 104.23 565.76 112.57 565.76 99.97 525.08 85.8 525.08 190.36 565.76 176.19 565.76 163.52 538.4 171.73" />
    <polygon points="218.26 21.11 218.26 255.05 232.29 259.94 232.29 16.22 218.26 21.11" />
    <polygon points="13.56 170.62 13.56 144.96 42.28 145.19 42.28 131.02 13.56 132.63 13.56 105.2 45.06 95.92 45.06 81.45 0 97.15 0 179.01 45.06 194.7 45.06 180.01 13.56 170.62" />
    <polygon points="108.51 59.34 108.51 175.74 73.28 71.62 59.51 76.42 59.51 199.74 73.07 204.46 73.07 114.53 108.3 216.74 122.07 221.53 122.07 54.62 108.51 59.34" />
    <path d="M501.92,148c7.82-4.35,9.29-12.79,9.29-18.47V98.77c0-11.81-5.16-19.61-15.56-23.25l-27-9.37V210l13.79-4.81V150.87h6.11l10.25,48.66L512,194.92Zm-4-22.8c0,10.15-3.28,12.23-8.21,12.23H482.4V84.82l7,2.2c6.61,2.07,8.45,5.77,8.45,15.25Z" />
    <path d="M291.06,139.45,277.6,121.74c-9.91-13.14-13.43-28.54-13.43-58.85V27.62c0-6.31,1.26-8.64,6.87-10.59l7.8-2.71L286.48,17c5.57,1.93,6.94,4.31,6.94,10.62V81.94h13.8V25.17c0-12.05-2.34-16.1-11.45-19.27L278.84,0l-17,5.92c-9.15,3.2-11.44,7.2-11.44,19.25V61.62c0,35.08,4.53,54.7,16.15,70L280,149.32c9.66,12.71,13.43,29.21,13.43,58.84v40.38c0,6.31-1.37,8.61-6.89,10.61l-7.69,2.69-7.73-2.69c-5.64-1.95-6.93-4.3-6.93-10.61V194.23h-13.8V251c0,12.05,2.48,16.12,11.46,19.27l17,5.9,16.94-5.94c9-3.11,11.43-7.18,11.43-19.23V209.44C307.21,174.25,302.68,154.63,291.06,139.45Z" />
    <path d="M440.2,56.22l-13.06-4.58c-8.57-3-18.36.33-18.36,15V210c0,14.49,9.34,17.68,19.54,14.11L443,219c7.84-2.74,11.27-7.77,11.27-17.2V78.53C454.23,66.69,449.77,59.6,440.2,56.22Zm.68,24.13v116.7c0,5.95-1.18,8.81-5.81,10.19L431,208.61c-5,1.54-7.94,1.32-7.94-7.33V74.16c0-8.19,2.45-8.29,6.58-7l5,1.6C439,70.15,440.88,72.06,440.88,80.35Z" />
    <polygon points="339.57 255 339.57 96.57 354.38 175.41 364.88 174.36 380.48 104.73 380.48 240.75 394.27 235.94 394.27 40.22 382.28 36.04 360.59 136.92 338.36 20.73 325.31 16.19 325.31 259.97 339.57 255" />
  </svg>
);
