import { css } from '@emotion/react';
import React, { useState } from 'react';

import { Box } from '@/box';
import {
  getAccessibleDateString,
  getLocalisedDateString,
} from '@/common/utils/date';
import { useLocale, useTranslation } from '@/i18n';
import { Input } from '@/ui/form';
import { useTheme } from '@/ui/theme';

export const EndDateField = ({
  endDateId,
  value,
  onFocusHandler,
  onClickHandler,
  triggerRef,
}: {
  endDateId: string;
  value?: string;
  onFocusHandler?: React.FocusEventHandler;
  onClickHandler?: React.MouseEventHandler;
  triggerRef: React.MutableRefObject<HTMLButtonElement>;
}) => {
  const { t } = useTranslation();
  /**
   * Used to conditionally add & remove Input from the tab order
   */
  const [inputTabIndex, setInputTabIndex] = useState(0);
  const locale = useLocale();
  const { forms } = useTheme();

  return (
    <Box
      width={'calc(50% - 7.5px)'}
      position="relative"
      {...forms?.searchForm?.endDateFieldContainer}
    >
      <Input
        type="text"
        name={endDateId}
        readOnly
        hideError
        value={value ? getLocalisedDateString(value, locale) : ''}
        placeholder={t('search:form.endDate')}
        onFocus={(e) => {
          // Remove the input from the tab order, so that backward tabbing works while on the button.
          setInputTabIndex(-1);
          triggerRef.current.focus();
          onFocusHandler?.(e);
        }}
        onClick={onClickHandler}
        tabIndex={inputTabIndex}
      >
        <button
          ref={triggerRef}
          css={css`
            position: absolute;
            height: 100%;
            width: 100%;
            &:focus {
              /* force applied as a temp solution while 'focus-visible' module is in place */
              outline: 1px solid #8f8f8f !important;
              outline-offset: 2px;
              border-radius: ${forms.input?.borderRadius ?? '2px'};
            }
          `}
          onFocus={(e) => {
            setInputTabIndex(-1);
            onFocusHandler?.(e);
          }}
          onClick={onClickHandler}
          onBlur={() => setInputTabIndex(0)} // Restore input to the tab order
          aria-label={`${t('search:form.checkOutLabel')} - ${
            value ? getAccessibleDateString(value, locale) : 'not selected'
          }`}
        />
      </Input>
    </Box>
  );
};
