import { useContext } from 'react';

import { ThemeSwitcherContext } from '../theme-switcher.context';

export const useThemeSwitcherControls = () => {
  const context = useContext(ThemeSwitcherContext);

  if (!context) {
    throw new Error('Missing <ThemeSwitcherProvider />');
  }

  return context;
};
