import { useEffect, useRef } from 'react';

export const usePrevValue = <T>(value: T): T | any => {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};
