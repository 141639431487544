import React, { useEffect } from 'react';
import { useCookie } from 'react-use';

import {
  HypertuneEvents,
  setupHypertuneEventBindings,
} from '@/analytics/hypertune';
import { useCookieConsentStatus } from '@/cookie-consent/consent-status';
import { getEnvironmentVars } from '@/env';
import { useAppEvents } from '@/events/hooks';
import { defaultLogger } from '@/logging';

import { HypertuneProvider, useHypertune } from './generated/hypertune.react';

function HypertuneEventSubscriberProvider(props: {
  children: React.ReactNode;
}) {
  const hypertune = useHypertune();
  const events = useAppEvents<HypertuneEvents>();

  useEffect(() => {
    return setupHypertuneEventBindings(events, hypertune);
  }, [events, hypertune]);

  return <>{props.children}</>;
}

export function HypertuneFeatureFlagProvider(props: {
  children: React.ReactNode;
}) {
  const consent = useCookieConsentStatus();

  defaultLogger.debug(`hypertune: consent status [${consent.performance}]`);

  useEffect(() => {
    if (!consent.performance) {
      defaultLogger.info(
        'user has not consented to the use of performance cookies so hypertune will be omitted'
      );
    }
  }, [consent.performance]);

  if (!consent.performance) {
    defaultLogger.debug('hypertune: skipping initialisation');
    return <>{props.children}</>;
  }

  defaultLogger.debug('hypertune: wrapping app with provider');

  return <HypertuneFeatureFlagProviderInner {...props} />;
}

function HypertuneFeatureFlagProviderInner({
  children,
}: {
  children: React.ReactNode;
}) {
  const [sessionId, setSessionId] = useCookie('flags.session-id');

  useEffect(() => {
    if (!sessionId) {
      setSessionId(crypto.randomUUID());
      return;
    }
  }, [sessionId, setSessionId]);

  return (
    <HypertuneProvider
      createSourceOptions={{
        token: getEnvironmentVars().NEXT_PUBLIC_HYPERTUNE_TOKEN!,
      }}
      rootArgs={{
        context: {
          environment: getEnvironmentVars().NEXT_PUBLIC_ENVIRONMENT,
          session: { id: sessionId ?? '' },
        },
      }}
    >
      <HypertuneEventSubscriberProvider>
        {children}
      </HypertuneEventSubscriberProvider>
    </HypertuneProvider>
  );
}
