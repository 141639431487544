import React from 'react';

import { Markdown } from '@/markdown';

import { BodySmall } from '../typography';

interface ErrorMessageProps {
  message?: string;
  textAlign?: 'center' | 'left';
}

export const ErrorMessage = ({ message, textAlign }: ErrorMessageProps) => {
  return (
    <BodySmall textAlign={textAlign ?? 'left'} color="error" role="alert">
      {message ? <Markdown source={message} /> : null}
    </BodySmall>
  );
};
