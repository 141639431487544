import { css } from '@emotion/react';
import React from 'react';

import { AvailableSpaceKeyType, useSpaceMatchingKey } from '../theme';

interface SpacerProps {
  s: AvailableSpaceKeyType;
  className?: string;
}

export const Spacer = (props: SpacerProps) => {
  return (
    <div
      className={props.className}
      css={css({ height: useSpaceMatchingKey(props.s) })}
    />
  );
};

export default Spacer;
