import { css } from '@emotion/react';
import React from 'react';

import { IRoomAvailabilityInstance } from '@/availability/models/room-availability.model';
import { Box, Flex } from '@/box';
import ImageSlider from '@/core/components/controls/ImageSlider.component';
import Card from '@/core/components/layout/Card.component';
import { useTheme } from '@/ui/theme';

interface SearchResultRoomCardProps {
  roomAvailabilityModel: IRoomAvailabilityInstance;
  children: React.ReactNode;
  'aria-labelledby'?: string;
  testId?: string;
}

export const SearchResultRoomCard = (props: SearchResultRoomCardProps) => {
  const imageUrls = props.roomAvailabilityModel.room.images || Array<string>();
  const {
    componentProperties: { bedroomCard },
  } = useTheme();

  return (
    <Card
      css={css({
        ...bedroomCard,
        span: {
          color: bedroomCard?.color,
          letterSpacing: bedroomCard?.description?.letterSpacing,
        },
        h2: {
          span: bedroomCard?.roomTitle,
        },
        ['@media all and (max-width: 639px)']: {
          padding: bedroomCard?.mobile?.padding ?? '0 28px 28px',
        },
        '.Description': bedroomCard?.description,
      })}
      aria-labelledby={props['aria-labelledby']}
      testId={props.testId}
      // Unmask section in FullStory
      className="fs-unmask"
    >
      <Flex flexWrap="wrap">
        <Box width={[1, 368]} paddingRight={[0, 32]}>
          {imageUrls && (
            <div
              css={css`
                @media all and (max-width: 639px) {
                  width: calc(100% + 56px);
                  margin-left: -28px;
                }
              `}
            >
              <ImageSlider images={imageUrls} />
            </div>
          )}
        </Box>
        <Flex flex={1} minWidth={200}>
          {props.children}
        </Flex>
      </Flex>
    </Card>
  );
};
