import { css } from '@emotion/react';
import React, { CSSProperties } from 'react';

import { cn } from '@/common/helpers';
import { useTheme } from '@/ui/theme';

import { FieldErrorMessage, Label } from '.';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  style?: CSSProperties;
  withBottomMargin?: boolean;
  isRequired?: boolean;
  hideError?: boolean;
  error?: string;
  value?: string;
  children?: React.ReactNode;
}

export const Input = React.forwardRef(
  (
    {
      label,
      name,
      style,
      withBottomMargin,
      isRequired,
      hideError,
      error,
      value,
      children,
      ...rest
    }: InputProps,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const fieldId = `field-${name}`;
    const {
      colors,
      fonts,
      keylines,
      text,
      forms: { input },
    } = useTheme();

    const containerStyles = css`
      display: flex;
      flex-direction: column;
      flex: 1;

      margin-bottom: ${withBottomMargin ? '30px' : '0'};

      &.error {
        label {
          color: ${colors.error};
        }
        input {
          border: 1px solid ${colors.error};
        }
        .error-message {
          visibility: visible;
        }

        &.empty {
          ::placeholder {
            color: ${colors.error};
          }
        }
      }

      input {
        padding: ${input?.padding ?? '16px'};
        outline: none;
        display: block;
        background: ${input?.backgroundColor ?? 'transparent'};
        color: ${input?.color ?? colors.textPrimary};

        font-family: ${fonts.body};
        font-size: ${input?.fontSize ?? text.bodyCopy.fontSize};
        border: ${input?.border ?? keylines?.border};
        border-radius: ${input?.borderRadius ?? '4px'};
        line-height: 24px;

        /* Ensure inputs fill the width of their container as a general rule: */
        width: 100%;

        &:focus {
          outline: 1px solid ${colors.textSecondary};
          outline-offset: 2px;
        }

        &:read-only {
          user-select: none;
        }

        ::placeholder {
          color: ${input?.placeholder?.color ?? '#718096'};
        }
      }
    `;

    const showError = !!error;
    const isEmpty = value && value.length <= 0;

    return (
      <div
        css={containerStyles}
        style={style}
        className={cn(showError && 'error', isEmpty && 'empty')}
      >
        {label && (
          <Label htmlFor={fieldId} isRequired={isRequired}>
            {label}
          </Label>
        )}
        <input
          ref={ref}
          name={name}
          id={fieldId}
          data-test={fieldId}
          aria-describedby={hideError ? undefined : `${fieldId}-error`}
          aria-required={isRequired}
          value={value}
          {...rest}
        />
        {children ? children : null}
        {hideError ? null : (
          <FieldErrorMessage
            fieldName={name}
            message={error}
            isVisible={showError}
          />
        )}
      </div>
    );
  }
);

Input.displayName = 'Input';
