import { z } from 'zod';

import { ChargeBreakdownModel } from '@/booking/models/charge-breakdown.model';
import { YesNoWithReasonModel } from '@/common/models';

export type IStayCost = typeof StayCostModel;
export type IStayCostInstance = z.infer<IStayCost>;
export type IStayCostSnapshot = z.input<IStayCost>;

export const StayCostModel = ChargeBreakdownModel.and(
  z.object({
    bookable: YesNoWithReasonModel.default({ value: false }),
  })
);
