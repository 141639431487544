import { z } from 'zod';

import { CurrencyCode as CurrencyCodeFull } from '../interfaces/currency-code.interface';

export const CurrencyCodeSchema = z.enum([
  'USD',
  'usd',
  'EUR',
  'eur',
  'GBP',
  'gbp',
  'chf',
  'CHF',
  'aed',
  'AED',
  'DKK',
  'dkk',
]);
export type CurrencyCode = z.infer<typeof CurrencyCodeSchema>;

const numberFormatIntlInstances: {
  [key: `${Intl.BCP47LanguageTag}-${CurrencyCodeFull}-${number}`]:
    | Intl.NumberFormat
    | undefined;
} = {};

/**
 * Gets a localised currency value
 * @param locale e.g. 'en', 'it' etc
 * @param value e.g. 200, 150.25 etc
 * @param currency e.g. 'GBP', 'EUR' etc
 * @param maximumFractionDigits 2 (default): 150.25, 0: 150
 * @param dropCurrencySymbol false (default): $150; true: 150
 * @param dropUnsupportedCurrencySymbol false (default): AED 150 / $150; true: would drop AED but keep $
 */
export const localisedCurrencyValue = ({
  locale,
  value,
  currency,
  maximumFractionDigits = 2,
  dropCurrencySymbol = false,
  dropUnsupportedCurrencySymbol = false,
}: {
  locale: Intl.BCP47LanguageTag;
  value: number;
  currency: CurrencyCodeFull | Lowercase<CurrencyCodeFull>;
  maximumFractionDigits?: number;
  dropCurrencySymbol?: boolean;
  dropUnsupportedCurrencySymbol?: boolean;
}) => {
  const upperCaseCurrency = currency.toUpperCase() as CurrencyCodeFull;
  let numberFormatter =
    numberFormatIntlInstances[
      `${locale}-${upperCaseCurrency}-${maximumFractionDigits}`
    ];

  if (!numberFormatter) {
    numberFormatIntlInstances[
      `${locale}-${upperCaseCurrency}-${maximumFractionDigits}`
    ] = new Intl.NumberFormat(
      // include fallback to English in case locale is unsupported
      [locale, 'en'],
      {
        style: 'currency',
        currency,
        currencyDisplay: 'narrowSymbol',
        maximumFractionDigits,
      }
    );
    numberFormatter =
      numberFormatIntlInstances[
        `${locale}-${upperCaseCurrency}-${maximumFractionDigits}`
      ];
  }

  const returnFallback = () => value?.toFixed(maximumFractionDigits);

  if (dropCurrencySymbol)
    return (
      numberFormatter
        ?.formatToParts(value)
        .map((p) =>
          p.type === 'literal' || p.type === 'currency' ? '' : p.value
        )
        .join('') ?? returnFallback()
    );

  if (dropUnsupportedCurrencySymbol)
    return (
      numberFormatter
        ?.formatToParts(value)
        .map((p) =>
          p.type === 'literal' ||
          (p.type === 'currency' && p.value === currency)
            ? ''
            : p.value
        )
        .join('') ?? returnFallback()
    );

  return numberFormatter?.format(value) ?? returnFallback();
};

/**
 * @deprecated
 */
export const getSymbolForCurrencyCode = (currencyCode: CurrencyCode) => {
  switch (currencyCode) {
    case 'EUR':
    case 'eur':
      return `€`;
    case 'GBP':
    case 'gbp':
      return `£`;
    case 'USD':
    case 'usd':
      return `$`;
    case 'CHF':
    case 'chf':
      return `₣`;
    case 'AED':
    case 'aed':
      return 'د.إ';
    case 'DKK':
    case 'dkk':
      return 'kr.';
  }
};

/**
 * Returns localised currency symbol, e.g. £, $, € etc.
 * @param locale e.g. 'en', 'it'
 * @param currencyCode e.g. 'GBP', 'USD', 'EUR'
 */
export const getLocalisedCurrencySymbol = (
  locale: Intl.BCP47LanguageTag | undefined,
  currencyCode: CurrencyCodeFull | Lowercase<CurrencyCodeFull>
) => {
  const normalisedCurrencyCode = currencyCode.toUpperCase() as CurrencyCodeFull; // I know ugly, but isnt' a lie.
  // Some currency symbols are best represented in their corresponding locale.
  let localeOverride: string | null = null;
  switch (normalisedCurrencyCode) {
    case 'AED':
      localeOverride = 'ar';
  }

  switch (normalisedCurrencyCode) {
    /**
     * Some additional massaging is required for certain currencies as the Intl API
     * is always returning currency code, no matter which locale is provided.
     */
    case 'CHF':
      return '₣';
    default:
      return Number(0)
        .toLocaleString(localeOverride ?? locale, {
          currency: normalisedCurrencyCode,
          style: 'currency',
          currencyDisplay: 'narrowSymbol',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
        .replace(/\d/g, '')
        .trim();
  }
};

/**
 * Returns translated currency name, e.g. US dollar, British pound, Euro etc
 * @param locale e.g. 'en', 'it'
 * @param currencyCode e.g. 'GBP', 'USD', 'EUR'
 */
export const getLocalisedCurrencyName = (
  locale: Intl.BCP47LanguageTag = 'en',
  currencyCode: string
) => {
  try {
    return (
      new Intl.DisplayNames(locale, {
        type: 'currency',
        fallback: 'none',
      }).of(currencyCode) ||
      // fallback due to greater browser support
      Number(1)
        .toLocaleString(locale, {
          currency: currencyCode,
          style: 'currency',
          currencyDisplay: 'name',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
        .replace(/\d/g, '')
        .trim()
    );
  } catch (_err) {
    // in case of unsupported currency code, just return it
    return currencyCode;
  }
};
