import { useEffect } from 'react';
import { useEffectOnce } from 'react-use';
import { snapshot, subscribe } from 'valtio';
import { proxyMap, useProxy } from 'valtio/utils';
import { z } from 'zod';

import { IAvailabilitySearchQueryInstance } from '@/availability/models';
import {
  BookingConfiguration,
  BookingConfigurationModel,
} from '@/booking/models';

/**
 * This file comprises a number of hooks used to manage client-side booking configuration,
 * such as additional information (comments, flexy time, etc).
 *
 * It's very much a WIP, created as a stopgap while we remove the old solution.
 */

type Fields = NonNullable<BookingConfiguration['additionalInfoFields']>;
const storeSchema = z.record(z.string(), BookingConfigurationModel);

const store = proxyMap<string, BookingConfiguration>();

/**
 * Fetches the config matching given bookingId.
 *
 * @param bookingId
 * @returns
 */
const useGetOrCreate = (bookingId: string): BookingConfiguration => {
  useProxy(store);

  const newConfig = store.get(bookingId) || BookingConfigurationModel.parse({});

  useEffect(() => {
    if (!store.has(bookingId)) store.set(bookingId, newConfig);
  });

  return newConfig;
};

export const useBookingCheckout = (bookingId: string) => {
  const config = useGetOrCreate(bookingId);

  return {
    config,
    setAdditionalFields(fields: Fields) {
      console.log('setting additional fields ', fields, JSON.stringify(config));
      config.additionalInfoFields = fields;
      config.isAdditionalInfoStepCompleted = true;
    },
    setIsBookingTrackedInGTM(value: boolean) {
      config.isPurchaseTrackedInGTM = value;
    },
    setOriginalSearchQuery(query: IAvailabilitySearchQueryInstance) {
      config.originalSearchQuery = query;
    },
  };
};

export const useOriginalSearchQuery = () => {
  const proxyStore = useProxy(store);

  const getOriginalSearchQuery = (bookingId: string) => {
    return proxyStore.get(bookingId)?.originalSearchQuery;
  };

  const setOriginalSearchQuery = (
    bookingId: string,
    query: IAvailabilitySearchQueryInstance
  ) => {
    if (!store.has(bookingId)) {
      store.set(bookingId, BookingConfigurationModel.parse({}));
    }

    const config = store.get(bookingId);
    if (config) config.originalSearchQuery = query;
  };

  return [getOriginalSearchQuery, setOriginalSearchQuery] as const;
};

const storageKey = 'booking-configurations';

/**
 * Observes changes to the configuration store and persists to sessionStorage.
 */
export function usePersistBookingConfigurations() {
  useEffectOnce(() => {
    if (typeof localStorage === 'undefined') return;

    subscribe(store, () => {
      localStorage.setItem(
        storageKey,
        JSON.stringify(Object.fromEntries(snapshot(store)))
      );
    });
  });

  useEffectOnce(() => {
    if (typeof localStorage === 'undefined') return;

    // We can assume if there are no entries in the map we could be on a fresh page load
    if (store.entries.length <= 0) {
      const storageItem = localStorage.getItem(storageKey);

      // If there's nothing in session storage, do nothing
      if (!storageItem) return;

      // Deserialize and validate whatever is in there
      const result = storeSchema.safeParse(JSON.parse(storageItem));

      if (result.success) {
        for (const [key, value] of Object.entries(result.data)) {
          console.log('seeding booking config from storage: ' + key);
          store.set(key, value);
        }
      } else {
        console.error('session data failed validation, clearing...');
        localStorage.removeItem(storageKey);
      }
    }
  });
}
