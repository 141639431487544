import { css } from '@emotion/react';
import dayjs from 'dayjs';
import React from 'react';

import { useActiveBrandConfig } from '@/brand';
import { CoreAppEvents } from '@/core/events';
import { useAppEvents } from '@/events';
import AnchorLink from '@/hox-core/components/controls/AnchorLink.component';
import { getProviderLogo } from '@/provider/getProviderLogo';
import { useSpaceMatchingKey, useTheme } from '@/ui/theme';
import { Caption } from '@/ui/typography';

export const Copyright = () => {
  const belowLogoMargin = useSpaceMatchingKey('xs');
  const { provider } = useActiveBrandConfig().footer;
  const events = useAppEvents<CoreAppEvents>();
  const {
    componentProperties: { footer },
  } = useTheme();

  const ProviderLogo = getProviderLogo(provider.key);

  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        ...footer?.copyright,
        span: {
          fontSize: footer?.copyright?.fontSize,
          fontFamily: footer?.copyright?.fontFamily,
          textTransform: footer?.copyright?.textTransform,
        },
        [`@media all and (max-width: 500px)`]: {
          flexDirection: 'column-reverse',
        },
      }}
    >
      <div
        css={css`
          margin-right: 12px;

          @media all and (max-width: 500px) {
            margin-right: 0;
          }
        `}
      >
        <Caption>{`© ${provider.name} ${dayjs().get('year')}`}</Caption>
      </div>
      <div
        css={css`
          @media all and (max-width: 500px) {
            margin-bottom: ${belowLogoMargin};
          }
        `}
      >
        <AnchorLink
          href={provider.url}
          openInNewTab
          onClick={() =>
            events.emit('navigation', {
              location: 'Footer',
              originator: `${provider.name} Logo`,
            })
          }
          color={footer?.color}
        >
          <ProviderLogo width={64} height={23} />
        </AnchorLink>
      </div>
    </div>
  );
};

export default Copyright;
