export const localisedDistance = (km: number, locale: Intl.Locale) => {
  // Less than ideal given distance is often not tied to language, but this gets us a certain way for now.
  if (locale.language === 'en') {
    const miles = Math.round((km / 1.609) * 10) / 10;
    // Pluralise if appropriate
    return `${miles} mile${miles !== 1 ? 's' : ''}`;
  }

  return `${km} km`;
};
