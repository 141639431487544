import * as React from 'react';

export const CssReset = () => {
  return (
    <style global jsx>{`
      html,
      body,
      div,
      span,
      applet,
      object,
      iframe,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      blockquote,
      pre,
      a,
      abbr,
      acronym,
      address,
      big,
      cite,
      code,
      del,
      dfn,
      em,
      img,
      ins,
      kbd,
      q,
      s,
      samp,
      small,
      strike,
      strong,
      sub,
      sup,
      tt,
      var,
      b,
      u,
      i,
      center,
      dl,
      dt,
      dd,
      ol,
      ul,
      li,
      fieldset,
      form,
      label,
      legend,
      table,
      caption,
      tbody,
      tfoot,
      thead,
      tr,
      th,
      td,
      article,
      aside,
      canvas,
      details,
      embed,
      figure,
      figcaption,
      footer,
      header,
      hgroup,
      menu,
      nav,
      output,
      ruby,
      section,
      summary,
      time,
      mark,
      audio,
      video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
      }
      /* HTML5 display-role reset for older browsers */
      article,
      aside,
      details,
      figcaption,
      figure,
      footer,
      header,
      hgroup,
      menu,
      nav,
      section {
        display: block;
      }
      body {
        line-height: 1;
      }
      ol,
      ul {
        list-style: none;
      }
      blockquote,
      q {
        quotes: none;
      }
      blockquote:before,
      blockquote:after,
      q:before,
      q:after {
        content: '';
        content: none;
      }
      table {
        border-collapse: collapse;
        border-spacing: 0;
      }
      // html::-webkit-scrollbar {
      //   width: 5px;
      // }

      // html::-webkit-scrollbar-thumb:vertical {
      //   margin: 5px;
      //   background-color: #999;
      //   -webkit-border-radius: 5px;
      // }

      // html::-webkit-scrollbar-button:start:decrement,
      // html::-webkit-scrollbar-button:end:increment {
      //   height: 5px;
      //   display: block;
      // }
      html,
      body,
      #__next {
        min-height: 100vh;
      }

      * {
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
      }

      textarea,
      select,
      input,
      button {
        outline: none;
        background: none;
        border: none;
        padding: 0;
        margin: 0;
      }

      * {
        box-sizing: border-box;
      }
    `}</style>
  );
};

export default CssReset;
