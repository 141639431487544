import { z } from 'zod';

import { AvailabilitySearchQuery } from '@/availability/models';
import {
  validateChildAges,
  validateRoomOccupancy,
} from '@/availability/validators/room-occupancy.validator';
import { validateSearchUrlQuery } from '@/availability/validators/search-url-query.validator';
import { useActiveBrandConfig } from '@/brand';
import { Occupancy, useHotel } from '@/hotel';
import { resolveHotelReferenceIdFromHotelCode } from '@/hotel/codes';
import { partialSafeParse } from '@/zod';

/**
 * Takes a URL query and validates it, then builds and returns an instance of the AvailabilitySearchQuery model.
 */
export const useParseAvailabilitySearchUrlQuery = (urlQuery: unknown) => {
  const brandConfig = useActiveBrandConfig();

  const validatedURLQuery = validateSearchUrlQuery(urlQuery, {
    // Check the hotel code exists in the current brand configuration
    validateHotelCode: (code) =>
      brandConfig.hotels.some((hotel) => hotel.code === code),
  });

  // Validate the URL query string
  const { hotelCode, rateCode, checkin, checkout, dayUse, rooms } =
    validatedURLQuery.validData;

  const hotelReferenceId = hotelCode
    ? resolveHotelReferenceIdFromHotelCode(hotelCode)
    : undefined;
  const hotel = useHotel(hotelReferenceId);

  const supportedRoomOccupancyConfigurations =
    hotel?.operational.supportedRoomOccupancyConfigurations ??
    brandConfig.supportedRoomOccupancyConfigurations;

  const availabilitySearchQueryWithRoomsRefinement =
    AvailabilitySearchQuery.extend({
      rooms: z
        .array(Occupancy)
        .default([])
        .refine((rooms) => {
          return [
            validateChildAges(hotel?.requiresChildAges),
            validateRoomOccupancy(supportedRoomOccupancyConfigurations),
          ].every((validate) => validate(rooms));
        }, 'Invalid room configuration'),
    });

  return partialSafeParse(availabilitySearchQueryWithRoomsRefinement, {
    hotelCode,
    rateCode,
    from: checkin,
    to: checkout,
    dayUse,
    rooms,
  });
};
