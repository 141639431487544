import React from 'react';

interface DropdownIndicatorUpProps {
  color?: string;
}

export const DropdownIndicatorUp = ({ color }: DropdownIndicatorUpProps) => {
  return (
    <svg
      width="12px"
      height="6px"
      viewBox="0 0 12 6"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlinkHref="http://www.w3.org/1999/xlink"
    >
      <g
        id="Booking-Journey---2-Adults-1-Child"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Desktop/Desktop-STEP-2-Additional-Info"
          transform="translate(-1323.000000, -489.000000)"
          fill={color || '#363636'}
        >
          <g id="Receipt" transform="translate(1070.000000, 240.000000)">
            <g id="Room-1" transform="translate(0.000000, 159.000000)">
              <g
                id="Price-Breakdown"
                transform="translate(24.000000, 81.000000)"
              >
                <polygon
                  id="Rectangle-Copy-4"
                  transform="translate(235.000000, 15.000000) scale(1, -1) rotate(135.000000) translate(-235.000000, -15.000000) "
                  points="231 11 239 11 239 19"
                ></polygon>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default DropdownIndicatorUp;
