/* Only ever used internally, don't need translations */

/* eslint-disable react/jsx-no-literals */
import React from 'react';

import CopyModal from '@/core/components/controls/CopyModal.component';
import { Spacer } from '@/ui/spacing';
import { Heading } from '@/ui/typography';

interface FeatureSwitchUIModalProps {
  features: React.ReactNode[];
  isOpen: boolean;
  onRequestClose: () => void;
  after?: React.ReactNode;
}

export const FeatureSwitchUIModal = (props: FeatureSwitchUIModalProps) => {
  return (
    <CopyModal isOpen={props.isOpen} onRequestClose={props.onRequestClose}>
      <Heading style="heading5" as="h5">
        Features
      </Heading>
      <Spacer s="xs" />
      {props.features}
      <Spacer s="xs" />
      {props.after}
    </CopyModal>
  );
};

export default FeatureSwitchUIModal;
