import type { LatestRateResponse } from 'pages/api/finance/forex';
import { useQuery } from 'react-query';

import type { CurrencyCode } from '@/finance/interfaces';

export const useExchangeRate = (
  baseCurrency: CurrencyCode | undefined,
  compareCurrency: CurrencyCode | undefined
) => {
  return useQuery(
    ['currencyExchangeRate', baseCurrency, compareCurrency],
    async () => {
      try {
        if (
          !baseCurrency ||
          !compareCurrency ||
          baseCurrency === compareCurrency
        ) {
          return undefined;
        }

        const result: LatestRateResponse = await fetch(
          `/api/finance/forex?base=${baseCurrency}&symbols=${compareCurrency}`
        ).then((res) => res.json());

        if (!result.success) throw new Error(result.error);

        return result.rates?.[compareCurrency];
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    {
      staleTime: 60 * 60e3,
      // When this is false, we would return undefined anyway
      enabled: Boolean(compareCurrency && baseCurrency !== compareCurrency),
    }
  );
};
