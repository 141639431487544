import { useEffect, useState } from 'react';
import { P, match } from 'ts-pattern';

import type { V2BookingStatus } from '@/api/clients/ohip/models';
import { useBookingPaymentStatus } from '@/booking-payment';

import type { BookingRequest } from '../interfaces';
import { useBookingSummary } from './use-booking-summary.hook';

const defaultPollInterval = 20e3;
const fastPollInterval = 2e3;

export const usePollBooking = (request: BookingRequest) => {
  const [isBookingNotFound, setIsBookingNotFound] = useState(false);
  const [bookingStatus, setBookingStatus] = useState<V2BookingStatus>('NONE');
  const [bookingCostConfirmed, setBookingCostConfirmed] = useState(false);

  const [paymentStatus] = useBookingPaymentStatus(request.bookingId);

  // We want a faster refresh rate if we're waiting for the payment to be confirmed
  const refetchInterval = match({
    isBookingNotFound,
    paymentStatus,
    bookingStatus,
    bookingCostConfirmed,
  })
    .with(
      // If the booking is confirmed, cancelled, has failed during payment or has come back as 404, disable polling
      P.union(
        { bookingStatus: 'CONFIRMED' },
        { bookingStatus: 'CANCELLED_BY_USER' },
        { bookingStatus: 'PAYMENT_ERROR' },
        { isBookingNotFound: true }
      ),
      () => false as const
    )
    // Fast poll when submitting a payment, or waiting for cancellation status change
    .with({ paymentStatus: 'submitting' }, () => fastPollInterval)
    .with({ bookingStatus: 'CANCEL_RECEIVED' }, () => fastPollInterval)
    // Poll slightly faster if the cost is not confirmed yet
    .with({ bookingCostConfirmed: false }, () => 10e3)
    .otherwise(() => defaultPollInterval);

  const { data: booking, error } = useBookingSummary(request, {
    refetchInterval,
  });

  // We're using effects here so that the poll interval is applied on the _next_ load
  useEffect(() => {
    if (typeof booking === 'undefined') {
      return;
    }

    setBookingCostConfirmed(booking.costConfirmed);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [booking?.costConfirmed]);

  useEffect(() => {
    setIsBookingNotFound(error?.isBookingNotFound ?? false);
  }, [error]);

  useEffect(() => {
    setBookingStatus(booking?.status ?? 'NONE');
  }, [booking?.status]);

  return { booking, error };
};
