import { css } from '@emotion/react';
import React from 'react';

import { getFullLocalisedDateString } from '@/common/utils/date';
import { DataTable } from '@/core/components/layout';
import Card from '@/core/components/layout/Card.component';
import DataTableRow from '@/core/components/layout/DataTableRow.component';
import { useLocale, useTranslation } from '@/i18n';
import { Spacer } from '@/ui/spacing';
import { useTheme } from '@/ui/theme';
import { Heading } from '@/ui/typography';

interface SearchSummaryMobileProps {
  hotelName: string;
  from: string;
  to: string;
}

export const SearchSummaryMobile = (props: SearchSummaryMobileProps) => {
  const { t } = useTranslation('bookingSummary');
  const locale = useLocale();
  const {
    componentProperties: { search },
  } = useTheme();

  return (
    <Card
      css={css({
        ['@media all and (max-width: 730px)']:
          search?.collapsibleForm?.searchSummary?.mobile,
      })}
    >
      <Heading
        style="heading5"
        as="h5"
        css={css({
          paddingBottom: '16px',
          ...search?.collapsibleForm?.searchSummary?.mobile?.title,
        })}
      >
        {t('title')}
      </Heading>
      <DataTable>
        <DataTableRow
          testId="hotel-name"
          label={t('labels.where')}
          value={props.hotelName}
        />
        <DataTableRow
          testId="check-in-date"
          label={t('labels.checkInDate')}
          value={getFullLocalisedDateString(props.from, locale)}
        />
        <DataTableRow
          testId="check-out-date"
          label={t('labels.checkOutDate')}
          value={getFullLocalisedDateString(props.to, locale)}
        />
      </DataTable>
    </Card>
  );
};
