import { css } from '@emotion/react';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';

import { useKeyPress } from '@/common/hooks';

import { useOuterClick } from '../utils/hooks';

const hiddenStyle = {
  opacity: 0,
  translateY: 20,
  scale: 0.9,
  // boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0.1)',
};
const visibleStyle = {
  opacity: 1,
  translateY: 0,
  scale: 1.0,
  // boxShadow: '0px 10px 20px 0px rgba(0, 0, 0, 0.1)',
};

interface TooltipModalProps {
  link: React.ReactNode;
  children: React.ReactNode | React.ReactNode[];
  isOpen: boolean;
  onRequestClose: () => void;
}

export const TooltipModal = (props: TooltipModalProps) => {
  // Close on escape
  useKeyPress((event) => event.key === 'Escape' && props.onRequestClose());

  // Close when clicking outside
  const ref = useOuterClick(props.onRequestClose);

  return (
    <div
      css={css`
        position: relative;
      `}
      ref={ref}
    >
      <div>{props.link}</div>
      <AnimatePresence>
        {props.isOpen ? (
          <motion.div
            css={css`
              position: absolute;
              bottom: 40px;
              /* left: 10px; */
            `}
            initial={hiddenStyle}
            animate={visibleStyle}
            exit={hiddenStyle}
            transition={{ type: 'spring', stiffness: 400, damping: 30 }}
          >
            <div
              css={css`
                background: white;
                padding: 26px;
                box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
                border-radius: 5px;
              `}
            >
              {props.children}
            </div>
            <div
              css={css`
                width: 0;
                height: 0;
                border-left: 15px solid transparent;
                border-right: 15px solid transparent;

                border-top: 15px solid #fff;
                position: relative;
                left: 15px;
              `}
            ></div>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </div>
  );
};

export default TooltipModal;
