import { getActiveSpan } from '@opentelemetry/api/build/src/trace/context-utils';
import * as Sentry from '@sentry/react';

import { defaultLogger } from '.';

export const captureException = (error: Error) => {
  Sentry.captureException(error);
  defaultLogger.error(error);
  getActiveSpan()?.recordException(error);
};
