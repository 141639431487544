import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { selectHotelConfigurationByCode } from '@/brand';
import { useActiveBrandConfig } from '@/brand/common/hooks';
import DateRangePickerField from '@/core/components/controls/DateRangePickerField.component';
import { SingleDatePickerField } from '@/core/components/controls/SingleDatePickerField.component';

import type { SearchFormFields } from '../validators/search-form.validator';

export const DatePickerField = () => {
  const { dayUse } = useActiveBrandConfig();

  const { watch } =
    useFormContext<
      Pick<SearchFormFields, 'rateCode' | 'hotelCode' | 'from' | 'to'>
    >();

  const [hotelCode, rateCode] = watch(['hotelCode', 'rateCode', 'from']);

  const brandConfig = useActiveBrandConfig();
  const hotelConfig = useMemo(
    () => selectHotelConfigurationByCode(brandConfig, hotelCode),
    [hotelCode, brandConfig]
  );

  const isDayUseSelected = Boolean(
    dayUse?.supported && rateCode === dayUse.rateCode
  );

  const openingDate = hotelConfig?.openingDate;

  if (isDayUseSelected)
    return <SingleDatePickerField allowSelectionFromDate={openingDate} />;

  return <DateRangePickerField allowSelectionFromDate={openingDate} />;
};
