/* eslint-disable @typescript-eslint/no-unsafe-member-access */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useCallback, useEffect, useState } from 'react';

import { COOKIE_CONSENT_ALLOWED_DOMAINS } from '../allowed-domains.const';
import {
  CookieConsentStatus,
  CookieConsentStatusContext,
  cookieConsentStatusInitialState,
} from '../consent-status';

export function ConsentManagerScript(props: { id: string }) {
  return (
    // eslint-disable-next-line @next/next/no-sync-scripts
    <script
      type="text/javascript"
      data-cmp-ab="1"
      src={`https://cdn.consentmanager.net/delivery/autoblocking/${props.id}.js`}
      data-cmp-host="b.delivery.consentmanager.net"
      data-cmp-cdn="cdn.consentmanager.net"
      data-cmp-codesrc="1"
    ></script>
  );
}

export function ConsentManagerButton(props: { children: React.ReactNode }) {
  return (
    <a
      href="#cmpscreen"
      onClick={() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        __cmp('showScreen');
      }}
    >
      {props.children}
    </a>
  );
}

export function ConsentManagerSideEffects(props: { id: string }) {
  useEffect(() => {
    if (
      !window.cmp_block_ignoredomains ||
      !window.cmp_block_ignoredomains.length
    ) {
      window.cmp_block_ignoredomains =
        COOKIE_CONSENT_ALLOWED_DOMAINS as string[];
    }
  }, [props.id]);
  return null;
}

function determineStatus(): CookieConsentStatus {
  // This is functionally different to an optional chain.
  // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
  if (typeof window === 'undefined' || typeof window.__cmp !== 'function') {
    return cookieConsentStatusInitialState;
  }

  const data = window.__cmp('getCMPData');

  // More info:
  // https://ennismore.atlassian.net/wiki/spaces/BP/pages/3820257284/Cookie+Consent+Status#Booking-Engine-UI-Usage
  return {
    functional: Boolean(data?.purposeConsents?.c51),
    necessary: Boolean(data?.purposeConsents?.c51),
    targeting: Boolean(data?.purposeConsents?.c52),
    performance: Boolean(data?.purposeConsents?.c54),
  };
}

export function ConsentManagerConsentStatusProvider(props: {
  children: React.ReactNode;
}) {
  const [state, setState] = useState<CookieConsentStatus>(() =>
    determineStatus()
  );

  const handler = useCallback(() => setState(determineStatus()), [setState]);

  useEffect(() => {
    if (typeof window === 'undefined' || typeof window.__cmp !== 'function') {
      return;
    }

    window.__cmp('addEventListener', ['consent', handler, false]);

    return () =>
      void window?.__cmp?.('removeEventListener', ['consent', handler, false]);
  }, [setState, handler]);

  return (
    <CookieConsentStatusContext.Provider value={state}>
      {props.children}
    </CookieConsentStatusContext.Provider>
  );
}
