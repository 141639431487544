import {
  V1CreateBookingRequest,
  V1GetBookingRequest,
} from '@ennismore/booking-api-client';
import { V1CreateBookingResponse } from '@ennismore/ows-api-client';

import { OWSBookingAPIBaseService } from '@/api/clients/ows';
import type { IBookingAddOnInstance } from '@/booking-add-ons/models';
import { captureException } from '@/logging';

import { CreateBookingError } from '../errors';
import { BookingError } from '../errors/booking.error';
import { BookingModel } from '../models';
import { BookingServiceClient } from './common';

export class OWSBookingServiceClient
  extends OWSBookingAPIBaseService
  implements BookingServiceClient
{
  async getBooking(args: V1GetBookingRequest) {
    try {
      const { data } = await this.client.bookingServiceBookingSummary(
        args.bookingId,
        this.options
      );

      return BookingModel.parse(data);
    } catch (e) {
      throw new BookingError(this.parseError(e));
    }
  }

  async createBooking(
    args: V1CreateBookingRequest
  ): Promise<V1CreateBookingResponse> {
    try {
      const { data } = await this.client.bookingServiceCreateBooking({
        from: args.rooms[0].checkInDate,
        to: args.rooms[0].checkOutDate,
        hotelReferenceId: args.hotelReferenceId,
        rateCode: args.rooms[0].rateCode,
        rooms: args.rooms.map((room) => ({
          roomCode: room.roomCode,
          guest: room.guests,
          twin: false, // TODO: Clean this
        })),
      });

      return data;
    } catch (e) {
      throw new CreateBookingError(this.parseError(e));
    }
  }
  /**
   * Add-ons are not supported by OWS hotels.
   */
  async getAvailableAddOns() {
    return [] as IBookingAddOnInstance[];
  }
  async updateBookingAddOns() {
    captureException(new Error('OWS hotel attempting to update add-ons.'));
    return { status: '' };
  }
}
