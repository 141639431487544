import { useHotel } from '@/hotel';

/**
 * Determines whether to display city tax override.
 */
export const useDisplayCityTaxOverride = (hotelReferenceId: string) => {
  const hotel = useHotel(hotelReferenceId);

  if (!hotel) return false;

  const { cityTaxSummary } = hotel;

  return Boolean(cityTaxSummary);
};
