import { motion } from 'framer-motion';
import React from 'react';

interface RadioIconProps {
  isSelected: boolean;
}

export const RadioIcon = ({ isSelected }: RadioIconProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="7.5" stroke="currentColor" />
      <motion.circle
        cx="8"
        cy="8"
        r="4"
        fill="currentColor"
        animate={{ scale: isSelected ? 1 : 0 }}
      />
    </svg>
  );
};

export default RadioIcon;
