import { css } from '@emotion/react';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { ScrollableSidebarContainer } from '.';

interface ContentWithSidebarProps {
  content: React.ReactNode;
  sidebar?: React.ReactNode;
}

const sidebarBreakpoint = 1100;

export const ContentWithSidebar = observer((props: ContentWithSidebarProps) => {
  return (
    <div
      css={css`
        display: flex;

        @media all and (max-width: ${sidebarBreakpoint}px) {
          display: block;
        }
      `}
    >
      <div
        css={css`
          flex: 1;
          /* margin-right: 20px; */
          @media all and (max-width: ${sidebarBreakpoint}px) {
            margin-right: 0px;
          }
        `}
        id="maincontent"
      >
        {props.content}
      </div>
      {props.sidebar && (
        <ScrollableSidebarContainer breakpoint={sidebarBreakpoint}>
          {props.sidebar}
        </ScrollableSidebarContainer>
      )}
    </div>
  );
});
