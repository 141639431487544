import { captureException, defaultLogger } from '@/logging';

import { APIError } from '../api-error.interface';

/**
 * Map common, network-related errors to the relative error code within the Ennismore domain.
 */
export const mapNetworkError = async (error: any): Promise<APIError> => {
  // TODO: This should be an enum from the API then extended
  const localNetworkError = { code: 'LOCAL_NETWORK_ERROR_01' } as const;

  if (error.message === 'Network request failed') {
    return localNetworkError;
  }

  if (error.status === 401) {
    return { code: 'LOCAL_UNAUTHORIZED' };
  }

  // If json is not a function, it's not a standard portable fetch error
  if (typeof error.json !== 'function') {
    // If it's an actual error, send to Sentry and log.
    if (error instanceof Error) {
      captureException(error);
      defaultLogger.error(error);
    }

    // Handle by returning the generic local network error.
    return localNetworkError;
  }

  // Pretty confident by this point that it's a portable-fetch error.
  return await error.json();
};
