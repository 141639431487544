import { css } from '@emotion/react';
import React from 'react';

interface FullScreenLayoutProps {
  children: React.ReactNode;
}

/**
 * Expand to fit the full height of the viewport, less the height of the sticky Header (need to centralise that).
 * This is usually to keep the footer below the fold for shorter pages.
 * @param props children
 */
export const FullScreenLayout = (props: FullScreenLayoutProps) => {
  return (
    <div
      css={css`
        min-height: calc(100vh - 61px);
        padding-bottom: 42px;
      `}
    >
      {props.children}
    </div>
  );
};
