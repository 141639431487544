import { css } from '@emotion/react';
import dayjs from 'dayjs';
import React from 'react';

import type { IFinancialAmountInstance } from '@/finance';
import { LoadingIndicator } from '@/ui/controls';
import { GreaterThanIcon, LessThanIcon } from '@/ui/icons';
import { Spacer } from '@/ui/spacing';
import { useTheme } from '@/ui/theme';
import { BodySmall } from '@/ui/typography';

import WeekCalendarDay from './WeekCalendarDay.component';

interface WeekCalendarProps {
  month: string;
  selectionStartDate?: string;
  selectionEndDate?: string;
  onDateClicked: (date: string) => void;
  dates: {
    date: string;
    price?: IFinancialAmountInstance;
    isAvailable: boolean;
    isLoaded: boolean;
  }[];
  isLoading: boolean;
  onIncrementWeeklyView: () => void;
  onDecrementWeeklyView: () => void;
}

export const WeekCalendar = (props: WeekCalendarProps) => {
  const { colors } = useTheme();

  const selectionStartDate = dayjs(props.selectionStartDate);
  const selectionEndDate = dayjs(props.selectionEndDate);

  const style = css`
    display: flex;
    justify-content: space-between;

    .left,
    .right {
      display: flex;
      background: ${colors.primary200};
      border: none;
      padding: 9px;
      align-items: center;
      height: 60px;
      box-sizing: border-box;
      width: 26px;

      &.focus-visible {
        outline: 1px solid ${colors.secondary300};
      }
    }
  `;
  return (
    <div aria-label="Availability calendar">
      <BodySmall color="secondary300">{props.month}</BodySmall>
      <Spacer s="xxs" />
      {props.isLoading ? (
        <div style={{ position: 'relative', height: 60 }}>
          <LoadingIndicator style="dark" width={30} height={60} />
        </div>
      ) : (
        <div css={style}>
          <button
            className="left"
            role="button"
            onClick={props.onDecrementWeeklyView}
            aria-label="Go back one week"
          >
            <LessThanIcon />
          </button>
          {props.dates.map((originalDate) => {
            const date = dayjs(originalDate.date);
            const finalNightOfStay = selectionEndDate.subtract(1, 'day');
            const isStart = props.selectionStartDate
              ? date.isSame(selectionStartDate, 'day')
              : undefined;
            const isFinalNight = props.selectionEndDate
              ? date.isSame(finalNightOfStay, 'day')
              : undefined;
            const isCheckOut = props.selectionEndDate
              ? date.isSame(selectionEndDate, 'day')
              : undefined;
            const isSelected =
              date.isAfter(selectionStartDate, 'day') &&
              date.isBefore(finalNightOfStay, 'day');

            return (
              <WeekCalendarDay
                key={originalDate.date}
                price={originalDate.price}
                date={date.format('ddd D')}
                rawDate={date.toDate()}
                isStart={isStart}
                isFinalNight={isFinalNight}
                isCheckOut={isCheckOut}
                isLoaded={originalDate.isLoaded}
                isAvailable={originalDate.isAvailable}
                isSelected={isSelected}
                isDisabled={
                  date.isBefore(dayjs(), 'date') || !originalDate.isAvailable
                }
                onClick={() => props.onDateClicked(originalDate.date)}
              />
            );
          })}
          <button
            className="right"
            role="button"
            onClick={props.onIncrementWeeklyView}
            aria-label="Move forward one week"
          >
            <GreaterThanIcon />
          </button>
        </div>
      )}
    </div>
  );
};

export default WeekCalendar;
