import { z } from 'zod';

export type IOccupancy = z.infer<typeof Occupancy>;
export const Occupancy = z
  .object({
    adults: z.number().positive(),
    children: z.number().nonnegative(),
    childrenAges: z.array(z.number().nonnegative().optional()),
    accessible: z.boolean().optional(),
  })
  .refine((occupancy) => {
    if (occupancy.childrenAges.length) {
      return (
        occupancy.childrenAges.filter(
          (childrenAge) => childrenAge !== undefined
        ).length === occupancy.children
      );
    }
    return true;
  }, 'Incorrect children age specification.');
