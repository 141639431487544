import { z } from 'zod';

import { ImageModel } from '@/common/models';

export type IHotelBanner = typeof HotelBanner;
export type IHotelBannerInstance = z.infer<IHotelBanner>;

export const HotelBanner = z
  .object({
    id: z.string(),
    description: z.string(),
    image: ImageModel.optional(),
  })
  .transform((self) => ({
    ...self,
    get imageUrl(): string | undefined {
      return self.image?.source || '/brands/hoxton/images/banner.png';
    },
  }));
