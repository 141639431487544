import React from 'react';
import { useFormContext } from 'react-hook-form';

import { useTranslation } from '@/i18n';
import { Input, InputProps } from '@/ui/form';

import type { SearchFormFields } from '../validators/search-form.validator';

const RateCodeTextInput = (props: InputProps) => {
  const { t } = useTranslation('search');
  const { register } = useFormContext<Pick<SearchFormFields, 'rateCode'>>();

  return (
    <Input
      type="text"
      label={t('form.rateCodeFieldLabel')}
      enterKeyHint="search"
      {...props}
      {...register('rateCode')}
    />
  );
};

export default RateCodeTextInput;
