import { css } from '@emotion/react';
import Image from 'next/legacy/image';
import React from 'react';

import { BookingRequest } from '@/booking/interfaces';
import { SidebarSection } from '@/core/components/layout';
import applePayMarkSvg from '@/ui/resources/svg/apple-pay-mark.svg';
import { Spacer } from '@/ui/spacing';
import { Heading, Text } from '@/ui/typography';

import { CheckoutHandoff } from './CheckoutHandoff.component';

interface CheckoutHandoffSectionProps extends BookingRequest {}

export const CheckoutHandoffSection: React.FC<CheckoutHandoffSectionProps> = (
  props
) => {
  return (
    <SidebarSection>
      <div css={sectionContentStyle}>
        <CheckoutHandoff {...props} />
      </div>
      <Heading as="h6" style="heading6">
        On the move?
      </Heading>
      <Text style="bodySmall">
        Scan this code to continue checkout on your phone.
      </Text>
      <div>
        <Spacer s="m" />
        <Image
          src={applePayMarkSvg}
          alt="Supports Apple Pay"
          width={50}
          height={33}
        />
      </div>
    </SidebarSection>
  );
};

const sectionContentStyle = css`
  display: inline;
  float: right;
  margin: 0 0 0 20px;
`;
