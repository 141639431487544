import { css } from '@emotion/react';
import React from 'react';

import iconSvg from '@/hox-core/resources/icons/info.svg';
import { useTheme } from '@/ui/theme';

interface InfoButtonProps {
  onClick: () => void;
}

export const InfoButton = (props: InfoButtonProps) => {
  const {
    componentProperties: { staySummary },
  } = useTheme();
  return (
    <button
      css={css`
        background: transparent;
        border: none;
        cursor: pointer;

        img {
          position: relative;
          top: 3px;
          ${staySummary?.dropdown?.infoIcon?.filter &&
          `filter: ${staySummary?.dropdown?.infoIcon?.filter};`}
        }
      `}
      onClick={props.onClick}
    >
      <img src={iconSvg.src} alt="Click to show more information" />
    </button>
  );
};

export default InfoButton;
