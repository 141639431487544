import React from 'react';

import { CookieConsentProviderConfig } from './config';
import { ConsentManagerButton } from './providers/ConsentManager.component';
import { OneTrustCookieSettingsButton } from './providers/OneTrust.component';

export function CookieConsentButton({
  children,
  config,
}: {
  children: React.ReactNode;
  config: CookieConsentProviderConfig;
}) {
  switch (config.provider) {
    case 'onetrust':
      return (
        <OneTrustCookieSettingsButton>{children}</OneTrustCookieSettingsButton>
      );
    case 'consent-manager':
      return <ConsentManagerButton>{children}</ConsentManagerButton>;
  }
}
