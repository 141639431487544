import { StateUsaState } from '@/em-api-client-typescript-fetch';

export type StateUsaStateWithoutNotSet = Exclude<
  StateUsaState,
  StateUsaState.USASTATENOTSET
>;

export const US_STATE_CODES = Object.values(StateUsaState).filter(
  (code) => code !== StateUsaState.USASTATENOTSET
) as StateUsaStateWithoutNotSet[];
