import { useTranslation } from '@/i18n';

import { FLEXI_TIME_RANGES } from './flexy-time-ranges.const';

export const useFlexyTimeOptions = () => {
  const { t } = useTranslation('additionalInformation');

  return Object.keys(FLEXI_TIME_RANGES).map((key) => ({
    value: key,
    label: t('flexyTime.form.labels.optionFormat', FLEXI_TIME_RANGES[key]),
  }));
};
