import { motion } from 'framer-motion';
import React from 'react';

interface AccordionDropdownIndicatorProps {
  isFlipped?: boolean;
  width?: number;
  height?: number;
}

export const AccordionDropdownIndicator = ({
  isFlipped,
  width,
  height,
}: AccordionDropdownIndicatorProps) => {
  return (
    <motion.svg
      width={width ?? 16}
      height={height ?? 9}
      // Set the origin to stop framer motion overriding
      // https://github.com/framer/motion/issues/255
      style={{ originX: 0.5, originY: 0.5 }}
      transition={{
        type: 'tween',
        duration: 0.1,
      }}
      animate={{
        rotate: isFlipped ? 180 : 0,
      }}
      viewBox="0 0 16 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15 8L8 0.999999L1 8" stroke="currentColor" />
    </motion.svg>
  );
};

export default AccordionDropdownIndicator;
