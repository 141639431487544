import { addBreadcrumb } from '@sentry/react';

export interface CommonBookingTrackingArgs {
  hotelCode: string;
  hotelName: string;
  hotelCity: string;
  rateCode: string;
  numAdults: number;
  numChildren: number;
  checkInDate: string;
  checkOutDate: string;
  nightCount: number;
  roomCount: number;
}

export interface CommonRoomTrackingArgs {
  price: string;
  roomName: string;
  roomCode: string;
  rateName: string;
}

export interface TrackBedroomBookingArgs extends CommonBookingTrackingArgs {
  chain: string;
  value: string;
  currency: string;
  tax: string;
  operaId: string;

  rooms: CommonRoomTrackingArgs[];
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const trackExpiredEvent = (url: string) => {
  // TBD
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const trackBookingEvent = (action: string, label: string) => {
  // TBD
};

export const trackModifySearchFormOpen = () =>
  trackBookingEvent('modify search', 'open');
export const trackNoAvailabilityEvent = (label: string) =>
  trackBookingEvent('no availability', label);
export const trackBookAnotherNightEvent = (label: string) =>
  trackBookingEvent('book another night', label);
export const trackBookingPaymentEvent = (label: string) =>
  trackBookingEvent('payment', label);
export const trackBookingPaymentErrorEvent = (label: string) =>
  trackBookingEvent('payment error', label);

export const trackMobXAction = (action: string, path?: string, args?: any) =>
  addBreadcrumb({
    category: 'mobx-action',
    data: {
      action,
      path,
      args,
    },
  });
