import { z } from 'zod';

import { YesNo } from '@/common/models';
import { RateCodeModel } from '@/finance/models/rate-code.model';

export type IBookableRateCode = typeof BookableRateCodeModel;
export type IBookableRateCodeInstance = z.infer<IBookableRateCode>;

export const BookableRateCodeModel = z.object({
  bookable: YesNo,
  rateCode: RateCodeModel,
});
