import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import React from 'react';

import { useActiveBrandConfig } from '@/brand';

import { ThemeProvider } from './ThemeProvider.component';
import { AvailableThemeKeys } from './available-theme-keys.type';
import { ThemeSwitcherContext } from './theme-switcher.context';

interface ThemeSwitcherProviderProps {
  children: React.ReactNode;
}

export const ThemeSwitcherProvider = observer(
  (props: ThemeSwitcherProviderProps) => {
    const defaultThemeKey = useActiveBrandConfig().themeKey;
    const [activeThemeKey, setActiveThemeKey] =
      useState<AvailableThemeKeys>(defaultThemeKey);
    const resetThemeKey = () => setActiveThemeKey(defaultThemeKey);

    return (
      <ThemeSwitcherContext.Provider
        value={{
          setActiveThemeKey,
          resetThemeKey,
          activeThemeKey,
          defaultThemeKey,
        }}
      >
        <ThemeProvider themeKey={activeThemeKey}>
          {props.children}
        </ThemeProvider>
      </ThemeSwitcherContext.Provider>
    );
  }
);

export default ThemeSwitcherProvider;
