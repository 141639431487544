import { Logger } from 'pino';
import React from 'react';

export const LoggingContext = React.createContext<Logger | null>(null);

export const LoggingProvider: React.FC<{
  logger: Logger;
  children: React.ReactNode;
}> = (props) => {
  return (
    <LoggingContext.Provider value={props.logger}>
      {props.children}
    </LoggingContext.Provider>
  );
};
