import { useContext } from 'react';

import { defaultLogger } from './log';
import { LoggingContext } from './logging-context';

/**
 * Returns either a child logger from the current context, or the default logger.
 */
export const useLogger = () => {
  const logger = useLoggerFromContext();

  if (!logger) {
    return defaultLogger;
  }

  return logger;
};

const useLoggerFromContext = () => useContext(LoggingContext);
