import Router from 'next/router';

import { AvailabilitySearchUrlQuery } from '../dtos/availability-search-url-query.dto';
import { availabilityQueryToURLParamString } from './query-url';

export const navigateToAvailabilityResultsPage = (
  query: AvailabilitySearchUrlQuery
) => {
  const queryString = availabilityQueryToURLParamString(query);
  return Router.push(`/availability?${queryString}`, undefined, {
    shallow: false,
  });
};

export const navigateToAvailabilitySearchPage = () =>
  Router.push('/availability', undefined, { shallow: false });
