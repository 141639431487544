import { z } from 'zod';

import { AvailabilitySearchQuery } from '@/availability/models/availability-search-query.model';
import { BookingDayTimePart } from '@/em-api-client-typescript-fetch';

export type IAdditionalInfoFields = typeof AdditionalInfoFields;
export type IAdditionalInfoFieldsInstance = z.infer<IAdditionalInfoFields>;
export type IAdditionalInfoFieldsSnapshot = z.input<IAdditionalInfoFields>;

const AdditionalInfoFields = z.object({
  specialRequests: z.string().optional(),
  checkInTime: z.nativeEnum(BookingDayTimePart).optional(),
  checkOutTime: z.nativeEnum(BookingDayTimePart).optional(),
  childBed: z.boolean().default(false),
  childCot: z.boolean().default(false),
  dog: z.boolean().default(false),
  accessRequirements: z.boolean().default(false),
});

export type IBookingConfiguration = typeof BookingConfigurationModel;
export type BookingConfiguration = z.infer<IBookingConfiguration>;
export type IBookingConfigurationSnapshot = z.input<IBookingConfiguration>;

export const BookingConfigurationModel = z.object({
  additionalInfoFields: AdditionalInfoFields.optional(),
  originalSearchQuery: AvailabilitySearchQuery.optional(),
  isPurchaseTrackedInGTM: z.boolean().default(false),
  isAdditionalInfoStepCompleted: z.boolean().default(false),
});
