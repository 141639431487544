import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { useEnnismoreApiClientConfig } from '@/api';
import { useLocale } from '@/i18n';

import { IHotelModelInstance } from '../models';
import { ConfigServiceClient } from '../services/hotel-config.service';

type Response = IHotelModelInstance | undefined;

// We want to fetch more regularly if we're in development, for previewing content
const staleTime = process.env.NODE_ENV === 'development' ? 10e3 : 120e3;

const getHotelCacheKey = (
  hotelReferenceId: string | undefined,
  locale: string
) => {
  return ['hotel', hotelReferenceId, locale];
};

export const useHotelQuery = (
  hotelReferenceId: string | undefined,
  options?: UseQueryOptions<Response>
) => {
  const clientConfig = useEnnismoreApiClientConfig();
  const locale = useLocale();

  return useQuery<Response>(
    getHotelCacheKey(hotelReferenceId, locale.baseName),
    async () => {
      if (!hotelReferenceId) return undefined;

      const client = new ConfigServiceClient(clientConfig);

      return await client.getHotelConfig({
        hotelReferenceId,
        locale: locale.baseName,
      });
    },
    {
      suspense: false,
      staleTime,
      retry: 2,
      ...options,
    }
  );
};

export const useHotel = (
  hotelReferenceId: string | undefined,
  options?: UseQueryOptions<Response>
) => {
  const response = useHotelQuery(hotelReferenceId, options);
  return response.data;
};
