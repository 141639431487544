import {
  FinancialAmountModel,
  IFinancialAmountInstance,
} from '../models/financial-amount.model';

export const sumFinancialAmount = (
  current: IFinancialAmountInstance,
  addValue: IFinancialAmountInstance
) => {
  return FinancialAmountModel.parse({
    ...current,
    value: current.value + addValue.value,
  });
};
