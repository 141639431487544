import React from 'react';

import { useTranslation } from '@/i18n';

import { AvailabilitySearchErrorCard } from './AvailabilitySearchErrorCard.component';

interface NoAccessibleRoomsAvailableCardProps {
  onModifySearchRequested: () => void;
}

export const NoAccessibleRoomsAvailableCard = (
  props: NoAccessibleRoomsAvailableCardProps
) => {
  const { t } = useTranslation('errors');

  return (
    <AvailabilitySearchErrorCard
      title={t('noAccessibleRoomsAvailableTitle')}
      subtitle={t('noAccessibleRoomsAvailableSubtitle')}
      description={t('noAccessibleRoomsAvailableBody')}
      onModifySearchRequested={props.onModifySearchRequested}
    />
  );
};
