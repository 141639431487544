import { V1Guest } from '@ennismore/ows-api-client';

import { DEFAULT_TRANSLATION_STRINGS } from '@/i18n';

export type BookingGuestWithoutUnspecified = (typeof V1Guest)[Exclude<
  keyof typeof V1Guest,
  'Unspecified'
>];

export type OccupancyConfiguration = {
  labelTranslationKey: RoomOccupancyTranslationKey;
  totalGuests: number;
  totalAdultCount: number;
  totalChildCount: number;
};

export type RoomOccupancyTranslationKey =
  keyof (typeof DEFAULT_TRANSLATION_STRINGS)['en-GB']['roomOccupancyTypes'];

/**
 * Configures the label and guest numbers for each occupancy type.
 */
export const ROOM_OCCUPANCY_TYPES: {
  [key in BookingGuestWithoutUnspecified]: OccupancyConfiguration;
} = {
  ONE_ADULT: {
    labelTranslationKey: 'oneAdult',
    totalAdultCount: 1,
    totalChildCount: 0,
    totalGuests: 1,
  },
  ONE_ADULT_ONE_CHILD: {
    labelTranslationKey: 'oneAdultOneChild',
    totalAdultCount: 1,
    totalChildCount: 1,
    totalGuests: 2,
  },
  ONE_ADULT_TWO_CHILD: {
    labelTranslationKey: 'oneAdultTwoChildren',
    totalAdultCount: 1,
    totalChildCount: 2,
    totalGuests: 3,
  },
  ONE_ADULT_THREE_CHILD: {
    labelTranslationKey: 'oneAdultThreeChildren',
    totalAdultCount: 1,
    totalChildCount: 3,
    totalGuests: 4,
  },
  ONE_ADULT_FOUR_CHILD: {
    labelTranslationKey: 'oneAdultFourChildren',
    totalAdultCount: 1,
    totalChildCount: 4,
    totalGuests: 5,
  },
  TWO_ADULT: {
    labelTranslationKey: 'twoAdults',
    totalAdultCount: 2,
    totalChildCount: 0,
    totalGuests: 2,
  },
  TWO_ADULT_ONE_CHILD: {
    labelTranslationKey: 'twoAdultsOneChild',
    totalAdultCount: 2,
    totalChildCount: 1,
    totalGuests: 3,
  },
  TWO_ADULT_TWO_CHILD: {
    labelTranslationKey: 'twoAdultsTwoChildren',
    totalAdultCount: 2,
    totalChildCount: 2,
    totalGuests: 4,
  },
  TWO_ADULT_THREE_CHILD: {
    labelTranslationKey: 'twoAdultsThreeChildren',
    totalAdultCount: 2,
    totalChildCount: 3,
    totalGuests: 5,
  },
  THREE_ADULT: {
    labelTranslationKey: 'threeAdults',
    totalAdultCount: 3,
    totalChildCount: 0,
    totalGuests: 3,
  },
  THREE_ADULT_ONE_CHILD: {
    labelTranslationKey: 'threeAdultsOneChild',
    totalAdultCount: 3,
    totalChildCount: 1,
    totalGuests: 4,
  },
  THREE_ADULT_TWO_CHILD: {
    labelTranslationKey: 'threeAdultsTwoChildren',
    totalAdultCount: 3,
    totalChildCount: 2,
    totalGuests: 5,
  },
  FOUR_ADULT: {
    labelTranslationKey: 'fourAdults',
    totalAdultCount: 4,
    totalChildCount: 0,
    totalGuests: 4,
  },
  FOUR_ADULT_ONE_CHILD: {
    labelTranslationKey: 'fourAdultsOneChild',
    totalAdultCount: 4,
    totalChildCount: 1,
    totalGuests: 5,
  },
  FIVE_ADULT: {
    labelTranslationKey: 'fiveAdults',
    totalAdultCount: 5,
    totalChildCount: 0,
    totalGuests: 5,
  },
};
