import { withDefaultProps } from 'react-propose';

import { Box } from '@/box';

/**
 * This is used where we have two fields side-by-side next to one another. For example, "First name" | "Last name".
 * On mobile, the component will collapse from horizontal to vertical, and fields will fill the container.
 */
export const FieldGroup = withDefaultProps(Box, {
  flexWrap: ['nowrap', 'wrap'],
  flexDirection: ['column', 'row'],
  columnGap: 20,
  display: 'flex',
});

/**
 * A box that fills it's flex container, used to wrap fields within a FieldGroup.
 */
export const FieldGroupItem = withDefaultProps(Box, { flex: 1 });
