import { useActiveBrandConfig } from '@/brand';
import { isTruthy } from '@/common/utils/array';
import { useHotel } from '@/hotel';
import {
  getBookingGuestFromCount,
  getOccupancyTypeByKey,
} from '@/hotel/helpers/occupancy-type.helpers';
import { useTranslation } from '@/i18n';

/**
 * Get hotel select field formatted options.
 * @returns Select-formatted options
 */
export const useRoomOccupancyOptions = (hotelReferenceId?: string) => {
  const { t: roomOccupancyTypeTranslation } =
    useTranslation('roomOccupancyTypes');
  const brandConfig = useActiveBrandConfig();

  const hotel = useHotel(hotelReferenceId);

  const roomOccupancyConfigurations =
    hotel?.operational.supportedRoomOccupancyConfigurations ??
    brandConfig.supportedRoomOccupancyConfigurations;

  const mappedRoomOccupancyConfigurations = roomOccupancyConfigurations
    .map(getBookingGuestFromCount)
    .filter(isTruthy);

  return mappedRoomOccupancyConfigurations.map((key) => {
    const type = getOccupancyTypeByKey(key.toString());

    return {
      label: roomOccupancyTypeTranslation(type.labelTranslationKey),
      value: key,
      disabled: false,
    };
  });
};

export default useRoomOccupancyOptions;
