import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { match } from 'ts-pattern';

import { trackCheckoutPageVisitEventInGTM } from '@/analytics/gtm';
import { useSentryScope } from '@/analytics/hooks/use-sentry-scope.hook';
import { BookingCheckoutEvents } from '@/booking-checkout/events';
import { useStandardBookingCheckoutNavigator } from '@/booking-checkout/hooks/use-standard-booking-checkout-navigator.hook';
import { useSyncHotelCurrency } from '@/currency-preview/state';
import { useAppEvents } from '@/events';
import { useHotel } from '@/hotel';
import { useSetHotelTheme } from '@/hotel/hooks/use-set-hotel-theme';
import { captureException, defaultLogger } from '@/logging';

import { formatBookingForGTM, formatRoomForGTM } from '../helpers/analytics';
import { CheckoutStep, useBookingUrls } from '../helpers/url';
import { useBooking } from './use-booking.hook';
import { usePollBooking } from './use-poll-booking.hook';
import {
  BookingCheckoutStep,
  useRedirectToCorrectStep,
} from './use-redirect-to-correct-step.hook';

export const useCheckoutPageSetup = ({
  hotelSlug,
  bookingId,
  checkoutStep,
}: {
  bookingId: string;
  hotelSlug: string;
  checkoutStep: BookingCheckoutStep;
}) => {
  usePollBooking({ bookingId, hotelSlug });

  const navigator = useStandardBookingCheckoutNavigator({
    hotelSlug,
    bookingId,
  });

  // If the user is on the wrong wizard step for the booking's status, redirect to the right one.
  useRedirectToCorrectStep({
    hotelSlug,
    bookingId,
    currentStep: checkoutStep,
    navigator,
  });

  useSentryScope({
    hotelSlug,
    bookingId,
  });

  useSyncHotelCurrency(useHotel(hotelSlug)?.payments.currencyCode);

  const booking = useBooking({ bookingId, hotelSlug });
  const hotel = useHotel(hotelSlug);
  const events = useAppEvents<BookingCheckoutEvents>();

  const urls = useBookingUrls({ bookingId, hotelSlug });
  const router = useRouter();

  async function prefetch(step: CheckoutStep) {
    await router.prefetch(urls(step));

    return defaultLogger.info(`prefetched ${step}`);
  }

  // Prefetch the next step in the booking checkout flow
  useEffect(() => {
    match(checkoutStep)
      .with('additional-info', () =>
        Promise.all([prefetch('payment'), prefetch('one-click')])
      )
      .with('payment', () =>
        Promise.all([prefetch('pending'), prefetch('confirmation')])
      )
      .otherwise(() => {
        // no preloading needed
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!booking || !hotel) return;

    try {
      trackCheckoutPageVisitEventInGTM({
        booking: formatBookingForGTM({ booking, hotel }),
        productCheckout: booking?.roomStay.map(formatRoomForGTM),
      });

      switch (checkoutStep) {
        case 'additional-info':
          return events.emit('additionalInfoPageLoad', { booking, hotel });
        default:
          return;
      }
    } catch (e) {
      captureException(e);
    }

    // TODO: Clean this up!
  }, [booking?.id, hotel?.referenceId, events]);

  // Sets the current theme for this checkout if the hotel has a theme property set
  useSetHotelTheme(hotel?.theme?.key);
};
