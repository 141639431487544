import { css } from '@emotion/react';
import React from 'react';

import { useTheme } from '../theme';

type ListBoxProps = {
  selectedOption: string;
  ariaLabel: string;
  children: React.ReactNode;
  multiSelect?: boolean;
};

/**
 * To be used as a replacement for the <select> HTML element
 * when the options need to be listed outright on the page instead of stored within a dropdown.
 * Prefer using native <select> otherwise.
 */
export const ListBox = ({ multiSelect = false, ...rest }: ListBoxProps) => (
  <div
    role="listbox"
    aria-label={rest.ariaLabel}
    aria-multiselectable={multiSelect}
    aria-activedescendant={`option-${rest.selectedOption}`}
  >
    {rest.children}
  </div>
);

type OptionProps = {
  optionName: string;
  selected: boolean;
  children: React.ReactNode;
  onSelect: React.MouseEventHandler<HTMLButtonElement>;
  ariaLabel?: React.AriaAttributes['aria-label'];
};

const Option = React.forwardRef(
  (props: OptionProps, ref: React.Ref<HTMLButtonElement>) => {
    const { colors, keylines } = useTheme();

    const optionStyle = css`
      display: flex;
      justify-content: space-between;
      height: 45px;
      width: 100%;
      padding: 0px 20px;
      align-items: center;
      border-radius: 100px;

      + button {
        margin-top: 15px;
      }
      &:hover:not([aria-selected='true']),
      &:focus:not([aria-selected='true']) {
        background: ${colors.primary100};
      }
      &[aria-selected='true'] {
        background: ${colors.primary200};
        border: ${keylines?.border || '1px solid #3e3a37'};
      }
    `;
    return (
      <button
        ref={ref}
        id={`option-${props.optionName}`}
        type="button"
        role="option"
        onClick={props.onSelect}
        aria-label={props.ariaLabel}
        aria-selected={props.selected}
        css={optionStyle}
      >
        {props.children}
      </button>
    );
  }
);
Option.displayName = 'Option';

ListBox.Option = Option;
