import { CountryCountry, ProfileTitle } from '@/em-api-client-typescript-fetch';

import type { BrandConfigurationFactory } from '../common/interfaces/BrandConfigurationFactory';
import enGB from './locale/en-GB.json';

export const estelleBrandConfig: BrandConfigurationFactory = ({
  isProduction,
}) => ({
  chainCode: 'estelle',
  themeKey: 'estelle',
  name: 'Estelle Manor',
  defaultHotelCode: 'estelle.manor',
  hotels: [
    {
      code: 'estelle.manor',
      name: 'Estelle Manor',
      referenceId: 'estelle.manor',
      dialect: 'ows',
      openingDate: '2023-04-27',
    },
  ],
  homeUrl: 'https://estellemanor.com',
  domains: [
    'booking.estellemanor.local',
    'estellemanor.dev.env.ennismore.com',
    'estellemanor.staging.env.ennismore.com',
    'booking.estellemanor.com',
    'vercel:em-booking-ui-estellemanor-mt',
  ],
  hideUnavailableRoomRates: true,
  hideAccessibleRoomsCheckbox: true,
  maxRoomCountPerBooking: 3,
  supportedRoomOccupancyConfigurations: [{ adults: 1 }, { adults: 2 }],
  legal: {
    urls: {
      en: {
        privacyPolicy: 'https://estellemanor.com/privacy-policy/',
        cookiePolicy: 'https://estellemanor.com/cookies-policy/',
        termsOfUse: 'https://estellemanor.com/terms-of-use/',
        contact: 'mailto:reservations@estellemanor.com',
      },
    },
  },
  profileTitleOptions: [
    ProfileTitle.MRS,
    ProfileTitle.MISS,
    ProfileTitle.MR,
    ProfileTitle.MRMRS,
    ProfileTitle.MS,
    ProfileTitle.DR,
    ProfileTitle.DRMR,
    ProfileTitle.DRMRS,
    ProfileTitle.LORD,
    ProfileTitle.LADY,
    ProfileTitle.SHEIKH,
    ProfileTitle.SIR,
    ProfileTitle.CAPTAIN,
    ProfileTitle.PROFESSOR,
    ProfileTitle.MX,
  ],
  search: {
    disableBanners: false,
  },
  showRoomTotalInBreakdown: false,
  showTaxTotalInBreakdown: false,
  showInlineCalendar: false,
  highlightedCountryCodes: [CountryCountry.GB],
  marketingEmailSignUpAtCheckout: false,
  marketingConsentMode: 'opt-out',
  features: {
    bookingManagement: false,
  },
  analytics: {
    gtm: {
      containerId: isProduction ? 'GTM-5RJSVB7' : 'GTM-KX79G7K',
    },
    cookieConsent: {
      provider: 'onetrust',
      oneTrustId: '018e1360-6ab1-700e-a918-7f00e7e93486',
    },
  },
  translationOverrides: {
    'en-GB': enGB,
  },
  footer: {
    provider: {
      key: 'estelle-manor',
      name: 'Estelle Manor',
      url: 'https://estellemanor.com',
    },
  },
});
