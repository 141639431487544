import React, { useEffect } from 'react';
import { ComponentProps, createContext, useContext } from 'react';

import { pushToDataLayer } from '@/analytics/gtm';

import { ActiveExperiments, ExperimentKey } from './experiments';

const ExperimentsContext = createContext<ActiveExperiments | undefined>(
  undefined
);

export const ExperimentsProvider = (
  props: ComponentProps<typeof ExperimentsContext.Provider>
) => {
  // Again, quick and dirty. This emits an 'experiment' event with name + status for every experiment.
  // Not using the events system here as these flags are set before the GTM event listeners are setup.
  useEffect(() => {
    if (props.value) {
      for (const experimentName of Object.keys(props.value)) {
        pushToDataLayer({
          event: 'experiment',
          experimentName,
          experimentStatus: props.value[experimentName],
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(props.value)]);

  return (
    <ExperimentsContext.Provider value={props.value}>
      {props.children}
    </ExperimentsContext.Provider>
  );
};

/**
 * This is temporary - need something quick and dirty for the banner.
 * In future will use the feature flag system already in place (useFeatureSwitchStatus).
 */
export function useExperiments() {
  return useContext(ExperimentsContext);
}

export function useExperimentStatus(key: ExperimentKey) {
  return Boolean(useExperiments()?.[key]);
}
