export class MissingBrandConfigError extends Error {
  constructor(hostname: string) {
    super(`Unable to find a brand config matching hostname: [${hostname}]`);
  }
}

export class MissingHostnameError extends Error {
  constructor() {
    super(`Hostname missing from request. Unable to resolve brand.`);
  }
}
