import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { useTranslation } from '@/i18n';
import { Select } from '@/ui/form';

import type { SearchFormFields } from '../validators/search-form.validator';

export const HotelCodeSelectInput = ({ options }) => {
  const { t } = useTranslation('search');
  const { register, watch, trigger } =
    useFormContext<Pick<SearchFormFields, 'hotelCode' | 'from'>>();

  const hotelCode = watch('hotelCode');

  useEffect(() => {
    // when a different hotel gets picked, trigger validation on the check-in field
    // to address varying hotel opening dates
    trigger('from');
  }, [hotelCode, trigger]);

  return (
    <Select
      label={t('form.locationLabel')}
      options={options}
      value={hotelCode}
      isRequired
      hideError
      {...register('hotelCode')}
    />
  );
};
