import { useEffect, useRef } from 'react';

type Callback = (e: MouseEvent) => void;

/**
 * Detects clicks outside of the element referenced using the returned ref.
 * Useful for closing modals, etc.
 * @param callback Called when user clicks outside the referenced element
 * @returns
 */
export const useOuterClick = (callback: Callback) => {
  const callbackRef = useRef<any>(); // initialize mutable callback ref
  const innerRef = useRef<any>(); // returned to client, who sets the "border" element

  // update callback on each render, so second useEffect has most recent callback
  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
    function handleClick(e: MouseEvent) {
      if (
        innerRef.current &&
        callbackRef.current &&
        !innerRef.current.contains(e.target)
      )
        callbackRef.current(e);
    }
  }, []);

  return innerRef;
};
