import React, { useEffect } from 'react';
import { UseFieldArrayUpdate, useFormContext } from 'react-hook-form';

import { Select } from '@/ui/form';

import { useRoomOccupancyOptions } from '../hooks';
import type { SearchFormFields } from '../validators/search-form.validator';

const RoomOccupancySelectInput = ({
  label,
  options,
  name,
  updateValue,
  index,
}: {
  label: string;
  options: ReturnType<typeof useRoomOccupancyOptions>;
  name: `roomOccupancy.${number}.value`;
  updateValue: UseFieldArrayUpdate<
    Pick<SearchFormFields, 'roomCount' | 'hotelCode' | 'roomOccupancy'>,
    'roomOccupancy'
  >;
  index: number;
}) => {
  const {
    register,
    watch,
    formState: { errors },
    trigger,
  } = useFormContext<Pick<SearchFormFields, 'roomOccupancy'>>();

  const roomOccupancy = watch(name);

  useEffect(() => {
    if (
      roomOccupancy &&
      options.findIndex((opt) => opt.value === roomOccupancy) === -1
    ) {
      updateValue(index, {
        value: '',
        childrenAges: [],
        accessibleRooms: [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(options)]);

  return (
    <Select
      label={label}
      value={roomOccupancy}
      error={errors.roomOccupancy?.[index]?.value?.message}
      isRequired
      options={options}
      {...register(name, {
        onBlur: () => trigger(`roomOccupancy.${index}.value`),
        onChange: () => trigger(`roomOccupancy.${index}.value`),
      })}
    />
  );
};

export default RoomOccupancySelectInput;
