import { useAvailabilityURLQuery } from '@/availability';
import {
  appendSearchQueryParamsToDisloyaltyUrl,
  getDisloyaltyBannerConfig,
} from '@/availability/components/banners/disloyalty-banner.helpers';

import { useDisloyaltyDummyRateFlag } from './useDisloyaltyDummyRateFlag.hook';

export const useDisloyaltySelectorOptionUrl = (hotelReferenceId: string) => {
  const query = useAvailabilityURLQuery();
  const disloyaltyBannerConfig = getDisloyaltyBannerConfig(hotelReferenceId);

  const showDummyRate = useDisloyaltyDummyRateFlag({ hotelReferenceId });
  const link = showDummyRate && disloyaltyBannerConfig?.link;

  return link
    ? appendSearchQueryParamsToDisloyaltyUrl(
        link.replace(/utm_content=banner(?=&)/, 'utm_content=banner_embedded'),
        query
      )
    : false;
};
