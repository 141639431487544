import { css } from '@emotion/react';
import React from 'react';

import { useTheme } from '@/ui/theme';
import { BodySmall } from '@/ui/typography';

export const Label = ({
  children,
  htmlFor,
  isRequired,
}: {
  children: string;
  htmlFor: string;
  isRequired?: boolean;
}) => {
  const {
    colors,
    forms: { label: labelStyles },
  } = useTheme();
  return (
    <label
      css={css({
        display: 'block',
        paddingBottom: '8px',
        transition: 'opacity 0.2s ease',
        color: colors.textPrimary,
        ['&.filled']: { opacity: 1 },
        span: labelStyles,
      })}
      htmlFor={htmlFor}
      // Unmask in FullStory
      className="fs-unmask"
    >
      <BodySmall>
        {children}
        {isRequired && <span aria-hidden="true">{'*'}</span>}
      </BodySmall>
    </label>
  );
};

export default Label;
