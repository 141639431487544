import flatten from 'flat';
import { Parser } from 'json2csv';

export const formatDictionaryAsCSV = (dictionary: any) => {
  const flattened = flatten(dictionary) as { [key: string]: string };
  const fields = Object.keys(flattened).map((key) => ({
    'Dictionary Key': key,
    English: flattened[key],
  }));

  return new Parser().parse(fields);
};
