import { css } from '@emotion/react';
import React from 'react';

import { useSpaceMatchingKey, useTheme } from '../theme';

interface CardFlushDividerProps {}

/**
 * This divider offsets itself by the padding of the card so it stretches across the padding.
 * @param props
 */
export const CardFlushDivider = () => {
  const space = useSpaceMatchingKey('s');
  const { colors, keylines } = useTheme();
  return (
    <div
      css={css`
        border-bottom: ${keylines?.border || `1px solid ${colors.primary300}`};
        margin-left: -${space};
        margin-right: -${space};
      `}
    />
  );
};
