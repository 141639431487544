import { V1GetAvailabilityRequest } from '@ennismore/booking-api-client';
import type { V1AvailabilityRequest } from '@ennismore/ows-api-client';
import { V1Guest } from '@ennismore/ows-api-client';

import { OWSBookingAPIBaseService } from '@/api/clients/ows';
import { getBookingGuestFromCount } from '@/hotel/helpers/occupancy-type.helpers';

import {
  AvailabilitySearchError,
  AvailabilitySearchResultsModel,
  IAvailabilitySearchResultsModelInstance,
} from '../models';
import type { AvailabilityServiceClient } from './common';

const V1GuestPatch = {
  ...V1Guest,
  OneAdultTwoChild: 'ONE_ADULT_TWO_CHILD',
} as const;

type V1GuestPatch = (typeof V1GuestPatch)[keyof typeof V1GuestPatch];
interface V1AvailabilityRequestPatch
  extends Omit<V1AvailabilityRequest, 'guest'> {
  guest: V1GuestPatch;
}

export class OWSAvailabilityServiceClient
  extends OWSBookingAPIBaseService
  implements AvailabilityServiceClient
{
  async getAvailability(
    request: V1GetAvailabilityRequest & { locale: { language: string } },
    options: V1AvailabilityRequest['availabilityOptions']
  ): Promise<IAvailabilitySearchResultsModelInstance> {
    // Map new v1 request to legacy request
    const transformedRequest: V1AvailabilityRequestPatch = {
      hotelReferenceId: request.hotelReferenceId,
      from: request.checkInDate,
      to: request.checkOutDate || request.checkInDate, // if no checkout date is available, assume it's a same-day stay
      guest: getBookingGuestFromCount(request.guests) as V1Guest,
      rateCode: request.discountCode ? [request.discountCode] : undefined,
    };

    try {
      const response = await this.client.bookingServiceAvailability(
        {
          ...transformedRequest,
          availabilityOptions: options,
        },
        {
          headers: {
            'Accept-Language': request.locale.language,
            ...this.options.headers,
          },
        }
      );

      const validated = AvailabilitySearchResultsModel.safeParse(response.data);

      if (!validated.success) {
        throw validated.error;
      }

      return validated.data;
    } catch (e) {
      throw new AvailabilitySearchError(this.parseError(e));
    }
  }
}
