import { FontFace } from './FontFace.interface';
import { createCSSFontFaceSourceString } from './createCSSFontFaceSourceString.function';

/**
 * Generates a CSS @font-face definition string from a FontFace object.
 * @param fontFace The font to be rendered
 */
export const createCSSFontFaceDefinition = (
  fontFace: FontFace,
  baseUrl: string
) => {
  const fontSrc = createCSSFontFaceSourceString(fontFace, baseUrl);

  return `
@font-face {
  font-family: ${fontFace.fontFamily};
  font-weight: ${fontFace.fontWeight};
  font-style: ${fontFace.fontStyle || 'normal'};
  font-display: swap;
  src: ${fontSrc};
}`;
};
