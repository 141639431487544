import { Paymentv1PaymentMethod } from '@ennismore/payment-stripe-api-client';

import { PaymentPaymentMethod } from '@/em-api-client-typescript-fetch';

/**
 * PaymentPaymentMethod -> V1PaymentMethod
 */
const PAYMENT_METHOD_TO_V1_PAYMENT_METHOD: Record<
  PaymentPaymentMethod,
  Paymentv1PaymentMethod
> = {
  [PaymentPaymentMethod.UNSPECIFIED]: Paymentv1PaymentMethod.Unspecified,
  [PaymentPaymentMethod.PAYLATER]: Paymentv1PaymentMethod.PayLater,
  [PaymentPaymentMethod.PAYLATERDELAYEDAUTH]:
    Paymentv1PaymentMethod.PayLaterDelayedAuth,
  [PaymentPaymentMethod.PAYNOW]: Paymentv1PaymentMethod.PayNow,
};

export const mapDomainToBufPaymentMethod = (
  paymentMethod: PaymentPaymentMethod
): Paymentv1PaymentMethod => PAYMENT_METHOD_TO_V1_PAYMENT_METHOD[paymentMethod];

export const mapBufToDomainPaymentMethod = (
  paymentMethod: Paymentv1PaymentMethod
): PaymentPaymentMethod =>
  Object.keys(PAYMENT_METHOD_TO_V1_PAYMENT_METHOD).find(
    (key) => PAYMENT_METHOD_TO_V1_PAYMENT_METHOD[key] === paymentMethod
  ) as unknown as PaymentPaymentMethod;
