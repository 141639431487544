import dayjs from 'dayjs';

import {
  IAvailabilitySearchQueryInstance,
  availabilitySearchQuerySelectors,
} from '@/availability/models';

/*
    hotel location|is it a day stay=true/false|check-in|check-out|number of nights
    |number of rooms|number of people|number of adults|number of children|check availability source|code
  */
export const createBookingAnalyticsLabel = (args: {
  hotelAnalyticsSlug: string;
  isDayStay?: boolean;
  checkin: string;
  checkout: string;
  totalNightCount: number;
  totalRoomCount: string | number;
  totalGuestCount: number;
  totalAdultCount: number;
  totalChildCount: number;
}) => {
  return [
    args.hotelAnalyticsSlug,
    args.isDayStay || 'false',
    dayjs(args.checkin).format('DD-MM-YY'),
    dayjs(args.checkout).format('DD-MM-YY'),
    args.totalNightCount,
    args.totalRoomCount,
    args.totalGuestCount,
    args.totalAdultCount,
    args.totalChildCount,
    'booking-engine',
  ].join('|');
};

export const createBookingSearchAnalyticsLabel = ({
  query,
  hotelReferenceId,
}: {
  query: IAvailabilitySearchQueryInstance;
  hotelReferenceId: string;
}) => {
  const selectors = availabilitySearchQuerySelectors(query);
  return createBookingAnalyticsLabel({
    hotelAnalyticsSlug: hotelReferenceId,
    isDayStay: query.dayUse,
    checkin: query.from,
    checkout: query.to,
    totalNightCount: selectors.totalNightCount(),
    totalRoomCount: selectors.roomCount(),
    totalGuestCount: selectors.totalGuestCount(),
    totalAdultCount: selectors.totalAdultCount(),
    totalChildCount: selectors.totalChildCount(),
  });
};
