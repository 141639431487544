import { css } from '@emotion/react';
import React from 'react';

import tick from '../../resources/svg/tick.svg';

export const Tick = () => {
  return (
    <img
      src={tick.src}
      css={css`
        width: 14px;
        height: 14px;
      `}
      alt="Tick"
    />
  );
};

export default Tick;
