import type { EnnismoreTheme } from '@/ui/theme';

const fonts = {
  headline: 'Romie, serif',
  intro: 'Romie, sans-serif',
  subheader: 'Romie, sans-serif',
  body: 'aktiv-grotesk, sans-serif',
};

export const slsTheme: EnnismoreTheme = {
  themeKey: 'sls',
  logoUrl: '/brands/sls/images/logo.svg',
  faviconUrl: '/brands/sls/icons/favicon.ico',
  space: ['0px', '8px', '16px', '20px', '24px', '32px', '40px', '56px'],
  fonts,
  text: {
    heading1: {
      fontFamily: fonts.headline,
      fontWeight: 300,
      textTransform: 'uppercase',
      fontSize: '32px',
      lineHeight: '32px',
    },
    heading2: {
      fontFamily: fonts.headline,
      fontSize: '56px',
      lineHeight: '64px',
    },
    heading3: {
      fontFamily: fonts.headline,
      fontSize: '40px',
      lineHeight: '48px',
    },
    heading4: {
      fontFamily: fonts.headline,
      fontSize: '32px',
      lineHeight: '40px',
    },
    heading5: {
      fontFamily: fonts.headline,
      fontSize: '24px',
      lineHeight: '38px',
      letterSpacing: '0.48px',
      fontWeight: 300,
    },
    heading6: {
      fontFamily: fonts.body,
      fontSize: '16px',
      lineHeight: '38px',
      letterSpacing: '0.32px',
    },
    intro1: {
      fontFamily: fonts.intro,
      fontSize: '40px',
      lineHeight: '56px',
    },
    intro2: {
      fontFamily: fonts.intro,
      fontSize: '24px',
      lineHeight: '40px',
    },
    subheader1: {
      fontFamily: fonts.subheader,
      fontSize: '24px',
      lineHeight: '32px',
    },
    subheader2: {
      fontFamily: fonts.subheader,
      fontSize: '18px',
      lineHeight: '32px',
    },
    subheader3: {
      fontFamily: fonts.subheader,
      fontSize: '14px',
      lineHeight: '24px',
    },
    bodyLarge: {
      fontFamily: fonts.body,
      fontSize: '24px',
      lineHeight: '32px',
    },
    bodyCopy: {
      fontFamily: fonts.body,
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '0.4px',
    },
    bodySmall: {
      fontFamily: fonts.body,
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '0.4px',
    },
    captions: {
      fontFamily: fonts.body,
      fontSize: '14px',
      lineHeight: '16px',
    },
  },
  colors: {
    white: '#fff',
    textPrimary: '#1F2145',
    textSecondary: '#1F2145',
    background: 'linear-gradient(180deg, #9DCCD5 0%, #E0F0F3 100%)',
    primary100: '#FEF9F3',
    primary200: 'white',
    primary300: '#F0E4D7',
    primary400: '#FFE7D9',
    primary500: '#FF9272',
    secondary100: '#F6F6F6',
    secondary200: '#D9D9D9',
    secondary300: '#949494',
    secondary400: '#3B423F',
    error: '#B00020',
  },
  buttons: {
    primary: {
      fontFamily: fonts.subheader,
      backgroundColor: '#1F2145',
      borderRadius: '0px',
      textTransform: 'uppercase',
      fontWeight: 300,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.6px',
      color: 'white',
    },
    secondary: {
      fontFamily: fonts.subheader,
      backgroundColor: 'transparent',
      border: '1px solid #1F2145',
      borderRadius: '0px',
      textTransform: 'uppercase',
      fontWeight: 300,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.6px',
      color: '#1F2145',
    },
  },
  forms: {
    input: {
      borderRadius: '0px',
      letterSpacing: '0.4px',
      backgroundColor: 'white',
      placeholder: {
        color: '#949494',
      },
    },
    label: {
      fontSize: '14px',
      color: '#1F2145',
      lineHeight: '24px',
    },
    checkbox: {
      borderRadius: '0px',
    },
    searchForm: {
      divider: { display: 'block', paddingBottom: '36px', marginTop: '20px' },
    },
    radioButton: {
      description: {
        color: '#1F2145',
      },
      collapsibleContent: {
        padding: '2px 0 0 0',
      },
    },
    heading: {
      fontFamily: 'Romie',
      fontSize: '24px',
      lineHeight: '38px',
      letterSpacing: '0.48px',
      mobile: {
        fontSize: '20px',
      },
    },
    checkoutForm: {
      billingInformationDescription: {
        opacity: 1,
      },
    },
    stripe: {
      tab: {
        border: '1px solid #949494',
        selected: {
          border: '1px solid #1F2145',
        },
      },
    },
  },
  fontFaceDefinitions: [
    {
      fontFamily: 'Romie',
      fontWeight: 400,
      fontStyle: 'normal',
      sources: {
        woff2: '/brands/sls/fonts/RomieEnnismore-Regular.woff2',
      },
    },
    {
      fontFamily: 'aktiv-grotesk',
      fontWeight: 300,
      fontStyle: 'normal',
      sources: {
        woff2:
          'https://use.typekit.net/af/63d81f/000000000000000077359d5a/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3',
      },
    },
    {
      fontFamily: 'aktiv-grotesk',
      fontWeight: 400,
      fontStyle: 'normal',
      sources: {
        woff2:
          'https://use.typekit.net/af/ab3e12/000000000000000077359d4f/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3',
      },
    },
    {
      fontFamily: 'aktiv-grotesk',
      fontWeight: 500,
      fontStyle: 'normal',
      sources: {
        woff2:
          'https://use.typekit.net/af/c11a71/000000000000000077359d5e/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3',
      },
    },
  ],
  keylines: {
    border: '1px solid #1F2145',
  },
  componentProperties: {
    header: {
      backgroundColor: '#9DCCD5',
      boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.20)',
      borderBottom: 'none',
      height: 90,
      logo: {
        borderRight: 'none',
      },
      wizard: {
        step: {
          textTransform: 'uppercase',
          fontFamily: fonts.subheader,
          opacity: 0.6,
          fontWeight: 300,
          fontSize: '14px',
          lineHeight: '26px',
          active: {
            opacity: 1,
          },
        },
      },
      languageAndCurrencySelector: {
        fontFamily: fonts.subheader,
        fontSize: '16px',
      },
    },
    footer: {
      color: '#1F2145',
      fontFamily: fonts.subheader,
      textDecoration: 'none',
      fontSize: '16px',
      fontWeight: 300,
      letterSpacing: '0.36px',
      lineHeight: '16px',
      textTransform: 'uppercase',
      link: {
        marginRight: '40px',
        mobile: {
          marginBottom: '28px',
        },
      },
      copyright: {
        fontSize: '14px',
        fontFamily: fonts.body,
        fontWeight: 400,
        letterSpacing: '0.4px',
        textTransform: 'none',
      },
    },
    addOnCard: {
      border: 'none',
      added: {
        border: '1px solid #1F2145',
      },
      title: {
        fontFamily: fonts.subheader,
        lineHeight: '24px',
        letterSpacing: '0.36px',
      },
      subTitle: {
        fontFamily: fonts.subheader,
        lineHeight: '16px',
        letterSpacing: '0.32px',
      },
      description: {
        color: '#1F2145',
      },
      disclaimer: {
        borderRadius: '0px',
        warning: {
          fontSize: '14px',
          fontFamily: fonts.body,
          fontWeight: 500,
          lineHeight: '24px',
          letterSpacing: '0.4px',
        },
        rateName: {
          fontSize: '14px',
          fontFamily: fonts.body,
          lineHeight: '24px',
          letterSpacing: '0.4px',
          fontWeight: 400,
        },
        rateDescription: {
          fontSize: '14px',
          fontFamily: fonts.body,
          lineHeight: '24px',
          letterSpacing: '0.4px',
          fontWeight: 400,
        },
      },
    },
    calendar: {
      boxShadow: 'none',
      borderRadius: '0px',
      border: '1px solid #1F2145',
      header: {
        caption: {
          textTransform: 'uppercase',
          fontSize: '18px',
          fontFamily: fonts.subheader,
          letterSpacing: '0.36px',
          lineHeight: '38px',
        },
        nav: {
          hover: {
            backgroundColor: '#20224640',
          },
        },
        backgroundColor: '#E0F0F3',
        arrow: {
          stroke: '#1F2145',
          top: '-1px',
        },
      },
      body: {
        date: {
          hover: { backgroundColor: '#20224640' },
          selected: {
            backgroundColor: '#1F2145',
          },
          selectedMiddle: {
            backgroundColor: '#9DCCD5',
          },
        },
      },
    },
    search: {
      collapsibleForm: {
        searchSummary: {
          modifySearchButton: {
            textTransform: 'uppercase',
            fontSize: '12px',
            lineHeight: '12px',
            letterSpacing: '1px',
          },
          mobile: {
            title: { fontSize: '16px', paddingBottom: '0' },
            padding: '14px 16px',
          },
        },
      },
      results: {
        sidebarContainer: {
          cancellationPolicy: {
            backgroundColor: 'transparent',
            borderRadius: '6px',
            lineHeight: '22px',
            mobile: {
              textTransform: 'uppercase',
            },
          },
          hotelPerks: {
            border: 'none',
            backgroundColor: 'rgba(255, 255, 255, 0.50)',
            mobile: {
              backgroundColor: 'white',
              title: {
                fontSize: '18px',
              },
              button: {
                textTransform: 'uppercase',
              },
            },
          },
        },
        bedroomAvailabilityList: {
          rowGap: '40px',
          mobile: {
            rowGap: '20px',
          },
        },
      },
    },
    sidebarCards: {
      secondary: {
        backgroundColor: 'transparent',
      },
    },
    page: {
      title: {
        mobile: {
          textTransform: 'uppercase',
          fontSize: '26px',
        },
      },
    },
    confirmation: {
      mobile: {
        rowGap: '20px',
      },
      headerImage: {
        height: '400px',
        objectFit: 'cover',
        mobile: {
          height: '220px',
        },
        container: {
          paddingBottom: '60px',
          width: '100%',
          margin: '0',
          mobile: {
            paddingBottom: '30px',
          },
        },
      },
      header: {
        backgroundColor: 'white',
        paddingBottom: '32px',
        mobile: {
          paddingBottom: '2px',
        },
      },
      page: {
        background: 'linear-gradient(180deg, #9DCCD5 0%, #E0F0F3 100%)',
      },
      title: {
        textTransform: 'uppercase',
        fontSize: '32px',
        letterSpacing: '0.64px',
        lineHeight: '38px',
        mobile: {
          fontSize: '26px',
        },
      },
      bookingConfirmationContainer: {
        margin: '60px auto auto auto',
        mobile: {
          margin: '40px 16px 0',
        },
      },
      bookingConfirmationAdditionalDetailsContainer: {
        rowGap: '30px',
        mobile: {
          padding: '30px 16px',
        },
      },
      bookingConfirmationDetailsCard: {
        manageMyBookingLink: {
          textTransform: 'uppercase',
          fontSize: '14px',
          letterSpacing: '0.4px',
        },
        customerNameHeading: {
          fontFamily: fonts.subheader,
          fontSize: '18px',
          fontWeight: 300,
          letterSpacing: '0.36px',
        },
      },
      anyQuestionsCard: {
        mobile: {
          padding: '30px 16px 35px',
          margin: '0 16px',
        },
      },
      restaurantsCard: {
        restaurantTitle: {
          fontFamily: fonts.subheader,
          fontSize: '20px',
          mobile: {
            fontSize: '18px',
          },
        },
        mobile: {
          padding: '30px 16px 35px',
          margin: '0 16px',
        },
      },
      whereToFindUsCard: {
        mobile: {
          padding: '30px 16px 35px',
          margin: '0 16px',
        },
      },
    },
    modals: {
      title: {
        textTransform: 'uppercase',
        fontSize: '32px',
        lineHeight: '32px',
        mobile: {
          fontSize: '26px',
          lineHeight: '38px',
          letterSpacing: '0.52px',
        },
      },
    },
    bedroomCard: {
      roomTitle: {
        textTransform: 'uppercase',
      },
      roomSpecItem: {
        color: '#949494',
        value: {
          color: '#1F2145',
        },
      },
      roomRateName: {
        fontFamily: fonts.subheader,
        fontSize: '20px',
        letterSpacing: '0.4px',
        fontWeight: 300,
        lineHeight: '20px',
        paddingBottom: '12px',
        textTransform: 'lowercase',
        firstLetter: {
          textTransform: 'uppercase',
        },
      },
      totalPrice: {
        fontFamily: fonts.subheader,
        fontWeight: 300,
        letterSpacing: '0.48px',
        lineHeight: '38px',
      },
    },
    staySummary: {
      color: '#1F2145',
      border: 'none',
      title: {
        fontFamily: fonts.subheader,
        fontSize: '24px',
        fontWeight: 300,
        lineHeight: '24px',
        letterSpacing: '0.48px',
        mobile: {
          fontSize: '20px',
          lineHeight: '20px',
        },
      },
      summarySection: {
        padding: '30px 24px',
      },
      roomNumberHeading: {
        fontFamily: fonts.subheader,
        fontSize: '18px',
        fontWeight: 300,
        letterSpacing: '0.36px',
        padding: '0 0 16px',
        lineHeight: '18px',
      },
      dataRow: {
        label: { opacity: 1, fontWeight: 300 },
      },
      dropdown: {
        textTransform: 'uppercase',
        opacity: 1,
      },
    },
    checkoutSidebar: {
      cancellationPolicy: { borderRadius: '6px' },
    },
    halfScreenLayout: {
      borderRight: 'none',
    },
    paymentMethods: {
      applePay: {
        borderRadius: '0px',
      },
      googlePay: {
        borderRadius: '0px',
      },
    },
    bookingManagement: {
      title: {
        textTransform: 'uppercase',
        fontSize: '32px',
        mobile: {
          fontSize: '26px',
        },
      },
      bookingCard: {
        border: 'none',
        header: {
          height: '78px',
          borderRadius: '0px',
          borderBottom: '1px solid #1F2145',
          textTransform: 'none',
          cancelled: {
            color: 'white',
            backgroundColor: '#1F2145',
          },
        },
        footer: {
          borderTop: '1px solid #1F2145',
        },
        button: {
          variant: 'secondary',
        },
        cancelled: {
          opacity: 1,
        },
        cancellationModal: {
          padding: '80px 124px',
          borderRadius: '0px',
          textAlign: 'center',
          maxWidth: '65ch',
          mobile: {
            padding: '40px 16px 60px',
            title: {
              fontSize: '26px',
            },
          },
          title: {
            fontFamily: fonts.subheader,
            fontSize: '32px',
            lineHeight: '32px',
            letterSpacing: '0.64px',
            textTransform: 'uppercase',
          },
        },
      },
    },
  },
  icons: {},
  __temporary: {
    displayCarTransportOnNewLine: true,
  },
};
