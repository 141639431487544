export const mockResponse = {
  id: '3115ae41e9cb3156e83d4fd7e33aa49d45283005',
  roomStay: [
    {
      ratePlan: [
        {
          rateCode: 'BARDAILY',
          rateDescription: [
            "Our flexiest room only rate! Don't pay until you stay, free cancellation until 2pm one day before arrival.",
            'Change It Up',
          ],
          cancelPolicy: 'CANCEL BY 04/15/21',
          taxInformation:
            'Tax not included Occupancy Tax 14.00  PCT City of LA LATMD 1.5 PCT California Tourism Assessment Tax .195 PCT ',
          depositPolicy:
            'A deposit is not required for guarantee of your reservation',
          chargeBreakdown: {
            rateCode: { requestRateCode: 'BARDAILY' },
            taxIncluded: 'NO',
            grandTotal: {
              value: 20710,
              decimal: 2,
              currencyCode: 'usd',
            },
            costBreakdown: {
              totalRoomRate: {
                value: 15900,
                decimal: 2,
                currencyCode: 'usd',
              },
              taxesFees: [
                {
                  tax: 'DESTINATION_FEE',
                  amount: {
                    value: 2000,
                    decimal: 2,
                    currencyCode: 'usd',
                  },
                },
                {
                  tax: 'CITY_TAX',
                  amount: {
                    value: 2775,
                    decimal: 2,
                    currencyCode: 'usd',
                  },
                },
                {
                  tax: 'TOURIST_TAX',
                  amount: { value: 35, decimal: 2, currencyCode: 'usd' },
                },
              ],
              totalTaxesFee: {
                value: 4810,
                decimal: 2,
                currencyCode: 'usd',
              },
            },
          },
        },
      ],
      roomDetail: [
        {
          name: 'Snug',
          code: 'SNUG',
          rateCode: 'BARDAILY',
          rateCost: { value: 17900, decimal: 2, currencyCode: 'USD' },
          currencyCode: 'USD',
          images: [
            {
              id: 'ROOM',
              source:
                'https://config-live.enniscdn.net/resources/images/21853524/3265fd1a344d4e1cfd620e11b02cbe6f481c7783.jpg',
              altText:
                'Natural soft light comes in through the large windows onto the bed',
            },
            {
              id: 'ROOM',
              source:
                'https://config-live.enniscdn.net/resources/images/21853524/e48c0cccd417025f1795300d6b802fa492c4fcc9.jpg',
              altText: 'Long gold framed mirror with a light above it',
            },
            {
              id: 'ROOM',
              source:
                'https://config-live.enniscdn.net/resources/images/21853524/e478e67ba3d017d0f32718616d8ab4ff11978940.jpg',
              altText: 'Side table, lamp and telephone beside the bed',
            },
            {
              id: 'ROOM',
              source:
                'https://config-live.enniscdn.net/resources/images/21853524/77057f4c7ec9903794032870298eb0d949840841.jpg',
              altText: 'Angled photo of the bathroom sink and mirror',
            },
          ],
        },
      ],
      from: '2021-04-16',
      to: '2021-04-17',
      profile: {
        id: '17921788',
        firstName: 'Web',
        lastName: 'User',
        phone: '-698173710@ennismore.com',
        email: '-698173710@ennismore.com',
      },
      status: 'RESERVED',
      operaId: '116892478',
      roomGuest: 'TWO_ADULT',
    },
  ],
  checkin: 'HOUR_14_TO_15',
  checkout: 'HOUR_11_TO_12',
  hotel: {
    name: 'The Hoxton, Downtown LA',
    code: '21853524',
    chainCode: 'HOXTON',
    address: '1060 S Broadway',
    city: 'Los Angeles',
    country: 'US',
    postCode: '90015',
    phone: '+1 213 725 5900',
    timeZone: 'America/Los_Angeles',
    checkIn: '02:00',
    checkOut: '12:00',
    currencySymbol: '$',
    images: [
      {
        id: 'HEADER',
        source:
          'https://config-live.enniscdn.net/resources/images/41f1eb7d0b7f3f559385a6531a014c7a3c3e8a48.svg',
        altText:
          'Colourful illustration of two girls reading the paper whilst dancing',
      },
      {
        id: 'HERO',
        source:
          'https://config-live.enniscdn.net/resources/images/21853524/9d04ee2d259c203261307ff34d728f92a58c06a4.jpg',
        altText:
          'Seating area with cocktails in front of the elevators in the hotel lobby',
      },
      {
        id: 'MAP',
        source:
          'https://config-live.enniscdn.net/resources/images/21853524/8c5e0a5b4dc036d6df9099f350f70f6c362a4738.jpg',
        altText: 'Street map of The Hoxton, Downtown LA',
      },
    ],
    restaurants: [
      {
        name: 'Sibling Rival',
        publicUrl:
          'https://thehoxton.com/downtown-la/sibling-rival-restaurant/',
        description:
          'A modern take on the classic diner, brought to you by the team behind New York’s beloved Sunday in Brooklyn.',
        images: [
          {
            id: 'FB',
            source:
              'https://config-live.enniscdn.net/resources/images/21853524/694e8b4c017cecac19bbb613d2fd9ad1fee84359.jpg',
            altText: 'Bar stools and booths at Sibling Rival',
          },
        ],
      },
      {
        name: 'Pilot',
        publicUrl: 'https://thehoxton.com/downtown-la/pilot-restaurant/',
        description:
          'Our rooftop restaurant with coastal vibes, Mediterranean-inspired fare and sweeping views over Broadway.',
        images: [
          {
            id: 'FB',
            source:
              'https://config-live.enniscdn.net/resources/images/21853524/826ff422b840c6c3986599530c9c0e15833c674f.jpg',
            altText: 'Empty seating and plants in the rooftop restaurant',
          },
        ],
      },
    ],
    locations: [
      {
        id: 'PLANE',
        title: 'LAX Airport',
        text: '30 min taxi (no traffic)',
      },
      { id: 'TRAIN', title: 'Union Station', text: '10 min taxi' },
      {
        id: 'PARKING',
        title: 'Bringing the wheels?',
        text: 'We offer valet parking daily - access is available from 6.30am-10.30pm for $39 per day. Friends coming to join you? Visitor parking is $15 for every 2 hours.',
      },
    ],
    supportedCharities: [
      {
        name: 'Skid Row Housing Trust',
        description:
          'We’re helping Skid Row Housing Trust provide permanent housing to those that have experienced homelessness in Los Angeles, so they can rebuild their lives! Every $1 donated will directly support this.',
        logo: {
          id: 'LOGO',
          source:
            'https://config-live.enniscdn.net/resources/images/21853524/44b8eef47f7db57470ec2e7536da2282cec0cd66.jpg',
          altText: 'Skid Row Housing Trust logo',
        },
      },
    ],
    urls: [
      {
        id: 'LOCATION',
        publicUrl: 'https://g.page/the-hoxton-downtown-la?share',
      },
      { id: 'FAQ', publicUrl: 'https://thehoxton.com/downtown-la/faqs/' },
      { id: 'TC', publicUrl: 'https://thehoxton.com/downtown-la/terms/' },
    ],
    emails: [
      {
        id: 'INFO',
        address: 'hello.DTLA@thehox.com',
        name: 'The Hoxton',
      },
      {
        id: 'BOOKING',
        address: 'book.DTLA@thehox.com',
        name: 'The Hoxton',
      },
      {
        id: 'SALES',
        address: 'sales.DTLA@thehox.com',
        name: 'The Hoxton',
      },
    ],
    cancellationAmount: { value: 2500, decimal: 2, currencyCode: 'USD' },
  },
  totalChargeBreakdown: [
    {
      rateCode: { requestRateCode: 'BARDAILY' },
      taxIncluded: 'NO',
      grandTotal: { value: 20710, decimal: 2, currencyCode: 'usd' },
      costBreakdown: {
        totalRoomRate: {
          value: 15900,
          decimal: 2,
          currencyCode: 'usd',
        },
        taxesFees: [
          {
            tax: 'DESTINATION_FEE',
            amount: { value: 2000, decimal: 2, currencyCode: 'usd' },
          },
          {
            tax: 'CITY_TAX',
            amount: { value: 2775, decimal: 2, currencyCode: 'usd' },
          },
          {
            tax: 'TOURIST_TAX',
            amount: { value: 35, decimal: 2, currencyCode: 'usd' },
          },
        ],
        totalTaxesFee: { value: 4810, decimal: 2, currencyCode: 'usd' },
      },
    },
  ],
  metaData: {},
  status: 'CREATED',
  locale: {},
};
