import { EnnismoreTheme } from '../ennismore-theme.interface';
import { DEFAULT_ICONS } from './default-icons.const';

/**
 * Extends theme with default icons.
 */
export const withDefaultIcons = (theme: EnnismoreTheme) => ({
  ...theme,
  icons: { ...DEFAULT_ICONS, ...theme.icons },
});
