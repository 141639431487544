import { css } from '@emotion/react';
import { animated } from '@react-spring/web';
import React from 'react';

import leftTriangle from '../../../resources/svg/triangle-left.svg';
import rightTriangle from '../../../resources/svg/triangle-right.svg';

interface SelectionDragIndicatorProps {
  direction: 'left' | 'right';
  onClick?: () => void;
}

export const SelectionDragIndicator = (props: SelectionDragIndicatorProps) => {
  const isRight = props.direction === 'right';

  const sharedStyles = css`
    position: absolute;
    background: transparent;
    border: none;
    color: white;
    background: black;
    top: 5px;
    cursor: pointer;

    font-size: 8px;
    line-height: 8px;
    z-index: 90;

    &:focus {
      outline: 1px solid #8f8f8f;
    }
  `;

  const leftStyles = css`
    ${sharedStyles}
    left: -11px;
    border-radius: 20px 0px 0px 20px;
    padding: 8px 10px 7px 3px;
    top: 7px;
  `;

  const rightStyles = css`
    ${sharedStyles}
    top: -33px;
    right: -11px;
    border-radius: 0 20px 20px 0;
    padding: 8px 3px 7px 10px;
  `;

  return (
    <div
      css={css`
        position: relative;
      `}
    >
      <animated.button
        title={`Select ${isRight ? 'next' : 'previous'} date`}
        css={isRight ? rightStyles : leftStyles}
        onClick={props.onClick}
      >
        <img src={isRight ? rightTriangle.src : leftTriangle.src} alt="" />
      </animated.button>
    </div>
  );
};

export default SelectionDragIndicator;
