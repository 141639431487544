import React from 'react';

import {
  IAvailabilitySearchQueryInstance,
  IAvailabilitySearchResultsModelInstance,
  availabilitySearchQuerySelectors,
} from '@/availability/models';
import { useActiveBrandConfig } from '@/brand';
import {
  FullHouseStructuredData,
  HotelRoomOffersStructuredData,
  HotelStructuredData,
  LodgingSearchStructuredData,
} from '@/data';
import { useHotel } from '@/hotel';
import { getCountryName } from '@/places';

/**
 * Adds current search-related JSON-LD to the page header.
 */
export const SearchStructuredDataContainer: React.FC<{
  query: IAvailabilitySearchQueryInstance;
  results?: IAvailabilitySearchResultsModelInstance;
  hotelReferenceId: string;
  roomIndex: number; // Used to track which room the user is selecting
}> = ({ query, results, hotelReferenceId, roomIndex }) => {
  const { name: brandName } = useActiveBrandConfig();
  const hotel = useHotel(hotelReferenceId);
  const querySelectors = availabilitySearchQuerySelectors(query);

  if (!hotel) return null;

  return (
    <>
      {results && hotel && (
        <>
          <HotelStructuredData
            name={hotel.name}
            identifier={hotel.code}
            brand={brandName}
            telephone={hotel.contact.phone}
            image={hotel.content.images.hero?.source}
            address={{
              streetAddress: hotel.location.address.lines.join(', '),
              addressRegion: hotel.location.address.city,
              postalCode: hotel.location.address.postcode,
              addressCountry: getCountryName(
                hotel.location.address.countryCode
              ),
            }}
          />
          {/*  Renders one <HotelRoomOffersStructuredData /> component per room, per rate code. */}
          {results.rooms.flatMap((room) =>
            room.availability
              .filter(
                (availability) =>
                  availability.bookable.value &&
                  // filter out inaccessible rooms if the user is looking for accessible rooms
                  ((query.rooms[roomIndex].accessible &&
                    room.isRoomAccessible) ||
                    // however proceed without filtering accessible rooms if the param isn't set in the URL
                    !query.rooms[roomIndex].accessible)
              )
              .map((availability) => (
                <HotelRoomOffersStructuredData
                  key={`${room.room.code}-${availability.rateCode.requestRateCode}`}
                  // Rate code
                  identifier={availability.rateCode.requestRateCode}
                  itemOffered={{
                    name: room.room.name,
                    identifier: room.room.code,
                  }}
                  priceSpecification={{
                    price: availability.grandTotal?.fixedValue || '',
                    priceCurrency:
                      availability.grandTotal?.currencyCode.toUpperCase() || '',
                  }}
                  name={availability.rateCode.name || ''}
                />
              ))
          )}
          {results.isHotelFullyBooked && <FullHouseStructuredData />}
        </>
      )}
      <LodgingSearchStructuredData
        checkinTime={query.from}
        checkoutTime={query.to}
        numAdults={querySelectors.totalAdultCount().toString()}
        numChildren={querySelectors.totalChildCount().toString()}
        numRooms={querySelectors.roomCount().toString()}
      />
    </>
  );
};

export default SearchStructuredDataContainer;
