/* eslint-disable react/no-unused-prop-types */

/* ^ Passing props down causes eslint to think they're unused */
import React from 'react';

import { PageStructuredData } from '.';
import { createStructuredDataObject } from './createStructuredDataObject.helper';

interface LodgingSearchStructuredDataProps {
  checkinTime: string;
  checkoutTime: string;
  numAdults: string;
  numChildren: string;
  numRooms: string;
}

/**
 * Adds LodgingSearch JSON-LD to the page <head />.
 * @param props
 */
export const LodgingSearchStructuredData = (
  props: LodgingSearchStructuredDataProps
) => {
  return (
    <PageStructuredData
      data={createStructuredDataObject('LodgingSearch', props)}
    />
  );
};

export default LodgingSearchStructuredData;
