import { z } from 'zod';

import { BookingTaxType } from '@/em-api-client-typescript-fetch';

import { getHumanTaxTypeNameKey } from '../helpers/getHumanTaxTypeName.func';
import { FinancialAmountModel } from './financial-amount.model';

export type TaxInstance = z.infer<typeof TaxModel>;
export type TaxInput = z.input<typeof TaxModel>;

export const TaxModel = z
  .object({
    /**
     * `tax` in this context refers to the legacy "tax type" enum, used for OWS.
     * The taxes service will supply translated name/description instead.
     * @deprecated Not used for OHIP
     */
    tax: z.optional(z.nativeEnum(BookingTaxType)),
    amount: z.optional(FinancialAmountModel),
    /**
     * Human-readable, translated tax name (eg. `VAT` or `City Tax`).
     * If this is not set, the UI will attempt to resolve a translation string
     * matching the `tax type`.
     */
    name: z.string().optional(),
    description: z.string().optional(),
  })
  .transform((self) => ({
    ...self,
    /**
     * @deprecated Not used for OHIP - transition to name
     */
    get humanTaxNameKey() {
      return self.tax ? getHumanTaxTypeNameKey(self.tax) : undefined;
    },
    /**
     * @deprecated Not used for OHIP - transition to description
     */
    get helpTextKey() {
      switch (self.tax) {
        case BookingTaxType.DESTINATIONFEE:
          return 'destinationFee';
        case BookingTaxType.EMPLOYEEPROTECTION:
          return 'employeeProtection';
        default:
          return undefined;
      }
    },
  }));

export const createTaxModel = (tax: TaxInput) => TaxModel.parse(tax);
