import React from 'react';

import { useTranslation } from '@/i18n';

import { AvailabilitySearchErrorCard } from './AvailabilitySearchErrorCard.component';

interface FullHouseNoAvailabilityCardProps {
  onModifySearchRequested: () => void;
}

export const FullHouseNoAvailabilityCard = (
  props: FullHouseNoAvailabilityCardProps
) => {
  const { t } = useTranslation('errors');

  return (
    <AvailabilitySearchErrorCard
      title={t('fullHouseNoAvailabilityTitle')}
      subtitle={t('fullHouseNoAvailabilitySubtitle')}
      description={t('fullHouseNoAvailabilityBody')}
      onModifySearchRequested={props.onModifySearchRequested}
    />
  );
};
