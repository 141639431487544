import { useTheme } from '.';
import type { EnnismoreThemeIcons } from '../ennismore-theme.interface';

export const useIcon = (key?: EnnismoreThemeIcons) => {
  const { icons } = useTheme();

  if (!key) return icons.BULLET;

  return icons[key] ?? icons.BULLET;
};
