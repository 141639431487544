import { z } from 'zod';

import { ImageModel } from '@/common/models/image.model';

import { BedroomDescription } from './bedroom-description.model';
import { HotelRoomExtraModel } from './hotel-room-extra.model';

export type IHotelRoom = typeof HotelRoom;
export type IHotelRoomInstance = z.infer<IHotelRoom>;

const baseSchema = z
  .object({
    code: z.string(),
    name: z.string(),
    images: z.array(ImageModel).default([]),
    roomDescription: z.array(BedroomDescription).default([]),
    twin: z.boolean().default(false),
    extras: z.array(HotelRoomExtraModel).default([]),
    header: z.string().optional(),
    accessibility: z
      .object({
        content: z.string(),
        totalRooms: z.number(),
      })
      .optional(),
    filters: z
      .object({
        accessible: z.boolean(),
      })
      .optional(),
  })
  .transform((self) => ({
    ...self,
    get description(): string | undefined {
      return self.roomDescription[0]?.description;
    },
  }));

export const HotelRoom = baseSchema.and(
  z.object({
    variations: z.record(baseSchema).optional(),
  })
);
