import { css } from '@emotion/react';
import React from 'react';

interface BackButtonProps {
  onClick: () => void;
}

export const BackButton = (props: BackButtonProps) => {
  return (
    <button
      css={css`
        height: 100%;
        touch-action: manipulation;
      `}
      aria-label="Go back"
      onClick={props.onClick}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14 7L8 12L14 17"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  );
};

export default BackButton;
