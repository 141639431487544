import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { Global, css } from '@emotion/react';
import React from 'react';

import { AvailableThemeKeys } from '.';
import FontProvider from '../typography/FontProvider.component';
import { EnnismoreThemeContext } from './ennismore-theme.context';
import { EnnismoreTheme } from './ennismore-theme.interface';
import { getTheme } from './getTheme.function';
import { withDefaultIcons } from './icons';

const getChakraTheme = () => {
  const config = {
    cssVarPrefix: 'em',
  };

  return {
    ...extendTheme({ config }),
    // Why are we not passing the breakpoints to config?
    // For some reason, extendTheme() doesn't pass them through...
    breakpoints: {
      base: '0em',
      sm: '40em',
      md: '48em',
      lg: '62em',
      xl: '80em',
      '2xl': '96em',
    },
  };
};

const themeOrKey = ({
  themeObject,
  themeKey,
}: {
  themeObject?: EnnismoreTheme;
  themeKey?: AvailableThemeKeys;
}): EnnismoreTheme => {
  if (themeObject) {
    return themeObject;
  }

  if (themeKey) {
    return getTheme(themeKey);
  }

  throw new Error('Either `theme` or `themeKey` prop is required');
};

export const ThemeProvider = ({
  children,
  themeKey,
  theme: themeObject,
}: {
  children: React.ReactNode;
  themeKey?: AvailableThemeKeys;
  theme?: EnnismoreTheme;
}) => {
  const theme = themeOrKey({ themeObject, themeKey });
  const chakraTheme = getChakraTheme();

  return (
    <FontProvider fontFaceDefinitions={theme.fontFaceDefinitions}>
      <EnnismoreThemeContext.Provider value={withDefaultIcons(theme)}>
        <ChakraProvider theme={chakraTheme}>
          <Global
            styles={css`
              body {
                background: ${theme.colors.background};
              }
            `}
          />
          {children}
        </ChakraProvider>
      </EnnismoreThemeContext.Provider>
    </FontProvider>
  );
};

export default ThemeProvider;
