import React from 'react';

import { useErrorTranslation } from '@/errors';

import { AvailabilitySearchErrorCard } from '../components';
import { AvailabilitySearchError } from '../models/availability-search.error';

/**
 * Renders a "Room Card"-shaped translated error message card.
 */
export const BedroomAvailabilitySearchErrorCard: React.FC<{
  error: AvailabilitySearchError;
  onOpenSearchFormRequested: () => void;
  onRetryRequested?: () => void;
}> = ({ error, onOpenSearchFormRequested, onRetryRequested }) => {
  const getErrorTranslation = useErrorTranslation();

  const {
    messageTranslationKey,
    titleTranslationKey,
    isRecoverableByModifyingSearch,
    isRetryable,
  } = error;

  return (
    <AvailabilitySearchErrorCard
      description={getErrorTranslation(messageTranslationKey || 'generic')}
      title={
        titleTranslationKey
          ? getErrorTranslation(titleTranslationKey)
          : undefined
      }
      onModifySearchRequested={
        isRecoverableByModifyingSearch ? onOpenSearchFormRequested : undefined
      }
      onRetryRequested={
        isRetryable && onRetryRequested ? onRetryRequested : undefined
      }
    />
  );
};
