import dayjs from 'dayjs';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { API_DATE_FORMAT } from '@/api';
import { useLocale } from '@/i18n';

import { DatePicker, DatePickerProps } from './DatePicker.component';

interface SingleDatePickerFieldProps extends Partial<DatePickerProps> {}

export const SingleDatePickerField: React.FC<SingleDatePickerFieldProps> = (
  props
) => {
  const { setValue, watch } = useFormContext<{
    from?: string;
    to?: string;
  }>();
  const locale = useLocale();

  const from = watch('from');

  return (
    <DatePicker
      singleDate
      startDate={from}
      onStartDateChange={(day?: Date) => {
        setValue('from', day ? dayjs(day).format(API_DATE_FORMAT) : undefined);
        setValue('to', day ? dayjs(day).format(API_DATE_FORMAT) : undefined);
      }}
      locale={locale}
      {...props}
    />
  );
};
