import { EnnismoreTheme } from '../ennismore-theme.interface';

export const DEFAULT_ICONS = {
  BULLET: '/icons/diamond-bullet.svg',

  /**
   * Travel / Transport icons
   */
  PLANE: '/icons/plane.svg',
  TRAIN: '/icons/train.svg',
  PARKING: '/icons/car.svg',
  TRAM: '/icons/tram.svg',
  CHAUFFEUR: '/icons/chauffeur.svg',
  HELICOPTER: '/icons/helicopter.svg',

  /**
   * Hotel Perks
   */
  ADA: '/icons/accessibility.svg',
  WIFI: '/icons/wifi.svg',
  SNOOZE: '/icons/late-snooze.svg',
  SWEET: '/icons/sweet.svg',
  BREAKFAST: '/icons/breakfast-bag.svg',
  DOG: '/icons/dog.svg',
  GYM: '/icons/gym.svg',
  POOL: '/icons/pool.svg',
  NIGHTCLUB: '/icons/nightclub.svg',
  BEACH: '/icons/beach.svg',
  RESTAURANT: '/icons/restaurant.svg',
  TOURS: '/icons/tours.svg',
  TRANSFERS: '/icons/transfers.svg',
  BAR: '/icons/bar.svg',
  BEDDING: '/icons/bedding.svg',
  PILLOW_MENU: '/icons/pillow_menu.svg',
  TEA_COFFEE: '/icons/tea_coffee.svg',
  LOCATION: '/icons/location.svg',
  VALET: '/icons/valet.svg',

  /**
   * Room extras
   */
  CAPACITY: '/icons/room-capacity.svg',
  ROOM_WITH_VIEW: '/icons/room-view-icon.svg',
  DOG_FRIENDLY: '/icons/dog.svg',
  BED_SIZE: '/icons/bed-size-icon.svg',
  SIZE: '/icons/room-size-icon.svg',
  EXTRAS1: '/icons/extra.svg',
  EXTRAS2: '/icons/extra.svg',
  EXTRAS3: '/icons/extra.svg',
  BIKE: '/icons/bike.svg',
  TERRACE: '/icons/terrace.svg',
  TV_MEDIA: '/icons/media.svg',
  BATHROOM: '/icons/bathroom.svg',
} satisfies EnnismoreTheme['icons'];
