import { css } from '@emotion/react';
import React from 'react';

import CopyModal from '@/core/components/controls/CopyModal.component';
import { useTranslation } from '@/i18n';
import { Markdown } from '@/markdown';
import { Spacer } from '@/ui/spacing';
import { BodyCopy, Heading } from '@/ui/typography';

interface CancellationPolicyModalProps {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  content: string;
}

export const CancellationPolicyModal = (
  props: CancellationPolicyModalProps
) => {
  const { t } = useTranslation('cancellationPolicy');

  return (
    <CopyModal
      isOpen={props.isModalOpen}
      onRequestClose={() => props.setIsModalOpen(false)}
    >
      <div
        css={css`
          max-width: 540px;
        `}
      >
        <div
          css={css`
            // TODO: Not a very clean fix, but fixes the title and the modal close button overlapping on mobile.
            padding-right: 20px;
          `}
        >
          <Heading style="heading5" as="h5">
            {t('title')}
          </Heading>
        </div>
        <Spacer s="s" />
        <BodyCopy>
          <Markdown source={props.content} />
        </BodyCopy>
      </div>
    </CopyModal>
  );
};
