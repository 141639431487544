import { css } from '@emotion/react';
import React from 'react';
import ReactMarkdown from 'react-markdown';

import { AnchorLink } from '@/ui/controls';
import { Text } from '@/ui/typography';

const useSpacingStyles = () => css`
  margin-bottom: 1em;

  &:last-child {
    margin-bottom: 0;
  }
`;

const LinkRenderer = ({
  href,
  children,
  title,
}: {
  href: string;
  children: string[];
  title: string;
}) => {
  return (
    <AnchorLink openInNewTab href={href} title={title}>
      {children}
    </AnchorLink>
  );
};

const ParagraphRenderer = (props: { children: React.ReactNode | string }) => {
  return <p css={useSpacingStyles()}>{props.children}</p>;
};

const ListRenderer = (props: { children: React.ReactNode | string }) => (
  <ul css={useSpacingStyles()}>{props.children}</ul>
);

const HeadingRenderer = (props: {
  children: React.ReactNode | string;
  level: number;
}) => {
  const HeadingTag = `h${props.level}` as any;
  return (
    <HeadingTag>
      <Text style={`headline${props.level}` as any}>{props.children}</Text>
    </HeadingTag>
  );
};

interface MarkdownProps {
  source: string;
}

export const Markdown = (props: MarkdownProps) => {
  return (
    <ReactMarkdown
      source={props.source}
      renderers={{
        link: LinkRenderer,
        paragraph: ParagraphRenderer,
        heading: HeadingRenderer,
        list: ListRenderer,
      }}
    />
  );
};

export default Markdown;
