import React from 'react';

import StepProgress from '@/booking/components/wizard/StepProgress.component';
import { ResponsiveConditional } from '@/common';
import { MobileWrapperPadding } from '@/ui/layout';
import { Spacer } from '@/ui/spacing';
import { Heading } from '@/ui/typography';

interface CheckoutStepHeadingProps {
  totalStepCount: number;
  currentStepNumber: number;
  title: string;
  alignDesktopTitle?: 'left' | 'center' | 'right' | undefined;
}

export const CheckoutStepHeading: React.FC<CheckoutStepHeadingProps> = ({
  title,
  totalStepCount,
  currentStepNumber,
  alignDesktopTitle,
}) => {
  return (
    <ResponsiveConditional
      desktop={
        <>
          <Spacer s="xxl" />
          <Heading style="heading1" as="h1" textAlign={alignDesktopTitle}>
            {title}
          </Heading>
          <Spacer s="xxl" />
        </>
      }
      mobile={
        <>
          <MobileWrapperPadding>
            <Spacer s="m" />
            <StepProgress total={totalStepCount} current={currentStepNumber} />
            <Spacer s="m" />
            <Heading style="heading1" as="h1">
              {title}
            </Heading>
            <Spacer s="m" />
          </MobileWrapperPadding>
        </>
      }
    />
  );
};
