import { css } from '@emotion/react';
import React from 'react';

import { useTheme } from '@/ui/theme';
import { Caption } from '@/ui/typography';

interface FieldErrorMessageProps {
  fieldName: string | undefined;
  message?: string;
  isVisible?: boolean;
}

export const FieldErrorMessage = (props: FieldErrorMessageProps) => {
  const { colors } = useTheme();
  return (
    <div
      css={css`
        margin-top: 5px;
        min-height: 20px;
        visibility: ${props.isVisible ? 'visible' : 'hidden'};
        span {
          color: ${colors.error};
        }
      `}
      id={`field-${props.fieldName}-error`}
    >
      <Caption color="error">{props.message}</Caption>
    </div>
  );
};
