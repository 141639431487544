import Cookies from 'js-cookie';
import Router from 'next/router';
import { useEffect } from 'react';

import { useActiveBrandConfig } from '@/brand';

/**
 * If canary mode is enabled for this brand, set cookie to canary=never and redirect the user to the fallback URL.
 */
export const useCanaryResetAndRedirect = () => {
  const { isCanary, canaryFallbackUrl } = useActiveBrandConfig();

  useEffect(() => {
    if (isCanary && canaryFallbackUrl) {
      Cookies.set('canary', 'never');
      Router.push(canaryFallbackUrl);
    } else {
      Router.push('/');
    }
  });
};
