import React from 'react';

import { LoggingProvider, useLogger } from '@/logging';

import type { BrandConfiguration } from '..';
import { BrandContext } from '../contexts';

interface BrandProviderProps {
  brandConfig: BrandConfiguration;
  children: React.ReactNode;
}

export const BrandProvider: React.FC<BrandProviderProps> = (props) => {
  const logger = useLogger();

  const brandLogger = logger.child({ brand: props.brandConfig.chainCode });

  return (
    <BrandContext.Provider value={props.brandConfig}>
      <LoggingProvider logger={brandLogger}>{props.children}</LoggingProvider>
    </BrandContext.Provider>
  );
};
