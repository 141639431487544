import { z } from 'zod';

import { FinancialAmountModel, TaxModel } from '@/finance';

import { BookingAddOnModel } from './booking-add-on.model';

export type IRoomAddOnsInstance = z.infer<typeof RoomAddOnsModel>;
export type IRoomAddOnsSnapshot = z.input<typeof RoomAddOnsModel>;

export const RoomAddOnsModel = z.object({
  detail: z.array(BookingAddOnModel),
  cost: z
    .object({
      amountAfterTax: FinancialAmountModel,
      amountBeforeTax: FinancialAmountModel,
      amountSubtotalDisplay: FinancialAmountModel,
      taxesFees: z.object({
        amount: FinancialAmountModel.optional(),
        detail: z.array(TaxModel).default([]),
      }),
    })
    .optional(),
});
