import loglevel, { LogLevelDesc } from 'loglevel';
import pino from 'pino';

function getLogLevel() {
  // Environment should take precedence
  const envLogLevel = process.env.NEXT_PUBLIC_LOG_LEVEL;
  if (envLogLevel) return envLogLevel;

  switch (process.env.NEXT_PUBLIC_ENVIRONMENT) {
    case 'local':
    case 'dev':
      return 'debug';
    case 'live':
    default:
      return 'info';
  }
}

export const defaultLogger = pino({
  level: getLogLevel(),
}).child({ env: process.env.NEXT_PUBLIC_ENVIRONMENT });

export const getLogger = () => {
  return defaultLogger;
};

((global as any).window || {})._logging = {
  enableTrace() {
    loglevel.enableAll();
  },
  resetLogLevel() {
    loglevel.setLevel('warn');
  },
  setLevel(level: LogLevelDesc) {
    loglevel.setLevel(level);
  },
};
