import React from 'react';

import { DataTable } from '@/core/components/layout';
import DataTableRow from '@/core/components/layout/DataTableRow.component';
import { CurrencyPreview } from '@/currency-preview';
import type { IFinancialAmountInstance } from '@/finance';

export interface TaxTotalsProps {
  taxes: {
    name: string;
    amount?: IFinancialAmountInstance;
    helpText?: string;
  }[];
}

export const TaxTotals = (props: TaxTotalsProps) => {
  return (
    <DataTable>
      {props.taxes.map((tax) => (
        <DataTableRow
          key={`${tax.name}-${tax.amount?.fixedValue}`}
          label={tax.name}
          value={<CurrencyPreview amount={tax.amount} />}
          helpText={tax.helpText}
          // NOTE: Only add a colon if there's no help icon visible
          hideColon={Boolean(tax.helpText)}
          testId="tax"
        />
      ))}
    </DataTable>
  );
};

export default TaxTotals;
