/**
 * Returns two-letter language code from full locale.
 */
export const getLanguageFromLocale = (locale: Intl.BCP47LanguageTag) => {
  try {
    return new Intl.Locale(locale).language;
  } catch (_err: unknown) {
    return null;
  }
};
