import { z } from 'zod';

import { DomaincommonRateCodeType } from '@/em-api-client-typescript-fetch';

import { FinancialAmountModel } from './financial-amount.model';

export type IRateCode = typeof RateCodeModel;
export type IRateCodeInstance = z.infer<IRateCode>;
export type IRateCodeSnapshot = z.input<IRateCode>;

export const RateCodeModel = z
  .object({
    requestRateCode: z.string(),
    internalRateCode: z.string().optional(),
    description: z.string().optional(),
    // 'name' should be a required field, but it's not supplied in the calendar model
    name: z.string().optional(),
    /**
     * Is this before or after tax?
     */
    amount: FinancialAmountModel.optional(),
    mandatoryDeposit: z.boolean().default(false),
    type: z.nativeEnum(DomaincommonRateCodeType).optional(),
  })
  .transform((self) => ({
    ...self,
    /**
     * A rate code is deemed to be a 'discount code' if it's either of type PROMOTION or DISCOUNT.
     */
    get isDiscount() {
      return (
        self.type === DomaincommonRateCodeType.PROMOTION ||
        self.type === DomaincommonRateCodeType.DISCOUNT
      );
    },
    /**
     * If `name` is null, the rate code is usually unavailable.
     * This is because Opera doesn't return any info about the code if it's not available.
     */
    get nameOrDefault() {
      return self.name || self.requestRateCode;
    },
    /**
     * If the description is null, the rate code is unavailable.
     */
    get descriptionOrDefault() {
      return (
        self.description ??
        'Sorry, this rate isn’t available for your dates or doesn’t apply for this room type.'
      );
    },
  }));
