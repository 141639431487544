import { css } from '@emotion/react';
import React from 'react';

import { useTheme } from '../theme';

interface HalfScreenLayoutProps {
  left: React.ReactNode;
  right: React.ReactNode;
}

export const HalfScreenLayout = ({ left, right }: HalfScreenLayoutProps) => {
  const {
    space,
    colors,
    keylines,
    componentProperties: { halfScreenLayout },
  } = useTheme();
  return (
    <main
      css={css`
        display: flex;

        // Expand to fit the full height of the viewport, less the height of the sticky Header.
        min-height: calc(100vh - 61px);
      `}
      id="maincontent"
    >
      <div
        css={css`
          width: 50%;
          border-right: ${halfScreenLayout?.borderRight ??
          keylines?.border ??
          `1px solid ${colors.primary300}`};
          padding: 0 ${space[3]} 0 ${space[3]};

          @media all and (max-width: 720px) {
            display: none;
          }
        `}
      >
        {left}
      </div>
      <div
        css={css`
          width: 50%;
          padding: 0 ${space[3]} 0 ${space[3]};

          @media all and (max-width: 720px) {
            width: 100%;
            padding: 0;
          }
        `}
      >
        {right}
      </div>
    </main>
  );
};
