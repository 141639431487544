import QRCode from 'qrcode.react';
import React, { useState } from 'react';
import { useInterval } from 'react-use';

import { useBooking } from '@/booking';
import { useBookingUrls } from '@/booking/helpers/url';
import type { BookingRequest } from '@/booking/interfaces';
import { useTheme } from '@/ui/theme/hooks';

interface CheckoutHandoffProps extends BookingRequest {}

export const CheckoutHandoff: React.FC<CheckoutHandoffProps> = (props) => {
  const booking = useBooking(props);
  const [, setCount] = useState(Date.now());
  const { colors } = useTheme();

  const urls = useBookingUrls(props);

  useInterval(() => {
    setCount(() => Date.now());
  }, 5000);

  if (!booking) {
    return null;
  }

  return (
    <QRCode
      value={
        `${location.origin}${urls('additional-information')}` +
        `#T-${Date.now()}`
      }
      bgColor={colors.background}
      fgColor={colors.textPrimary}
    />
  );
};
