import { observer } from 'mobx-react-lite';
import Head from 'next/head';
import React from 'react';

import { useTheme } from '../theme';

export const Favicons = observer(() => {
  const { faviconUrl } = useTheme();

  return (
    <Head>
      <link rel="icon" href={faviconUrl} type="image/png" />
    </Head>
  );
});

export default Favicons;
