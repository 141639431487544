import * as Sentry from '@sentry/react';
import { useEffect } from 'react';

/**
 * Sets the Sentry scope information for the booking.
 * @param param0 Booking information
 */
export const useSentryScope = ({
  bookingId,
  hotelSlug,
}: {
  bookingId: string;
  hotelSlug: string;
}) => {
  useEffect(() => {
    Sentry.configureScope((scope) => {
      scope.setTags({
        bookingId,
        hotelSlug,
      });
    });
  }, [hotelSlug, bookingId]);
};
