import { proxy } from 'valtio';
import { useProxy } from 'valtio/utils';

type PaymentState = 'idle' | 'submitting';

const statuses = proxy<Record<string, PaymentState>>({});

export const useBookingPaymentStatus = (bookingId: string) => {
  const state = useProxy(statuses);

  const setStatus = (paymentState: PaymentState) => {
    state[bookingId] = paymentState;
  };

  const status = state[bookingId] || 'idle';

  return [status, setStatus] as const;
};
