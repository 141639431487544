import { useEffect } from 'react';

export const useKeyPress = (handler: (event: KeyboardEvent) => void) => {
  useEffect(() => {
    if (!process.browser) {
      return;
    }

    document.addEventListener('keydown', handler);
    return () => document.removeEventListener('keydown', handler);
  }, []);
};
