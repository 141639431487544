import { APIDialect } from '@/api';
import { EnnismoreAPIClientConfig } from '@/api/interfaces';

import type { BookingServiceClient } from './common';
import { OHIPBookingServiceClient } from './ohip-booking.service';
import { OWSBookingServiceClient } from './ows-booking.service';

export const getBookingServiceClient = (
  config: EnnismoreAPIClientConfig,
  dialect: APIDialect
): BookingServiceClient => {
  switch (dialect) {
    case 'ohip':
      return new OHIPBookingServiceClient(config);
    case 'ows':
      return new OWSBookingServiceClient(config);
  }
};
