import { Global, css } from '@emotion/react';
import React from 'react';

import { createCSSFontImports } from '@/ui/typography/createCSSFontImports.function';

import { FontFace } from './FontFace.interface';
import { createCSSFontFaceDefinition } from './createCSSFontFaceDefinition.function';

/**
 * Provides CSS font loading for given font face definitions.
 * @param param0
 */
export const FontProvider = ({
  children,
  fontFaceDefinitions,
}: {
  children: React.ReactNode;
  fontFaceDefinitions: FontFace[];
}) => {
  const fontFaceCSSDefinition = fontFaceDefinitions.map((def) =>
    createCSSFontFaceDefinition(def, document.location.origin)
  );

  const externalImportStatements = createCSSFontImports(fontFaceDefinitions);
  return (
    <>
      <Global
        styles={css`
          * {
            text-rendering: optimizeLegibility;
          }
          ${externalImportStatements}
          ${fontFaceCSSDefinition}
        `}
      />
      {children}
    </>
  );
};

export default FontProvider;
